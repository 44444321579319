import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Antd
import { Form, Input, Switch, Space, Button, Tooltip } from "antd";
import {
  SaveOutlined,
  ExclamationCircleOutlined,
  CheckCircleTwoTone,
  WifiOutlined,
} from "@ant-design/icons";

// external functions
import {
  getModemAPN,
  getModemStatus,
  toggleModemStatus,
  setModemAPN,
  getModemReset,
} from "../../actions/utl";

const { TextArea } = Input;

// Actual Componet
const TabModem = ({
  permissions,
  modemtoggling,
  apnSetting,
  setModemAPN,
  toggleModemStatus,
  getModemStatus,
  getModemAPN,
}) => {
  const [modemForm] = Form.useForm();
  const [rstButtonLoading, setRstButtonLoading] = useState(false);
  const [modemformData, setFormData] = useState({
    SIMFailure: true,
    SIMMessage: "",
    sigStrength: 0,
  });
  const intervalRef = useRef();

  useEffect(() => {
    const modemAPN = async () => {
      const apn = await getModemAPN();

      modemForm.setFieldsValue({
        API: apn,
      });
    };

    modemAPN();
  }, [modemForm, getModemAPN]);

  const modemStatus = useCallback(async () => {
    const status = await getModemStatus();

    if (status) {
      modemForm.setFieldsValue({
        ModemStatus: status.message,
      });
      setFormData({
        ...modemformData,
        ModemEnable: status.active,
        SIMFailure: status && status.sim && status.sim.isError,
        SIMMessage: status && status.sim && status.sim.message,
        sigStrength: status && status.signal === undefined ? 0 : status.signal,
      });
    }
  }, [modemformData, modemForm, getModemStatus]);

  useEffect(() => {
    if (intervalRef.current === undefined) {
      intervalRef.current = setInterval(async () => {
        await modemStatus();
      }, 3000);
    }

    return () => {
      clearInterval(intervalRef.current);
      intervalRef.current = undefined;
    };
  }, [modemStatus]);

  const handleDefaults = () => {
    modemForm.setFieldsValue({
      API: "apn.trintel.co.za",
    });
  };

  const handleSave = () => {
    const newAPN = modemForm.getFieldValue("API");

    setModemAPN(newAPN);
  };

  const handleSwitch = () => {
    toggleModemStatus();
  };

  const handleReset = () => {
    setRstButtonLoading(true);
    getModemReset();
    setTimeout(() => {
      setRstButtonLoading(false);
    }, 5000);
  };

  return (
    <Form
      form={modemForm}
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 5 }}
      size="small"
    >
      <Form.Item label="API" name="API" style={{ marginBottom: "10px" }}>
        <Input
          disabled={
            apnSetting || (permissions && permissions.write) ? false : true
          }
        />
      </Form.Item>
      <Form.Item
        label="Modem"
        name="ModemEnable"
        style={{ marginBottom: "10px" }}
        tooltip={
          "This is the current state of the modem, click on it to change state, it might take a while to change after clicking"
        }
      >
        <Switch
          checkedChildren="On"
          unCheckedChildren="Off"
          loading={modemtoggling}
          size="default"
          checked={modemformData && modemformData.ModemEnable}
          onClick={handleSwitch}
          disabled={permissions && permissions.write ? false : true}
        />
      </Form.Item>
      <Form.Item
        label="SIM"
        name="SIMStatus"
        style={{ marginBottom: "10px" }}
        tooltip={
          "Current state of the SIM card, hover over the icon for more information"
        }
      >
        <Tooltip title={modemformData && modemformData.SIMMessage}>
          {modemformData && modemformData.SIMFailure === false ? (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          ) : (
            <ExclamationCircleOutlined style={{ color: "#FF0000" }} />
          )}
        </Tooltip>
      </Form.Item>
      <Form.Item
        label="Signal"
        name="modemSignal"
        style={{ marginBottom: "10px" }}
        tooltip={
          "Signal strength in percentage, hover of the icon for more information"
        }
      >
        <Tooltip title={modemformData && modemformData.sigStrength + "%"}>
          <WifiOutlined
            style={
              modemformData && modemformData.sigStrength > 64
                ? { color: "#03fc0f" }
                : modemformData.sigStrength > 46
                ? { color: "#fcfc03" }
                : modemformData.sigStrength > 28
                ? { color: "#fc8c03" }
                : { color: "#fc0303" }
            }
          />
        </Tooltip>
      </Form.Item>
      <Form.Item
        label="Status"
        name="ModemStatus"
        style={{ marginBottom: "10px" }}
        tooltip={"This is the modem status, if it is on or off with details"}
      >
        <TextArea
          rows={3}
          bordered={false}
          disabled
          style={{ resize: "none" }}
        />
      </Form.Item>
      {permissions && permissions.write && (
        <Form.Item wrapperCol={{ offset: 9, span: 4 }}>
          <Space>
            <Button type="primary" loading={apnSetting} onClick={handleSave}>
              <SaveOutlined /> Save
            </Button>
            <Tooltip title="This will set the information above to the default settings, but will not save it, you will still need to press on the save button">
              <Button onClick={handleDefaults} disabled={apnSetting}>
                Defaults
              </Button>
            </Tooltip>
            <Tooltip title="This will reset the modem, rebooting of the modem is +-2min, verfy with the LED's on the UC4, all 3 should be on with 1 blinking if okay">
              <Button
                onClick={handleReset}
                disabled={apnSetting}
                loading={rstButtonLoading}
                danger
              >
                Reset Modem
              </Button>
            </Tooltip>
          </Space>
        </Form.Item>
      )}
    </Form>
  );
};

TabModem.propTypes = {
  permissions: PropTypes.object,
  apnSetting: PropTypes.bool,
  modemtoggling: PropTypes.bool,
  setModemAPN: PropTypes.func.isRequired,
  toggleModemStatus: PropTypes.func.isRequired,
  getModemStatus: PropTypes.func.isRequired,
  getModemAPN: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  permissions: state.auth.permissions,
  apnSetting: state.utl.apnSetting,
  modemtoggling: state.utl.modemtoggling,
});

export default connect(mapStateToProps, {
  setModemAPN,
  toggleModemStatus,
  getModemStatus,
  getModemAPN,
})(TabModem);
