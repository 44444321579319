import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Ant design components
import { Table, Tooltip, Space, Popconfirm } from "antd";
import {
  PlayCircleFilled,
  CheckCircleOutlined,
  CloseCircleOutlined,
  EditFilled,
} from "@ant-design/icons";

// External Functions
import {
  GetSelectedConfig,
  EnableSelectedConfig,
  getConfigurationFiles,
} from "../../../../actions/deviceList";
import { updateBackend } from "../../../../actions/modbusconfig";
import { updateSNMPBackend } from "../../../../actions/SNMPConfig";
import { CreateAlarmsList } from "../../../../actions/alarms";

// actual component
const TableConfigList = ({
  AllConfigs,
  GetSelectedConfig,
  EnableSelectedConfig,
  getConfigurationFiles,
  sitedetails,
  updateBackend,
  updateSNMPBackend,
  CreateAlarmsList,
}) => {
  const handleEdit = (item) => {
    GetSelectedConfig(item._id);
  };

  const handleUse = async (item) => {
    // Set the correct config file
    let currentList = await EnableSelectedConfig(item._id);

    let entireModbusConfig = [];
    let entireSNMPConfig = [];
    let configData = {};

    currentList.forEach((item) => {
      configData = {};

      if (item.Information.deviceCommType === "Modbus") {
        configData.templateID = item.Information.template._id;
        configData.config = {};

        // Does the device have a name, usually a temperature boards doesn't
        if (item.Information.deviceName)
          configData.config.Name = item.Information.deviceName;
        else {
          configData.config.customNames = [];

          item.Details.childNames.forEach((arrayItem) => {
            if (arrayItem.name !== "") {
              configData.config.customNames.push(arrayItem);
            }
          });
        }

        // the fuel sensors require a constanst and calculation ID
        if (item.Information.deviceType === "Fuel Sensor") {
          configData.config.calculations = [];

          configData.config.calculations.push({
            calculationID: item.Details.calculationID,
            constants: item.Details.constants,
          });
        } else if (item.Information.deviceType === "Analogue Input Board") {
          configData.config.calculations = [];

          // go through each input
          item.Details.childNames.forEach((child) => {
            // if it is set
            if (child.deviceType !== "" && child.deviceName !== "") {
              configData.config.calculations.push({
                calculationID: child.calculationID,
                constants: child.constants,
                name: child.deviceName,
                type: child.deviceType,
                input: child.input,
              });
            }
          });
        }

        if (item.Information.deviceInterfaceType === "TCP") {
          configData.config.interfaceType = 0;
          if (item.Information.deviceTCPSlaveID)
            configData.config.TCPSlaveID = item.Information.deviceTCPSlaveID;
        } else configData.config.interfaceType = 1;

        configData.config.sidIP = item.Information.deviceIPSlaveID;
        configData.config.pollrate = item.Information.devicePollRate;
        configData.config.devType = item.Information.deviceType;
        configData.config.baudRate = item.Information.deviceRTUBaudRate;

        entireModbusConfig.push(configData);
      } else if (item.Information.deviceCommType === "SNMP") {
        configData.templateID = item.Information.template._id;
        configData.config = {};

        if (item.Information.deviceName)
          configData.config.Name = item.Information.deviceName;
        else {
          configData.config.customNames = [];

          item.Details.childNames.forEach((arrayItem) => {
            if (arrayItem.name !== "") {
              configData.config.customNames.push(arrayItem);
            }
          });
        }

        // Always TCP
        configData.config.interfaceType = 0;
        // Other Information
        configData.config.sidIP = item.Information.deviceIPSlaveID;
        configData.config.pollrate = item.Information.devicePollRate;
        configData.config.devType = item.Information.deviceType;
        configData.config.SNMPVersion = item.Information.SNMPVersion;
        configData.config.SNMPPort = item.Information.SNMPPort;
        configData.config.communityString = item.Information.communityString;
        configData.config.deviceID = item.Information.deviceID;

        entireSNMPConfig.push(configData);
      }
    });

    const FileName = sitedetails.name + " Initial";

    // Save the SNMP configuration
    if (entireSNMPConfig.length > 0) {
      let formData = {};

      formData.config = entireSNMPConfig;
      formData.name = FileName;
      formData.current = true;

      updateSNMPBackend(formData);
    } else {
      let formData = {};

      formData.config = [];
      formData.name = FileName;
      formData.current = false;

      updateSNMPBackend(formData);
    }

    // Save the Modbus configuration
    if (entireModbusConfig.length > 0) {
      let formData = {};

      formData.config = entireModbusConfig;
      formData.name = FileName;
      formData.current = true;

      updateBackend(formData);
    } else {
      let formData = {};

      formData.config = [];
      formData.name = FileName;
      formData.current = false;

      updateBackend(formData);
    }

    await GetSelectedConfig(item._id, true);
    await CreateAlarmsList(currentList);

    // Refresh the list
    getConfigurationFiles();
  };

  const tableColumns = [
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
      width: 80,
    },
    {
      title: "Enabled",
      dataIndex: "enabled",
      key: "enabled",
      width: 80,
      render: (text) =>
        text === true ? (
          <CheckCircleOutlined style={{ fontSize: "16px", color: "#00c400" }} />
        ) : (
          <CloseCircleOutlined />
        ),
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 210,
      render: (text) => <p>{text.replace("T", " ").replace("Z", "")}</p>,
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
    },
    {
      title: "Actions",
      dataIndex: "operation",
      width: 80,
      render: (_, record) => (
        <Space size="middle">
          <Fragment>
            {record.enabled === false && (
              <Popconfirm
                title="Sure to use this Config?"
                onConfirm={() => handleUse(record)}
              >
                <Tooltip title="Click here to use">
                  <a href="#!">
                    <PlayCircleFilled
                      style={{ fontSize: "120%", color: "green" }}
                    />
                  </a>
                </Tooltip>
              </Popconfirm>
            )}
            <Tooltip title="Click here to Edit or view this config">
              <a href="#!" onClick={() => handleEdit(record)}>
                <EditFilled style={{ fontSize: "120%" }} />
              </a>
            </Tooltip>
          </Fragment>
        </Space>
      ),
    },
  ];

  return (
    <Table
      bordered
      size={"small"}
      scroll={{ y: 200 }}
      dataSource={
        AllConfigs &&
        AllConfigs.sort((a, b) => {
          return b.version - a.version;
        })
      }
      columns={tableColumns}
      pagination={{
        defaultPageSize: 30,
        showSizeChanger: true,
        pageSizeOptions: ["30", "40", "50"],
      }}
    />
  );
};

TableConfigList.propTypes = {
  AllConfigs: PropTypes.array,
  GetSelectedConfig: PropTypes.func.isRequired,
  EnableSelectedConfig: PropTypes.func.isRequired,
  getConfigurationFiles: PropTypes.func.isRequired,
  updateSNMPBackend: PropTypes.func.isRequired,
  updateBackend: PropTypes.func.isRequired,
  CreateAlarmsList: PropTypes.func.isRequired,
  sitedetails: PropTypes.object,
};

const mapStateToProps = (state) => ({
  AllConfigs: state.deviceList.allConfigFiles,
  sitedetails: state.site.siteDetails,
});

export default connect(mapStateToProps, {
  GetSelectedConfig,
  EnableSelectedConfig,
  getConfigurationFiles,
  updateSNMPBackend,
  updateBackend,
  CreateAlarmsList,
})(TableConfigList);
