import {
  UTL_IP_ETH_DEL,
  UTL_IP_REFRESH,
  UTL_IP_SAVE_END,
  UTL_IP_SAVE_START,
  UTL_MODAL_ADDIP_CLOSE,
  UTL_MODAL_ADDIP_OPEN,
  UTL_MODEM_APNSET_START,
  UTL_MODEM_APNSET_STOP,
  UTL_MODEM_TOGGLE_START,
  UTL_MODEM_TOGGLE_STOP,
  UTL_UC_INFO,
  UTL_VPN_GETKEYSTART,
  UTL_VPN_KEYUPDATE,
  UTL_WIFI_CONNECTING,
  UTL_WIFI_MODAL_CON_CLOSE,
  UTL_WIFI_MODAL_CON_OPEN,
} from "../actions/types";

const initalState = {
  modemtoggling: false,
  apnSetting: false,
  savingIP: false,
  modalAddIPVis: false,
  modalAddIPInfo: {},
  modalConnWIFIVis: false,
  modalConnWIFInfo: {},
  WIFIisConnecting: false,
  InterfaceIP: {},
  UCInfo: null,
  VPNKeyGenRunning: false,
  VPNKey: "",
};

export default function site(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case UTL_MODAL_ADDIP_OPEN:
      return { ...state, modalAddIPVis: true, modalAddIPInfo: payload };
    case UTL_MODAL_ADDIP_CLOSE:
      const InterfaceIP = { ...state.InterfaceIP };

      // Check for blanks
      if (payload !== undefined) {
        const tabletItems = [...InterfaceIP.Ethernet];

        // Check to see if we are editing an existing item, existing ones will have keys
        if (payload.key !== undefined) {
          const tableIndex = tabletItems.findIndex(
            (item) => item.key === payload.key
          );

          tabletItems[tableIndex] = { ...payload };
        } else {
          tabletItems.push({
            ...payload,
            key: `${payload.address}@${tabletItems.length + 1}`,
          });
        }

        InterfaceIP.Ethernet = tabletItems;
      }
      return {
        ...state,
        modalAddIPVis: false,
        modalAddIPInfo: {},
        InterfaceIP,
      };
    case UTL_IP_ETH_DEL:
      const AllIP = { ...state.InterfaceIP };

      if (payload !== undefined) {
        let tabletItems = [...AllIP.Ethernet];

        tabletItems = tabletItems.filter((item) => item.key !== payload.key);

        AllIP.Ethernet = tabletItems;
      }
      return {
        ...state,
        InterfaceIP: AllIP,
      };
    case UTL_MODEM_APNSET_START:
      return { ...state, apnSetting: true };
    case UTL_MODEM_APNSET_STOP:
      return { ...state, apnSetting: false };
    case UTL_MODEM_TOGGLE_START:
      return { ...state, modemtoggling: true };
    case UTL_MODEM_TOGGLE_STOP:
      return { ...state, modemtoggling: false };
    case UTL_VPN_KEYUPDATE:
      return { ...state, VPNKey: payload, VPNKeyGenRunning: false };
    case UTL_VPN_GETKEYSTART:
      return { ...state, VPNKeyGenRunning: true };
    case UTL_WIFI_MODAL_CON_OPEN:
      return { ...state, modalConnWIFIVis: true, modalConnWIFInfo: payload };
    case UTL_WIFI_MODAL_CON_CLOSE:
      return {
        ...state,
        modalConnWIFIVis: false,
        modalConnWIFInfo: {},
        WIFIisConnecting: false,
      };
    case UTL_WIFI_CONNECTING:
      return { ...state, WIFIisConnecting: payload };
    case UTL_IP_SAVE_START:
      return { ...state, savingIP: true };
    case UTL_UC_INFO:
      return { ...state, UCInfo: { ...payload } };
    case UTL_IP_SAVE_END:
      return { ...state, savingIP: false };
    case UTL_IP_REFRESH:
      return { ...state, InterfaceIP: { ...payload } };
    default:
      return state;
  }
}
