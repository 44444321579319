import React, { Fragment, useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";

// ant design Components
import {
  Space,
  Tooltip,
  Table,
  Typography,
  Popconfirm,
  Button,
  Input,
  Row,
  Col,
  Upload,
} from "antd";
import {
  EditFilled,
  PoweroffOutlined,
  SearchOutlined,
  SaveOutlined,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";

// Other components
import EditAlarmModal from "./AlarmEdit";

// CSS styles
import "./Alarms.css";

// functions
import {
  getAlarmsList,
  openAlarmsModal,
  disableAlarmList,
  saveAlarmListTODB,
  resetToDefaults,
} from "../../../../actions/alarms";
import { ClearDeviceList } from "../../../../actions/deviceList";

// Constants
const { Title, Paragraph, Text } = Typography;

// actual component
const Alarms = ({
  getAlarmsList,
  loading,
  currentAlarmsList,
  openAlarmsModal,
  disableAlarmList,
  saveAlarmListTODB,
  isSaving,
  resetToDefaults,
  fromWizard,
  permissions,
  sitedetails,
  ClearDeviceList,
}) => {
  const [deviceTypes, setDeviceTypes] = useState([]);
  const [defaultButtonReset, setDefaultButtonReset] = useState(false);
  const [searchState, setsearchState] = useState({
    searchText: "",
    searchedColumn: "",
  });
  const searchInput = useRef(null);

  useEffect(() => {
    const getTypes = async () => {
      const devTypes = await axios.get("/api/devtemplates/devicetypes");

      setDeviceTypes(devTypes.data);
    };

    getTypes();

    getAlarmsList();
  }, [getAlarmsList, setDeviceTypes]);

  // unload Effect
  useEffect(() => {
    return () => {
      clearInterval(sessionStorage.getItem("intervalTimerId"));
      sessionStorage.removeItem("intervalTimerId");
      if (fromWizard === undefined) ClearDeviceList();
    };
  }, [ClearDeviceList, fromWizard]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 5 }}>
        <Input
          size="small"
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setsearchState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text, record) =>
      // render: (text, record) => {
      record.device_type === "Input Board" && dataIndex === "device_name" ? (
        record.deviceName
      ) : text && searchState.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchState.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  // table columbs
  const columns = [
    {
      title: "Device Name",
      dataIndex: "device_name",
      key: "device_name",
      ...getColumnSearchProps("device_name"),
    },
    {
      title: "Device Type",
      dataIndex: "device_type",
      key: "device_type",
      filters: deviceTypes.map((item) => ({
        // text: item.charAt(0) + item.slice(1).toLowerCase(),
        text: item,
        value: item,
      })),
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      onFilter: (value, record) => record.device_type.indexOf(value) === 0,
      render: (text, record) => {
        if (record.device_type === "Input Board")
          return record.deviceType + " (Input Board)";
        else return text;
      },
    },
    {
      title: "Alarm Name",
      dataIndex: "alarmName",
      key: "alarmName",
      ...getColumnSearchProps("alarmName"),
    },
    {
      title: "Trigger Level",
      dataIndex: "alarmLevel",
      key: "alarmLevel",
      render: (level, record) =>
        record && record.fixed === true ? null : level,
    },
    {
      title: "Warm up time",
      dataIndex: "alarmDelayOn",
      key: "alarmDelayOn",
    },
    {
      title: "Cool down time",
      dataIndex: "alarmDelayOff",
      key: "alarmDelayOff",
    },
    {
      title: "Priority",
      dataIndex: "alarmPriority",
      key: "alarmPriority",
      filters: [
        {
          text: "Event",
          value: "Event",
        },
        {
          text: "Warning",
          value: "Warning",
        },
        {
          text: "Urgent",
          value: "Urgent",
        },
        {
          text: "Critical",
          value: "Critical",
        },
      ],
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      onFilter: (value, record) => record.alarmPriority.indexOf(value) === 0,
    },
    {
      title: "Disabled",
      dataIndex: "alarmDisabled",
      key: "alarmDisabled",
      render: (text) => (text === true ? "Yes" : null),
      filters: [
        {
          text: "Yes",
          value: true,
        },
        {
          text: "No",
          value: false,
        },
      ],
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      onFilter: (value, record) =>
        value === true
          ? record.alarmDisabled && record.alarmDisabled === value
          : record.alarmDisabled === undefined,
    },
    {
      title: "Action",
      dataIndex: "operation",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          {permissions && permissions.write && (
            <Fragment>
              <Tooltip title={record && "Edit " + record.alarmName}>
                <a href="#!" onClick={() => handleEdit(record)}>
                  <EditFilled />
                </a>
              </Tooltip>

              <Popconfirm
                title={
                  record && record.alarmDisabled
                    ? "Sure to enable this alarm?"
                    : "Sure to disable this alarm?"
                }
                onConfirm={() => handleEnable(record)}
              >
                <Tooltip
                  title={
                    record && record.alarmDisabled
                      ? "Enable " + record.alarmName
                      : "Disable " + record.alarmName
                  }
                >
                  <a href="#!">
                    <PoweroffOutlined />
                  </a>
                </Tooltip>
              </Popconfirm>
            </Fragment>
          )}
        </Space>
      ),
    },
  ];

  const handleEnable = (record) => {
    disableAlarmList(record.key, currentAlarmsList);
  };

  const handleEdit = (record) => {
    openAlarmsModal(true, record);
  };

  const HandleExport = async () => {
    const d = new Date();
    var utc = d.toJSON().slice(0, 10);
    var hours = d.getHours() + "h" + d.getMinutes();

    // Gets the current alarms list
    const receivedData = await axios.get("/api/alarm");
    let exportAlarmsList = receivedData.data.alarmsList;

    const fileName = sitedetails.name + " Alarms-" + utc + " " + hours;
    const json = JSON.stringify(exportAlarmsList, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const href = await URL.createObjectURL(blob);
    const link = document.createElement("a");

    link.href = href;
    link.download = fileName + ".json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSave = () => {
    saveAlarmListTODB(currentAlarmsList);
  };

  const handleDefault = () => {
    setDefaultButtonReset(true);
    resetToDefaults();
    setTimeout(() => {
      setDefaultButtonReset(false);
      window.location.reload(false);
    }, 5000);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setsearchState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setsearchState({ ...searchState, searchText: "" });
  };

  const handelUpload = ({ fileList }) => {
    let reader = new FileReader();

    // when the conversion is finsished this will run
    reader.onload = (e) => {
      const newAlarmsFile = JSON.parse(e.target.result);

      getAlarmsList(newAlarmsFile);
    };

    // convert the blob to a texxt file
    reader.readAsText(fileList[0].originFileObj);
  };

  const UploaderProps = {
    name: "file",
    maxCount: 1,
    showUploadList: false,
    accept: ".json",
  };

  return (
    <Fragment>
      {fromWizard === undefined ? (
        <Fragment>
          <Title level={2}>Alarms configuration</Title>

          {permissions && permissions.write && (
            <Fragment>
              <Paragraph>
                Use the below table to edit individual alarms timer and levels,
                the list will not be saved until the <Text keyboard>Save</Text>{" "}
                button is pressed.
              </Paragraph>
              <Paragraph>
                If you want to be able to reset all the values to the original
                values press on the <Text keyboard>Reset all to default</Text>,
                note doing this will be permanent and any custom timers and
                level will be erased.
              </Paragraph>

              <Tooltip title="Save this alarms list to the device to start using it">
                <Button
                  onClick={handleSave}
                  style={{ marginBottom: "10px" }}
                  type="primary"
                  loading={isSaving}
                  icon={<SaveOutlined />}
                >
                  Save List
                </Button>
              </Tooltip>
              <Button
                onClick={handleDefault}
                style={{ marginBottom: "10px", marginLeft: "10px" }}
                type="warning"
                loading={defaultButtonReset}
              >
                Reset all to default
              </Button>
            </Fragment>
          )}
          <Tooltip title="Click to download the saved alarm config to your machine">
            <Button
              style={{ marginLeft: "10px" }}
              icon={<DownloadOutlined />}
              type="primary"
              onClick={() => HandleExport()}
            >
              Export
            </Button>
          </Tooltip>
          {permissions && permissions.write && (
            <Tooltip title="Click to upload a new configuration from your machine, you will need to hit the save button to save on the device">
              <Upload
                {...UploaderProps}
                onChange={handelUpload}
                beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
              >
                <Button
                  style={{ marginLeft: "10px" }}
                  icon={<UploadOutlined />}
                >
                  Import
                </Button>
              </Upload>
            </Tooltip>
          )}
        </Fragment>
      ) : (
        <Fragment>
          <Row style={{ marginBottom: "20px" }}>
            <Col span={8}></Col>
            <Col span={8}>
              <Typography style={{ textAlign: "center" }}>
                <Title level={2}>Alarm configuration</Title>
                <Paragraph>
                  This is the list of all the alarms your UC-4 will produce, use
                  the buttons in the Action columb to edit the alarm. Once you
                  are done hit the <Text code>Done</Text> button to save the
                  list.
                </Paragraph>
                <Paragraph>
                  <Text keyboard strong type="warning">
                    NB:
                  </Text>
                  You dont need to complete this now, it can be edited later
                </Paragraph>
              </Typography>
            </Col>
            <Col span={8}></Col>
          </Row>

          {permissions && permissions.write && (
            <Tooltip title="Click to upload a new configuration from your machine, you will need to hit the save button to save on the device">
              <Upload
                {...UploaderProps}
                onChange={handelUpload}
                beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
              >
                <Button style={{ margin: "10px" }} icon={<UploadOutlined />}>
                  Import
                </Button>
              </Upload>
            </Tooltip>
          )}
        </Fragment>
      )}

      <EditAlarmModal />

      <Table
        bordered
        size={"small"}
        style={{ marginBottom: "10px" }}
        scroll={{ y: fromWizard ? 430 : 500 }}
        dataSource={currentAlarmsList}
        loading={loading}
        columns={columns}
        rowClassName={(record, _) => record.alarmDisabled === true && "redRow"}
        pagination={{
          defaultPageSize: 50,
          showSizeChanger: true,
          pageSizeOptions: ["50", "100", "150"],
        }}
      />
    </Fragment>
  );
};

Alarms.propTypes = {
  getAlarmsList: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  isSaving: PropTypes.bool,
  currentList: PropTypes.array,
  currentAlarmsList: PropTypes.array,
  openAlarmsModal: PropTypes.func.isRequired,
  disableAlarmList: PropTypes.func.isRequired,
  saveAlarmListTODB: PropTypes.func.isRequired,
  resetToDefaults: PropTypes.func.isRequired,
  permissions: PropTypes.object,
  sitedetails: PropTypes.object,
  ClearDeviceList: PropTypes.func,
};

const mapStateToProps = (state) => ({
  currentAlarmsList: state.alarms.alarmsList,
  loading: state.alarms.loading,
  permissions: state.auth.permissions,
  isSaving: state.alarms.saving,
  sitedetails: state.site.siteDetails,
});

export default connect(mapStateToProps, {
  getAlarmsList,
  openAlarmsModal,
  disableAlarmList,
  saveAlarmListTODB,
  resetToDefaults,
  ClearDeviceList,
})(Alarms);
