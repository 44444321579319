import React from "react";
import { Layout } from "antd";

const { Content } = Layout;

const Contents = ({ children }) => {
  return (
    <Content style={{ padding: "0 25px" }}>
      <div className="site-layout-content">{children}</div>
    </Content>
  );
};

export default Contents;
