import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import axios from "axios";

// Antd componets
import {
  Table,
  notification,
  Button,
  Form,
  Input,
  Select,
  InputNumber,
  Radio,
  Tooltip,
  Space,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

// Actual Component
const ModbusPoll = () => {
  const [formData] = Form.useForm();

  const [tableLoading, setTabletLoad] = useState(false);
  const [modbusParmameters, setmodbusParmameters] = useState();
  const [dataS, setdataS] = useState([]);
  const [columns, setcolumns] = useState([]);
  const [refreshBtnDisable, setRefreshBtnDisable] = useState(true);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const IntervalTimer = useRef();

  const getModbusData = useCallback(async () => {
    if (!autoRefresh) {
      setTabletLoad(true);
      setdataS([]);
    }

    try {
      const result = await axios.post("/api/utl/pollDevice", modbusParmameters);

      if (result.data["error"].length > 0) {
        result.data["error"].forEach((err) => {
          openNotification(err.error);
        });
      } else {
        const queryData = result.data["data"];

        // work out the amount of cloums
        let amountOfRegisters = Math.floor(queryData.length / 10);
        const Remainder = queryData.length % 10;

        if (Remainder > 0) amountOfRegisters++;

        setcolumns([]);
        let tblcolumns = [];

        if (modbusParmameters.Protocal === "modbus") {
          for (let i = 0; i < amountOfRegisters; i++) {
            let columnAdd = {};
            columnAdd.title = "Address";
            columnAdd.dataIndex = "address" + i;
            columnAdd.key = "address" + i;
            columnAdd["render"] = (text) => <strong>{text}</strong>;
            tblcolumns.push(columnAdd);

            columnAdd = {};
            columnAdd.title = "Value";
            columnAdd.dataIndex = "value" + i;
            columnAdd.key = "value" + i;

            tblcolumns.push(columnAdd);
          }

          setcolumns(tblcolumns);

          const TableInfo = [];
          amountOfRegisters = 0;
          let dataCounter = 0;

          queryData.forEach((data, index) => {
            let TableData = {};

            TableData["address" + amountOfRegisters] =
              modbusParmameters.start + index;
            TableData["value" + amountOfRegisters] = data;

            if (amountOfRegisters === 0) {
              TableInfo.push(TableData);
            } else {
              TableData = { ...TableData, ...TableInfo[dataCounter] };
              TableInfo[dataCounter] = TableData;
            }

            dataCounter++;

            if (dataCounter > 9) {
              amountOfRegisters++;
              dataCounter = 0;
            }
          });
          setdataS(TableInfo);
        } else {
          let columnAdd = {};
          columnAdd.title = "OID";
          columnAdd.dataIndex = "OID";
          columnAdd.key = "OID";
          columnAdd["render"] = (text) => <strong>{text}</strong>;
          tblcolumns.push(columnAdd);

          columnAdd = {};
          columnAdd.title = "Value";
          columnAdd.dataIndex = "value";
          columnAdd.key = "value";

          tblcolumns.push(columnAdd);

          setcolumns(tblcolumns);

          console.log(result.data["data"]);
          const TableInfo = [
            {
              OID: result.data["data"][0].oid,
              value: result.data["data"][0].value,
            },
          ];

          setdataS(TableInfo);
        }
      }
    } catch (error) {
      openNotification("Server error or timeout");
    }
    setTabletLoad(false);
  }, [modbusParmameters, autoRefresh]);

  const refreshData = useCallback(() => {
    if (autoRefresh) getModbusData();
  }, [getModbusData, autoRefresh]);

  useEffect(() => {
    const listener = (event) => {
      // Listen fro both enter keys
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        // Check for data
        refreshData();
      }
    };

    document.addEventListener("keydown", listener);

    if (IntervalTimer.current === undefined) {
      IntervalTimer.current = setInterval(() => {
        refreshData();
      }, 2000);
    }

    // clean up the effect by removing it completely
    return () => {
      document.removeEventListener("keydown", listener);
      clearInterval(IntervalTimer.current);
      IntervalTimer.current = undefined;
    };
  }, [refreshData]);

  const openNotification = (Message) => {
    notification.open({
      message: "Query Error!!",
      description: Message,
      icon: <ExclamationCircleOutlined style={{ color: "#ff0000" }} />,
    });
  };

  const checkButtonDisable = () => {
    setRefreshBtnDisable(true);

    if (formData.getFieldValue("Protocal")) {
      if (formData.getFieldValue("Protocal") === "SNMP") {
        if (formData.getFieldValue("IP")) {
          if (formData.getFieldValue("OID")) {
            if (formData.getFieldValue("version")) {
              if (formData.getFieldValue("Port")) {
                setRefreshBtnDisable(false);
              }
            }
          }
        }
      } else {
        if (formData.getFieldValue("modbusType")) {
          if (formData.getFieldValue("modbusType") === "TCP") {
            if (formData.getFieldValue("IP")) {
              if (formData.getFieldValue("start") !== undefined) {
                if (formData.getFieldValue("quantity")) {
                  if (formData.getFieldValue("functionCode")) {
                    setRefreshBtnDisable(false);
                  }
                }
              }
            }
          } else {
            if (formData.getFieldValue("SID")) {
              if (formData.getFieldValue("BuadRate")) {
                if (formData.getFieldValue("start") !== undefined) {
                  if (formData.getFieldValue("quantity")) {
                    if (formData.getFieldValue("functionCode")) {
                      setRefreshBtnDisable(false);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  const AutorefreshData = () => {
    setAutoRefresh(!autoRefresh);
  };

  return (
    <Fragment>
      <h1 className="large text-primary">Device Information Query</h1>
      <Form
        form={formData}
        labelCol={{ span: 4 }}
        wrapperCol={{ xs: 12, md: 3 }}
        layout="horizontal"
        size={"small"}
        onValuesChange={(_, all) => {
          if (all.Protocal === "SNMP") {
            if (all.modbusType !== "TCP") {
              all.modbusType = "TCP";
              formData.setFieldsValue({ modbusType: "RTU" });
            }

            if (all.Port === undefined) {
              all.Port = 161;
              formData.setFieldsValue({ Port: 161 });
            }

            if (all.CommString === undefined) {
              all.CommString = "public";
              formData.setFieldsValue({ CommString: "public" });
            }
          } else {
            if (
              modbusParmameters &&
              modbusParmameters.Protocal !== all.Protocal
            ) {
              formData.resetFields();
              formData.setFieldsValue({ Protocal: all.Protocal });
              all.modbusType = formData.getFieldValue("modbusType");
            }
          }

          checkButtonDisable();

          setmodbusParmameters(all);
        }}
      >
        <Form.Item
          name="Protocal"
          label="Protocal"
          style={{ marginBottom: "10px" }}
        >
          <Radio.Group disabled={autoRefresh}>
            <Radio value="modbus">Modbus</Radio>
            <Radio value="SNMP">SNMP</Radio>
          </Radio.Group>
        </Form.Item>

        {modbusParmameters && modbusParmameters.Protocal && (
          <Fragment>
            {modbusParmameters && modbusParmameters.Protocal === "modbus" && (
              <Form.Item
                name="modbusType"
                label="Modbus Type"
                style={{ marginBottom: "10px" }}
              >
                <Radio.Group disabled={autoRefresh}>
                  <Radio value="RTU">RTU</Radio>
                  <Radio value="TCP">TCP</Radio>
                </Radio.Group>
              </Form.Item>
            )}

            {modbusParmameters && modbusParmameters.modbusType !== "RTU" ? (
              <Fragment>
                <Form.Item
                  name="IP"
                  label="IP"
                  style={{ marginBottom: "10px" }}
                >
                  <Input disabled={autoRefresh} />
                </Form.Item>

                {modbusParmameters.Protocal === "modbus" && (
                  <Form.Item
                    name="SID"
                    label="Device ID"
                    style={{ marginBottom: "10px" }}
                  >
                    <InputNumber disabled={autoRefresh} />
                  </Form.Item>
                )}
              </Fragment>
            ) : (
              <Fragment>
                <Form.Item
                  name="SID"
                  label="Slave ID"
                  style={{ marginBottom: "10px" }}
                >
                  <InputNumber disabled={autoRefresh} />
                </Form.Item>
                <Form.Item
                  name="BuadRate"
                  label="Baud Rate"
                  style={{ marginBottom: "10px" }}
                >
                  <Select allowClear showSearch disabled={autoRefresh}>
                    <Select.Option key="4800" value={4800}>
                      4800
                    </Select.Option>
                    <Select.Option key="9600" value={9600}>
                      9600
                    </Select.Option>
                    <Select.Option key="19200" value={19200}>
                      19200
                    </Select.Option>
                    <Select.Option key="38400" value={38400}>
                      38400
                    </Select.Option>
                    <Select.Option key="115200" value={115200}>
                      115200
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Fragment>
            )}

            {modbusParmameters && modbusParmameters.Protocal === "modbus" ? (
              <Fragment>
                <Form.Item
                  name="start"
                  label="Start Address"
                  style={{ marginBottom: "10px" }}
                >
                  <InputNumber min={0} max={65000} disabled={autoRefresh} />
                </Form.Item>
                <Form.Item
                  name="quantity"
                  label="Amount"
                  style={{ marginBottom: "10px" }}
                >
                  <InputNumber min={1} max={250} disabled={autoRefresh} />
                </Form.Item>
                <Form.Item
                  name="functionCode"
                  label="Function"
                  style={{ marginBottom: "10px" }}
                >
                  <Select disabled={autoRefresh}>
                    <Select.Option key="1" value={1}>
                      1
                    </Select.Option>
                    <Select.Option key="3" value={3}>
                      3
                    </Select.Option>
                    <Select.Option key="4" value={4}>
                      4
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Fragment>
            ) : (
              <Fragment>
                <Form.Item
                  name="CommString"
                  label="Community string"
                  style={{ marginBottom: "10px" }}
                >
                  <Input disabled={autoRefresh} />
                </Form.Item>
                <Form.Item
                  name="OID"
                  label="OID"
                  style={{ marginBottom: "10px" }}
                >
                  <Input disabled={autoRefresh} />
                </Form.Item>
                <Form.Item
                  name="Port"
                  label="Port"
                  style={{ marginBottom: "10px" }}
                >
                  <InputNumber disabled={autoRefresh} />
                </Form.Item>
                <Form.Item
                  name="version"
                  label="SNMP Version"
                  style={{ marginBottom: "10px" }}
                >
                  <Select disabled={autoRefresh}>
                    <Select.Option key="s1" value={1}>
                      1
                    </Select.Option>
                    <Select.Option key="s2" value={2}>
                      2
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Fragment>
            )}
            <Form.Item wrapperCol={{ offset: 4, span: 10 }}>
              <Space>
                <Tooltip title="Click to enable or diable the auto refresh">
                  <Button
                    type={autoRefresh ? "primary" : "default"}
                    onClick={AutorefreshData}
                    disabled={refreshBtnDisable}
                  >
                    {!autoRefresh
                      ? "Enable Auto Refresh"
                      : "Disable Auto Refresh"}
                  </Button>
                </Tooltip>
                <Tooltip title="Click here or press Enter on your keyboard to poll">
                  <Button
                    type={"primary"}
                    onClick={getModbusData}
                    disabled={refreshBtnDisable || tableLoading || autoRefresh}
                  >
                    Refresh Data
                  </Button>
                </Tooltip>
              </Space>
            </Form.Item>
          </Fragment>
        )}
      </Form>

      <Table
        columns={columns}
        size="small"
        dataSource={dataS}
        pagination={{ pageSize: 10 }}
        scroll={{ y: 390 }}
        loading={tableLoading}
        bordered
      />
    </Fragment>
  );
};

export default ModbusPoll;
