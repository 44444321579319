import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd
import { Modal, Form, Select, Input, Divider } from "antd";
// import { PlusOutlined } from "@ant-design/icons";

// external functions
import {
  modalAddVirtualClose,
  saveConfigToList,
} from "../../../../../actions/advanced";

// Constants
const { Option } = Select;

// actual componet
const ModalAddVirtual = ({
  modalVis,
  modalData,
  modalAddVirtualClose,
  availableCalcs,
  typeNamesList,
  saveConfigToList,
}) => {
  const [formData] = Form.useForm();
  const [virtualFormData, setVirtualFormData] = useState();
  const [deviceList, setDeviceList] = useState([]);

  const HandleOk = () => {
    const calc = getCalc(formData.getFieldValue("calculationName"))[0];

    if (modalData === null) {
      saveConfigToList({
        ...virtualFormData,
        calculationName: calc.calculationName,
        calculationID: calc._id,
        isVirtual: true,
      });
    } else {
      saveConfigToList(
        {
          ...virtualFormData,
          calculationName: calc.calculationName,
          calculationID: calc._id,
          key: modalData.key,
          isVirtual: true,
        },
        true
      );
    }
    modalAddVirtualClose();
  };

  const HandleCancel = () => {
    modalAddVirtualClose();
  };

  // Select List - Calculation Types
  const CalcTypeList =
    availableCalcs !== null
      ? availableCalcs.map(
          (calc, _) =>
            calc.isVirtual && (
              <Option value={calc._id} key={calc._id}>
                {calc.calculationName}
              </Option>
            )
        )
      : null;

  // Select List - Devices
  const selectableDevices =
    deviceList !== null
      ? deviceList.map((device, index) => (
          <Option value={device.name} key={index}>
            {device.name}
          </Option>
        ))
      : null;

  const getCalc = (calcID) => {
    return availableCalcs.filter((calc) => calc._id === calcID);
  };

  if (modalData !== null && modalVis && virtualFormData === undefined) {
    console.log("modalData: ", modalData);
    formData.setFieldsValue({
      calculationName: modalData.calculationID,
      calculationDescription: modalData.calculationDescription,
      deviceName: modalData.deviceName,
      constants: modalData.constants,
    });
    setVirtualFormData(modalData);

    let thisCalc = getCalc(modalData.calculationID);
    thisCalc = thisCalc[0];

    setDeviceList(
      typeNamesList.filter((names) => names.type === thisCalc.inputOutputType)
    );
  }

  return (
    <Modal
      onCancel={HandleCancel}
      width={900}
      afterClose={() => {
        setVirtualFormData();
        setDeviceList([]);
        formData.resetFields();
      }}
      okButtonProps={{
        disabled: virtualFormData && !virtualFormData.calculationName,
      }}
      okText={modalData !== null ? "Edit" : "Save"}
      onOk={() => formData.validateFields().then(() => HandleOk())}
      title={
        modalData === null
          ? "Create new virtual device"
          : "Editing virtual device"
      }
      visible={modalVis}
    >
      <Form
        form={formData}
        size="small"
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 10 }}
        onValuesChange={(_, all) => {
          setVirtualFormData(all);

          if (all.calculationName) {
            if (
              modalData === null &&
              virtualFormData &&
              virtualFormData.calculationName !== all.calculationName
            ) {
              formData.setFieldsValue({
                calculationDescription: "",
                deviceName: "",
                constants: [],
              });
            }
            let thisCalc = getCalc(
              modalData !== null ? modalData.calculationID : all.calculationName
            );
            thisCalc = thisCalc[0];

            setDeviceList(
              typeNamesList.filter(
                (names) => names.type === thisCalc.inputOutputType
              )
            );

            formData.setFieldsValue({
              calculationDescription: thisCalc.calculationDescription,
            });
          } else {
            formData.setFieldsValue({
              calculationDescription: "",
              deviceName: "",
              constants: [],
            });

            setDeviceList([]);
          }
        }}
      >
        <Form.Item
          label="Virtual Device"
          name="calculationName"
          style={{ marginBottom: "5px" }}
          rules={[
            { required: true, message: "Need to select a virtual device type" },
          ]}
        >
          <Select
            allowClear
            showSearch
            disabled={modalData !== null ? true : false}
          >
            {CalcTypeList}
          </Select>
        </Form.Item>
        {virtualFormData && virtualFormData.calculationName !== undefined && (
          <Fragment>
            <Form.Item label="Description" name="calculationDescription">
              <Input.TextArea rows={3} disabled />
            </Form.Item>

            <Form.Item
              label="Device Name"
              name="deviceName"
              tooltip="This is the name given to the virtualized device"
              rules={[
                { required: true, message: "Device needs a name" },
                () => ({
                  validator(_, value) {
                    const index = typeNamesList.findIndex(
                      (Name) =>
                        Name.name.toLowerCase().trim() ===
                        value.toLowerCase().trim()
                    );

                    if (index !== -1) {
                      return Promise.reject(
                        "This name already exists, please choose another"
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input />
            </Form.Item>

            <Divider>Input devices to makes this virtual device</Divider>

            <Form.Item
              label="Inputs"
              name="constants"
              rules={[
                { required: true, message: "Need to select at least 1 device" },
              ]}
            >
              <Select mode="multiple" allowClear showSearch>
                {selectableDevices}
              </Select>
            </Form.Item>
          </Fragment>
        )}
      </Form>
    </Modal>
  );
};

ModalAddVirtual.propTypes = {
  modalVis: PropTypes.bool,
  modalData: PropTypes.object,
  modalAddVirtualClose: PropTypes.func.isRequired,
  saveConfigToList: PropTypes.func.isRequired,
  availableCalcs: PropTypes.array,
  typeNamesList: PropTypes.array,
};

const mapStateToProps = (state) => ({
  modalVis: state.advanced.modalAddVirtualVis,
  modalData: state.advanced.modalAddVirtualData,
  availableCalcs: state.calcs.allCalcs,
  typeNamesList: state.deviceList.typeNameList,
});

export default connect(mapStateToProps, {
  modalAddVirtualClose,
  saveConfigToList,
})(ModalAddVirtual);
