import React, { useState } from "react";
import { Form, Input, Table, Button, Space } from "antd";
import { openConfigModal } from "../../../../actions/modbusconfig";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Components
import NewDevModal from "./newDevModal";

const DeviceDetail = ({ modbusConfig: { entireConfig }, openConfigModal }) => {
  const columns = [
    {
      title: "Name",
      dataIndex: ["config", "Name"],
    },
    {
      title: "Interface",
      dataIndex: ["config", "interfaceType"],
      render: (text) => <p>{text === 0 ? "TCP" : "RTU"}</p>,
    },
    {
      title: "Device",
      dataIndex: ["config", "devType"],
    },
    {
      title: "IP/Slave ID",
      dataIndex: ["config", "sidIP"],
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <a href="#!">Delete</a>
        </Space>
      ),
    },
  ];

  const [configName, setconfigName] = useState(
    localStorage.getItem("configName")
  );

  const onChange = (e) => {
    const { value, name } = e.target;

    setconfigName(value);
    localStorage.setItem(name, value);
  };

  return (
    <div style={{ marginTop: "2vh" }}>
      <Form
        labelCol={{ span: 4, offset: 5 }}
        wrapperCol={{ span: 5 }}
        layout="horizontal"
        size="default"
      >
        <Form.Item label="Configuration Name">
          <Input
            value={configName}
            name="configName"
            rules={[{ required: true }]}
            onChange={(e) => onChange(e)}
          />
        </Form.Item>
      </Form>

      <NewDevModal />
      <Button
        type="primary"
        style={{ margin: "10px" }}
        onClick={openConfigModal}
      >
        Add Device
      </Button>
      <h1 className="large text-primary">Modbus Devices</h1>
      <Table
        columns={columns}
        pagination={{ pageSize: 10 }}
        scroll={{ y: 300 }}
        dataSource={entireConfig}
        // loading={!dataloaded && templates === null ? true : false}
      />
    </div>
  );
};

DeviceDetail.propTypes = {
  modbusConfig: PropTypes.object.isRequired,
  openConfigModal: PropTypes.func.isRequired,
};

const mapStateToProp = (state) => ({
  modbusConfig: state.modbusConfig,
});

export default connect(mapStateToProp, { openConfigModal })(DeviceDetail);
