import React, { Fragment, useEffect, useState } from "react";
import { Descriptions, Button, Collapse } from "antd";
import axios from "axios";
import { Link } from "react-router-dom";

const { Panel } = Collapse;

const Details = (props) => {
  const [deviceDetails, setdeviceDetails] = useState();
  const [dataLoaded, setdataLoaded] = useState(false);
  const [tempItems, settempItems] = useState([]);

  //   const { devices, name, createdAt, updatedAt, user, current } = configDetails;

  const styles = {
    card: {
      height: "300px",
      overflow: "auto",
    },
  };

  useEffect(() => {
    async function fetchTemplateDetails() {
      const templateDetails = await axios.get(
        "/api/devtemplates/" + props.match.params.id
      );
      // const templateDetails = await axios.get(
      //   "http://localhost:5000/api/devtemplates/" + props.match.params.id
      // );

      setdeviceDetails(templateDetails.data);

      const allBatchsDetails = [];

      templateDetails.data.Batchs.forEach((Batch) => {
        Batch.Map.forEach((address) => {
          allBatchsDetails.push(address);
        });
      });

      settempItems(allBatchsDetails);
      setdataLoaded(true);

      console.log(templateDetails.data);
    }

    fetchTemplateDetails();
  }, [props.match.params.id]);

  const templateItems = tempItems.map((items, index) => {
    return (
      <Panel header={items.name} key={index}>
        <p>Scale Factor: {items.scalingFactor}</p>
        <p>Scale Operation: {items.scalingOperation}</p>
        <p>Unit: {items.symbol}</p>
        <p>Start Address: {items.address}</p>
      </Panel>
    );
  });

  return (
    <Fragment>
      <Descriptions title="Template Information" layout="vertical" bordered>
        <Descriptions.Item label="Device type Name">
          {dataLoaded ? deviceDetails.device_type_name : null}
        </Descriptions.Item>
        <Descriptions.Item label="Created">
          {dataLoaded ? deviceDetails.createdAt : null}
        </Descriptions.Item>
        <Descriptions.Item label="Updated">
          {dataLoaded ? deviceDetails.updatedAt : null}
        </Descriptions.Item>
        <Descriptions.Item label="Manufacture">
          {dataLoaded ? deviceDetails.maunfacture : null}
        </Descriptions.Item>
        <Descriptions.Item label="Model">
          {dataLoaded ? deviceDetails.model : null}
        </Descriptions.Item>
        <Descriptions.Item label="Communication Protocal">
          {dataLoaded ? deviceDetails.commProtocol : null}
        </Descriptions.Item>
        <Descriptions.Item label="Description" span={3}>
          {dataLoaded ? deviceDetails.description : null}
        </Descriptions.Item>
        <Descriptions.Item label="Template Items">
          {dataLoaded ? (
            <Collapse style={styles.card}>{templateItems}</Collapse>
          ) : null}
        </Descriptions.Item>
      </Descriptions>
      <Link to="/tools/plugin/devtemp">
        <Button type="primary" style={{ marginTop: "20px" }}>
          Back
        </Button>
      </Link>
    </Fragment>
  );
};

export default Details;
