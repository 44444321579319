import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd
import { Modal, Form, Input, Typography } from "antd";

//external fuctions
import { WIFIConnectModalClose, ConnectToWIFI } from "../../../../actions/utl";
import FormItem from "antd/lib/form/FormItem";

// acutal component
const ModalConnectWIFI = ({
  WIFIConnectModalClose,
  modalConnWIFInfo,
  modalConnWIFIVis,
  ConnectToWIFI,
  WIFIisConnecting,
}) => {
  const [FormData] = Form.useForm();

  const handleOK = () => {
    ConnectToWIFI({
      ssid: modalConnWIFInfo.ssid,
      psk: FormData.getFieldValue("psk"),
    });
  };

  const handleCancel = () => {
    WIFIConnectModalClose();
  };

  return (
    <Modal
      title={"Connect to " + modalConnWIFInfo.ssid}
      visible={modalConnWIFIVis}
      onCancel={handleCancel}
      onOk={handleOK}
      okText="Connect"
      okButtonProps={{ loading: WIFIisConnecting }}
      afterClose={FormData.resetFields()}
    >
      <Typography style={{ textAlign: "center" }}>
        Please enter the password for {modalConnWIFInfo.ssid} and press connect
      </Typography>
      <Form form={FormData} style={{ marginTop: "20px" }} size="small">
        <FormItem label="Password" name="psk">
          <Input.Password disabled={WIFIisConnecting} />
        </FormItem>
      </Form>
    </Modal>
  );
};

ModalConnectWIFI.propTypes = {
  WIFIConnectModalClose: PropTypes.func.isRequired,
  modalConnWIFIVis: PropTypes.bool,
  WIFIisConnecting: PropTypes.bool,
  modalConnWIFInfo: PropTypes.object,
  ConnectToWIFI: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  modalConnWIFIVis: state.utl.modalConnWIFIVis,
  modalConnWIFInfo: state.utl.modalConnWIFInfo,
  WIFIisConnecting: state.utl.WIFIisConnecting,
});
export default connect(mapStateToProps, {
  WIFIConnectModalClose,
  ConnectToWIFI,
})(ModalConnectWIFI);
