import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Ant design components
import { Space, Table, Button, Popconfirm, Tooltip } from "antd";

// Functions
import { fetchGroups } from "../../actions/templates";

// Components
import Spinner from "../layout/Spinner";

const Index = ({ fetchGroups, groupsLoaded, groups }) => {
  // fetch the groups from the DB
  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  const columns = [
    {
      title: "Name",
      dataIndex: "",
      key: "name",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Popconfirm
            title={"Are you sure to delete " + record + " ?"}
            onConfirm={() => {
              handleDelete(record);
            }}
          >
            <Tooltip title={"Click to remove this item"}>
              <a href="#!">Delete</a>
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleDelete = (record) => {
    console.log(record);
  };

  return !groupsLoaded ? (
    <Spinner />
  ) : (
    <Fragment>
      <h1 className="large text-primary">Device Groups</h1>

      <Table
        columns={columns}
        dataSource={groups}
        size="small"
        pagination={{ pageSize: 40 }}
        scroll={{ y: 600 }}
      />

      <Button type="primary" style={{ margin: "10px" }}>
        Create New
      </Button>
    </Fragment>
  );
};

Index.propTypes = {
  groups: PropTypes.array,
  groupsLoaded: PropTypes.bool,
  auth: PropTypes.object.isRequired,
  fetchGroups: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  groups: state.template.groups,
  groupsLoaded: state.template.groupsLoaded,
  auth: state.auth,
});

export default connect(mapStateToProps, { fetchGroups })(Index);
