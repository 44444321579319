import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd components
import { Form, Input, Button, Space, Tooltip, message } from "antd";
import { CopyOutlined, CheckCircleTwoTone } from "@ant-design/icons";

// extneral functions
import { postVPNPublicKey } from "../../actions/utl";

// Actual component
const TabVPN = ({ postVPNPublicKey, keyGenRunning, VPNKey }) => {
  const [copiedIndicator, setCopiedIndicator] = useState(false);
  const [formData] = Form.useForm();

  useEffect(() => {
    formData.setFieldsValue({ VPNKey });
  }, [VPNKey, formData]);

  const copyToClipboard = (textToCopy) => {
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      // navigator clipboard api method'
      return navigator.clipboard.writeText(textToCopy);
    } else {
      // text area method
      let textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      // make the textarea out of viewport
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((res, rej) => {
        // here the magic happens
        document.execCommand("copy") ? res() : rej();
        textArea.remove();
      });
    }
  };

  return (
    <Form
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 15 }}
      size="small"
      form={formData}
    >
      <Form.Item label="IP address" style={{ marginBottom: "0px" }}>
        <Space>
          <Form.Item>
            <Input />
          </Form.Item>
          <Button style={{ color: "orange", marginBottom: "24px" }}>
            Save IP
          </Button>
        </Space>
      </Form.Item>
      <Form.Item
        label="Client public key"
        tooltip="Dont share the key with anyone you dont know"
        style={{ marginBottom: "0px" }}
      >
        <Space>
          <Form.Item style={{ width: "330px" }} name="VPNKey">
            <Input
              disabled
              addonAfter={
                !copiedIndicator ? (
                  <Tooltip title="Click to copy">
                    <CopyOutlined
                      onClick={() => {
                        setCopiedIndicator(true);
                        setTimeout(() => setCopiedIndicator(false), 1000);
                        copyToClipboard(formData.getFieldValue("VPNKey"))
                          .then(() => message.success("Key copied"))
                          .catch(() => message.error("Error in copy"));
                      }}
                    />
                  </Tooltip>
                ) : (
                  <CheckCircleTwoTone twoToneColor="#52c41a" />
                )
              }
            />
          </Form.Item>
          <Tooltip title="This will generate a new public and private keys">
            <Button
              onClick={() => {
                postVPNPublicKey();
              }}
              style={{ color: "green", marginBottom: "24px" }}
              loading={keyGenRunning}
            >
              New Key
            </Button>
          </Tooltip>
        </Space>
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 9, span: 4 }}>
        <Space>
          <Button type={"primary"}>Auto Setup</Button>
          <Button>Test Connection</Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

TabVPN.propTypes = {
  postVPNPublicKey: PropTypes.func.isRequired,
  keyGenRunning: PropTypes.bool,
  VPNKey: PropTypes.string,
};

const mapStateToProps = (state) => ({
  keyGenRunning: state.utl.VPNKeyGenRunning,
  VPNKey: state.utl.VPNKey,
});

export default connect(mapStateToProps, { postVPNPublicKey })(TabVPN);
