import React, { Fragment, useEffect } from "react";

import { Card, Col, Row, Button } from "antd";
import { Link, useParams } from "react-router-dom";
import { getConfigDetails } from "../../../../actions/modbusconfig";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const Details = ({ getConfigDetails, configDetails, isLoaded }) => {
  // const { devices, name, createdAt, updatedAt, user, current } = configDetails;
  const { config } = configDetails;
  let { id } = useParams();

  useEffect(() => {
    getConfigDetails(id);
  }, [getConfigDetails, id]);

  const PrintIPAddress = (IPAddress) => <Fragment>IP: {IPAddress}</Fragment>;
  const PrintSlaveID = (SlaveID) => <Fragment>SlaveID: {SlaveID}</Fragment>;

  let DetailsCard = [];

  try {
    DetailsCard = config.map((item, index) => (
      <Fragment>
        <Col span={2}></Col>
        <Col span={6}>
          <Card key={index} title={item.config.Name} bordered={false}>
            Interface: {item.config.interfaceType === 0 ? "Ethernet" : "RS485"}
            <br />
            Poll Rate: {item.config.pollrate} Seconds
            <br />
            Device Type: {item.config.devType}
            <br />
            {item.config.interface === 0
              ? PrintIPAddress(item.config.sidIP)
              : PrintSlaveID(item.config.sidIP)}
          </Card>
        </Col>
      </Fragment>
    ));
  } catch (error) {}

  return (
    <div style={{ background: "#ececec", padding: "30px" }}>
      {isLoaded && <Row>{DetailsCard}</Row>}
      <Link to="/tools/config/modbus">
        <Button type="primary">Back</Button>
      </Link>
    </div>
  );
};

Details.propTypes = {
  getConfigDetails: PropTypes.func.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  configDetails: PropTypes.object.isRequired,
};

const mapStateToProp = (state) => ({
  configDetails: state.modbusConfig.configDetails,
  isLoaded: state.modbusConfig.hasDetailData,
});

export default connect(mapStateToProp, { getConfigDetails })(Details);
