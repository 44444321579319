import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Ant design components
import { Space, Form, Select, Button, Typography, Row, Col } from "antd";

// functions
import { updateSiteDetails } from "../../actions/site";
import {
  fetchTemplates,
  updateClients,
  getSitesFromClient,
} from "../../actions/client";

// Components
import SiteContact from "./siteContact";

const { Title, Paragraph, Text } = Typography;

// actual component
const SiteDetail = ({
  updateSiteDetails,
  fetchTemplates,
  sitedetails,
  updateClients,
  getSitesFromClient,
  user,
  client: { clientsList, dataloaded, clientSiteListLoaded, clientSiteList },
}) => {
  const [siteName, setSiteName] = useState(
    sitedetails ? sitedetails.name : null
  );

  // fetch any clients in the DB
  useEffect(() => {
    fetchTemplates();
  }, [fetchTemplates]);

  // get clients from the server
  const handleUpdate = () => {
    updateClients();

    window.location.reload(false);
  };

  // if items on the form change
  const handleFormDataChange = (data) => {
    if (data.client_name !== undefined) {
      // get the list of sites for this client
      getSitesFromClient(data.client_name);

      // update the site details
      if (data.name !== undefined) {
        const siteDetails = clientSiteList.filter(
          (site) => site.name === data.name
        )[0];

        console.log("siteDetails", siteDetails);

        siteDetails.client_name = data.client_name;
        updateSiteDetails(siteDetails);
      } else {
        updateSiteDetails({ client_name: data.client_name });
      }
    }

    if (data.name) {
      const siteDetails = clientSiteList.filter(
        (site) => site.name === data.name
      )[0];
      updateSiteDetails(siteDetails);
      setSiteName(data.name);
    } else setSiteName(null);
  };

  // Select list items, client list
  const clientsListing =
    clientsList &&
    clientsList.map((client) => (
      <Select.Option value={client.name} key={client.name}>
        {client.name}
      </Select.Option>
    ));

  // Select list items, client list
  const clientsSiteListing =
    clientSiteList &&
    clientSiteList.map((site) => (
      <Select.Option value={site.name} key={site._id}>
        {site.name}
      </Select.Option>
    ));

  return (
    <div style={{ marginTop: "3vh" }}>
      {siteName === null && (
        <Row style={{ marginBottom: "20px" }}>
          <Col sm={0} lg={5} xl={7}></Col>
          <Col sm={24} lg={14} xl={10}>
            <Typography style={{ textAlign: "center" }}>
              <Title>Welcome {user && user.name && user.name}!</Title>
              <Title level={2}>Let's configure your UC 4!</Title>
              <Paragraph>
                <Text strong>
                  Welcome,
                  {user && user.name && " " + user.name + " " + user.surname}
                </Text>
                {", "}
                to the configuration of the <Text strong>UC 4!</Text> I see this
                is your <Text strong>first time here</Text> and the device you
                are accessing has not yet been configured.
              </Paragraph>
              <Paragraph>To get started:</Paragraph>
              <Paragraph
                style={{
                  marginBottom: "0px",
                  textAlign: "left",
                  marginLeft: "20%",
                }}
              >
                1) Select a client
              </Paragraph>
              <Paragraph
                style={{
                  marginBottom: "20px",
                  textAlign: "left",
                  marginLeft: "20%",
                }}
              >
                2) Then, select the site you are configuring pertaining to the
                selected client from the dropdown.
              </Paragraph>
              <Paragraph style={{ textAlign: "left" }}>
                If you do not see the client or site and client combination you
                are looking for, please contact the Master Power Technologies
                Administrator overseeing your access to add the relevant client
                and/or site.
              </Paragraph>
              <Paragraph style={{ textAlign: "left" }}>
                Once the administrator confirms the addition of said
                site/client, please click <Text keyboard>Update List</Text>, and
                select the newly added client and/or site.
              </Paragraph>
            </Typography>
          </Col>
        </Row>
      )}

      <Form
        labelCol={{ span: 4, offset: 5 }}
        wrapperCol={{ span: 5 }}
        initialValues={sitedetails}
        onValuesChange={(_, allValues) => {
          handleFormDataChange(allValues);
        }}
      >
        {siteName === null && (
          <Form.Item label="Client" style={{ marginBottom: "-20px" }}>
            <Space align="baseline">
              <Form.Item
                name="client_name"
                extra="Don't see your client, add on it clientzone"
              >
                <Select
                  allowClear
                  showSearch
                  style={{ minWidth: "150px", width: "250px" }}
                  loading={!dataloaded}
                >
                  {clientsListing}
                </Select>
              </Form.Item>
              <Button onClick={handleUpdate}>Update List</Button>
            </Space>
          </Form.Item>
        )}

        {sitedetails && sitedetails.client_name && (
          <Form.Item
            label="Site Name"
            name="name"
            extra="Don't see your site, add on it clientzone"
            style={{ marginBottom: "-10px" }}
          >
            <Select allowClear showSearch loading={!clientSiteListLoaded}>
              {clientsSiteListing}
            </Select>
          </Form.Item>
        )}
      </Form>

      {siteName !== null && <SiteContact />}
    </div>
  );
};

SiteDetail.propTypes = {
  updateSiteDetails: PropTypes.func.isRequired,
  getSitesFromClient: PropTypes.func.isRequired,
  fetchTemplates: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  sitedetails: PropTypes.object,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  sitedetails: state.site.siteDetails,
  client: state.client,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  updateSiteDetails,
  fetchTemplates,
  updateClients,
  getSitesFromClient,
})(SiteDetail);
