import {
  ALARMS_LOADING,
  ALARMS_MODAL_CLOSE,
  ALARMS_MODAL_OPEN,
  ALARMS_SAVING,
  ALARMS_UPDATED,
} from "../actions/types";

const initalState = {
  alarmsList: null,
  modalVisability: false,
  modalisEdit: false,
  modalData: null,
  loading: false,
  saving: false,
};

export default function alarms(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case ALARMS_SAVING:
      return { ...state, saving: payload };
    case ALARMS_UPDATED:
      return { ...state, loading: false, alarmsList: payload };
    case ALARMS_LOADING:
      return { ...state, loading: true };
    case ALARMS_MODAL_OPEN:
      return {
        ...state,
        modalVisability: true,
        modalisEdit: payload.isEdit,
        modalData: payload.data ? payload.data : null,
      };
    case ALARMS_MODAL_CLOSE:
      return { ...state, modalVisability: false, modalData: null };
    default:
      return state;
  }
}
