import {
  TYPE_FAIL,
  TYPE_LOADING_DETAILED,
  TYPE_LOADING_SHORT,
  TYPE_MODAL_ADDALA_CLOSE,
  TYPE_MODAL_ADDALA_OPEN,
  TYPE_MODAL_ADDVAR_CLOSE,
  TYPE_MODAL_ADDVAR_OPEN,
  TYPE_MODAL_CLOSE,
  TYPE_MODAL_OPEN,
  TYPE_MODAL_OPEN_EDIT,
  TYPE_UPDATED_DETAILED,
  TYPE_UPDATED_SHORT,
  TYPE_UPDATE_CURRENT,
} from "./types";

import setAuthToken from "../utils/setAuthToken";
import axios from "axios";
import { setAlert } from "./alert";

// Get the device types from the DB, this is the detailed version
export const getDeviceTypesDetailed = () => async (dispatch) => {
  dispatch({
    type: TYPE_LOADING_DETAILED,
  });

  const devTypes = await GetTypesFromController();

  if (devTypes === true) {
    dispatch(setAlert("Types list is blank", "error"));

    dispatch({
      type: TYPE_FAIL,
    });
  } else {
    dispatch({
      type: TYPE_UPDATED_DETAILED,
      payload: devTypes,
    });
  }
};

const GetTypesFromController = () => {
  return new Promise(async (resolve, reject) => {
    try {
      if (localStorage.token) {
        setAuthToken(localStorage.token);
      }

      let devTypes = await axios.get("/api/devtemplates/devtype");
      devTypes = devTypes.data;

      return resolve(devTypes);
    } catch (error) {
      return resolve(true);
    }
  });
};

// Get the device types from the DB, this is the shortend version
export const getDeviceTypesShort = () => async (dispatch) => {
  dispatch({
    type: TYPE_LOADING_SHORT,
  });

  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const devTypes = await axios.get("/api/devtemplates/devicetypes");

    dispatch({
      type: TYPE_UPDATED_SHORT,
      payload: devTypes.data,
    });
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
    }

    dispatch({
      type: TYPE_FAIL,
    });
  }
};

// Update the types from the server
export const updateDeviceTypes =
  (fromWizard = false) =>
  async (dispatch) => {
    let isEmpty = true;

    // from the wizard we dont want to wait for this to finish
    if (fromWizard) isEmpty = await GetTypesFromController();

    if (isEmpty === true) {
      dispatch({
        type: TYPE_LOADING_DETAILED,
      });

      try {
        if (localStorage.token) {
          setAuthToken(localStorage.token);
        }

        await axios.get("/api/devtemplates/devicetypes/update");

        const devTypes = await GetTypesFromController();

        dispatch({
          type: TYPE_UPDATED_DETAILED,
          payload: devTypes,
        });

        dispatch(setAlert("Device types updated from server", "success"));
      } catch (error) {
        const errors = error.response.data.errors;

        if (errors) {
          errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
        }

        dispatch({
          type: TYPE_FAIL,
        });
      }
    }
  };

// Create a new device type
export const createDeviceType = (newType) => async (dispatch) => {
  dispatch({
    type: TYPE_LOADING_DETAILED,
  });

  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    //   const data = {
    //     name: typeName,
    //     type: typeNumber,
    //     vaiables,
    //   };

    let devTypes = await axios.post("/api/devtemplates/devtype", newType);
    // fetch the list
    devTypes = await axios.get("/api/devtemplates/devtype");

    dispatch({
      type: TYPE_UPDATED_DETAILED,
      payload: devTypes.data,
    });
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
    }

    dispatch({
      type: TYPE_FAIL,
    });
  }
};

// remove a device type
export const removeDeviceType = (id) => async (dispatch) => {
  dispatch({
    type: TYPE_LOADING_DETAILED,
  });

  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    // send the request to delete to backend
    let devTypes = await axios.delete("/api/devtemplates/devtype/" + id);
    // update the list
    devTypes = await axios.get("/api/devtemplates/devtype");

    dispatch({
      type: TYPE_UPDATED_DETAILED,
      payload: devTypes.data,
    });
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
    }

    dispatch({
      type: TYPE_FAIL,
    });
  }
};

// open modal
export const openTypeModal = () => (dispatch) => {
  dispatch({
    type: TYPE_MODAL_OPEN,
  });
};

// open modal for edit
export const openTypeModalEdit = (typeTOEdit) => (dispatch) => {
  const payload = JSON.parse(JSON.stringify(typeTOEdit));

  dispatch({
    type: TYPE_MODAL_OPEN_EDIT,
    payload,
  });
};

// close modal
export const closeTypeModal = () => (dispatch) => {
  dispatch({
    type: TYPE_MODAL_CLOSE,
  });
};

// Open the add var modal
export const openTypeAddVarModal = () => (dispatch) => {
  dispatch({
    type: TYPE_MODAL_ADDVAR_OPEN,
  });
};

// close the add var modal
export const closeTypeAddVarModal = () => (dispatch) => {
  dispatch({
    type: TYPE_MODAL_ADDVAR_CLOSE,
  });
};

// Open the add alarm modal
export const openTypeAddAlaModal = () => (dispatch) => {
  dispatch({
    type: TYPE_MODAL_ADDALA_OPEN,
  });
};

// close the add alarm modal
export const closeTypeAddAlaModal = () => (dispatch) => {
  dispatch({
    type: TYPE_MODAL_ADDALA_CLOSE,
  });
};

// update the editing type
export const updateCurrentType = (data) => (dispatch) => {
  dispatch({
    type: TYPE_UPDATE_CURRENT,
    payload: data,
  });
};

// update the editing type
export const updateTypesList = (data) => async (dispatch) => {
  try {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    const Newdata = {
      name: data.device_type_name,
      vaiables: data.vairables,
      alarms: data.alarms ? data.alarms : [],
    };
    // Post to the database
    await axios.post("/api/devtemplates/devtype", Newdata);
    // Get the entire list
    const devTypes = await axios.get("/api/devtemplates/devtype");

    dispatch({
      type: TYPE_UPDATED_DETAILED,
      payload: devTypes.data,
    });

    dispatch(setAlert("Device type was edited", "success"));
  } catch (error) {
    console.log(error);
  }
};
