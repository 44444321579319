import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Antd comonent
import { Form, Button, Tooltip, Row, Col, Typography } from "antd";

// external functions
import {
  updateHMIComponents,
  updateConfigServices,
  updateBackendServices,
  updateHMIServices,
} from "../../actions/site";
import {
  updateManufacturesList,
  updateTemplates,
} from "../../actions/templates";
import { updateClients } from "../../actions/client";
import { updateCalcsList } from "../../actions/calculations";
import { updateDeviceTypes } from "../../actions/deviceTypes";

const { Title } = Typography;

// Actual componet
const TabUpdates = ({
  updatingBackend,
  updateHMIComponents,
  updatingHMIServices,
  updateConfigServices,
  updatingBkServices,
  updateBackendServices,
  updateHMIServices,
  updateDeviceTypes,
  updateCalcsList,
  updateClients,
  updateTemplates,
  updateManufacturesList,
}) => {
  const [updatingAll, setUpdatingAll] = useState(false);

  const HandleUpdateAll = async () => {
    setUpdatingAll(true);

    await updateClients();
    await updateManufacturesList();
    await updateTemplates();
    await updateDeviceTypes();
    await updateCalcsList();

    setUpdatingAll(false);
  };

  return (
    <Row>
      <Col
        xs={24}
        md={11}
        style={{
          borderRadius: "5px",
          borderStyle: "dashed",
          borderWidth: "1px",
        }}
      >
        <Typography>
          <Title style={{ textAlign: "center" }} level={3}>
            Config Tool Updates
          </Title>
        </Typography>
        <Form labelCol={{ span: 9 }} wrapperCol={{ span: 5 }} size="small">
          <Form.Item
            label="Updates config tool services"
            style={{ marginBottom: "5px" }}
          >
            <Tooltip title="Click to update the services to the latest version, requires internet">
              <Button onClick={updateConfigServices} loading={updatingBackend}>
                Config tool
              </Button>
            </Tooltip>
          </Form.Item>
          <Form.Item
            label="Updates the backend services"
            style={{ marginBottom: "5px" }}
          >
            <Tooltip title="Click to update the services to the latest version, requires internet">
              <Button
                onClick={updateBackendServices}
                loading={updatingBkServices}
              >
                Backend services
              </Button>
            </Tooltip>
          </Form.Item>
          <Form.Item
            label="Updates the Databases"
            style={{ marginBottom: "5px" }}
          >
            <Tooltip title="Click to update the devices type and templates databases, requires internet">
              <Button onClick={HandleUpdateAll} loading={updatingAll}>
                Databases
              </Button>
            </Tooltip>
          </Form.Item>
        </Form>
      </Col>
      <Col xs={1} md={2}></Col>
      <Col
        xs={24}
        md={11}
        style={{
          borderRadius: "5px",
          borderStyle: "dashed",
          borderWidth: "1px",
        }}
      >
        <Typography>
          <Title style={{ textAlign: "center" }} level={3}>
            HMI Updates
          </Title>
        </Typography>
        <Form labelCol={{ span: 9 }} wrapperCol={{ span: 5 }} size="small">
          <Form.Item
            label="Updates HMI services"
            style={{ marginBottom: "5px" }}
          >
            <Tooltip title="Click to update the services including the page to the latest version, requires internet">
              <Button onClick={updateHMIServices} loading={updatingHMIServices}>
                HMI services
              </Button>
            </Tooltip>
          </Form.Item>
          <Form.Item
            label="Updates HMI components from the server"
            style={{ marginBottom: "5px" }}
          >
            <Tooltip title="Click to fetch the latest components from the server, required internet">
              <Button onClick={updateHMIComponents}>HMI components</Button>
            </Tooltip>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

TabUpdates.propTypes = {
  updatingBackend: PropTypes.bool,
  updatingHMIServices: PropTypes.bool,
  updatingBkServices: PropTypes.bool,
  updateHMIComponents: PropTypes.func.isRequired,
  updateConfigServices: PropTypes.func.isRequired,
  updateBackendServices: PropTypes.func.isRequired,
  updateHMIServices: PropTypes.func.isRequired,
  updateDeviceTypes: PropTypes.func.isRequired,
  updateCalcsList: PropTypes.func.isRequired,
  updateClients: PropTypes.func.isRequired,
  updateTemplates: PropTypes.func.isRequired,
  updateManufacturesList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  updatingBackend: state.site.updateConfigBackend,
  updatingBkServices: state.site.updatingBkServices,
  updatingHMIServices: state.site.updatingHMIServices,
});

export default connect(mapStateToProps, {
  updateHMIComponents,
  updateConfigServices,
  updateBackendServices,
  updateHMIServices,
  updateDeviceTypes,
  updateCalcsList,
  updateClients,
  updateTemplates,
  updateManufacturesList,
})(TabUpdates);
