import axios from "axios";
import {
  CLIENT_FAIL,
  CLIENT_LOADING,
  CLIENT_UPDATED,
  CLIENT_SITE_UPDATE,
  CLIENT_SITE_LOADING,
  CLIENT_SITE_FAIL,
} from "./types";
import { setAlert } from "./alert";
import setAuthToken from "../utils/setAuthToken";

// Update the list of clients
export const updateClients =
  (fromWizard = false) =>
  async (dispatch) => {
    let isEmpty = true;

    // from the wizard we dont want to wait for this to finish
    if (fromWizard) isEmpty = await getClietsData();

    if (isEmpty === true) {
      dispatch({
        type: CLIENT_LOADING,
      });

      if (localStorage.token) {
        setAuthToken(localStorage.token);
      }

      try {
        let clientsList = await axios.get("/api/clients/update");

        clientsList = clientsList.data;
        clientsList.map((client, index) => (client.key = index));

        dispatch({
          type: CLIENT_UPDATED,
          payload: clientsList,
        });
        dispatch(setAlert("Clients list updated from server", "success"));
      } catch (error) {
        const errors = error.response.data.errors;

        if (errors) {
          errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
        }

        dispatch({
          type: CLIENT_FAIL,
        });
      }
    } else {
      isEmpty.map((clientInfo, index) => {
        return (clientInfo.key = index);
      });

      dispatch({
        type: CLIENT_UPDATED,
        payload: isEmpty,
      });
    }
  };

const getClietsData = () => {
  return new Promise(async (resolve, reject) => {
    try {
      if (localStorage.token) {
        setAuthToken(localStorage.token);
      }

      const res = await axios.get("/api/clients");

      return resolve(res.data);
    } catch (error) {
      return resolve(true);
    }
  });
};

// Update the clients list
export const fetchTemplates = () => async (dispatch) => {
  dispatch({
    type: CLIENT_SITE_LOADING,
  });

  let clientsList = await getClietsData();

  if (clientsList === true) {
    dispatch(setAlert("Clients List Empty", "error"));

    dispatch({
      type: CLIENT_FAIL,
    });
  } else {
    clientsList.map((clientInfo, index) => {
      return (clientInfo.key = index);
    });

    dispatch({
      type: CLIENT_UPDATED,
      payload: clientsList,
    });
  }
};

export const getSitesFromClient = (clientName) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    // remove any white space at the start and the end if any
    clientName = clientName.trim();
    // request to backend service
    const clientsList = await axios.get("/api/clients/sites/" + clientName);

    dispatch({
      type: CLIENT_SITE_UPDATE,
      payload: clientsList.data,
    });
  } catch (error) {
    if (error.response) {
      const statusCode = error.response.status;

      if (statusCode === 400) {
        const errors = error.response.data.msg;

        if (errors) {
          dispatch(setAlert(errors, "error"));
        }
      }
    }

    dispatch({
      type: CLIENT_SITE_FAIL,
    });
  }
};
