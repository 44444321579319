import {
  TEMPLATE_FAIL,
  TEMPLATE_LOADING,
  TEMPLATE_UPDATED,
  TEMPLATE_CLEAR,
  TEMPLATE_MODAL_CLOSE,
  TEMPLATE_MODAL_OPEN,
  TEMPLATE_CREATE_DETAILS,
  MANUFACTURE_FAIL,
  MANUFACTURE_LOADING,
  TEMPLATE_UPDATE_MAP,
  MANUFACTURE_UPDATED,
  TEMPLATE_MODAL_BATCH_CLOSE,
  TEMPLATE_MODAL_BATCH_OPEN,
  TEMPLATE_MODAL_MODBUSMAPPING_CLOSE,
  TEMPLATE_MODAL_MODBUSMAPPING_OPEN,
  GROUPS_FAIL,
  GROUPS_UPDATED,
  GROUPS_LOADING,
  TEMPLATE_MODAL_ALARMEDIT_OPEN,
  TEMPLATE_MODAL_ALARMEDIT_CLOSE,
  TEMPLATE_UPDATE_ALARM,
  TEMPLATE_ALARMEDIT_UPDATE,
  TEMPLATE_MODAL_COPYALA_CLOSE,
  TEMPLATE_MODAL_COPYALA_OPEN,
  TEMPLATE_MODAL_CALCADD_CLOSE,
  TEMPLATE_MODAL_CALCADD_OPEN,
  TEMPLATE_UPDATE_CALC,
  BUSSES_LOADING,
  BUSSES_UPDATE,
} from "../actions/types";

const initalState = {
  dataloaded: false,
  templates: null,
  modalOpen: false,
  newTemplate: null,
  manufacturesLoading: false,
  manufactures: null,
  addBatchModalOpen: false,
  EditTemplate: false,
  modbusAddressMappingModalOpen: false,
  modbusAddressDetails: null,
  groupsLoaded: false,
  groups: null,
  bussesLoading: false,
  busses: null,
  alarmsEditModalOpen: false,
  alarmsModalDetails: null,
  copyAlarmModalVisabiliy: false,
  modalVisCalcAdd: false,
  modalVisCalcAddData: null,
};

export default function template(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case BUSSES_UPDATE:
      return { ...state, bussesLoading: payload };
    case BUSSES_LOADING:
      return { ...state, busses: payload, bussesLoading: false };
    case TEMPLATE_MODAL_CALCADD_OPEN:
      return { ...state, modalVisCalcAdd: true, modalVisCalcAddData: payload };
    case TEMPLATE_MODAL_CALCADD_CLOSE:
      return { ...state, modalVisCalcAdd: false, modalVisCalcAddData: null };
    case TEMPLATE_UPDATE_ALARM:
      let alarmDetails = { ...state.newTemplate };
      alarmDetails.Alarms = payload;

      return { ...state, newTemplate: alarmDetails };
    case TEMPLATE_UPDATE_CALC:
      let calc = { ...state.newTemplate };
      calc.Calculations = [];
      calc.Calculations = payload;

      return { ...state, newTemplate: { ...calc } };
    case TEMPLATE_UPDATE_MAP:
      let template = { ...state.newTemplate };
      template.Batchs[payload.index].Map = payload.data;

      return { ...state, newTemplate: template };
    case MANUFACTURE_FAIL:
      return { ...state, manufacturesLoading: false, manufactures: null };
    case MANUFACTURE_UPDATED:
      return { ...state, manufacturesLoading: false, manufactures: payload };
    case MANUFACTURE_LOADING:
      return { ...state, manufacturesLoading: true };
    case TEMPLATE_MODAL_CLOSE:
      return {
        ...state,
        modalOpen: false,
        newTemplate: null,
        EditTemplate: false,
      };
    case TEMPLATE_MODAL_OPEN:
      return { ...state, modalOpen: true, EditTemplate: payload };
    case TEMPLATE_MODAL_BATCH_CLOSE:
      return { ...state, addBatchModalOpen: false };
    case TEMPLATE_MODAL_BATCH_OPEN:
      return { ...state, addBatchModalOpen: true };
    case TEMPLATE_CREATE_DETAILS:
      return { ...state, newTemplate: payload };
    case GROUPS_LOADING:
      return { ...state, groupsLoaded: false };
    case GROUPS_UPDATED:
      return { ...state, groupsLoaded: true, groups: payload };
    case TEMPLATE_UPDATED:
      return { ...state, dataloaded: true, templates: payload };
    case TEMPLATE_MODAL_MODBUSMAPPING_OPEN:
      return {
        ...state,
        modbusAddressMappingModalOpen: true,
        modbusAddressDetails: payload,
      };
    case TEMPLATE_MODAL_MODBUSMAPPING_CLOSE:
      return {
        ...state,
        modbusAddressMappingModalOpen: false,
        modbusAddressDetails: null,
      };
    case TEMPLATE_ALARMEDIT_UPDATE:
      return {
        ...state,
        alarmsModalDetails: payload,
      };
    case TEMPLATE_MODAL_ALARMEDIT_OPEN:
      return {
        ...state,
        alarmsEditModalOpen: true,
        alarmsModalDetails: payload,
      };
    case TEMPLATE_MODAL_ALARMEDIT_CLOSE:
      return {
        ...state,
        alarmsEditModalOpen: false,
        alarmsModalDetails: null,
      };
    case TEMPLATE_MODAL_COPYALA_OPEN:
      return {
        ...state,
        copyAlarmModalVisabiliy: true,
        alarmsModalDetails: payload,
      };
    case TEMPLATE_MODAL_COPYALA_CLOSE:
      return {
        ...state,
        copyAlarmModalVisabiliy: false,
        alarmsModalDetails: null,
      };
    case TEMPLATE_LOADING:
    case TEMPLATE_CLEAR:
    case GROUPS_FAIL:
    case TEMPLATE_FAIL:
      return {
        ...state,
        dataloaded: false,
        templates: null,
        groups: null,
        busses: null,
        modalOpen: false,
        groupsLoaded: false,
      };
    default:
      return state;
  }
}
