import {
  ALARMS_LOADING,
  ALARMS_MODAL_CLOSE,
  ALARMS_MODAL_OPEN,
  ALARMS_SAVING,
  ALARMS_UPDATED,
} from "./types";
import setAuthToken from "../utils/setAuthToken";
import axios from "axios";
import { setAlert } from "./alert";

// Create the alarms list
export const CreateAlarmsList = (currentConfig) => async (dispatch) => {
  // this function is divided into 3 sections,
  // 1) create the list from the devices
  // 2) check with the saved list on the DB to see if the devices are the save
  // 3) save the list again

  dispatch({
    type: ALARMS_SAVING,
    payload: true,
  });

  // Section 1: create the list from the device list
  let comibinedList = [];

  comibinedList = await getAlarmsFromTemplateList(currentConfig);

  // Section 2: get the list from the DB and match them with this new one
  const dbList = await axios.get("/api/alarm");

  // Check to see that we are actually getting data back, on the wizard this would be null
  if (dbList.data) {
    dbList.data.alarmsList.map((dbAlarm) => {
      if (dbAlarm.device_type !== "Input Board") {
        const indexOfAlarm = comibinedList.findIndex(
          (newAlarm) =>
            newAlarm.device_type === dbAlarm.device_type &&
            newAlarm.device_name === dbAlarm.device_name
        );

        // there is a match overwrite the settings of the new list with the items in the old list, but only the alarm that are old, if there is a new one it will need to be added
        if (indexOfAlarm !== -1) {
          let newAlarmMap = [];

          comibinedList[indexOfAlarm].alarmMap.map((mapAlarm) => {
            const newAlarmMapConditions = { ...mapAlarm };

            const individualMappedAlarmIndex = dbAlarm.alarmMap.findIndex(
              (dbAlarmMap) => dbAlarmMap.name === mapAlarm.name
            );

            // match found
            if (individualMappedAlarmIndex !== -1) {
              let newAlarmDetails = [];

              mapAlarm.alarmDetail.map((alarmDetail) => {
                const indexMapDetail = dbAlarm.alarmMap[
                  individualMappedAlarmIndex
                ].alarmDetail.findIndex(
                  (detail) =>
                    detail.alarmName === alarmDetail.alarmName &&
                    detail.alarmPriority === alarmDetail.alarmPriority
                );

                // Match Found
                if (indexMapDetail !== -1) {
                  let tempObject = {
                    ...dbAlarm.alarmMap[individualMappedAlarmIndex].alarmDetail[
                      indexMapDetail
                    ],
                  };

                  if (tempObject.device_name) delete tempObject.device_name;
                  if (tempObject.device_type) delete tempObject.device_type;

                  newAlarmDetails.push(tempObject);
                } else {
                  newAlarmDetails.push({ ...alarmDetail });
                }

                return true;
              });

              newAlarmMapConditions.alarmDetail = newAlarmDetails;

              newAlarmMap.push({ ...newAlarmMapConditions });
            } else {
              newAlarmMap.push({ ...mapAlarm });
            }

            return true;
          });

          comibinedList[indexOfAlarm].alarmMap = newAlarmMap;
        }
      } else {
        console.log("Alarms creationg - Input board found, skipping");
      }

      return true;
    });
  }

  // Section 3: save to the DB
  if (await saveListTODB(comibinedList)) {
    dispatch(setAlert("Alarms list is saved", "success"));
  } else {
    dispatch(setAlert("Alarms list not saved", "error"));
  }

  dispatch({
    type: ALARMS_SAVING,
    payload: false,
  });
};

// Saving to the DB via the backend API
const saveListTODB = async (list) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios.post(
      "/api/alarm",
      {
        alarmingList: list,
      },
      config
    );

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

// Get the alarms for each device from the DB
const getAlarmsFromTemplateList = async (configutaion) => {
  try {
    // set the auth token
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    // Get all the templates to be able to extract the children from the list
    const devTemplates = await axios.get("/api/devtemplates");
    // get the calcs list
    const allCalcs = await axios.get("/api/calcs");

    let comibinedList = [];

    // Make up the parents list, first
    if (configutaion) {
      configutaion.map((config, _) => {
        let thisDeviceTemplate = devTemplates.data.find(
          (template) => template._id === config.Information.template._id
        );

        // the Inputs are a special case, and need extra work
        if (config.Information.deviceType === "Input Board") {
          let alarmMap = [];

          // go through all the inputs
          config.Details.childNames.map((alarm) => {
            // check to see if there is at the least a type, this is the minimum
            if (alarm.deviceType !== "") {
              // if it the first alarm, then we create the map as they all belong under one map because there is only one variable
              if (alarmMap.length === 0) {
                alarmMap.push({
                  name: "Inputs",
                  isFixed: true,
                  alarmDetail: [],
                });
              }

              alarmMap[0].alarmDetail.push({
                alarmName: alarm.alarmName,
                deviceType: alarm.deviceType,
                deviceName: alarm.deviceName,
                alarmPriority: alarm.alarmPriority,
                key: alarm.key,
                binaryBits: alarm.binaryBits,
                floor: alarm.floor,
                group: alarm.group,
                alarmDelayOn: alarm.alarmDelayOn,
                alarmDelayOff: alarm.alarmDelayOff,
                alarmLevel: alarm.alarmLevel,
                alarmOperation: alarm.alarmOperation,
              });
            }

            return true;
          });

          if (alarmMap.length > 0) {
            // Create the Device list
            let newAlarmsItem = {
              device_name: config.Information.deviceName,
              device_type: config.Information.deviceType,
              alarmMap,
            };

            comibinedList.push(newAlarmsItem);
          }
        } else if (config.Information.deviceType === "Fuel Sensor") {
          let thisCalcTemplate = allCalcs.data.find(
            (template) => template._id === config.Details.calculationID
          );

          // Calc template exists
          if (thisCalcTemplate) {
            // check to see if it has outputs
            if (thisCalcTemplate.outputs) {
              // got through each one
              thisCalcTemplate.outputs.map((calcOutput) => {
                // check to see if there is a alarm name assigned
                if (calcOutput.alarmName) {
                  // Create the Device list
                  let newAlarmsItem = {
                    device_name: config.Information.deviceName,
                    device_type: calcOutput.outputType,
                    alarmMap: [
                      {
                        name: calcOutput.outputVariable,
                        isFixed: false,
                        alarmDetail: [
                          {
                            alarmName: calcOutput.alarmName,
                            alarmLevel: calcOutput.alarmLevel,
                            alarmOperation: calcOutput.alarmOperation,
                            alarmDelayOn: calcOutput.alarmDelayOn,
                            alarmDelayOff: calcOutput.alarmDelayOff,
                            alarmPriority: calcOutput.alarmPriority,
                            key: calcOutput.key,
                          },
                        ],
                      },
                    ],
                  };

                  comibinedList.push(newAlarmsItem);
                }

                return true;
              });
            }
          }
        } else if (config.Information.deviceType === "Analogue Input Board") {
          // check to see if it exisits
          if (config.Details.childNames) {
            config.Details.childNames.map((child) => {
              // check to see if it is mapped
              if (child.deviceName) {
                let thisCalcTemplate = allCalcs.data.find(
                  (template) => template._id === child.calculationID
                );

                // Calc template exists
                if (thisCalcTemplate) {
                  // check to see if it has outputs
                  if (thisCalcTemplate.outputs) {
                    // got through each one
                    thisCalcTemplate.outputs.map((calcOutput) => {
                      // check to see if there is a alarm name assigned
                      if (calcOutput.alarmName) {
                        // Create the Device list
                        let newAlarmsItem = {
                          device_name: child.deviceName,
                          device_type: calcOutput.outputType,
                          alarmMap: [
                            {
                              name: calcOutput.outputVariable,
                              isFixed: false,
                              alarmDetail: [
                                {
                                  alarmName: calcOutput.alarmName,
                                  alarmLevel: calcOutput.alarmLevel,
                                  alarmOperation: calcOutput.alarmOperation,
                                  alarmDelayOn: calcOutput.alarmDelayOn,
                                  alarmDelayOff: calcOutput.alarmDelayOff,
                                  alarmPriority: calcOutput.alarmPriority,
                                  key: calcOutput.key,
                                },
                              ],
                            },
                          ],
                        };

                        comibinedList.push(newAlarmsItem);
                      }

                      return true;
                    });
                  }
                }
              }
              return true;
            });
          }
        } else {
          // check to see if there is any alarms on this template, and the template is valid
          if (thisDeviceTemplate) {
            if (thisDeviceTemplate.Alarms) {
              // if this is a primary device
              if (config.Information.deviceName) {
                if (thisDeviceTemplate.Alarms.length > 0) {
                  let alarmMap = [];
                  let deviceName = "";
                  let childType = "";

                  // Create the Device list
                  let newAlarmsItem = {
                    device_name: "",
                    device_type: "",
                    alarmMap,
                  };

                  thisDeviceTemplate.Alarms.map((alarm) => {
                    if (alarm.enabled) {
                      if (alarm.childname) {
                        if (thisDeviceTemplate.commProtocol === "SNMP") {
                          childType = alarm.dataType;
                        } else {
                          thisDeviceTemplate.Batchs.every(
                            (batchItem) =>
                              batchItem.Map &&
                              batchItem.Map.every((batchMapItem) => {
                                if (batchMapItem.ExtraChildren) {
                                  if (
                                    batchMapItem.ExtraChildren.name ===
                                    alarm.childname
                                  ) {
                                    childType = batchMapItem.ExtraChildren.type;
                                    return false;
                                  }
                                }
                                return true;
                              })
                          );
                        }

                        // Battery Banks has a redundant battery test at the end of it
                        if (config.Information.deviceType !== "Battery Bank") {
                          deviceName =
                            config.Information.deviceName +
                            " " +
                            alarm.childname +
                            " " +
                            childType;
                        } else {
                          deviceName =
                            config.Information.deviceName +
                            " " +
                            alarm.childname;
                        }
                      } else {
                        deviceName = config.Information.deviceName;
                        childType = config.Information.deviceType;
                      }

                      const match = comibinedList.findIndex(
                        (listItem) => listItem.device_name === deviceName
                      );

                      if (match !== -1) {
                        newAlarmsItem = comibinedList[match];

                        newAlarmsItem.alarmMap.push({
                          name: alarm.name,
                          isFixed: alarm.fixed,
                          alarmDetail: alarm.alarmMap,
                        });

                        comibinedList[match] = newAlarmsItem;
                      } else {
                        // Create the Device list
                        newAlarmsItem = {
                          device_name: deviceName,
                          device_type: childType,
                          alarmMap: [
                            {
                              name: alarm.name,
                              isFixed: alarm.fixed,
                              alarmDetail: alarm.alarmMap,
                            },
                          ],
                        };

                        comibinedList.push(newAlarmsItem);
                      }
                    }

                    return true;
                  });
                }
              } else {
                // usually children ones
                if (config.Information.deviceType === "Environment Board") {
                  config.Details.childNames.map((child) => {
                    if (child.name !== "") {
                      let alarmMap = [];

                      thisDeviceTemplate.Alarms.map((alarm) => {
                        if (alarm.enabled) {
                          if (alarm.childname === child.sensor) {
                            alarmMap.push({
                              name: alarm.name,
                              isFixed: false,
                              alarmDetail: alarm.alarmMap,
                            });
                          }
                        }
                        return true;
                      });

                      if (alarmMap.length > 0) {
                        // Create the Device list
                        let newAlarmsItem = {
                          device_name: child.name,
                          device_type: config.Information.deviceType,
                          alarmMap,
                        };

                        comibinedList.push(newAlarmsItem);
                      }
                    }
                    return true;
                  });
                }
              }
            }
          }
        }

        return true;
      });
    }

    return comibinedList;
  } catch (error) {
    console.log(error);
    return [];
  }
};

// open modal
export const openAlarmsModal = (isEdit, data) => (dispatch) => {
  dispatch({
    type: ALARMS_MODAL_OPEN,
    payload: { isEdit, data },
  });
};

// close modal
export const closeAlarmsModal = () => (dispatch) => {
  dispatch({
    type: ALARMS_MODAL_CLOSE,
  });
};

// Formates the alarms list in the DB to the one for the Page
export const getAlarmsList = (uploadedList) => async (dispatch) => {
  try {
    dispatch({
      type: ALARMS_LOADING,
    });

    let currentAlarmsList = [];

    if (uploadedList) {
      currentAlarmsList = uploadedList;
    } else {
      // set the auth token
      if (localStorage.token) {
        setAuthToken(localStorage.token);
      }

      // Gets the current alarms list
      const receivedData = await axios.get("/api/alarm");
      currentAlarmsList = receivedData.data.alarmsList;
    }

    let comibinedList = [];
    let keyNumber = 0;

    if (currentAlarmsList) {
      currentAlarmsList.map((alarmItem) => {
        return alarmItem.alarmMap.map((actualAlarm) => {
          return actualAlarm.alarmDetail.map((singleAlarm) => {
            // Create the Device list
            let newAlarmsItem = {
              device_name: alarmItem.device_name,
              device_type: alarmItem.device_type,
              name: actualAlarm.name,
              fixed: actualAlarm.isFixed,
              ...singleAlarm,
              key: keyNumber,
            };

            keyNumber++;
            return comibinedList.push(newAlarmsItem);
          });
        });
      });
    }
    dispatch({
      type: ALARMS_UPDATED,
      payload: comibinedList,
    });
  } catch (error) {
    dispatch({
      type: ALARMS_UPDATED,
      payload: [],
    });

    dispatch(setAlert("Alarms list error, Please try again", "error"));
  }
};

// update the alarms list in the DB and for the page with the new single alarm
export const updateAlarmsList =
  (alarmData, currentAlarmsList) => (dispatch) => {
    dispatch({
      type: ALARMS_LOADING,
    });

    const indexOfAlarm = currentAlarmsList.findIndex(
      (alarmItem) => alarmItem.key === alarmData.key
    );

    let newAlarmsList = [...currentAlarmsList];

    if (indexOfAlarm >= 0) {
      newAlarmsList[indexOfAlarm] = { ...alarmData };
    }

    dispatch({
      type: ALARMS_UPDATED,
      payload: newAlarmsList,
    });
  };

// disable or enable an alarm
export const disableAlarmList = (alarmKey, currentAlarmsList) => (dispatch) => {
  dispatch({
    type: ALARMS_LOADING,
  });

  const indexOfAlarm = currentAlarmsList.findIndex(
    (alarmItem) => alarmItem.key === alarmKey
  );

  let newAlarmsList = [...currentAlarmsList];

  if (indexOfAlarm >= 0) {
    let changedAlarmDetails = { ...newAlarmsList[indexOfAlarm] };

    if (changedAlarmDetails.alarmDisabled)
      delete changedAlarmDetails.alarmDisabled;
    else changedAlarmDetails.alarmDisabled = true;
    newAlarmsList[indexOfAlarm] = changedAlarmDetails;
  }

  dispatch({
    type: ALARMS_UPDATED,
    payload: newAlarmsList,
  });
};

// saves the passed list to the DB
export const saveAlarmListTODB = (currentAlarmsList) => async (dispatch) => {
  dispatch({
    type: ALARMS_SAVING,
    payload: true,
  });

  let newAlarmsList = [...currentAlarmsList];

  // Re-assemble the list to save on the DB
  let newDBAlarmList = [];
  let dbNEWItem = {};

  if (newAlarmsList.length > 0) {
    newAlarmsList.map((alarmItem) => {
      // search device list
      const dbIndex = newDBAlarmList.findIndex(
        (dbItem) => dbItem.device_name === alarmItem.device_name
      );

      // doesnt exist yet
      if (dbIndex === -1) {
        dbNEWItem.device_name = alarmItem.device_name;
        dbNEWItem.device_type = alarmItem.device_type;
        dbNEWItem.alarmMap = [];

        dbNEWItem.alarmMap.push({
          name: alarmItem.name,
          isFixed: alarmItem.fixed,
          alarmDetail: [],
        });

        if (alarmItem.alarmDisabled) {
          dbNEWItem.alarmMap[0].alarmDetail.push({
            ...alarmItem,
            alarmDisabled: true,
            key: alarmItem.alarmkey,
          });
        } else {
          dbNEWItem.alarmMap[0].alarmDetail.push({
            ...alarmItem,
            key: alarmItem.alarmkey,
          });
        }

        newDBAlarmList.push({ ...dbNEWItem });
      } else {
        let mappingIndex = newDBAlarmList[dbIndex].alarmMap.findIndex(
          (alarmMapItem) => alarmMapItem.name === alarmItem.name
        );

        // No Match found
        if (mappingIndex === -1) {
          newDBAlarmList[dbIndex].alarmMap.push({
            name: alarmItem.name,
            isFixed: alarmItem.fixed,
            alarmDetail: [],
          });
        }

        // check for the item again
        mappingIndex = newDBAlarmList[dbIndex].alarmMap.findIndex(
          (alarmMapItem) => alarmMapItem.name === alarmItem.name
        );

        if (alarmItem.alarmDisabled) {
          newDBAlarmList[dbIndex].alarmMap[mappingIndex].alarmDetail.push({
            ...alarmItem,
            alarmDisabled: true,
            key: alarmItem.alarmkey,
          });
        } else {
          newDBAlarmList[dbIndex].alarmMap[mappingIndex].alarmDetail.push({
            ...alarmItem,
            key: alarmItem.alarmkey,
          });
        }
      }

      return true;
    });
  }

  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const result = await axios.post(
      "/api/alarm",
      {
        alarmingList: newDBAlarmList,
      },
      config
    );

    if (result) dispatch(setAlert("Alarms list is saved", "success"));

    dispatch({
      type: ALARMS_SAVING,
      payload: false,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ALARMS_SAVING,
      payload: false,
    });
  }
};

// set default vaules
export const resetToDefaults = () => async (dispatch) => {
  // 3 steps
  // step 1: get the list of devices
  let currentConfig = await getConfigurationFileList();

  let newList = Object.entries(currentConfig[0]);
  currentConfig = [];

  newList.map((item) => {
    return currentConfig.push(item[1]);
  });

  if (newList !== false) {
    // step 2: get alarms from templates
    let comibinedList = [];

    comibinedList = await getAlarmsFromTemplateList(currentConfig);

    // step 3: save the list
    if (await saveListTODB(comibinedList)) {
      dispatch(setAlert("Alarms list is set to default", "success"));
    } else {
      dispatch(setAlert("Alarms list not saved", "error"));
    }
  }
};

const getConfigurationFileList = async () => {
  // set the auth token
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    let result = await axios.get("/api/site/configfile");

    if (result) {
      result = result.data[0].configuration;
    } else {
      result = false;
    }

    return result;
  } catch (error) {
    console.log(error);
    return false;
  }
};
