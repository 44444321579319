import React, { Fragment, useEffect, useRef, useState } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Functions
import { login, openOptionsModal } from "../../actions/auth";

/// Ant Design
import { Form, Input, Button, Typography, Row, Col, Checkbox } from "antd";
import { LoginOutlined, SettingOutlined } from "@ant-design/icons";

// external Components
import LoginModal from "./loginModal";

// Css style
import "./landing.css";

const { Title } = Typography;

// actual component
const Login = ({
  login,
  isAuthenticated,
  loading,
  isNewSite,
  isDataloaded,
  openOptionsModal,
}) => {
  const [formData, setFormData] = useState({
    email: localStorage.getItem("email"),
    password: localStorage.getItem("password"),
    remember: localStorage.getItem("remember") === "true" ? true : false,
  });
  const loginForm = useRef();

  useEffect(() => {
    const listener = (event) => {
      // Listen fro both enter keys
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        // submit the form
        loginForm.current.submit();
      }
    };
    document.addEventListener("keydown", listener);

    // clean up the effect by removing it completely
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  const onFinish = (values) => {
    if (values.remember === true) {
      setFormData({ ...formData, email: values.email });
      localStorage.setItem("email", values.email);
      localStorage.setItem("password", values.password);
      localStorage.setItem("remember", true);
    } else {
      localStorage.removeItem("email");
      localStorage.removeItem("password");
      localStorage.removeItem("remember");
    }

    login(values.email, values.password);
  };

  // If we are already authenticated
  if (isAuthenticated) {
    if (isDataloaded) {
      if (isNewSite) return <Redirect to="/siteWizard" />;
      else return <Redirect to="/dashboard" />;
    }
  }

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <Fragment>
      <Row style={{ marginTop: "10%", marginBottom: "20%" }}>
        <Col sm={4} md={5} lg={7} xl={8} xxl={9}></Col>
        <Col sm={16} md={14} lg={10} xl={8} xxl={6}>
          <Title className={"heading-font"}>UC4 Configuration Tool</Title>
          <Title level={3}>Log In</Title>
          <Form
            name="basic"
            initialValues={{
              remember: formData.remember,
              email: formData.email,
              password: formData.password,
            }}
            ref={loginForm}
            onFinish={onFinish}
            {...layout}
          >
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please input your email address!",
                },
              ]}
            >
              <Input placeholder="Email Address" autoComplete="username" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password
                placeholder="Password"
                autoComplete="current-password"
              />
            </Form.Item>

            <Form.Item {...tailLayout} name="remember" valuePropName="checked">
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <LoginModal />

            <Form.Item {...tailLayout}>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                icon={<LoginOutlined />}
              >
                Log In
              </Button>

              <a
                style={{ marginLeft: "50%" }}
                href="#!"
                onClick={openOptionsModal}
              >
                <SettingOutlined />
              </a>
            </Form.Item>
          </Form>
        </Col>
        <Col sm={4} md={5} lg={7} xl={8} xxl={6}></Col>
      </Row>
    </Fragment>
  );
};

const mapStateToProp = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isNewSite: state.site.newSite,
  isDataloaded: state.site.dataloaded,
  loading: state.auth.loading,
});

Login.propTypes = {
  login: PropTypes.func.isRequired,
  openOptionsModal: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  isNewSite: PropTypes.bool.isRequired,
  isDataloaded: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
};

export default connect(mapStateToProp, {
  login,
  openOptionsModal,
})(Login);
