import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd
import { Modal, Form, Input, Tooltip, Button } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

// external functions
import {
  createNewModalClose,
  stepModalOpen,
  saveCalculation,
  constantModalOpen,
  outputModalOpen,
} from "../../../actions/calculations";

// external components
import StepsModal from "../newStepModal";
import StepsTable from "../createNewStepsTable";
import ConstantModal from "../newConstModal";
import ConstantTable from "../createNewConstTable";
import OutputModal from "../newOutputModal";
import OutputsTable from "../outputTable";

// actual component
const ModalCreateCalc = ({
  modalTempCalcVis,
  createNewModalClose,
  newCalculation,
  stepModalOpen,
  constantModalOpen,
  saveCalculation,
  outputModalOpen,
}) => {
  const isEditing = useRef(null);
  const [formData] = Form.useForm();
  const [stateFormData, setStateFormData] = useState();

  const setInitalParameters = useCallback(() => {
    if (isEditing.current === null) {
      if (!formData.getFieldValue("calculationName") && newCalculation) {
        setStateFormData({ ...newCalculation });
        isEditing.current = true;
        formData.setFieldsValue({
          calculationName: newCalculation.calculationName,
          calculationDescription: newCalculation.calculationDescription,
        });
      } else {
        isEditing.current = false;
      }
    }
  }, [newCalculation, formData]);

  useEffect(() => {
    if (modalTempCalcVis) {
      setInitalParameters();
    }
  }, [modalTempCalcVis, setInitalParameters]);

  const handleOk = () => {
    if (formData.getFieldValue("calculationName")) {
      saveCalculation({
        ...newCalculation,
        calculationName: formData.getFieldValue("calculationName"),
        calculationDescription: formData.getFieldValue(
          "calculationDescription"
        ),
        isTemplateOnly: true,
      });
      createNewModalClose();
    }
  };

  const handleCancel = () => {
    createNewModalClose();
  };

  return (
    <Modal
      title={
        isEditing.current === true
          ? "Editing calculation"
          : "Create new calculation"
      }
      okButtonProps={{
        disabled:
          newCalculation &&
          newCalculation.steps &&
          stateFormData &&
          stateFormData.calculationName !== "" &&
          stateFormData.calculationName !== undefined
            ? false
            : true,
      }}
      afterClose={() => {
        formData.resetFields();
        setStateFormData();
        isEditing.current = null;
      }}
      okText={isEditing.current === true ? "Update" : "Save"}
      visible={modalTempCalcVis}
      onCancel={handleCancel}
      onOk={handleOk}
      width={900}
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 10 }}
        form={formData}
        size="small"
        onValuesChange={(_, Values) => {
          setStateFormData(Values);
        }}
      >
        <Form.Item
          label="Name"
          name="calculationName"
          tooltip="The name for this calculation"
          style={{ marginBottom: "5px" }}
        >
          <Input disabled={isEditing.current} />
        </Form.Item>
        <Form.Item label="Description" name="calculationDescription">
          <Input.TextArea
            autoSize={{ minRows: 3, maxRows: 4 }}
            showCount
            maxLength={150}
          />
        </Form.Item>
      </Form>

      <Tooltip title="Add a new output to the list">
        <Button onClick={() => outputModalOpen(null)}>
          <PlusCircleOutlined />
          Calculation Output
        </Button>
      </Tooltip>

      <OutputModal />

      <OutputsTable />

      <Tooltip title="Add a new constant or input to the list">
        <Button onClick={constantModalOpen}>
          <PlusCircleOutlined />
          Constant/Input
        </Button>
      </Tooltip>

      <ConstantModal thisIsTemplateCalc={true} />

      <ConstantTable />

      <Tooltip title="Add a new step to the list">
        <Button onClick={() => stepModalOpen(null)}>
          <PlusCircleOutlined />
          Calculation Step
        </Button>
      </Tooltip>

      <StepsModal thisIsTemplateCalc={true} />

      <StepsTable />
    </Modal>
  );
};

ModalCreateCalc.propTypes = {
  modalTempCalcVis: PropTypes.bool,
  createNewModalClose: PropTypes.func.isRequired,
  stepModalOpen: PropTypes.func.isRequired,
  constantModalOpen: PropTypes.func.isRequired,
  saveCalculation: PropTypes.func.isRequired,
  outputModalOpen: PropTypes.func.isRequired,
  newCalculation: PropTypes.object,
};

const mapStateToProps = (state) => ({
  modalTempCalcVis: state.calcs.createNewModalVis,
  newCalculation: state.calcs.newCalculation,
});

export default connect(mapStateToProps, {
  createNewModalClose,
  stepModalOpen,
  constantModalOpen,
  saveCalculation,
  outputModalOpen,
})(ModalCreateCalc);
