import { combineReducers } from "redux";

// individiual reducers
import alert from "./alert";
import auth from "./auth";
import site from "./site";
import template from "./templates";
import client from "./client";
import users from "./users";
import modbusConfig from "./modbusconfig";
import deviceList from "./deviceList";
import deviceTypes from "./deviceTypes";
import alarms from "./alarms";
import calcs from "./calculations";
import advanced from "./advanced";
import utl from "./utl";
import distribution from "./distribution";

export default combineReducers({
  alert,
  auth,
  site,
  template,
  client,
  users,
  deviceList,
  deviceTypes,
  modbusConfig,
  alarms,
  calcs,
  advanced,
  utl,
  distribution,
});
