import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Ant design
import {
  Table,
  Popconfirm,
  Space,
  Modal,
  Form,
  Input,
  Select,
  Divider,
  Typography,
  Button,
  Tooltip,
} from "antd";

// Icons
import { PlusOutlined, EditFilled, DeleteFilled } from "@ant-design/icons";

// Functions
import { updateDeviceDetails } from "../../../../actions/deviceList";
import axios from "axios";

// Constants
const { Option } = Select;
const { Title, Paragraph, Text } = Typography;

const ModalDetailsEnvironment = ({
  deviceList,
  updateDeviceDetails,
  deviceListInfo,
  siteFloors,
}) => {
  // Table Columns
  const columns = [
    {
      title: "Sensor",
      dataIndex: "sensor",
      key: "sensor",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Floor",
      dataIndex: "floor",
      key: "floor",
    },
    {
      title: "Groups",
      dataIndex: "group",
      key: "group",
      render: (groupItem) => {
        if (groupItem.length > 1) return groupItem + ", ";
        else return groupItem;
      },
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record) => (
        <Space size="middle">
          <Popconfirm
            title="Sure you want to clear the information?"
            onConfirm={() => handleDelete(record)}
          >
            <Tooltip title="Delete device details">
              <a href="#!">
                <DeleteFilled />
              </a>
            </Tooltip>
          </Popconfirm>
          <a href="#!" onClick={() => handleEdit(record)}>
            <Tooltip title="Edit Sensor details">
              <EditFilled />
            </Tooltip>
          </a>
        </Space>
      ),
    },
  ];

  let InitialTableData = [];

  if (deviceList !== undefined && deviceList.childNames)
    InitialTableData = deviceList.childNames;
  const [tableData, setTableData] = useState(InitialTableData);

  const [groupsNames, setGroupsNames] = useState([]);

  useEffect(() => {
    const getGroupList = async () => {
      const groupList = await axios.get("/api/site/groups");
      setGroupsNames(groupList.data);
    };

    getGroupList();

    const GetTemplateDetails = async () => {
      if (
        deviceList === undefined ||
        deviceList.childNames === undefined ||
        deviceList.childNames.length === 0
      ) {
        const results = await axios.get(
          "/api/devtemplates/" + deviceListInfo.template._id
        );

        let Sensors = [];

        results.data.Batchs.forEach((batch) => {
          batch.Map.forEach((MapItem, index) => {
            if (MapItem.ExtraChildren !== undefined) {
              if (Sensors.length === 0)
                Sensors.push({
                  sensor: MapItem.ExtraChildren.name,
                  floor: "",
                  group: [],
                  name: "",
                });
              else {
                if (
                  !Sensors.find((o) => o.sensor === MapItem.ExtraChildren.name)
                )
                  Sensors.push({
                    sensor: MapItem.ExtraChildren.name,
                    floor: "",
                    group: [],
                    name: "",
                  });
              }
            }
          });
        });

        setTableData(Sensors);
        updateDeviceDetails({ childNames: Sensors });
      }
    };

    GetTemplateDetails();
  }, [updateDeviceDetails, deviceListInfo, deviceList]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [recordToEdit, setRecordToEdit] = useState({});

  const handleEdit = (record) => {
    setRecordToEdit({ ...record });
    setIsModalVisible(true);
  };

  const handleDelete = (record) => {
    let sensorList = [...tableData];

    sensorList.forEach((sensor) => {
      if (sensor.sensor === record.sensor) {
        sensor.floor = "";
        sensor.group = [];
        sensor.name = "";
      }
    });
    setTableData(sensorList);
  };

  const handelClearAll = () => {
    let sensorList = [...tableData];

    sensorList.forEach((sensor) => {
      sensor.floor = "";
      sensor.group = [];
      sensor.name = "";
    });
    setTableData(sensorList);
  };

  // Floors select
  const floorSelect = siteFloors
    ? siteFloors.map((device, index) => (
        <Option value={device.name} key={index}>
          {device.name}
        </Option>
      ))
    : null;

  // Edit Modal
  const EditItemModal = () => {
    const [groupInputName, setGroupInputName] = useState("");

    const [sensorDetails, setSensorDetails] = useState(recordToEdit);

    const handelCanel = () => {
      setIsModalVisible(false);
    };

    const HandelAddGroupName = async () => {
      try {
        const groupList = await axios.post("/api/site/groups", {
          name: groupInputName,
        });
        setGroupsNames(groupList.data);
      } catch (error) {
        console.log(error);
      }

      setGroupInputName("");
    };

    const HandelOK = () => {
      let AllSensorData = tableData;

      const sensorIndex = tableData.findIndex(
        (sensor) => sensor.sensor === sensorDetails.sensor
      );

      AllSensorData.splice(sensorIndex, 1, sensorDetails);

      // set the table data to the new data
      setTableData([...AllSensorData]);
      // and the details
      updateDeviceDetails({ childNames: AllSensorData });
      // Close the modal
      setIsModalVisible(false);
    };

    return (
      <Modal
        title={"Editing " + recordToEdit.sensor}
        visible={isModalVisible}
        onCancel={handelCanel}
        onOk={HandelOK}
      >
        <Form
          labelCol={{ span: 9 }}
          wrapperCol={{ span: 9 }}
          layout="horizontal"
          initialValues={recordToEdit}
          onValuesChange={(_, all) => {
            setSensorDetails({ ...sensorDetails, ...all });
          }}
          size="small"
        >
          <Form.Item label="Sensor Name" name="name">
            <Input />
          </Form.Item>
          <Form.Item label="Floor" name="floor">
            <Select showSearch allowClear>
              {floorSelect}
            </Select>
          </Form.Item>
          <Form.Item
            label="Group"
            extra="Group this device with others"
            name="group"
          >
            <Select
              mode="multiple"
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <Divider style={{ margin: "4px 0" }} />
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "nowrap",
                      padding: 8,
                    }}
                  >
                    <Input
                      style={{ flex: "auto" }}
                      value={groupInputName}
                      onChange={(event) =>
                        setGroupInputName(event.target.value)
                      }
                    />
                    <a
                      href="#!"
                      style={{
                        flex: "none",
                        padding: "5px",
                        display: "block",
                        cursor: "pointer",
                      }}
                      onClick={HandelAddGroupName}
                    >
                      <PlusOutlined /> Add item
                    </a>
                  </div>
                </div>
              )}
            >
              {groupsNames.map((item) => (
                <Option key={item}>{item}</Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  return (
    <Fragment>
      <Title level={2}>Sensor Input Mapping</Title>
      <Paragraph>
        <Text code>NB</Text> A sensor with a blank name will not to read or
        saved
      </Paragraph>
      <Button type="primary" onClick={handelClearAll}>
        <Tooltip title="This will clear the entire list of details per sensor">
          Clear all details
        </Tooltip>
      </Button>

      <EditItemModal />

      <Table
        style={{ marginTop: "10px" }}
        scroll={{ y: 300 }}
        pagination={{
          defaultPageSize: 20,
        }}
        bordered
        size="small"
        columns={columns}
        dataSource={tableData}
      ></Table>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  deviceList: state.deviceList.currentDeviceList.Details,
  deviceListInfo: state.deviceList.currentDeviceList.Information,
  siteFloors: state.site.siteDetails.floors,
});

ModalDetailsEnvironment.propTypes = {
  updateDeviceDetails: PropTypes.func.isRequired,
  deviceList: PropTypes.object,
  deviceListInfo: PropTypes.object,
  siteFloors: PropTypes.array,
};

export default connect(mapStateToProps, { updateDeviceDetails })(
  ModalDetailsEnvironment
);
