import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// ant design components
import { Modal, Form, InputNumber } from "antd";

// functions
import { closeAlarmsModal, updateAlarmsList } from "../../../../actions/alarms";

// Actual component
const AlarmEdit = ({
  modalVisability,
  closeAlarmsModal,
  modalData,
  modalisEdit,
  updateAlarmsList,
  fullAlarmsList,
}) => {
  const [pageLoaded, setPageLoaded] = useState(false);
  const [editAlarmForm] = Form.useForm();

  const handleCancel = () => {
    closeAlarmsModal();
  };

  const handleOK = () => {
    let pageFormData = { ...modalData };

    if (
      editAlarmForm.getFieldValue("alarmDelayOn") !== null &&
      editAlarmForm.getFieldValue("alarmDelayOn") >= 0 &&
      editAlarmForm.getFieldValue("alarmDelayOff") !== null &&
      editAlarmForm.getFieldValue("alarmDelayOff") >= 0 &&
      editAlarmForm.getFieldValue("alarmLevel") !== null
    ) {
      pageFormData.alarmDelayOn = editAlarmForm.getFieldValue("alarmDelayOn");
      pageFormData.alarmDelayOff = editAlarmForm.getFieldValue("alarmDelayOff");

      if (editAlarmForm.getFieldValue("alarmLevel"))
        pageFormData.alarmLevel = editAlarmForm.getFieldValue("alarmLevel");

      updateAlarmsList(pageFormData, fullAlarmsList);

      closeAlarmsModal();
    }
  };

  // this is used to keep the data in the form fresh
  if (pageLoaded === false) {
    if (modalVisability) {
      setTimeout(() => editAlarmForm.resetFields(), 250);
      setPageLoaded(true);
    }
  }

  return (
    <Modal
      width={650}
      title={
        modalData && modalisEdit
          ? "Editing " +
            modalData.device_name +
            " - " +
            modalData.alarmName +
            " : " +
            modalData.alarmPriority
          : "Alarm details"
      }
      afterClose={() => setPageLoaded(false)}
      onCancel={handleCancel}
      onOk={handleOK}
      visible={modalVisability}
    >
      <Form
        form={editAlarmForm}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 9 }}
        size="small"
        initialValues={modalData}
      >
        <Form.Item
          label="Warm Up"
          name="alarmDelayOn"
          tooltip="Time in seconds, delay on activating the alarm, the alarm will only trigger after this time has lapsed"
        >
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item
          label="CoolDown"
          name="alarmDelayOff"
          tooltip="Time in seconds, delay on clearing the alarm, the alarm will only clear after this time has lapsed"
        >
          <InputNumber min={0} />
        </Form.Item>
        {modalData && modalData.fixed === false && (
          <Form.Item
            label="Trigger Value"
            name="alarmLevel"
            tooltip="This is the value to which the alarm will occur"
          >
            <InputNumber />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

AlarmEdit.propTypes = {
  modalVisability: PropTypes.bool,
  modalisEdit: PropTypes.bool,
  modalData: PropTypes.object,
  closeAlarmsModal: PropTypes.func.isRequired,
  updateAlarmsList: PropTypes.func.isRequired,
  fullAlarmsList: PropTypes.array,
};

const mapStateToProps = (state) => ({
  modalVisability: state.alarms.modalVisability,
  modalData: state.alarms.modalData,
  modalisEdit: state.alarms.modalisEdit,
  fullAlarmsList: state.alarms.alarmsList,
});

export default connect(mapStateToProps, { closeAlarmsModal, updateAlarmsList })(
  AlarmEdit
);
