import {
  TYPE_FAIL,
  TYPE_LOADING_DETAILED,
  TYPE_LOADING_SHORT,
  TYPE_MODAL_CLOSE,
  TYPE_MODAL_OPEN,
  TYPE_MODAL_OPEN_EDIT,
  TYPE_UPDATED_DETAILED,
  TYPE_UPDATED_SHORT,
  TYPE_MODAL_ADDVAR_OPEN,
  TYPE_MODAL_ADDVAR_CLOSE,
  TYPE_UPDATE_CURRENT,
  TYPE_MODAL_ADDALA_OPEN,
  TYPE_MODAL_ADDALA_CLOSE,
} from "../actions/types";

const initalState = {
  typesLoadingDetailed: false,
  typesLoadingShort: false,
  types_detailed: null,
  types_short: null,
  isEdit: false,
  modalVisablity: false,
  modalAddVariableVisablity: false,
  modalAddAlarmVisablity: false,
  editTypeDetials: null,
};

export default function template(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case TYPE_UPDATE_CURRENT:
      return { ...state, editTypeDetials: payload };
    case TYPE_MODAL_ADDALA_OPEN:
      return { ...state, modalAddAlarmVisablity: true };
    case TYPE_MODAL_ADDALA_CLOSE:
      return { ...state, modalAddAlarmVisablity: false };
    case TYPE_MODAL_ADDVAR_OPEN:
      return { ...state, modalAddVariableVisablity: true };
    case TYPE_MODAL_ADDVAR_CLOSE:
      return { ...state, modalAddVariableVisablity: false };
    case TYPE_MODAL_OPEN:
      return { ...state, modalVisablity: true, isEdit: false };
    case TYPE_MODAL_OPEN_EDIT:
      return {
        ...state,
        modalVisablity: true,
        isEdit: true,
        editTypeDetials: payload,
      };
    case TYPE_MODAL_CLOSE:
      return {
        ...state,
        modalVisablity: false,
        isEdit: false,
        editTypeDetials: null,
      };
    case TYPE_UPDATED_DETAILED:
      return { ...state, typesLoadingDetailed: false, types_detailed: payload };
    case TYPE_UPDATED_SHORT:
      return { ...state, typesLoadingShort: false, types_short: payload };
    case TYPE_LOADING_DETAILED:
      return { ...state, typesLoadingDetailed: true, types_detailed: null };
    case TYPE_LOADING_SHORT:
      return { ...state, typesLoadingShort: true, types_short: null };
    case TYPE_FAIL:
      return {
        ...state,
        typesLoadingDetailed: false,
        typesLoadingShort: false,
        isEdit: false,
        modalVisablity: false,
        types_detailed: null,
        types_short: null,
      };
    default:
      return state;
  }
}
