import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//antd comoonetns
import { Modal, Form, Input, Select, Divider, InputNumber } from "antd";
// Icons
import { PlusOutlined } from "@ant-design/icons";

// external functions
import { ModalDeviceEditClose } from "../../../actions/distribution";

// Actual function
const EditDevice = ({
  modalVis,
  modalData,
  ModalDeviceEditClose,
  tempTypeNameList,
}) => {
  const [formData] = Form.useForm();
  const [deviceName, setDeviceName] = useState("");

  const handleOK = () => {
    let devData = modalData ?? { ...modalData };

    devData.distributionData = {
      ...devData.distributionData,
      ...formData.getFieldsValue(),
    };

    if (modalData?.distributionData.type === "Breaker") {
      devData.distributionData.breakerRate += "A";
    }
    ModalDeviceEditClose(devData);
  };

  const handleCancel = () => {
    ModalDeviceEditClose();
  };

  const handleAddDeviceName = () => {};

  return (
    <Modal
      title={"Editing " + modalData?.distributionData.type}
      visible={modalVis}
      onCancel={handleCancel}
      onOk={() => formData.validateFields().then(() => handleOK())}
    >
      <Form
        initialValues={modalData?.distributionData}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        form={formData}
        size="small"
      >
        {modalData?.distributionData?.type === "Breaker" && (
          <Fragment>
            <Form.Item
              label="Breaker Type"
              name="breakerType"
              style={{ marginBottom: "5px" }}
              rules={[{ required: true, message: "Can't be blank" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Breaker Rating"
              name="breakerRate"
              tooltip="Breaker Current Rating in Amps"
              style={{ marginBottom: "5px" }}
              rules={[{ required: true, message: "Can't be blank" }]}
            >
              <InputNumber min={1} />
            </Form.Item>
            <Form.Item label="Breaker Name" name="breakerName">
              <Select
                allowClear
                showSearch
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <Divider style={{ margin: "4px 0" }} />
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        padding: 8,
                      }}
                    >
                      <Input
                        style={{ flex: "auto" }}
                        value={deviceName}
                        onChange={(event) => setDeviceName(event.target.value)}
                      />
                      <a
                        href="#!"
                        style={{
                          flex: "none",
                          padding: "5px",
                          display: "block",
                          cursor: "pointer",
                        }}
                        onClick={handleAddDeviceName}
                      >
                        <PlusOutlined /> Add name
                      </a>
                    </div>
                  </div>
                )}
              >
                {tempTypeNameList &&
                  tempTypeNameList.map(
                    (item, index) =>
                      item.type === "Breaker" && (
                        <Select.Option key={index} value={item.name}>
                          {item.name}
                        </Select.Option>
                      )
                  )}
              </Select>
            </Form.Item>
          </Fragment>
        )}
      </Form>
    </Modal>
  );
};

EditDevice.propTypes = {
  modalVis: PropTypes.bool,
  modalData: PropTypes.object,
  ModalDeviceEditClose: PropTypes.func.isRequired,
  tempTypeNameList: PropTypes.array,
};

const mapStateToProps = (state) => ({
  modalVis: state.distribution.modalDeviceEditVisablity,
  modalData: state.distribution.modalDeviceEditData,
  tempTypeNameList: state.deviceList.tempTypeNameList,
});

export default connect(mapStateToProps, {
  ModalDeviceEditClose,
})(EditDevice);
