import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// ant design components
import { Modal, Form, Select } from "antd";

// functions
import { closeCopyAlarmModal, updateAlarms } from "../../../actions/templates";

// Actual component
const NewTemplateAlarmCopy = ({
  copyAlarmModalVisabiliy,
  closeCopyAlarmModal,
  children,
  copyDetails,
  currentTemplate,
  updateAlarms,
}) => {
  const [alarmCopyForm] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handelOK = () => {
    const newCopyDetails = alarmCopyForm.getFieldValue("copyName");

    if (newCopyDetails.length > 0) {
      // show the loading on the okay button
      setConfirmLoading(true);
      // Unload the data to edit it later
      let dataSource = [...currentTemplate.Alarms];

      // go through each item on the list
      newCopyDetails.forEach((newItemsDetails) => {
        // Find the one we want
        const removeItemIdex = dataSource.findIndex(
          (item) =>
            item.childname === newItemsDetails && item.name === copyDetails.name
        );

        // Load the replacement object
        let newAlarmData = {
          name: dataSource[removeItemIdex].name,
          dataType: dataSource[removeItemIdex].dataType,
          key: dataSource[removeItemIdex].key,
          childname: dataSource[removeItemIdex].childname,
          enabled: true,
          alarmMap: copyDetails.alarmMap,
        };

        // the index is not it the current list
        if (removeItemIdex !== -1) {
          dataSource[removeItemIdex] = newAlarmData;
        }
      });

      // Update the redux MAPs of this template
      updateAlarms(dataSource);
      alarmCopyForm.resetFields();
      setConfirmLoading(false);
      closeCopyAlarmModal();
    }
  };

  const handelCancel = () => {
    closeCopyAlarmModal();
  };

  // select List
  const copyToName =
    children &&
    copyDetails &&
    children.map(
      (alarmVar, index) =>
        alarmVar.name === copyDetails.name &&
        alarmVar.childname !== copyDetails.childname && (
          <Select.Option key={index} value={alarmVar.childname}>
            {alarmVar.childname}
          </Select.Option>
        )
    );
  return (
    <Modal
      onCancel={handelCancel}
      onOk={handelOK}
      title="Copy alarms configuration"
      visible={copyAlarmModalVisabiliy}
      confirmLoading={confirmLoading}
    >
      <Form form={alarmCopyForm}>
        <Form.Item label="Alarms to copy to?" name="copyName">
          <Select mode={"multiple"} allowClear showSearch>
            {copyToName}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

NewTemplateAlarmCopy.propTypes = {
  copyAlarmModalVisabiliy: PropTypes.bool,
  closeCopyAlarmModal: PropTypes.func.isRequired,
  updateAlarms: PropTypes.func.isRequired,
  children: PropTypes.array,
  copyDetails: PropTypes.object,
  currentTemplate: PropTypes.object,
};

const mapStateToProps = (state) => ({
  copyAlarmModalVisabiliy: state.template.copyAlarmModalVisabiliy,
  children: state.template.newTemplate && state.template.newTemplate.Alarms,
  copyDetails: state.template.alarmsModalDetails,
  currentTemplate: state.template.newTemplate,
});

export default connect(mapStateToProps, { closeCopyAlarmModal, updateAlarms })(
  NewTemplateAlarmCopy
);
