import React, { Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from "axios";
import { v4 as uuid } from "uuid";

// Ant design items
import { Form, Select, Input, InputNumber, Table, Divider } from "antd";
import { PlusOutlined } from "@ant-design/icons";

// Functions
import {
  updateDeviceInformation,
  updateDeviceDetails,
} from "../../../../actions/deviceList";

// Constants
const { Option } = Select;

const templateTableColumns = [
  {
    title: "Model",
    dataIndex: "model",
    key: "model",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Maunfacture",
    dataIndex: "maunfacture",
    key: "maunfacture",
  },
];

// Actual Component
const AddDeviceInfo = ({
  deviceTypes,
  groupsDeviceNames,
  updateDeviceInformation,
  deviceList,
  inEditMode,
  updateDeviceDetails,
  formID,
  duplicatesList,
  openAddDeviceModal,
}) => {
  const [formDeviceToRead] = Form.useForm(formID);
  const EditingIP = useRef(null);
  const SelectedTemplate = useRef("");

  useEffect(() => {
    const getGroupNames = async () => {
      const devGroups = await axios.get("/api/site/groups");
      setGroupName(devGroups.data);
    };

    getGroupNames();

    const ChangeSelect = async (value) => {
      const res = await axios.get(
        "/api/devtemplates/type/" + deviceList.deviceType
      );

      const template = [];

      res.data.forEach((data, index) => {
        let templateData = {};

        if (data.commProtocol === deviceList.deviceCommType) {
          if (
            inEditMode &&
            deviceList.template !== undefined &&
            SelectedTemplate.current === ""
          ) {
            if (data._id === deviceList.template._id) {
              SelectedTemplate.current = index;
              deviceList.template.key = index;
            }
          }
          templateData.key = index;
          templateData._id = data._id;
          templateData.model = data.model;
          templateData.description = data.description;
          templateData.maunfacture = data.maunfacture;

          template.push(templateData);
        }
      });

      setTemplateData(template);
    };

    if (deviceList) {
      if (deviceList.deviceType) {
        ChangeSelect(deviceList.deviceType);
      }
    }

    if (inEditMode && EditingIP.current === null) {
      EditingIP.current = deviceList.deviceIPSlaveID;
    }
  }, [deviceList, inEditMode]);

  const NamesOfTypes = useRef(groupsDeviceNames);
  const [templateData, setTemplateData] = useState([]);
  const [groupInputName, setGroupInputName] = useState("");
  const [groupName, setGroupName] = useState([]);
  const [groupDeviceName, setGroupDeviceName] = useState(NamesOfTypes.current);
  const [groupInputDeviceName, setGroupInputDeviceName] = useState("");

  if (openAddDeviceModal === true) {
    if (NamesOfTypes.current !== groupsDeviceNames) {
      NamesOfTypes.current = groupsDeviceNames;
      setTimeout(() => setGroupDeviceName(groupsDeviceNames), 200);
    }
  }

  const DeviceTypeList =
    deviceTypes !== null
      ? deviceTypes.map((device, index) => (
          <Select.Option value={device} key={index}>
            {device}
          </Select.Option>
        ))
      : null;

  async function handleChangeSelect(value) {
    const res = await axios.get(
      "/api/devtemplates/type/" + deviceList.deviceType
    );

    const template = [];

    res.data.forEach((data, index) => {
      let templateData = {};

      if (data.commProtocol === deviceList.deviceCommType) {
        templateData.key = index;
        templateData._id = data._id;
        templateData.model = data.model;
        templateData.description = data.description;
        templateData.maunfacture = data.maunfacture;

        template.push(templateData);
      }
    });

    setTemplateData(template);
  }

  // Adding a group name to the group list from the select
  const HandelAddGroupName = async () => {
    if (groupInputName !== "") {
      try {
        const groupList = await axios.post("/api/site/groups", {
          name: groupInputName,
        });
        setGroupName([...groupList.data]);
      } catch (error) {
        console.log(error);
      }

      setGroupInputName("");
    }
  };

  // Adding a device name to the devices names list from the select
  const HandelAddGroupDeviceName = async () => {
    if (groupInputDeviceName !== "") {
      let names = [...groupDeviceName];
      // let names = [...NamesOfTypes.current];

      names.push({
        name: groupInputDeviceName,
        type: deviceList.deviceType,
      });
      setGroupDeviceName(names);
      // NamesOfTypes.current = names;
      setGroupInputDeviceName("");
    }
  };

  return (
    <Form
      form={formDeviceToRead}
      style={{ marginTop: "20px" }}
      name="DeviceInfo"
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 6 }}
      layout="horizontal"
      scrollToFirstError={true}
      initialValues={deviceList}
      onValuesChange={(_, all) => {
        // Minimum is the device type
        if (all.deviceType) {
          // this is as the user freshly starts off need to populate the Name of the input board
          if (
            (all.deviceType === "Input Board" ||
              all.deviceType === "Analogue Input Board") &&
            (all.deviceName === undefined || all.deviceName === null)
          ) {
            const id = uuid();

            formDeviceToRead.setFieldsValue({
              deviceName: id,
            });

            updateDeviceInformation({ ...deviceList, deviceName: id });

            all.deviceName = id;
          }

          if (all.devicePollRate === undefined || all.devicePollRate === null)
            formDeviceToRead.setFieldsValue({ devicePollRate: 5 });

          // check to see if the user has changed the type midway
          if (deviceList && all.deviceType !== deviceList.deviceType) {
            // save the new type else it is going to get overwritten
            const newType = all.deviceType;

            updateDeviceInformation({});
            updateDeviceDetails({});
            setTimeout(() => formDeviceToRead.resetFields(), 200);

            if (
              newType === "Input Board" ||
              newType === "Analogue Input Board"
            ) {
              const id = uuid();

              formDeviceToRead.setFieldsValue({ deviceName: id });
              updateDeviceInformation({
                deviceType: newType,
                deviceName: id,
                devicePollRate: 5,
              });
            } else {
              updateDeviceInformation({
                deviceType: newType,
                devicePollRate: 5,
              });
            }
            formDeviceToRead.setFieldsValue({
              deviceType: newType,
              devicePollRate: 5,
            });
          } else {
            if (all.deviceCommType === "SNMP") {
              if (all.communityString === undefined) {
                formDeviceToRead.setFieldsValue({
                  communityString: "public",
                });
                all.communityString = "public";
              }
              if (all.SNMPPort === undefined) {
                formDeviceToRead.setFieldsValue({
                  SNMPPort: 161,
                });
                all.SNMPPort = 161;
              }
            }

            updateDeviceInformation({ ...deviceList, ...all });
          }
        } else {
          updateDeviceInformation({});
          setTimeout(() => formDeviceToRead.resetFields(), 200);
        }
      }}
      size="small"
    >
      <Form.Item
        label="Device Type"
        name="deviceType"
        style={{ marginBottom: "5px" }}
        rules={[{ required: true, message: "Device type can not be blank" }]}
      >
        <Select disabled={inEditMode} allowClear showSearch>
          {DeviceTypeList}
        </Select>
      </Form.Item>
      {deviceList && deviceList.deviceType !== undefined && (
        <Fragment>
          {deviceList.deviceType !== "Environment Board" && (
            <Fragment>
              <Form.Item
                label="Name"
                name="deviceName"
                style={{ marginBottom: "5px" }}
                rules={[
                  { required: true, message: "Device name can not be blank" },
                ]}
              >
                {deviceList.deviceType === "Input Board" ||
                deviceList.deviceType === "Analogue Input Board" ? (
                  <Input disabled />
                ) : (
                  <Select
                    allowClear
                    dropdownRender={(menu) => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            padding: 8,
                          }}
                        >
                          <Input
                            style={{ flex: "auto" }}
                            size="small"
                            value={groupInputDeviceName}
                            onChange={(event) =>
                              setGroupInputDeviceName(event.target.value)
                            }
                          />
                          <a
                            href="#!"
                            style={{
                              flex: "none",
                              padding: "5px",
                              display: "block",
                              cursor: "pointer",
                            }}
                            onClick={HandelAddGroupDeviceName}
                          >
                            <PlusOutlined /> Add name
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    {groupDeviceName &&
                      groupDeviceName.map(
                        (item, index) =>
                          deviceList.deviceType === item.type && (
                            <Option key={index} value={item.name}>
                              {item.name}
                            </Option>
                          )
                      )}
                  </Select>
                )}
              </Form.Item>
              {deviceList.deviceType !== "Input Board" &&
                deviceList.deviceType !== "Analogue Input Board" && (
                  <Form.Item
                    label="Group"
                    extra="Optional"
                    name="deviceGroup"
                    style={{ marginBottom: "5px" }}
                    tooltip="Groups are used for analytics, group this device with others"
                  >
                    <Select
                      // style={{ width: 240 }}
                      mode="multiple"
                      dropdownRender={(menu) => (
                        <div>
                          {menu}
                          <Divider style={{ margin: "4px 0" }} />
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "nowrap",
                              padding: 8,
                            }}
                          >
                            <Input
                              style={{ flex: "auto" }}
                              value={groupInputName}
                              onChange={(event) =>
                                setGroupInputName(event.target.value)
                              }
                            />
                            <a
                              href="#!"
                              style={{
                                flex: "none",
                                padding: "5px",
                                display: "block",
                                cursor: "pointer",
                              }}
                              onClick={HandelAddGroupName}
                            >
                              <PlusOutlined /> Add item
                            </a>
                          </div>
                        </div>
                      )}
                    >
                      {groupName &&
                        groupName.map((item) => (
                          <Option key={item} value={item}>
                            {item}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                )}
            </Fragment>
          )}
          <Form.Item
            label="Communication"
            name="deviceCommType"
            style={{ marginBottom: "5px" }}
            rules={[{ required: true, message: "A type on comms is required" }]}
          >
            <Select
              disabled={inEditMode}
              onChange={handleChangeSelect}
              allowClear
            >
              <Select.Option value="Modbus">Modbus</Select.Option>
              <Select.Option value="SNMP">SNMP</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            tooltip="This is the time in seconds that it will poll"
            label="Poll Rate"
            name="devicePollRate"
            style={{ marginBottom: "5px" }}
            rules={[{ required: true, message: "Can not be blank" }]}
          >
            <InputNumber min={1} step={1} />
          </Form.Item>
          {deviceList.deviceCommType !== undefined &&
            deviceList.deviceCommType === "Modbus" && (
              <Fragment>
                <Form.Item
                  label="Interface Type"
                  name="deviceInterfaceType"
                  style={{ marginBottom: "5px" }}
                  rules={[
                    { required: true, message: "Interface can not be blank" },
                  ]}
                >
                  <Select allowClear>
                    <Select.Option value="TCP">TCP</Select.Option>
                    <Select.Option value="RTU">RTU</Select.Option>
                  </Select>
                </Form.Item>
                {deviceList.deviceInterfaceType !== undefined && (
                  <Fragment>
                    <Form.Item
                      label={
                        deviceList.deviceInterfaceType === "TCP"
                          ? "IP"
                          : "Slave ID"
                      }
                      rules={[
                        {
                          required: true,
                          message:
                            deviceList.deviceInterfaceType === "TCP"
                              ? "IP address can not be blank"
                              : "RTU slave ID can not be blank",
                        },
                        {
                          message:
                            deviceList.deviceInterfaceType === "TCP"
                              ? "Format incorrect valid IP only"
                              : "Invalid RTU Number only 1-255",
                          pattern:
                            deviceList.deviceInterfaceType === "TCP"
                              ? /((\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
                              : /^([1-9]|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])$/,
                        },
                        // () => ({
                        //   validator(_, value) {
                        //     if (inEditMode) {
                        //       if (EditingIP.current === value)
                        //         return Promise.resolve();
                        //     }

                        //     const index = duplicatesList.indexOf(value);

                        //     if (index !== -1) {
                        //       if (deviceList.deviceInterfaceType === "TCP") {
                        //         if (value.length > 3) {
                        //           return Promise.reject(
                        //             "IP address already in use"
                        //           );
                        //         }
                        //       } else {
                        //         return Promise.reject(
                        //           "RTU Slave ID already in use"
                        //         );
                        //       }
                        //     }
                        //     return Promise.resolve();
                        //   },
                        // }),
                      ]}
                      name="deviceIPSlaveID"
                      style={{ marginBottom: "5px" }}
                    >
                      <Input />
                    </Form.Item>
                    {deviceList.deviceInterfaceType === "TCP" ? (
                      <Form.Item
                        extra="Optional"
                        label="TCP Slave ID"
                        name="deviceTCPSlaveID"
                        tooltip="Slave ID sent on TCP, usually used to address items on one IP,leave bank you dont need it"
                      >
                        <Input />
                      </Form.Item>
                    ) : (
                      <Form.Item
                        label="Baud Rate"
                        name="deviceRTUBaudRate"
                        rules={[
                          { required: true, message: "Baud rate is required" },
                        ]}
                        tooltip="This is the required baud rate to talk to this piece of equipment"
                      >
                        <Select allowClear showSearch>
                          <Select.Option key={4800} value={4800}>
                            4800
                          </Select.Option>
                          <Select.Option key={9600} value={9600}>
                            9600
                          </Select.Option>
                          <Select.Option key={19200} value={19200}>
                            19200
                          </Select.Option>
                          <Select.Option key={38400} value={38400}>
                            38400
                          </Select.Option>
                          <Select.Option key={115200} value={115200}>
                            115200
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    )}
                  </Fragment>
                )}
              </Fragment>
            )}

          {deviceList.deviceCommType === "SNMP" && (
            <Fragment>
              <Form.Item
                label={"IP"}
                name="deviceIPSlaveID"
                style={{ marginBottom: "5px" }}
                rules={[
                  { required: true, message: "IP address can not be blank" },
                  {
                    message: "Format incorrect valid IP only",
                    pattern:
                      /((\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                tooltip="Usually set to on most devices public"
                label={"Community String"}
                name="communityString"
                style={{ marginBottom: "5px" }}
              >
                <Input defaultValue="public" />
              </Form.Item>
              <Form.Item
                tooltip="If there are multiple devices on one IP, usually this would indicate the device within that IP, leave blank if not used"
                label={"Device ID"}
                name="deviceID"
                style={{ marginBottom: "5px" }}
              >
                <InputNumber min={0} />
              </Form.Item>
              <Form.Item
                tooltip="Usually 161 on most machines"
                label={"SNMP Port"}
                name="SNMPPort"
                style={{ marginBottom: "5px" }}
                rules={[{ required: true, message: "Port can not be blank" }]}
              >
                <InputNumber defaultValue={161} min={0} />
              </Form.Item>
              <Form.Item
                label={"SNMP Version"}
                name="SNMPVersion"
                style={{ marginBottom: "5px" }}
                rules={[
                  { required: true, message: "There needs to be a version" },
                ]}
              >
                <Select>
                  <Select.Option value={1} key="1">
                    1
                  </Select.Option>
                  <Select.Option value={2} key="2">
                    2
                  </Select.Option>
                </Select>
              </Form.Item>
            </Fragment>
          )}

          <Divider orientation="left">Template/controller</Divider>

          <Table
            rowSelection={{
              type: "radio",
              selectedRowKeys: [
                deviceList.template !== undefined
                  ? deviceList.template.key
                  : "",
              ],
              onChange: (selectedKey, selectedRows) => {
                updateDeviceInformation({
                  ...deviceList,
                  template: selectedRows[0],
                });
              },
            }}
            pagination={{ pageSize: 30 }}
            scroll={{ y: 200 }}
            columns={templateTableColumns}
            dataSource={templateData}
          />
        </Fragment>
      )}
    </Form>
  );
};

AddDeviceInfo.propTypes = {
  updateDeviceInformation: PropTypes.func.isRequired,
  updateDeviceDetails: PropTypes.func.isRequired,
  deviceList: PropTypes.object,
  deviceTypes: PropTypes.array,
  groupsDeviceNames: PropTypes.array,
  deviceTypesLoading: PropTypes.bool,
  inEditMode: PropTypes.bool,
  openAddDeviceModal: PropTypes.bool,
  duplicatesList: PropTypes.array,
};

const mapStateToProps = (state) => ({
  deviceList: state.deviceList.currentDeviceList.Information,
  inEditMode: state.deviceList.isEdit,
  openAddDeviceModal: state.deviceList.openAddDeviceModal,
  groupsDeviceNames: state.deviceList.typeNameList,
  deviceTypes: state.deviceTypes.types_short,
  deviceTypesLoading: state.template.typesLoading,
  duplicatesList: state.deviceList.IPRTUDuplicatonList,
});

export default connect(mapStateToProps, {
  updateDeviceInformation,
  updateDeviceDetails,
})(AddDeviceInfo);
