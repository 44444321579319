import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Ant design components
import { Table, Space, Popconfirm, Tooltip } from "antd";
import {
  BellOutlined,
  InfoCircleOutlined,
  LockOutlined,
  UnlockOutlined,
  CopyFilled,
} from "@ant-design/icons";

// Functions
import {
  openAlarmEditModal,
  updateAlarms,
  openCopyAlarmModal,
} from "../../../actions/templates";

// Other components
import AlarmEditModal from "./newTemplateEditAlarm";
import AlarmCopyModal from "./newTemplateAlarmCopy";

// Actual component
const NewTemplateAlarms = ({
  currentTemplate,
  updateAlarms,
  openAlarmEditModal,
  openCopyAlarmModal,
  permissions,
}) => {
  const [tableData, setTableData] = useState([]);

  // table comlumbs
  const tableColumns = [
    {
      title: "Vaiable",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Child",
      dataIndex: "childname",
      key: "childname",
    },
    {
      title: "is Fixed",
      dataIndex: "fixed",
      key: "fixed",
      render: (fixed, record) => (fixed === undefined ? null : fixed && "Yes"),
    },
    {
      title: "Alarm Active",
      dataIndex: "enabled",
      key: "enabled",
      render: (enable, record) =>
        enable === undefined ? null : enable && "Yes",
    },
    {
      title: "Alarm Configured",
      dataIndex: "alarmMap",
      key: "alarmMap",
      render: (_, record) => (record.alarmMap === undefined ? null : "Yes"),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "action",
      render: (_, record) =>
        permissions &&
        permissions.write && (
          <Space size="middle">
            {record.alarmMap && (
              <Popconfirm
                title={
                  record && record.enabled
                    ? "Sure to disable alarm?"
                    : "Sure to enable alarm?"
                }
                onConfirm={() => handleEnableDisable(record)}
              >
                <Tooltip
                  title={
                    record && record.enabled ? "Disable alarm" : "Enable alarm"
                  }
                >
                  <a href="#!">
                    <BellOutlined />
                  </a>
                </Tooltip>
              </Popconfirm>
            )}
            <Tooltip title="Edit variable alarm information">
              <a href="#!" onClick={() => handleEdit(record)}>
                <InfoCircleOutlined />
              </a>
            </Tooltip>
            {record.alarmMap && (
              <Tooltip
                title={
                  record && record.fixed ? "Unfix this alarm" : "Fix this alarm"
                }
              >
                <a href="#!" onClick={() => handleFixed(record)}>
                  {record.fixed ? <UnlockOutlined /> : <LockOutlined />}
                </a>
              </Tooltip>
            )}
            {record.childname !== undefined && record.childname !== "" && (
              <Tooltip title={"Copy this alarms configuration to another one"}>
                <a href="#!" onClick={() => handleCopy(record)}>
                  <CopyFilled />
                </a>
              </Tooltip>
            )}
          </Space>
        ),
    },
  ];

  const handleCopy = (item) => {
    openCopyAlarmModal(item);
  };

  const handleFixed = (item) => {
    // Unload the data to edit it later
    let dataSource = [...currentTemplate.Alarms];

    if (item.alarmMap !== undefined) {
      // Find the one we want
      const fixedItemIdex = dataSource.findIndex(
        (individualAlarm) => individualAlarm.key === item.key
      );

      if (item.fixed) {
        item.fixed = false;
      } else {
        item.fixed = true;
      }

      // Load the replacement object
      let newAlarmData = {
        ...item,
      };

      dataSource[fixedItemIdex] = newAlarmData;
      // Update the redux MAPs of this template
      updateAlarms(dataSource);
    }
  };

  const handleEnableDisable = (item) => {
    // Unload the data to edit it later
    let dataSource = [...currentTemplate.Alarms];

    if (item.alarmMap !== undefined) {
      // Find the one we want
      const removeItemIdex = dataSource.findIndex(
        (individualAlarm) => individualAlarm.key === item.key
      );

      if (item.enabled) {
        item.enabled = false;
      } else {
        item.enabled = true;
      }

      // Load the replacement object
      let newAlarmData = {
        ...item,
      };

      dataSource[removeItemIdex] = newAlarmData;
      // Update the redux MAPs of this template
      updateAlarms(dataSource);
    }
  };

  const handleEdit = (item) => {
    openAlarmEditModal(item);
  };

  useEffect(() => {
    // find only the mapped items from the list
    const pullMappedVariables = () => {
      if (tableData.length === 0) {
        const allMappedVariables = [];
        let KeyNumbers = 0;

        // Go through each batch, then through the map to find items that have been mapped
        if (currentTemplate.commProtocol === "SNMP") {
          currentTemplate.Batchs.forEach((batch) => {
            KeyNumbers++;
            if (batch.name !== undefined) {
              if (batch.name !== "") {
                allMappedVariables.push({
                  name: batch.name,
                  dataType:
                    batch.ExtraChildren && batch.ExtraChildren.type
                      ? batch.ExtraChildren.type
                      : "Unsigned",
                  childname: batch.ExtraChildren && batch.ExtraChildren.name,
                  key: KeyNumbers,
                });
              }
            }
          });
        } else {
          currentTemplate.Batchs.forEach((batch) => {
            batch.Map.forEach((mapVariable) => {
              KeyNumbers++;
              if (mapVariable.name !== undefined) {
                if (mapVariable.name !== "") {
                  allMappedVariables.push({
                    name: mapVariable.name,
                    dataType: mapVariable.dataType,
                    childname:
                      mapVariable.ExtraChildren &&
                      mapVariable.ExtraChildren.name,
                    key: KeyNumbers,
                  });
                }
              }
            });
          });
        }

        // Add calculation outputs to the alarms
        if (currentTemplate.Calculations) {
          currentTemplate.Calculations.forEach((calc) => {
            calc.calculationOutputs.forEach((calcOutput) => {
              KeyNumbers++;

              allMappedVariables.push({
                name: calcOutput.outputVariable,
                dataType: calcOutput.outputType,
                childname: calc.childName,
                key: KeyNumbers,
              });
            });
          });
        }

        KeyNumbers++;

        // populate all the alarms already configured in the list of registers made
        if (currentTemplate) {
          if (currentTemplate.Alarms) {
            if (currentTemplate.device_type_name === "Environment Board") {
              currentTemplate.Alarms.forEach((alarm) => {
                let index = allMappedVariables.findIndex(
                  (mapAlarm) =>
                    mapAlarm.name === alarm.name &&
                    mapAlarm.childname === alarm.childname
                );

                if (index !== -1) {
                  allMappedVariables.splice(index, 1, {
                    ...alarm,
                    key: KeyNumbers,
                  });
                }

                KeyNumbers++;
              });
            } else if (currentTemplate.device_type_name === "Battery Bank") {
              currentTemplate.Alarms.forEach((alarm) => {
                // check through the list to find the correct alarm to variable
                let index = allMappedVariables.findIndex(
                  (mapAlarm) =>
                    mapAlarm.name === alarm.name &&
                    mapAlarm.childname === alarm.childname
                );
                // Match found
                if (index !== -1) {
                  const dataType = allMappedVariables[index].dataType;

                  allMappedVariables.splice(index, 1, {
                    ...alarm,
                    key: KeyNumbers,
                    dataType,
                  });
                }

                KeyNumbers++;
              });
            } else {
              currentTemplate.Alarms.forEach((alarm) => {
                let index = allMappedVariables.findIndex(
                  (mapAlarm) => mapAlarm.name === alarm.name
                );

                if (index !== -1) {
                  const dataType = allMappedVariables[index].dataType;

                  allMappedVariables.splice(index, 1, {
                    ...alarm,
                    key: KeyNumbers,
                    dataType,
                  });
                }

                KeyNumbers++;
              });
            }
          }
        }
        updateAlarms(allMappedVariables);
        setTableData(allMappedVariables);
      }
    };

    pullMappedVariables();
  }, [currentTemplate, updateAlarms, tableData]);

  return (
    <Fragment>
      <AlarmEditModal />

      <AlarmCopyModal />

      <Table
        // dataSource={tableData}
        dataSource={currentTemplate && currentTemplate.Alarms}
        bordered
        scroll={{ y: 600 }}
        pagination={{ pageSize: 20 }}
        style={{ marginTop: "10px", marginBottom: "10px" }}
        size="small"
        columns={tableColumns}
        expandable={{
          expandedRowRender: (record, indexNu) =>
            record.alarmMap.map((alarmitem) => {
              return (
                <p
                  key={
                    indexNu +
                    alarmitem.alarmOperation +
                    alarmitem.alarmLevel +
                    record.name +
                    record.childname
                  }
                  style={{ margin: 0 }}
                >
                  {alarmitem.alarmOperation !== "range"
                    ? "Value " +
                      alarmitem.alarmOperation +
                      alarmitem.alarmLevel +
                      " : " +
                      alarmitem.alarmPriority +
                      " - " +
                      alarmitem.alarmName
                    : "Range " +
                      alarmitem?.rangeMin +
                      "<>" +
                      alarmitem?.rangeMax +
                      " : " +
                      alarmitem.alarmPriority +
                      " - " +
                      alarmitem.alarmName}
                </p>
              );
            }),
          rowExpandable: (record) => record.alarmMap !== undefined,
        }}
      ></Table>
    </Fragment>
  );
};

NewTemplateAlarms.propTypes = {
  currentTemplate: PropTypes.object,
  openAlarmEditModal: PropTypes.func,
  updateAlarms: PropTypes.func,
  openCopyAlarmModal: PropTypes.func.isRequired,
  permissions: PropTypes.object,
};

const mapStateToProps = (state) => ({
  currentTemplate: state.template.newTemplate,
  permissions: state.auth.permissions,
});

export default connect(mapStateToProps, {
  openAlarmEditModal,
  updateAlarms,
  openCopyAlarmModal,
})(NewTemplateAlarms);
