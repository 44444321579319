import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Ant design components
import { Modal, Form, InputNumber, Select } from "antd";

// functions
import { CloseBatchModal, createInitalTemplate } from "../../actions/templates";

const NewTemplateAddBatch = ({
  addBatchModalOpen,
  CloseBatchModal,
  createInitalTemplate,
  currentTemplate,
}) => {
  // Constants
  const [form] = Form.useForm();

  let formData = {};

  const handleCancel = () => {
    CloseBatchModal();
  };

  const handleOK = () => {
    if (Object.keys(formData).length > 0) {
      if (
        formData.quantity &&
        formData.function &&
        formData.startAddress >= 0
      ) {
        let Batchs = [];

        if (currentTemplate.Batchs !== undefined) {
          Batchs = [...currentTemplate.Batchs];

          formData.key = 0;

          if (Batchs.length > 0) {
            Batchs.forEach((item) => {
              if (item.key > formData.key) formData.key = item.key;
            });

            formData.key++;
          } else {
            formData.key = 0;
          }
        } else {
          formData.key = 0;
        }

        // Create the inital Map
        formData.Map = [];

        for (let i = 0; i < formData.quantity; i++) {
          let mapData = {};

          mapData.address = formData.startAddress + i;
          mapData.key = i;
          mapData.index = formData.key;
          formData.Map.push(mapData);
        }

        Batchs.push({ ...formData });
        createInitalTemplate({ ...currentTemplate, Batchs });
        CloseBatchModal();
      }
    }
  };

  return (
    <Modal
      title="Add a modbus batch"
      onCancel={handleCancel}
      onOk={handleOK}
      visible={addBatchModalOpen}
      afterClose={() => {
        formData = {};
        form.resetFields();
      }}
    >
      <Form
        form={form}
        initialValues={formData}
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 6 }}
        onValuesChange={(_, all) => {
          formData = all;
        }}
      >
        <Form.Item
          label="Start Address"
          name="startAddress"
          style={{ marginBottom: "5px" }}
        >
          <InputNumber step={1} min={0} />
        </Form.Item>
        <Form.Item
          label="Quantity"
          name="quantity"
          style={{ marginBottom: "5px" }}
        >
          <InputNumber step={1} max={255} min={1} />
        </Form.Item>

        <Form.Item
          label="Function Code"
          name="function"
          style={{ marginBottom: "5px" }}
        >
          <Select>
            <Select.Option value={1} key={0}>
              1
            </Select.Option>
            <Select.Option value={3} key={1}>
              3
            </Select.Option>
            <Select.Option value={4} key={2}>
              4
            </Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

NewTemplateAddBatch.propTypes = {
  addBatchModalOpen: PropTypes.bool,
  CloseBatchModal: PropTypes.func,
  createInitalTemplate: PropTypes.func,
  currentTemplate: PropTypes.object,
};

const mapStateToProps = (state) => ({
  addBatchModalOpen: state.template.addBatchModalOpen,
  currentTemplate: state.template.newTemplate,
});

export default connect(mapStateToProps, {
  CloseBatchModal,
  createInitalTemplate,
})(NewTemplateAddBatch);
