import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd
import { Modal, Form, Input, Tooltip, Button, Select } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

// external functions
import {
  closeModalVirtualDevice,
  stepModalOpen,
  saveCalculation,
  constantModalOpen,
  outputModalOpen,
} from "../../../actions/calculations";

// external components
import StepsModal from "../newStepModal";
import StepsTable from "../createNewStepsTable";
import ConstantModal from "../newConstModal";
import ConstantTable from "../createNewConstTable";
import OutputTable from "../outputTable";
import OutputModal from "../newOutputModal";

const { Option } = Select;

// actual component
const ModalCreateVirtual = ({
  modalTempCalcVis,
  closeModalVirtualDevice,
  newCalculation,
  stepModalOpen,
  constantModalOpen,
  saveCalculation,
  deviceTypes,
  outputModalOpen,
}) => {
  const isEditing = useRef(null);
  const [formData] = Form.useForm();
  const [stateFormData, setStateFormData] = useState();

  const setInitalParameters = useCallback(() => {
    if (isEditing.current === null) {
      if (!formData.getFieldValue("calculationName") && newCalculation) {
        setStateFormData({ ...newCalculation });
        isEditing.current = true;
        formData.setFieldsValue({
          calculationName: newCalculation.calculationName,
          calculationDescription: newCalculation.calculationDescription,
          inputOutputType: newCalculation.inputOutputType,
        });
      } else {
        isEditing.current = false;
      }
    }
  }, [newCalculation, formData]);

  useEffect(() => {
    if (modalTempCalcVis) {
      setInitalParameters();
    }
  }, [modalTempCalcVis, setInitalParameters]);

  const handleOk = () => {
    if (formData.getFieldValue("calculationName")) {
      saveCalculation({
        ...newCalculation,
        calculationName: formData.getFieldValue("calculationName"),
        inputOutputType: formData.getFieldValue("inputOutputType"),
        calculationDescription: formData.getFieldValue(
          "calculationDescription"
        ),
        isVirtual: true,
      });
      closeModalVirtualDevice();
    }
  };

  const handleCancel = () => {
    closeModalVirtualDevice();
  };

  const DeviceTypeList =
    deviceTypes !== null
      ? deviceTypes.map((device, index) => (
          <Option value={device.device_type_name} key={index}>
            {device.device_type_name}
          </Option>
        ))
      : null;

  return (
    <Modal
      title={
        isEditing.current === true
          ? "Editing virtual device"
          : "Create new virtual device"
      }
      okButtonProps={{
        disabled:
          newCalculation &&
          newCalculation.steps &&
          stateFormData &&
          stateFormData.calculationName !== "" &&
          stateFormData.calculationName !== undefined
            ? false
            : true,
      }}
      afterClose={() => {
        formData.resetFields();
        setStateFormData();
        isEditing.current = null;
      }}
      okText={isEditing.current === true ? "Update" : "Save"}
      visible={modalTempCalcVis}
      onCancel={handleCancel}
      onOk={handleOk}
      width={900}
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 10 }}
        form={formData}
        size="small"
        onValuesChange={(_, Values) => {
          setStateFormData(Values);
        }}
      >
        <Form.Item
          label="Name"
          name="calculationName"
          tooltip="The name for this calculation"
          style={{ marginBottom: "5px" }}
        >
          <Input disabled={isEditing.current} />
        </Form.Item>
        <Form.Item label="Description" name="calculationDescription">
          <Input.TextArea
            autoSize={{ minRows: 3, maxRows: 4 }}
            showCount
            maxLength={150}
          />
        </Form.Item>
        <Form.Item
          label="Type"
          name="inputOutputType"
          tooltip="This is the base type for this virtual device"
        >
          <Select allowClear showSearch disabled={isEditing.current}>
            {DeviceTypeList}
          </Select>
        </Form.Item>
      </Form>

      <Tooltip title="Add a new output">
        <Button
          onClick={() =>
            outputModalOpen({
              type: "Virtual",
              deviceType: stateFormData && stateFormData.inputOutputType,
            })
          }
          disabled={
            stateFormData && stateFormData.inputOutputType ? false : true
          }
        >
          <PlusCircleOutlined />
          Output
        </Button>
      </Tooltip>

      <OutputModal />

      <OutputTable />

      <Tooltip title="Add a new constant or input to the list">
        <Button
          onClick={() =>
            constantModalOpen({
              virtualType: stateFormData && stateFormData.inputOutputType,
              thisIsVitualCalc: true,
            })
          }
          disabled={
            stateFormData && stateFormData.inputOutputType ? false : true
          }
        >
          <PlusCircleOutlined />
          Constant/Input
        </Button>
      </Tooltip>

      <ConstantModal />

      <ConstantTable />

      <Tooltip title="Add a new step to the list">
        <Button onClick={() => stepModalOpen(null)}>
          <PlusCircleOutlined />
          Calculation Step
        </Button>
      </Tooltip>

      <StepsModal />

      <StepsTable />
    </Modal>
  );
};

ModalCreateVirtual.propTypes = {
  modalTempCalcVis: PropTypes.bool,
  closeModalVirtualDevice: PropTypes.func.isRequired,
  outputModalOpen: PropTypes.func.isRequired,
  stepModalOpen: PropTypes.func.isRequired,
  constantModalOpen: PropTypes.func.isRequired,
  saveCalculation: PropTypes.func.isRequired,
  newCalculation: PropTypes.object,
  deviceTypes: PropTypes.array,
};

const mapStateToProps = (state) => ({
  modalTempCalcVis: state.calcs.modalVitualDeviceVis,
  newCalculation: state.calcs.newCalculation,
  deviceTypes: state.deviceTypes.types_detailed,
});

export default connect(mapStateToProps, {
  closeModalVirtualDevice,
  stepModalOpen,
  constantModalOpen,
  saveCalculation,
  outputModalOpen,
})(ModalCreateVirtual);
