import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Ant Design Components
import { Modal, Form, Select, Input, InputNumber } from "antd";
import Spinner from "../layout/Spinner";

// Addional functions
import {
  CloseAddressMappingModal,
  updateMapping,
} from "../../actions/templates";

// Actual Fucntion
const NewTemplateModbusAddress = ({
  ModalOpen,
  CloseAddressMappingModal,
  addressInfo,
  currentTemplate,
  updateMapping,
  types,
}) => {
  const [devList, setDevList] = useState([]);
  const [SymbolList, setSymbolList] = useState([]);
  const [childNames, setChildNames] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [editAddressform] = Form.useForm();

  const handleOk = () => {
    // needs to at the least have a name and data type
    if (
      editAddressform.getFieldValue("name") &&
      editAddressform.getFieldValue("dataType")
    ) {
      // show the loading on the okay button
      setConfirmLoading(true);

      const indexOfBatch = currentTemplate.Batchs.findIndex(
        (Batch) => Batch.key === addressInfo.index
      );

      // console.log(indexOfBatch);

      let dataSource = [...currentTemplate.Batchs[indexOfBatch].Map];

      // Find the one we want
      const removeItemIdex = dataSource.findIndex(
        (item) => item.address === addressInfo.address
      );

      // Load the replacement object
      let newAddressData = {
        address: addressInfo.address,
        index: addressInfo.index,
        key: addressInfo.key,
      };

      if (
        editAddressform.getFieldValue("clampMax") ||
        editAddressform.getFieldValue("clampMin")
      ) {
        newAddressData.clamp = {};

        if (editAddressform.getFieldValue("clampMax")) {
          newAddressData.clamp.max = editAddressform.getFieldValue("clampMax");
        }

        if (editAddressform.getFieldValue("clampMin")) {
          newAddressData.clamp.min = editAddressform.getFieldValue("clampMin");
        }
      }

      if (editAddressform.getFieldValue("childType")) {
        let ExtraChildren = {
          name: editAddressform.getFieldValue("childName"),
          type: editAddressform.getFieldValue("childType"),
        };

        newAddressData = { ...newAddressData, ExtraChildren };
      }

      newAddressData.dataType = editAddressform.getFieldValue("dataType");
      newAddressData.name = editAddressform.getFieldValue("name");

      if (editAddressform.getFieldValue("scalingFactor"))
        newAddressData.scalingFactor = editAddressform.getFieldValue(
          "scalingFactor"
        );
      if (editAddressform.getFieldValue("scalingOperation"))
        newAddressData.scalingOperation = editAddressform.getFieldValue(
          "scalingOperation"
        );
      if (editAddressform.getFieldValue("symbol"))
        newAddressData.symbol = editAddressform.getFieldValue("symbol");

      // the index is not it the current list
      if (removeItemIdex === -1) {
        dataSource.push(newAddressData);
      } else {
        dataSource[removeItemIdex] = newAddressData;
      }

      // Update the redux MAPs of this template
      updateMapping(indexOfBatch, dataSource);
      // Signal to close the Modal
      CloseAddressMappingModal();
    }
  };

  if (ModalOpen) {
    if (devList.length === 0) {
      if (ModalOpen) {
        setTimeout(() => {
          editAddressform.resetFields();
        }, 100);
      }

      setDevList(
        types.filter(
          (listItems) =>
            listItems.device_type_name === currentTemplate.device_type_name
        )[0].vairables
      );
    }
  }

  const handleCancel = () => {
    CloseAddressMappingModal();
  };

  const createNames = devList.map((lists) => {
    return (
      <Select.Option value={lists.variable} key={lists.variable}>
        {lists.variable}
      </Select.Option>
    );
  });

  const createSymbolList = SymbolList.map((lists, index) => {
    return (
      <Select.Option value={lists} key={index}>
        {lists}
      </Select.Option>
    );
  });

  const createChildNamesList = childNames.map((lists, index) => {
    return (
      <Select.Option value={lists} key={index}>
        {lists}
      </Select.Option>
    );
  });

  return (
    <Modal
      title={"Editing Address " + (addressInfo && addressInfo.address)}
      visible={ModalOpen}
      onOk={() => handleOk()}
      onCancel={handleCancel}
      afterClose={() => {
        setConfirmLoading(false);
        setDevList([]);
        setSymbolList([]);
        setChildNames([]);
      }}
      confirmLoading={confirmLoading}
    >
      {devList.length === 0 ? (
        <Spinner />
      ) : (
        <Form
          form={editAddressform}
          labelCol={{ span: 9 }}
          wrapperCol={{ span: 9 }}
          size={"small"}
          initialValues={
            addressInfo && {
              ...addressInfo,
              clampMax: addressInfo.clamp && addressInfo.clamp.max,
              clampMin: addressInfo.clamp && addressInfo.clamp.min,
              childType:
                addressInfo.ExtraChildren && addressInfo.ExtraChildren.type,
              childName:
                addressInfo.ExtraChildren && addressInfo.ExtraChildren.name,
            }
          }
          onValuesChange={(_, values) => {
            const sym = devList.filter(
              (item) => item.variable === values.name
            )[0];

            if (!values.name) {
              editAddressform.setFields([{ name: "symbol", value: undefined }]);
            }

            let HasArray = "";
            let HasArrayChildren = "";

            if (sym) {
              if (sym.symbol) {
                HasArray = sym.symbol.includes(",");
              }

              setSymbolList([]);
              setChildNames([]);

              if (HasArray) {
                HasArray = sym.symbol.split(",");
                setSymbolList(HasArray);
              } else {
                editAddressform.setFields([
                  { name: "symbol", value: sym.symbol },
                ]);
              }

              if (sym.ExtraChildren !== undefined) {
                HasArrayChildren = sym.ExtraChildren.name.includes(",");

                if (HasArrayChildren) {
                  HasArrayChildren = sym.ExtraChildren.name.split(",");
                  setChildNames(HasArrayChildren);
                  HasArrayChildren = true;
                } else {
                  HasArrayChildren = false;
                }

                editAddressform.setFields([
                  { name: "childType", value: sym.ExtraChildren.type },
                ]);
              }
            }
          }}
        >
          <Form.Item label="Name" name="name">
            <Select showSearch allowClear>
              {createNames}
            </Select>
          </Form.Item>
          <Form.Item label="Symbol" name="symbol">
            {SymbolList.length === 0 ? (
              <Input disabled />
            ) : (
              <Select>{createSymbolList}</Select>
            )}
          </Form.Item>
          <Form.Item label="Child Type" name="childType">
            <Input disabled />
          </Form.Item>
          <Form.Item label="Child Name" name="childName">
            {childNames.length === 0 ? (
              <Input disabled />
            ) : (
              <Select>{createChildNamesList}</Select>
            )}
          </Form.Item>
          <Form.Item label="Scaling Operation" name="scalingOperation">
            <Select allowClear>
              <Select.Option value="*" key="*">
                Times
              </Select.Option>
              <Select.Option value="^" key="^">
                XOR
              </Select.Option>
              <Select.Option value="/" key="/">
                Divied
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Scaling Factor" name="scalingFactor">
            <InputNumber step={0.1} />
          </Form.Item>
          <Form.Item label="Data Type" name="dataType">
            <Select allowClear showSearch>
              <Select.Option value="Signed" key="0">
                16S
              </Select.Option>
              <Select.Option value="Unsigned" key="1">
                16
              </Select.Option>
              <Select.Option value="UINT32" key="2">
                32
              </Select.Option>
              <Select.Option value="INT32" key="3">
                32S
              </Select.Option>
              <Select.Option value="Hex" key="4">
                Hex
              </Select.Option>
              <Select.Option value="Binary" key="5">
                Binary
              </Select.Option>
              <Select.Option value="Long" key="6">
                Long
              </Select.Option>
              <Select.Option value="LongInverse" key="7">
                Long Inverse
              </Select.Option>
              <Select.Option value="Float" key="8">
                Float
              </Select.Option>
              <Select.Option value="FloatInverse" key="9">
                Float Inverse
              </Select.Option>
              <Select.Option value="Double" key="10">
                Double
              </Select.Option>
              <Select.Option value="DoubleInverse" key="11">
                Double Inverse
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Max Value" name="clampMax">
            <InputNumber step={0.1} />
          </Form.Item>
          <Form.Item label="Min Value" name="clampMin">
            <InputNumber step={0.1} />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

NewTemplateModbusAddress.propTypes = {
  ModalOpen: PropTypes.bool,
  CloseAddressMappingModal: PropTypes.func.isRequired,
  addressInfo: PropTypes.object,
  currentTemplate: PropTypes.object,
  updateMapping: PropTypes.func,
  types: PropTypes.array,
};

const mapStateToProps = (state) => ({
  ModalOpen: state.template.modbusAddressMappingModalOpen,
  addressInfo: state.template.modbusAddressDetails,
  currentTemplate: state.template.newTemplate,
  types: state.deviceTypes.types_detailed,
});

export default connect(mapStateToProps, {
  CloseAddressMappingModal,
  updateMapping,
})(NewTemplateModbusAddress);
