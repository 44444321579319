import axios from "axios";
import { v4 as uuid } from "uuid";
import {
  CALC_NEWMODAL_CLOSED,
  CALC_NEWMODAL_OPEN,
  CALC_NEWMODAL_UPDATE,
  CALC_CONSTMODAL_CLOSE,
  CALC_CONSTMODAL_OPEN,
  CALC_CONST_ADD,
  CALC_NEWSTEPMODAL_OPEN,
  CALC_NEWSTEPMODAL_CLOSE,
  CALC_CONST_DELETE,
  CALC_STEP_ADD,
  CALC_STEP_DELETE,
  CALC_UPDATED,
  CALC_FAILED,
  CALC_UPDATING,
  CALC_NEWOUTPUTMODAL_OPEN,
  CALC_NEWOUTPUTMODAL_CLOSE,
  CALC_OUTPUT_ADD,
  CALC_OUTPUT_DELETE,
  CALC_MODAL_TEMPL_OPEN,
  CALC_MODAL_TEMPL_CLOSE,
  CALC_MODAL_VIRTUAL_OPEN,
  CALC_MODAL_VIRTUAL_CLOSE,
} from "./types";
import setAuthToken from "../utils/setAuthToken";
import { setAlert } from "./alert";
import { logout } from "./auth";

// create new modal open
export const createNewModalOpen = (record) => (dispatch) => {
  dispatch({
    type: CALC_NEWMODAL_OPEN,
    payload: record,
  });
};

// create new modal close
export const createNewModalClose = () => (dispatch) => {
  dispatch({
    type: CALC_NEWMODAL_CLOSED,
  });
};

// update the new calc details
export const updateNewCalculation = (data) => (dispatch) => {
  dispatch({
    type: CALC_NEWMODAL_UPDATE,
    payload: data,
  });
};

// Adds a new output
export const outputAdd = (data) => (dispatch) => {
  const key = uuid();

  dispatch({
    type: CALC_OUTPUT_ADD,
    payload: { ...data, key },
  });
};

// removes an output
export const outputDelete = (data) => (dispatch) => {
  dispatch({
    type: CALC_OUTPUT_DELETE,
    payload: data,
  });
};

// create new modal open for the constants
export const constantModalOpen = (data) => (dispatch) => {
  dispatch({
    type: CALC_CONSTMODAL_OPEN,
    payload: data,
  });
};

// create new modal close for the constants
export const constantModalClose = () => (dispatch) => {
  dispatch({
    type: CALC_CONSTMODAL_CLOSE,
  });
};

// create new modal open for the calculation steps
export const stepModalOpen = (record) => (dispatch) => {
  dispatch({
    type: CALC_NEWSTEPMODAL_OPEN,
    payload: record,
  });
};

// create new modal close for the calculation steps
export const stepModalClose = () => (dispatch) => {
  dispatch({
    type: CALC_NEWSTEPMODAL_CLOSE,
  });
};

// create new modal open for the outputs steps
export const outputModalOpen = (record) => (dispatch) => {
  dispatch({
    type: CALC_NEWOUTPUTMODAL_OPEN,
    payload: record,
  });
};

// create new modal close for the outputs steps
export const outputModalClose = () => (dispatch) => {
  dispatch({
    type: CALC_NEWOUTPUTMODAL_CLOSE,
  });
};

// add constant to the calc
export const constantAdd = (data) => (dispatch) => {
  const key = uuid();

  dispatch({
    type: CALC_CONST_ADD,
    payload: { ...data, key },
  });
};
// add constant to the calc
export const constantDelete = (data) => (dispatch) => {
  dispatch({
    type: CALC_CONST_DELETE,
    payload: data,
  });
};

// add constant to the calc
export const stepAdd = (data) => (dispatch) => {
  const key = uuid();

  dispatch({
    type: CALC_STEP_ADD,
    payload: { ...data, key },
  });
};
// add constant to the calc
export const stepDelete = (data) => (dispatch) => {
  dispatch({
    type: CALC_STEP_DELETE,
    payload: data,
  });
};

// update the backend to save the value
export const saveCalculation = (data) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    await axios.post("/api/calcs", data);

    dispatch({
      type: CALC_UPDATED,
      payload: null,
    });

    setTimeout(async () => {
      const allCalcs = await GetCalcsFromController();

      dispatch({
        type: CALC_UPDATED,
        payload: allCalcs,
      });
    }, 100);

    dispatch(setAlert("Calculation Saved!!", "success"));
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      dispatch(logout());
      dispatch(
        setAlert(
          "Your session has timed out, please log in and try again",
          "error"
        )
      );
    } else {
      dispatch(setAlert("An error has occured, please check logs", "error"));
    }

    dispatch({
      type: CALC_FAILED,
    });
  }
};

// update the backend to save the value
export const getAllCalculation = () => async (dispatch) => {
  dispatch({
    type: CALC_UPDATING,
  });

  const allCalcs = await GetCalcsFromController();

  if (allCalcs === true) {
    dispatch(setAlert("Calculation list is empty", "error"));

    dispatch({
      type: CALC_FAILED,
    });
  } else {
    dispatch({
      type: CALC_UPDATED,
      payload: allCalcs,
    });
  }
};

const GetCalcsFromController = () => {
  return new Promise(async (resolve, reject) => {
    try {
      if (localStorage.token) {
        setAuthToken(localStorage.token);
      }
      let allCalcs = await axios.get("/api/calcs");
      allCalcs = allCalcs.data;

      return resolve(allCalcs);
    } catch (error) {
      return resolve(true);
    }
  });
};

// update the backend to save the value
export const deleteCalculation = (record) => async (dispatch) => {
  dispatch({
    type: CALC_UPDATING,
  });

  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    // delete the record
    await axios.delete("/api/calcs/" + record.calculationName);

    dispatch({
      type: CALC_UPDATED,
      payload: null,
    });

    setTimeout(async () => {
      const allCalcs = await GetCalcsFromController();

      dispatch({
        type: CALC_UPDATED,
        payload: allCalcs,
      });
    }, 100);

    dispatch(setAlert("Calculation deleted!!", "success"));
  } catch (error) {
    dispatch(setAlert("An error occcured, check logs!!", "error"));

    dispatch({
      type: CALC_FAILED,
    });
  }
};

// Update the calcs list from the server
export const updateCalcsList =
  (fromWizard = false) =>
  async (dispatch) => {
    let isEmpty = true;

    // from the wizard we dont want to wait for this to finish
    if (fromWizard) isEmpty = await GetCalcsFromController();

    if (isEmpty === true) {
      dispatch({
        type: CALC_UPDATING,
      });

      try {
        if (localStorage.token) {
          setAuthToken(localStorage.token);
        }

        await axios.get("/api/calcs/update");

        const allCalcs = await GetCalcsFromController();

        dispatch({
          type: CALC_UPDATED,
          payload: allCalcs,
        });

        dispatch(setAlert("Calculations list updated from server", "success"));
      } catch (error) {
        const errors = error.response.data.errors;

        if (errors) {
          errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
        }

        dispatch({
          type: CALC_FAILED,
        });
      }
    }
  };

// Open Template type calculation Modal
export const openModalTempCalc = (record) => async (dispatch) => {
  dispatch({
    type: CALC_MODAL_TEMPL_OPEN,
    payload: record,
  });
};

// Close Template type calculation Modal
export const closeModalTempCalc = () => async (dispatch) => {
  dispatch({
    type: CALC_MODAL_TEMPL_CLOSE,
  });
};

// Open virtual device calculation Modal
export const openModalVirtualDevice = (record) => async (dispatch) => {
  dispatch({
    type: CALC_MODAL_VIRTUAL_OPEN,
    payload: record,
  });
};

// Close virtual device calculation Modal
export const closeModalVirtualDevice = () => async (dispatch) => {
  dispatch({
    type: CALC_MODAL_VIRTUAL_CLOSE,
  });
};
