import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Ant design components
import { Form, Input, DatePicker } from "antd";

// Functions
import { updateDeviceDetails } from "../../../../actions/deviceList";

const ModalDetailsUPS = ({ deviceList, updateDeviceDetails }) => {
  return (
    <Form
      style={{ marginTop: "20px" }}
      name="DeviceInfo"
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 6 }}
      layout="horizontal"
      initialValues={deviceList}
      onValuesChange={(_, all) => {
        updateDeviceDetails(all);
      }}
      size="small"
    >
      <Form.Item
        name="manufacture"
        label="Manufacturer"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="UPS Size" name="size">
        <Input />
      </Form.Item>
      <Form.Item label="Serial Number" name="serial">
        <Input />
      </Form.Item>
      <Form.Item label="Commisioned" name="commisionedDate">
        <DatePicker />
      </Form.Item>
      <Form.Item label="Last Service" name="lastServiceDate">
        <DatePicker />
      </Form.Item>
    </Form>
  );
};

const mapStateToProps = (state) => ({
  deviceList: state.deviceList.currentDeviceList.Details,
});

ModalDetailsUPS.propTypes = {
  updateDeviceDetails: PropTypes.func.isRequired,
  deviceList: PropTypes.object,
};

export default connect(mapStateToProps, { updateDeviceDetails })(
  ModalDetailsUPS
);
