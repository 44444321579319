import {
  ADV_MODAL_ADD_CLOSE,
  ADV_MODAL_ADD_OPEN,
  ADV_LIST_ADD,
  ADV_LIST_DELETE,
  ADV_LIST_SAVE,
  ADV_LIST_SAVE_DONE,
  ADV_LIST_LOAD,
  ADV_LIST_LOAD_DONE,
  ADV_LIST_GET,
  ADV_MODAL_VIRTUAL_OPEN,
  ADV_MODAL_VIRTUAL_CLOSE,
} from "../actions/types";

const initalState = {
  modalAddNewData: null,
  modalAddNewVis: false,
  modalAddVirtualData: null,
  modalAddVirtualVis: false,
  calculationList: null,
  saving: false,
  loading: false,
};

export default function advanced(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADV_LIST_LOAD_DONE:
      return {
        ...state,
        loading: false,
      };
    case ADV_LIST_LOAD:
      return {
        ...state,
        loading: true,
      };
    case ADV_LIST_SAVE_DONE:
      return {
        ...state,
        saving: false,
      };
    case ADV_LIST_SAVE:
      return {
        ...state,
        saving: true,
      };
    case ADV_LIST_DELETE:
      let oldList = [...state.calculationList];

      if (oldList && oldList.length > 1) {
        oldList = oldList.filter((listItem) => listItem.key !== payload.key);

        return {
          ...state,
          calculationList: [...oldList],
        };
      }

      return {
        ...state,
        calculationList: [],
      };
    case ADV_LIST_GET:
      return {
        ...state,
        calculationList: payload,
      };
    case ADV_LIST_ADD:
      let entireList = [];

      if (state.calculationList) {
        entireList = [...state.calculationList];

        const indexOfItem = state.calculationList.findIndex(
          (listitem) => listitem.key === payload.key
        );

        // this is a match
        if (indexOfItem !== -1) {
          entireList[indexOfItem] = payload;
        } else {
          entireList = [...state.calculationList, payload];
        }
      } else {
        entireList = [payload];
      }

      return {
        ...state,
        calculationList: entireList,
      };
    case ADV_MODAL_ADD_OPEN:
      return {
        ...state,
        modalAddNewVis: true,
        modalAddNewData: payload,
      };
    case ADV_MODAL_ADD_CLOSE:
      return { ...state, modalAddNewVis: false, modalAddNewData: null };
    case ADV_MODAL_VIRTUAL_OPEN:
      return {
        ...state,
        modalAddVirtualVis: true,
        modalAddVirtualData: payload,
      };
    case ADV_MODAL_VIRTUAL_CLOSE:
      return { ...state, modalAddVirtualVis: false, modalAddVirtualData: null };
    default:
      return state;
  }
}
