import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// ant design components
import {
  Table,
  Space,
  Tooltip,
  Popconfirm,
  Typography,
  Row,
  Col,
  Button,
} from "antd";
import {
  EditFilled,
  PlusCircleFilled,
  DeleteOutlined,
  SaveOutlined,
} from "@ant-design/icons";

// external function
import {
  modalAddNewOpen,
  deleteItem,
  createAndSaveEntireList,
  getTheList,
  modalAddVirtualOpen,
} from "../../../../actions/advanced";
import { getAllCalculation } from "../../../../actions/calculations";
import {
  getConfigurationFiles,
  GetSelectedConfig,
  ClearDeviceList,
} from "../../../../actions/deviceList";
import {
  getDeviceTypesShort,
  getDeviceTypesDetailed,
} from "../../../../actions/deviceTypes";

// external components
import AddNewModal from "./Modals/modalAddNew";
import AddVirtualModal from "./Modals/ModalAddVirtual";

// Constants
const { Title, Paragraph, Text } = Typography;

// actual component
const Advanced = ({
  permissions,
  fromWizard,
  modalAddNewOpen,
  getAllCalculation,
  getDeviceTypesDetailed,
  tableData,
  deleteItem,
  createAndSaveEntireList,
  getTheList,
  tableLoading,
  listSaving,
  modalAddVirtualOpen,
  getDeviceTypesShort,
  getConfigurationFiles,
  allConfigFiles,
  GetSelectedConfig,
  ClearDeviceList,
}) => {
  useEffect(() => {
    getTheList();
    getAllCalculation();
    getDeviceTypesDetailed();
    getDeviceTypesShort();
    getConfigurationFiles();
  }, [
    getAllCalculation,
    getDeviceTypesDetailed,
    getTheList,
    getDeviceTypesShort,
    getConfigurationFiles,
  ]);

  // this is to get the names of all the equipment used
  useEffect(() => {
    if (allConfigFiles && allConfigFiles.length > 0) {
      allConfigFiles.map((config) => {
        if (config.enabled) {
          GetSelectedConfig(config._id, false, true);
        }
        return true;
      });
    }
  }, [allConfigFiles, GetSelectedConfig]);

  // unload Effect
  useEffect(() => {
    return () => {
      clearInterval(sessionStorage.getItem("intervalTimerId"));
      sessionStorage.removeItem("intervalTimerId");
      if (fromWizard === undefined) ClearDeviceList();
    };
  }, [ClearDeviceList, fromWizard]);

  const handleEdit = (record) => {
    modalAddNewOpen(record);
  };

  const handleEditVD = (record) => {
    modalAddVirtualOpen(record);
  };

  const handleDelete = (record) => {
    deleteItem(record);
  };

  const handleSave = async () => {
    await createAndSaveEntireList(tableData);
    getTheList();
  };

  // table columbs
  const columns = [
    {
      title: "Name",
      dataIndex: "calculationName",
      key: "calculationName",
      render: (text, record) => (
        <a href="#!" onClick={() => handleEdit(record)}>
          {text}
        </a>
      ),
      //   ...getColumnSearchProps("device_name"),
    },
    {
      title: "Description",
      dataIndex: "calculationDescription",
      key: "calculationDescription",
      //   ...getColumnSearchProps("device_name"),
    },
    {
      title: "Virtual Device",
      dataIndex: "isVirtual",
      key: "isVirtual",
      render: (text, record) => (text !== undefined ? "Yes" : null),
      //   ...getColumnSearchProps("device_name"),
    },
    {
      title: "Device Name",
      dataIndex: "deviceName",
      key: "deviceName",
      //   ...getColumnSearchProps("device_name"),
    },
    {
      title: "Action",
      dataIndex: "operation",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          {permissions && permissions.write && (
            <Fragment>
              <Popconfirm
                title={"Sure you want to delete?"}
                onConfirm={() => handleDelete(record)}
              >
                <Tooltip title={record && "Delete " + record.calculationName}>
                  <a href="#!">
                    <DeleteOutlined style={{ color: "red" }} />
                  </a>
                </Tooltip>
              </Popconfirm>

              {record && record.isVirtual ? (
                <Tooltip
                  title={
                    record &&
                    "Virtual device  " + record.calculationName + " edit"
                  }
                >
                  <a href="#!" onClick={() => handleEditVD(record)}>
                    <EditFilled />
                  </a>
                </Tooltip>
              ) : (
                <Tooltip title={record && "Edit " + record.calculationName}>
                  <a href="#!" onClick={() => handleEdit(record)}>
                    <EditFilled />
                  </a>
                </Tooltip>
              )}
            </Fragment>
          )}
        </Space>
      ),
    },
  ];

  // Custom Compont - add button
  const AddButton = () => {
    return (
      <Tooltip title="Click to add a new statistical configuraion">
        <Button
          onClick={() => modalAddNewOpen(null)}
          style={{ marginBottom: "10px" }}
          type="primary"
          icon={<PlusCircleFilled />}
          size="small"
        >
          New Stat
        </Button>
      </Tooltip>
    );
  };

  // Custom Compont - add button
  const AddVirtualDeviceButton = () => {
    return (
      <Tooltip title="Click to add a new virtual device">
        <Button
          onClick={() => modalAddVirtualOpen(null)}
          style={{ marginBottom: "10px" }}
          type="primary"
          icon={<PlusCircleFilled />}
          size="small"
        >
          New Virtual Device
        </Button>
      </Tooltip>
    );
  };

  // Custom Compont - save button
  const SaveButton = () => {
    return (
      <Tooltip title="Click to save this configuration">
        <Button
          onClick={handleSave}
          style={{ marginBottom: "10px" }}
          type="primary"
          icon={<SaveOutlined />}
          loading={listSaving}
          size="small"
        >
          Save
        </Button>
      </Tooltip>
    );
  };

  return (
    <Fragment>
      {fromWizard === undefined ? (
        <Fragment>
          <Title level={2}>Statistic items configuration</Title>

          {permissions && permissions.write && (
            <Fragment>
              <Paragraph>
                The below table is used to edit all the statistical
                configurations on this device, use the <Text keyboard>New</Text>{" "}
                to add a new one to the table.
              </Paragraph>
            </Fragment>
          )}
        </Fragment>
      ) : (
        <Fragment>
          <Row style={{ marginBottom: "20px" }}>
            <Col span={8}></Col>
            <Col span={8}>
              <Typography style={{ textAlign: "center" }}>
                <Title level={2}>Statistic items configuration</Title>
                <Paragraph>
                  This section is for deriving statistical variables. Press on
                  the <Text code>Add</Text> button to add a new one to the list,
                  once you are done hit the <Text code>Done</Text> button to
                  save the list.
                </Paragraph>
                <Paragraph>
                  <Text keyboard strong type="warning">
                    NB:
                  </Text>
                  You dont need to complete this now, it can be edited later
                </Paragraph>
              </Typography>
            </Col>
            <Col span={8}></Col>
          </Row>
        </Fragment>
      )}

      <Space>
        {fromWizard === undefined && <SaveButton />}

        <AddButton />

        <AddVirtualDeviceButton />
      </Space>

      <AddNewModal />

      <AddVirtualModal />

      <Table
        loading={tableLoading}
        dataSource={tableData}
        columns={columns}
      ></Table>
    </Fragment>
  );
};

Advanced.propTypes = {
  permissions: PropTypes.object,
  modalAddNewOpen: PropTypes.func,
  getDeviceTypesDetailed: PropTypes.func.isRequired,
  deleteItem: PropTypes.func,
  createAndSaveEntireList: PropTypes.func,
  getTheList: PropTypes.func,
  tableData: PropTypes.array,
  tableLoading: PropTypes.bool,
  listSaving: PropTypes.bool,
  modalAddVirtualOpen: PropTypes.func.isRequired,
  getDeviceTypesShort: PropTypes.func.isRequired,
  getConfigurationFiles: PropTypes.func.isRequired,
  GetSelectedConfig: PropTypes.func.isRequired,
  ClearDeviceList: PropTypes.func.isRequired,
  allConfigFiles: PropTypes.array,
};

const mapStateToProps = (state) => ({
  permissions: state.auth.permissions,
  tableData: state.advanced.calculationList,
  tableLoading: state.advanced.loading,
  listSaving: state.advanced.saving,
  allConfigFiles: state.deviceList.allConfigFiles,
});

export default connect(mapStateToProps, {
  modalAddNewOpen,
  getAllCalculation,
  deleteItem,
  createAndSaveEntireList,
  getDeviceTypesDetailed,
  getTheList,
  modalAddVirtualOpen,
  getDeviceTypesShort,
  getConfigurationFiles,
  GetSelectedConfig,
  ClearDeviceList,
})(Advanced);
