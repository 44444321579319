import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { v4 as uuid } from "uuid";

// antd
import { Table, Button, Row, Col, Typography } from "antd";
import { RetweetOutlined } from "@ant-design/icons";

import { GetSelectedConfig } from "../../actions/deviceList";

const { Title, Paragraph, Text } = Typography;

// non-accepted list
const NotAccepted = ["UC"];

// actual component
const SiteDiscovery = ({ templates, GetSelectedConfig }) => {
  const [tableLoading, setTabletLoad] = useState(true);
  const [dataS, setdataS] = useState([]);
  const [selectedItemsKeys, setSelectedItemsKeys] = useState([]);

  const getDiscoveryData = async () => {
    const data = {
      task: "discover",
    };
    let result = await axios.post("/api/utl", data);
    result = result.data;

    result.forEach((element, index) => {
      element.key = index;

      NotAccepted.forEach((item) => {
        if (element.DeviceType.includes(item)) element.disabled = true;
      });
    });

    setdataS(result);
    setTabletLoad(false);
  };

  useEffect(() => {
    getDiscoveryData();
  }, []);

  const refreshData = () => {
    setdataS([]);
    setSelectedItemsKeys([]);
    getDiscoveryData();
    setTabletLoad(true);
  };

  const columns = [
    {
      title: "IP Address",
      dataIndex: "IPaddress",
      key: "IPaddress",
    },
    {
      title: "Device Type",
      dataIndex: "DeviceType",
      key: "DeviceType",
    },
    {
      title: "MACaddress",
      dataIndex: "MACaddress",
      key: "MACaddress",
      responsive: ["lg"],
    },
    {
      title: "Code Version",
      dataIndex: "CodeVersion",
      key: "CodeVersion",
      responsive: ["lg"],
    },
    {
      title: "Details",
      dataIndex: "Details",
      key: "Details",
      responsive: ["lg"],
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const entireList = [];

      selectedRows.map((item) => {
        const id = uuid();

        let device = {
          Information: {
            deviceType: "",
            deviceName: "",
            deviceCommType: "Modbus",
            devicePollRate: 5,
            deviceInterfaceType: "TCP",
            deviceIPSlaveID: "",
            template: {
              key: 0,
              _id: "",
              model: "",
              description: "",
              maunfacture: "",
            },
          },
          Details: {},
          KeyID: id,
        };

        device.Information.deviceIPSlaveID = item.IPaddress;
        device.Information.deviceName = item.Details;

        const templateIndex = templates.findIndex(
          (temp) => temp.model === item.DeviceType
        );

        if (templateIndex !== -1) {
          device.Information.deviceType =
            templates[templateIndex].device_type_name;

          if (device.Information.deviceType === "Input Board") {
            device.Information.deviceName = id;
            device.Details.childNames = [];
          } else if (device.Information.deviceType === "Environment Board") {
            delete device.Information.deviceName;
            device.Details.childNames = [];
          }

          device.Information.template._id = templates[templateIndex]._id;
          device.Information.template.model = templates[templateIndex].model;
          device.Information.template.description =
            templates[templateIndex].description;
          device.Information.template.maunfacture =
            templates[templateIndex].maunfacture;
        } else {
          device = null;
        }

        if (device !== null) {
          entireList.push({ ...device });
        }

        return true;
      });

      if (entireList.length > 0) {
        GetSelectedConfig([entireList], true, false);
      }
      setSelectedItemsKeys(selectedRowKeys);
    },
    selectedRowKeys: selectedItemsKeys,
    getCheckboxProps: (record) => ({
      disabled: record.disabled === true,
      // Column configuration not to be checked
      name: record.key,
    }),
  };

  return (
    <Fragment>
      <Row style={{ marginBottom: "20px" }}>
        <Col sm={0} lg={5} xl={7}></Col>
        <Col sm={24} lg={14} xl={10}>
          <Typography style={{ textAlign: "center" }}>
            <Title>Device Discovery</Title>
            <Title level={2}>Find Master Power Devices</Title>

            <Paragraph style={{ textAlign: "center" }}>
              The table shows any devices the UC finds on the network, these are
              all Master Power technologies devices only. If a device is not in
              the table hit the <Text keyboard>Refresh</Text> button to attempt
              to find them. Make sure the UC is on the same network and in the
              same range to find them. Select the devices you want to add by
              checking them in the list. Non Master power devices can be added
              in the next screen.
            </Paragraph>
          </Typography>
        </Col>
      </Row>

      <Button type="primary" onClick={refreshData} icon={<RetweetOutlined />}>
        Refresh
      </Button>

      <Table
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
        columns={columns}
        style={{ marginTop: "10px" }}
        size="small"
        dataSource={dataS}
        pagination={{ pageSize: 50 }}
        scroll={{ y: 800 }}
        loading={tableLoading}
      />
    </Fragment>
  );
};

SiteDiscovery.propTypes = {
  templates: PropTypes.array,
  GetSelectedConfig: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  templates: state.template.templates,
});

export default connect(mapStateToProps, { GetSelectedConfig })(SiteDiscovery);
