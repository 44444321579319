import {
  SITE_LOADED,
  SITE_FAIL,
  SITE_UPDATE_DETAILS,
  SITE_CLEAR,
  SITE_NEW,
  SITE_UPDATE_BACKENDVERSION,
  SITE_UPDATE_BACKEND_START,
  SITE_UPDATE_BACKEND_END,
  SITE_UPDATE_BKSERVICE_START,
  SITE_UPDATE_BKSERVICE_END,
  SITE_UPDATE_HMISERVICE_START,
  SITE_UPDATE_HMISERVICE_END,
} from "../actions/types";

const initalState = {
  dataloaded: false,
  newSite: false,
  siteDetails: null,
  backendVersion: null,
  updateConfigBackend: false,
  updatingBkServices: false,
  updatingHMIServices: false,
};

export default function site(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case SITE_UPDATE_HMISERVICE_START:
      return { ...state, updatingHMIServices: true };
    case SITE_UPDATE_HMISERVICE_END:
      return { ...state, updatingHMIServices: false };
    case SITE_UPDATE_BKSERVICE_START:
      return { ...state, updatingBkServices: true };
    case SITE_UPDATE_BKSERVICE_END:
      return { ...state, updatingBkServices: false };
    case SITE_UPDATE_BACKEND_START:
      return { ...state, updateConfigBackend: true };
    case SITE_UPDATE_BACKEND_END:
      return { ...state, updateConfigBackend: false };
    case SITE_UPDATE_BACKENDVERSION:
      return { ...state, backendVersion: payload };
    case SITE_UPDATE_DETAILS:
      return { ...state, siteDetails: payload };
    case SITE_NEW:
      return { ...state, dataloaded: true, newSite: true };
    case SITE_LOADED:
      return {
        ...state,
        dataloaded: true,
        siteDetails: payload,
        newSite: false,
      };
    case SITE_CLEAR:
    case SITE_FAIL:
      return {
        ...state,
        dataloaded: false,
        newSite: false,
        siteDetails: null,
        backendVersion: null,
      };
    default:
      return state;
  }
}
