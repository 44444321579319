import {
  AUTH_ERROR,
  USER_LOADED,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  LOGIN_LOAD,
  USER_PERMISIONS,
  USER_TEMPLATE_PERMISIONS,
  LOGIN_MODAL_OPEN,
  LOGIN_MODAL_CLOSE,
} from "../actions/types";

const initalState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  isAdmin: false,
  loading: true,
  user: null,
  permissions: null,
  templatePermissions: null,
  modalLoginVis: false,
};

export default function auth(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_MODAL_CLOSE:
      return { ...state, modalLoginVis: false };
    case LOGIN_MODAL_OPEN:
      return { ...state, modalLoginVis: true };
    case USER_PERMISIONS:
      return { ...state, permissions: payload };
    case USER_TEMPLATE_PERMISIONS:
      return { ...state, templatePermissions: payload };
    case LOGIN_LOAD:
      return { ...state, loading: true };
    case USER_LOADED:
      return { ...state, isAuthenticated: true, loading: false, user: payload };
    case LOGIN_SUCCESS:
      localStorage.setItem("token", payload.token);
      return { ...state, ...payload, isAuthenticated: true };
    case LOGIN_FAIL:
    case AUTH_ERROR:
    case LOGOUT:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
        permissions: null,
      };
    default:
      return state;
  }
}
