import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Other Components
import Spinner from "../layout/Spinner";

// Antd components
import { Typography, Row, Col } from "antd";

// other components
import LeftColumn from "./LeftColumn/leftColumn";
import MidwayColumn from "./MidColumn/midColumn";
import RightColumn from "./RightColumn/RightColumn";

const { Title } = Typography;

// Actual Component
const Dashboard = ({ auth: { user, loading } }) => {
  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <Typography>
        <Title level={2}>
          Welcome {user && user.name} {user && user.surname}
        </Title>
        <Fragment>
          <Row>
            <Col>
              <LeftColumn />
            </Col>
            <Col span={2}></Col>
            <Col>
              <MidwayColumn />
            </Col>
            <Col span={2}></Col>
            <Col>
              <RightColumn />
            </Col>
          </Row>
        </Fragment>
      </Typography>
    </Fragment>
  );
};

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProp = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProp)(Dashboard);
