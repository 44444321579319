import axios from "axios";
import {
  CONFIG_OPEN,
  CONFIG_CLOSE,
  CONFIG_UPDATE,
  CONFIG_ADD,
  CONFIG_CURRENT_ADD,
  CONFIG_GET,
  CONFIG_DETAILS,
} from "./types";
import { setAlert } from "./alert";
import setAuthToken from "../utils/setAuthToken";

// OPen the modal to edit the modbus specific items
export const openConfigModal = () => async (dispatch) => {
  dispatch({
    type: CONFIG_OPEN,
  });
};

//  Close the modal to edit the modbus specific items
export const closeConfigModal = () => async (dispatch) => {
  dispatch({
    type: CONFIG_CLOSE,
  });
};

//  Update the specific items
export const updateConfig = (items) => async (dispatch) => {
  dispatch({
    type: CONFIG_ADD,
    payload: items,
  });
};

export const updateCurrentDevice = (DeviceData) => (dispatch) => {
  dispatch({
    type: CONFIG_CURRENT_ADD,
    payload: DeviceData,
  });
};

export const updateBackend = (data, isNew) => async (dispatch) => {
  try {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (isNew) data.version = 0;

    // await axios.post("http://localhost:5000/api/modbus", data, config);
    await axios.post("/api/modbus", data, config);

    if (isNew) dispatch(setAlert("New Config added", "success"));
    else dispatch(setAlert("Config updated", "success"));

    dispatch({
      type: CONFIG_UPDATE,
    });
  } catch (error) {}
};

export const fetchConfigurationsList = () => async (dispatch) => {
  try {
    // const modbusTemplateList = await axios.get("http://localhost:5000/api/modbus");
    const modbusTemplateList = await axios.get("/api/modbus");

    dispatch({
      type: CONFIG_GET,
      payload: modbusTemplateList.data,
    });
  } catch (error) {}
};

export const enableConfig = (enable, id) => async (dispatch) => {
  try {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    const data = {
      current: enable,
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // await axios.post("http://localhost:5000/api/modbus/" + id, data, config);
    await axios.post("/api/modbus/" + id, data, config);

    // const modbusTemplateList = await axios.get("http://localhost:5000/api/modbus");
    const modbusTemplateList = await axios.get("/api/modbus");

    dispatch({
      type: CONFIG_GET,
      payload: modbusTemplateList.data,
    });

    if (enable) dispatch(setAlert("Config enabled as current", "success"));
    else dispatch(setAlert("Config disabled", "success"));
  } catch (error) {}
};

export const getConfigDetails = (id) => async (dispatch) => {
  try {
    // const modbusConfig = await axios.get("http://localhost:5000/api/modbus/" + id);
    const modbusConfig = await axios.get("/api/modbus/" + id);

    dispatch({
      type: CONFIG_DETAILS,
      payload: modbusConfig.data,
    });
  } catch (error) {}
};

export const deleteConfig = (id) => async (dispatch) => {
  try {
    // await axios.delete("http://localhost:5000/api/modbus/" + id);
    await axios.delete("/api/modbus/" + id);

    // const modbusTemplateList = await axios.get("http://localhost:5000/api/modbus");
    const modbusTemplateList = await axios.get("/api/modbus");

    dispatch({
      type: CONFIG_GET,
      payload: modbusTemplateList.data,
    });

    dispatch(setAlert("Config deleted", "success"));
  } catch (error) {}
};
