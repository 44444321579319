import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd componets
import { Table, Space, Popconfirm, Tooltip } from "antd";
import { DeleteFilled, EditFilled } from "@ant-design/icons";

// external functions
import {
  openCalcAddModal,
  updateTemplateCalc,
} from "../../../actions/templates";

// actual component
const NewTemplateCalcTable = ({
  currentTemplate,
  openCalcAddModal,
  updateTemplateCalc,
}) => {
  const [tableData, setTableData] = useState();
  useEffect(() => {
    if (currentTemplate) {
      if (currentTemplate.Calculations) {
        setTableData([...currentTemplate.Calculations]);
      } else {
        setTableData([]);
      }
    }
  }, [currentTemplate]);

  const handleDelete = (calc) => {
    if (currentTemplate.Calculations) {
      const newCalulation = currentTemplate.Calculations.filter(
        (calcItem) => calcItem.key !== calc.key
      );

      updateTemplateCalc(newCalulation);
    }
  };

  const handleEdit = (calc) => {
    openCalcAddModal(calc);
  };

  const tableColums = [
    {
      title: "Calculation",
      dataIndex: "calculationName",
      key: "calculationName",
    },
    {
      title: "Child",
      dataIndex: "childName",
      key: "childName",
    },
    {
      title: "Output",
      dataIndex: ["calculationOutputs", "0", "outputVariable"],
      key: "outputVariable",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          <Popconfirm
            title="Delete this calculation?"
            onConfirm={() => handleDelete(record)}
          >
            <Tooltip title="Delete this calculation">
              <a href="#!">
                <DeleteFilled />
              </a>
            </Tooltip>
          </Popconfirm>
          <Tooltip title="Edit this calculation details">
            <a href="#!" onClick={() => handleEdit(record)}>
              <EditFilled />
            </a>
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <Table
      style={{ marginTop: "10px", marginBottom: "10px" }}
      size="small"
      columns={tableColums}
      scroll={{ y: 150 }}
      dataSource={tableData}
    ></Table>
  );
};

NewTemplateCalcTable.propTypes = {
  currentTemplate: PropTypes.object,
  openCalcAddModal: PropTypes.func.isRequired,
  updateTemplateCalc: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  currentTemplate: state.template.newTemplate,
});

export default connect(mapStateToProps, {
  openCalcAddModal,
  updateTemplateCalc,
})(NewTemplateCalcTable);
