import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Antd
import { Button, Table, Popconfirm, Tooltip, Space, Typography } from "antd";
import {
  EditFilled,
  DeleteFilled,
  PlusCircleOutlined,
  SaveOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";

// external components
import AddIPModal from "./modalAddIP";

// External functions
import {
  ModalAddIPOpen,
  EthernetIPDelete,
  SaveEthernetIP,
  ResetInterface,
  OffInterface,
} from "../../../actions/utl";

const { Title } = Typography;

// Actual Component
const Ethernet = ({
  permissions,
  InterfaceIP,
  ModalAddIPOpen,
  EthernetIPDelete,
  savingIP,
  SaveEthernetIP,
  ResetInterface,
  OffInterface,
}) => {
  const handleDelete = (record) => {
    EthernetIPDelete(record);
  };

  const handleEdit = (record) => {
    ModalAddIPOpen(record);
  };

  const columns = [
    {
      title: "IP Address",
      key: "IPAddress",
      dataIndex: "address",
    },
    {
      title: "Subnet",
      key: "Subnet",
      dataIndex: "netmask",
    },
    {
      title: "IPV",
      key: "IPV",
      dataIndex: "family",
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (text, record) => (
        <Space size="middle">
          {permissions && permissions.write && (
            <Fragment>
              {permissions.delete && (
                <Popconfirm
                  title="Sure to delete?"
                  onConfirm={() => handleDelete(record)}
                >
                  <Tooltip title="Delete device">
                    <a href="#!">
                      <DeleteFilled />
                    </a>
                  </Tooltip>
                </Popconfirm>
              )}
              <Tooltip title="Edit device">
                <a href="#!" onClick={() => handleEdit(record)}>
                  <EditFilled />
                </a>
              </Tooltip>
            </Fragment>
          )}
        </Space>
      ),
    },
  ];

  return (
    <Fragment>
      <Typography>
        <Title level={4}>Ethernet Interface</Title>
      </Typography>

      <AddIPModal />

      <Space>
        {permissions && permissions.write && (
          <Fragment>
            <Tooltip title="Click to add an IP to this interface">
              <Button
                type={"primary"}
                size={"small"}
                icon={<PlusCircleOutlined />}
                onClick={() => ModalAddIPOpen(undefined)}
              >
                Add IP
              </Button>
            </Tooltip>
            <Tooltip title="Click to save the IP's to the controller">
              <Button
                type={"primary"}
                size={"small"}
                icon={<SaveOutlined />}
                loading={savingIP}
                onClick={() => SaveEthernetIP(InterfaceIP.Ethernet)}
              >
                Save
              </Button>
            </Tooltip>
          </Fragment>
        )}
        <Tooltip title="Click to reset the interface, turns it off and the on">
          <Button
            type={"primary"}
            size={"small"}
            icon={<PoweroffOutlined />}
            loading={savingIP}
            onClick={() => ResetInterface("eth")}
          >
            Reset interface
          </Button>
        </Tooltip>
        <Tooltip title="Click to turn off the ethernet port, press on reset interface to turn on again">
          <Button
            type={"primary"}
            size={"small"}
            icon={<PoweroffOutlined />}
            loading={savingIP}
            onClick={() => OffInterface("eth")}
          >
            Turn Off
          </Button>
        </Tooltip>
      </Space>

      <Table
        bordered
        style={{ marginTop: "10px" }}
        size={"small"}
        scroll={320}
        dataSource={InterfaceIP.Ethernet}
        columns={columns}
        pagination={{
          defaultPageSize: 30,
          showSizeChanger: true,
          pageSizeOptions: ["30", "40", "50"],
        }}
      />
    </Fragment>
  );
};

Ethernet.propTypes = {
  permissions: PropTypes.object,
  InterfaceIP: PropTypes.object,
  ModalAddIPOpen: PropTypes.func.isRequired,
  EthernetIPDelete: PropTypes.func.isRequired,
  ResetInterface: PropTypes.func.isRequired,
  SaveEthernetIP: PropTypes.func.isRequired,
  OffInterface: PropTypes.func.isRequired,
  savingIP: PropTypes.bool,
};

const MapStateToProps = (state) => ({
  permissions: state.auth.permissions,
  InterfaceIP: state.utl.InterfaceIP,
  savingIP: state.utl.savingIP,
});

export default connect(MapStateToProps, {
  ModalAddIPOpen,
  EthernetIPDelete,
  SaveEthernetIP,
  ResetInterface,
  OffInterface,
})(Ethernet);
