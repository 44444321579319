import axios from "axios";
import { setAlert } from "./alert";
import { clearDeviceLists } from "./deviceList";
import setAuthToken from "../utils/setAuthToken";
import { loadSite } from "./site";
import {
  AUTH_ERROR,
  USER_LOADED,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  LOGIN_LOAD,
  CLEAR_PROFILE,
  SITE_CLEAR,
  USER_PERMISIONS,
  USER_TEMPLATE_PERMISIONS,
  LOGIN_MODAL_CLOSE,
  LOGIN_MODAL_OPEN,
} from "./types";

// load User
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    // const res = await axios.post("http://localhost:5000/api/auth/me", {
    //   token: localStorage.token,
    // });
    const res = await axios.post("/api/auth/me", {
      token: localStorage.token,
    });
    // const res = await axios.post("http://172.18.100.14:5000/api/auth/me", {
    //   token: localStorage.token,
    // });

    dispatch({
      type: USER_LOADED,
      payload: res.data.user,
    });

    dispatch(loadSite());

    // get the permisions
    const perm = await axios.post("/api/users/permissions", {
      token: localStorage.token,
    });

    dispatch({
      type: USER_PERMISIONS,
      payload: perm.data,
    });

    // get the Template permisions
    const templatePerm = await axios.post("/api/users/templatepermission", {
      token: localStorage.token,
    });

    dispatch({
      type: USER_TEMPLATE_PERMISIONS,
      payload: templatePerm.data,
    });
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Login a user
export const login = (email, password) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email, password });

  try {
    dispatch({
      type: LOGIN_LOAD,
    });

    const res = await axios.post("/api/auth/", body, config);
    // const res = await axios.post(
    //   "http://localhost:5000/api/auth/",
    //   body,
    //   config
    // );
    // const res = await axios.post(
    //   "http://172.18.100.14:5000/api/auth/",
    //   body,
    //   config
    // );

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    dispatch(loadUser());
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
    }
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

// login out
export const logout = () => (dispatch) => {
  dispatch(clearDeviceLists());
  dispatch({ type: CLEAR_PROFILE });
  dispatch({ type: LOGOUT });
  dispatch({ type: SITE_CLEAR });
};

// Login Screen option modal OPEN
export const openOptionsModal = () => (dispatch) => {
  dispatch({ type: LOGIN_MODAL_OPEN });
};

// Login Screen option modal CLOSE
export const closeOptionsModal = () => (dispatch) => {
  dispatch({ type: LOGIN_MODAL_CLOSE });
};
