import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Ant design components
import { Form, Input, Select, Button, Table, Popconfirm } from "antd";

// Functions
import { createInitalTemplate, OpenBatchModal } from "../../actions/templates";

// Components
import AddBatchModal from "./newTemplateAddBatch";

const NewTemplateDetails = ({
  manufacturesList,
  typesList,
  createInitalTemplate,
  currentTemplate,
  deviceTypesLoading,
  deviceManufacturesLoading,
  OpenBatchModal,
  inEditMode,
  permissions,
}) => {
  // Constants
  const [formDetails] = Form.useForm();
  // Manufactures OPtions for select statement
  const DeviceManufactureList =
    deviceManufacturesLoading === false && manufacturesList !== null
      ? manufacturesList.map((device, index) => (
          <Select.Option value={device.name} key={index}>
            {device.name}
          </Select.Option>
        ))
      : null;

  // Tyoes Options for select statement
  const DeviceTypesList =
    deviceTypesLoading === false && typesList !== null
      ? typesList.map((device, index) => (
          <Select.Option value={device.device_type_name} key={index}>
            {device.device_type_name}
          </Select.Option>
        ))
      : null;

  // table columns
  const modbusBatchTableColumns = [
    {
      title: "Start Address",
      dataIndex: "startAddress",
      key: "startAddress",
      editable: true,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      editable: true,
    },
    {
      title: "Function",
      dataIndex: "function",
      key: "function",
      editable: true,
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record) =>
        permissions &&
        permissions.delete && (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.key)}
          >
            <a href="#!">Delete</a>
          </Popconfirm>
        ),
    },
  ];

  const handleDelete = (key) => {
    const dataSource = [...currentTemplate.Batchs];

    currentTemplate.Batchs = dataSource.filter((item) => item.key !== key);
    createInitalTemplate({ ...currentTemplate });
  };

  return (
    <Form
      form={formDetails}
      style={{ marginTop: "20px" }}
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 6 }}
      layout="horizontal"
      initialValues={currentTemplate}
      onValuesChange={(_, all) => {
        // Check to see if the user changes the manufacuture, then clear the protocal, but only if something is there
        if (all.maunfacture === undefined) {
          createInitalTemplate(null);
          setTimeout(() => formDetails.resetFields(), 1000);
        } else {
          if (all.model) {
            all.model = all.model.trim();
          }
          createInitalTemplate({ ...currentTemplate, ...all });
        }
      }}
      size="small"
    >
      <Form.Item
        label="Device Maunfacture"
        name="maunfacture"
        style={{ marginBottom: "5px" }}
      >
        <Select disabled={inEditMode} allowClear>
          {/* <Select onChange={handleChangeSelect} allowClear> */}
          {DeviceManufactureList}
        </Select>
      </Form.Item>
      {currentTemplate !== null && currentTemplate.maunfacture !== undefined && (
        <Fragment>
          <Form.Item
            label="Device Model"
            name="model"
            style={{ marginBottom: "5px" }}
          >
            <Input disabled={permissions && permissions.write ? false : true} />
          </Form.Item>
          <Form.Item
            label="Device Type"
            name="device_type_name"
            style={{ marginBottom: "5px" }}
          >
            <Select disabled={inEditMode} allowClear showSearch>
              {/* <Select onChange={handleChangeSelect} allowClear> */}
              {DeviceTypesList}
            </Select>
          </Form.Item>
          <Form.Item label="Protocol" name="commProtocol">
            <Select disabled={inEditMode} allowClear>
              <Select.Option value="Modbus" key="Modbus">
                Modbus
              </Select.Option>
              <Select.Option value="SNMP" key="SNMP">
                SNMP
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            style={{ marginBottom: "5px" }}
          >
            <Input.TextArea autoSize={{ minRows: 4, maxRows: 6 }} />
          </Form.Item>
          {currentTemplate.commProtocol === "Modbus" && (
            <Fragment>
              {permissions && permissions.write && (
                <Button
                  style={{ marginBottom: "10px" }}
                  onClick={OpenBatchModal}
                >
                  Add Batch
                </Button>
              )}

              <AddBatchModal />

              <Table
                columns={modbusBatchTableColumns}
                dataSource={
                  currentTemplate.Batchs !== undefined && [
                    ...currentTemplate.Batchs,
                  ]
                }
                bordered
                scroll={{ y: 200 }}
              ></Table>
            </Fragment>
          )}
        </Fragment>
      )}
    </Form>
  );
};

NewTemplateDetails.propTypes = {
  manufacturesList: PropTypes.array,
  typesList: PropTypes.array,
  currentTemplate: PropTypes.object,
  createInitalTemplate: PropTypes.func.isRequired,
  OpenBatchModal: PropTypes.func.isRequired,
  deviceManufacturesLoading: PropTypes.bool,
  deviceTypesLoading: PropTypes.bool,
  inEditMode: PropTypes.bool,
  permissions: PropTypes.object,
};

const mapStateToProps = (state) => ({
  manufacturesList: state.template.manufactures,
  inEditMode: state.template.EditTemplate,
  typesList: state.deviceTypes.types_detailed,
  currentTemplate: state.template.newTemplate,
  deviceTypesLoading: state.deviceTypes.typesLoadingDetailed,
  deviceManufacturesLoading: state.template.manufacturesLoading,
  permissions: state.auth.permissions,
});

export default connect(mapStateToProps, {
  createInitalTemplate,
  OpenBatchModal,
})(NewTemplateDetails);
