import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Ant design
import { Select, Form, Modal, Input, Divider, InputNumber } from "antd";

// Icons
import { PlusOutlined } from "@ant-design/icons";

// External Functions
import {
  CloseEditInputModal,
  updateTempTypeNameList,
  updateDeviceDetails,
} from "../../../../../actions/deviceList";

// Actual component
const ModalEditFeild = ({
  modalVis,
  CloseEditInputModal,
  modalData,
  deviceTypesDetailed,
  tempTypeNameList,
  updateTempTypeNameList,
  availableCalcs,
  currentDeviceListDetails,
  updateDeviceDetails,
}) => {
  const [deviceName, setDeviceName] = useState("");
  const [inputDetail, setInputDetail] = useState(null);
  const [calcDetails, setCalcDetails] = useState(null);
  const [formEditInputDetails] = Form.useForm();

  const closingSequence = () => {
    CloseEditInputModal();
  };

  const handelCancel = () => {
    closingSequence();
  };

  const handelOK = () => {
    let newInputData = [...currentDeviceListDetails];

    const inputIndex = currentDeviceListDetails.findIndex(
      (Input) => Input.input === modalData.input
    );

    newInputData[inputIndex] = {
      ...newInputData[inputIndex],
      ...inputDetail,
      calculationID: calcDetails._id,
    };
    // add the details
    setTimeout(() => {
      updateDeviceDetails({ childNames: newInputData });
    }, 200);
    // Close the modal
    CloseEditInputModal();
  };

  const handleAddDeviceName = () => {
    if (deviceName !== "") {
      let names = [...tempTypeNameList];

      names.push({
        name: deviceName,
        type: inputDetail.deviceType,
      });
      updateTempTypeNameList(names);
      setDeviceName("");
    }
  };

  const setConstantFromCalc = (calcData) => {
    const indexOfCalc = availableCalcs.findIndex(
      (calc) => calc.calculationName === calcData
    );

    if (indexOfCalc !== -1) {
      setCalcDetails(availableCalcs[indexOfCalc]);
      formEditInputDetails.setFieldsValue({
        constants: [...availableCalcs[indexOfCalc].constant],
      });
    }
  };

  // Device Types select
  const DeviceTypeList =
    deviceTypesDetailed !== null
      ? deviceTypesDetailed.map((device, index) => (
          <Select.Option value={device.device_type_name} key={index}>
            {device.device_type_name}
          </Select.Option>
        ))
      : null;

  // Calculation Types select
  const CalcTypeList =
    availableCalcs !== null
      ? availableCalcs.map(
          (calc, _) =>
            !calc.noInputRequired &&
            !calc.outputNeedsName && (
              <Select.Option value={calc.calculationName} key={calc._id}>
                {calc.calculationName}
              </Select.Option>
            )
        )
      : null;

  const disableOkButton =
    inputDetail &&
    (inputDetail.deviceType === undefined ||
      inputDetail.deviceType === "" ||
      inputDetail.deviceName === undefined ||
      inputDetail.deviceName === "" ||
      inputDetail.calculation === "" ||
      inputDetail.calculation === undefined)
      ? true
      : inputDetail === null
      ? true
      : false;

  if (modalVis) {
    if (inputDetail === null) {
      setInputDetail(modalData);

      setTimeout(() => {
        if (modalData) {
          if (modalData.calculation) setConstantFromCalc(modalData.calculation);
        }
        formEditInputDetails.resetFields();
      }, 150);
    }
  } else {
    if (inputDetail !== null) {
      setInputDetail(null);
      formEditInputDetails.resetFields();
    }
  }

  return (
    <Modal
      title={modalData && "Editing " + modalData.input}
      visible={modalVis}
      onCancel={handelCancel}
      onOk={handelOK}
      okButtonProps={{
        disabled: disableOkButton,
      }}
    >
      <Form
        form={formEditInputDetails}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 12 }}
        layout="horizontal"
        initialValues={modalData}
        onValuesChange={(_, all) => {
          setInputDetail({ ...inputDetail, ...all });

          if (all) {
            if (all.deviceType === undefined) {
              setInputDetail({});
              formEditInputDetails.setFieldsValue({ deviceName: "" });
              formEditInputDetails.setFieldsValue({ calculation: "" });
            }

            if (all.calculation) {
              if (all.constants === undefined) {
                setConstantFromCalc(all.calculation);
              }
            } else {
              formEditInputDetails.setFieldsValue({
                constants: undefined,
              });
              setCalcDetails(null);
            }
          }
        }}
        size="small"
      >
        <Form.Item label="Device Type" name="deviceType">
          <Select allowClear showSearch>
            {DeviceTypeList}
          </Select>
        </Form.Item>
        <Form.Item
          label={
            inputDetail && inputDetail.deviceType === "Fuel Sensor"
              ? "Tank Name"
              : "Device Name"
          }
          name="deviceName"
        >
          <Select
            allowClear
            showSearch
            dropdownRender={(menu) => (
              <div>
                {menu}
                <Divider style={{ margin: "4px 0" }} />
                <div
                  style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    padding: 8,
                  }}
                >
                  <Input
                    style={{ flex: "auto" }}
                    value={deviceName}
                    onChange={(event) => setDeviceName(event.target.value)}
                  />
                  <a
                    href="#!"
                    style={{
                      flex: "none",
                      padding: "5px",
                      display: "block",
                      cursor: "pointer",
                    }}
                    onClick={handleAddDeviceName}
                  >
                    <PlusOutlined /> Add name
                  </a>
                </div>
              </div>
            )}
          >
            {tempTypeNameList &&
              tempTypeNameList.map(
                (item, index) =>
                  inputDetail &&
                  inputDetail.deviceType === item.type && (
                    <Select.Option key={index} value={item.name}>
                      {item.name}
                    </Select.Option>
                  )
              )}
          </Select>
        </Form.Item>
        <Form.Item label="Calculation" name="calculation">
          <Select allowClear showSearch>
            {CalcTypeList}
          </Select>
        </Form.Item>
        {calcDetails && calcDetails.calculationDescription && (
          <Form.Item label="Description">
            <Input.TextArea
              rows={3}
              disabled
              value={calcDetails && calcDetails.calculationDescription}
            />
          </Form.Item>
        )}
        {inputDetail && inputDetail.calculation && (
          <Fragment>
            <Divider>Calculation Inputs</Divider>
            <Form.List name="constants">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((data, index) => (
                    <Form.Item
                      {...data}
                      name={[data.name, "value"]}
                      key={[data.key, index]}
                      label={calcDetails && calcDetails.constant[index].name}
                    >
                      <InputNumber />
                    </Form.Item>
                  ))}
                </>
              )}
            </Form.List>
          </Fragment>
        )}
      </Form>
    </Modal>
  );
};

ModalEditFeild.propTypes = {
  modalVis: PropTypes.bool,
  CloseEditInputModal: PropTypes.func.isRequired,
  modalData: PropTypes.object,
  deviceTypesDetailed: PropTypes.array,
  tempTypeNameList: PropTypes.array,
  updateTempTypeNameList: PropTypes.func,
  availableCalcs: PropTypes.array,
  currentDeviceListDetails: PropTypes.array,
  updateDeviceDetails: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  modalVis: state.deviceList.modalEditInputVisible,
  modalData: state.deviceList.modalEditInputEditData,
  deviceTypesDetailed: state.deviceTypes.types_detailed,
  tempTypeNameList: state.deviceList.tempTypeNameList,
  availableCalcs: state.calcs.allCalcs,
  currentDeviceListDetails:
    state.deviceList.currentDeviceList.Details &&
    state.deviceList.currentDeviceList.Details.childNames,
});

export default connect(mapStateToProps, {
  CloseEditInputModal,
  updateTempTypeNameList,
  updateDeviceDetails,
})(ModalEditFeild);
