import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Functions
import { createInitalTemplate } from "../../actions/templates";

// Ant design components
import {
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  Table,
  Tooltip,
  Popconfirm,
  Space,
} from "antd";

// icons
import {
  DeleteFilled,
  EditFilled,
  FileExcelOutlined,
  FundOutlined,
} from "@ant-design/icons";

// Actual Component
const NewTemplateSNMPMapping = ({
  allTypes,
  currentTemplate,
  createInitalTemplate,
  permissions,
}) => {
  const [mappingForm] = Form.useForm();

  const [devList, setDevList] = useState([]);
  const [SymbolList, setSymbolList] = useState([]);
  const [childNames, setChildNames] = useState([]);
  const [isEdit, setIsEdit] = useState({
    state: false,
    key: null,
  });

  useEffect(() => {
    if (allTypes) {
      if (currentTemplate !== null) {
        if (allTypes.length > 0) {
          let list = allTypes.filter(
            (listItems) =>
              listItems.device_type_name === currentTemplate.device_type_name
          )[0];

          if (list) {
            setDevList(list.vairables);
          }
        }
      }
    }
  }, [allTypes, currentTemplate]);

  const HandleDeleteOID = (record) => {
    let OIDList = [];
    OIDList = currentTemplate.Batchs.filter(
      (OIDItem) => OIDItem.key !== record.key
    );
    createInitalTemplate({ ...currentTemplate, Batchs: OIDList });
  };

  const HandleCancelOID = () => {
    mappingForm.resetFields();
    setFormData({});
    setIsEdit({ ...isEdit, state: false });
  };

  const HandleEditOID = (record) => {
    let oldFormData = { ...record };

    if (oldFormData.ExtraChildren) {
      if (oldFormData.ExtraChildren.name) {
        oldFormData.childName = oldFormData.ExtraChildren.name;
      }

      if (oldFormData.ExtraChildren.type) {
        oldFormData.childType = oldFormData.ExtraChildren.type;
      }

      delete oldFormData.ExtraChildren;
    }

    if (oldFormData.clamp) {
      if (oldFormData.clamp.max) {
        oldFormData.max = oldFormData.clamp.max;
      }

      if (oldFormData.clamp.min) {
        oldFormData.min = oldFormData.clamp.min;
      }

      delete oldFormData.clamp;
    }

    setFormData(oldFormData);

    for (var key in oldFormData) {
      if (oldFormData.hasOwnProperty(key)) {
        mappingForm.setFields([{ name: key, value: oldFormData[key] }]);
      }
    }

    setIsEdit({ state: true, key: record.key });
  };

  const handleLogging = (record) => {
    let OIDList = [...currentTemplate.Batchs];

    const indexOfBatch = OIDList.findIndex((Batch) => Batch.key === record.key);

    let snmpItem = { ...OIDList[indexOfBatch] };

    if (snmpItem.notLogging) delete snmpItem.notLogging;
    else snmpItem.notLogging = true;

    OIDList[indexOfBatch] = { ...snmpItem };

    createInitalTemplate({ ...currentTemplate, Batchs: OIDList });
  };

  const OIDListColumbs = [
    {
      title: "OID Number",
      dataIndex: "OID",
      width: 250,
      key: "OID",
      fixed: "left",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 120,
      fixed: "left",
    },
    {
      title: "Symbol",
      width: 65,
      dataIndex: "symbol",
      key: "symbol",
    },
    {
      title: "Children",
      children: [
        {
          title: "Name",
          dataIndex: ["ExtraChildren", "name"],
          key: "childName",
          width: 100,
        },
        {
          title: "Type",
          dataIndex: ["ExtraChildren", "type"],
          key: "childType",
          width: 100,
        },
      ],
    },
    {
      title: "Clamping",
      children: [
        {
          title: "Max",
          dataIndex: ["clamp", "max"],
          key: "clampMax",
        },
        {
          title: "Min",
          dataIndex: ["clamp", "min"],
          key: "clampMin",
        },
      ],
    },
    {
      title: "Scaling",
      children: [
        {
          title: "Operation",
          dataIndex: "scalingOperation",
          key: "scalingOperation",
        },
        {
          title: "Amount",
          dataIndex: "scalingFactor",
          key: "scalingFactor",
        },
      ],
    },
    {
      title: "Don't log",
      dataIndex: "notLogging",
      key: "notLogging",
      width: 55,
      render: (text) => (text ? "True" : null),
    },
    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      fixed: "right",
      render: (_, record) =>
        permissions &&
        permissions.write && (
          <Space size={"middle"}>
            <Popconfirm
              title={"Are you sure you want to delete ?"}
              onConfirm={() => HandleDeleteOID(record)}
            >
              <Tooltip title="Delete this OID">
                <a href="#!">
                  <DeleteFilled />
                </a>
              </Tooltip>
            </Popconfirm>
            <Tooltip title="Edit this OID">
              <a
                href="#!"
                onClick={() => {
                  HandleEditOID(record);
                }}
              >
                <EditFilled />
              </a>
            </Tooltip>
            <Popconfirm
              title={
                record.notLogging ? "Enable logging ?" : "Disable logging ?"
              }
              onConfirm={() => handleLogging(record)}
            >
              <Tooltip
                title={
                  record.notLogging
                    ? "Enable logging of this variable"
                    : "Disable logging of this variable"
                }
              >
                {record.notLogging ? <FundOutlined /> : <FileExcelOutlined />}
              </Tooltip>
            </Popconfirm>
          </Space>
        ),
    },
  ];

  // all the variables for the type OPtions for select statement
  const DeviceVariables =
    devList.length > 0
      ? devList.map((device, index) => (
          <Select.Option value={device.variable} key={index}>
            {device.variable}
          </Select.Option>
        ))
      : null;

  // If there is more than one symbol show the list
  const createSymbolList = SymbolList.map((lists, index) => {
    return (
      <Select.Option value={lists} key={index}>
        {lists}
      </Select.Option>
    );
  });

  // If there is more than one symbol show the list
  const createChildList = childNames.map((lists, index) => {
    return (
      <Select.Option value={lists} key={index}>
        {lists}
      </Select.Option>
    );
  });

  const checkUpdateChildNamesSymbols = (data) => {
    if (data) {
      const sym = devList.filter((item) => item.variable === data.name)[0];

      let HasArray = "";
      let HasArrayChildren = "";

      setSymbolList([]);
      setChildNames([]);

      if (sym) {
        if (sym.symbol) {
          HasArray = sym.symbol.includes(",");

          if (HasArray) {
            HasArray = sym.symbol.split(",");
            setSymbolList(HasArray);
            HasArray = true;
          } else {
            HasArray = false;
            mappingForm.setFields([{ name: "symbol", value: sym.symbol }]);
          }

          if (sym.ExtraChildren !== undefined) {
            HasArrayChildren = sym.ExtraChildren.name.includes(",");

            if (HasArrayChildren) {
              HasArrayChildren = sym.ExtraChildren.name.split(",");
              setChildNames(HasArrayChildren);
              HasArrayChildren = true;
            } else {
              HasArrayChildren = false;
            }

            mappingForm.setFields([
              { name: "childType", value: sym.ExtraChildren.type },
            ]);
          }
        }
      }
    }
  };

  const [formData, setFormData] = useState({});

  const handleADDOID = () => {
    if (formData && formData.OID) {
      if (formData.name) {
        formData.OID = formData.OID.trim();

        let Batchs = [];

        if (currentTemplate.Batchs) {
          Batchs = currentTemplate.Batchs;
        }

        // Add Clamp Data
        if (formData.max || formData.min) {
          formData.clamp = {};

          if (formData.max) {
            formData.clamp.max = formData.max;
            delete formData.max;
          }

          if (formData.min) {
            formData.clamp.min = formData.min;
            delete formData.min;
          }
        }

        // Add Children Data
        if (formData.childType) {
          formData.ExtraChildren = {};
          formData.ExtraChildren.type = formData.childType;
          delete formData.childType;
          if (formData.childName) {
            formData.ExtraChildren.name = formData.childName;
            delete formData.childName;
          }
        }

        // if we are edit, update
        if (isEdit.state) {
          let indexOfItem = Batchs.findIndex((item) => item.key === isEdit.key);

          // Index and key used for logging, index will always be 0, key is a random but not the same number
          formData.index = 0;
          formData.key = isEdit.key;

          Batchs.splice(indexOfItem, 1, formData);
          setIsEdit({ ...isEdit, state: false });
        } else {
          // Index and key used for logging, index will always be 0, key is a random but not the same number
          formData.index = 0;
          formData.key = Batchs.length;

          Batchs.push(formData);
        }

        createInitalTemplate({ ...currentTemplate, Batchs });
        mappingForm.resetFields();
        setFormData({});
      }
    }
  };

  return (
    <Fragment>
      <Form
        form={mappingForm}
        style={{ marginTop: "20px" }}
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 6 }}
        size={"small"}
        onValuesChange={(_, All) => {
          checkUpdateChildNamesSymbols(All);
          setFormData(All);
        }}
      >
        {permissions && permissions.write && (
          <Form.Item
            tooltip="This is the OID to get a value from the device, use a # at the end of the OID as a wild cards for the device ID"
            label="OID"
            name="OID"
          >
            <Input />
          </Form.Item>
        )}
        {formData && formData.OID && (
          <Fragment>
            <Form.Item label="Name" name="name">
              <Select allowClear showSearch>
                {DeviceVariables}
              </Select>
            </Form.Item>
            {formData.name && (
              <Fragment>
                <Form.Item label="Symbol" name="symbol">
                  {SymbolList.length === 0 ? (
                    <Input disabled />
                  ) : (
                    <Select>{createSymbolList}</Select>
                  )}
                </Form.Item>
                <Form.Item label="Child Type" name="childType">
                  <Input disabled />
                </Form.Item>
                <Form.Item label="Child Name" name="childName">
                  {childNames.length === 0 ? (
                    <Input disabled />
                  ) : (
                    <Select>{createChildList}</Select>
                  )}
                </Form.Item>
                <Form.Item label="Maximum" name="max">
                  <InputNumber />
                </Form.Item>
                <Form.Item label="Minimum" name="min">
                  <InputNumber />
                </Form.Item>
                <Form.Item label="Scaling Operation" name="scalingOperation">
                  <Select allowClear>
                    <Select.Option value="*" key="*">
                      Times
                    </Select.Option>
                    <Select.Option value="/" key="/">
                      Divide
                    </Select.Option>
                  </Select>
                </Form.Item>
                {formData.scalingOperation && (
                  <Form.Item label="Scaling Factor" name="scalingFactor">
                    <InputNumber />
                  </Form.Item>
                )}
              </Fragment>
            )}
          </Fragment>
        )}
      </Form>

      {isEdit.state ? (
        <Fragment>
          <Tooltip title="Save changes">
            <Button onClick={handleADDOID}>Save</Button>
          </Tooltip>
          <Tooltip title="Undo changes">
            <Button
              onClick={HandleCancelOID}
              danger
              style={{ marginLeft: "10px" }}
            >
              Cancel
            </Button>
          </Tooltip>
        </Fragment>
      ) : (
        permissions &&
        permissions.write && (
          <Tooltip title="Add the above information to the list below">
            <Button onClick={handleADDOID}>Add OID</Button>
          </Tooltip>
        )
      )}

      <Table
        style={{ marginTop: "10px", marginBottom: "10px" }}
        columns={OIDListColumbs}
        dataSource={
          currentTemplate &&
          currentTemplate.Batchs !== undefined && [...currentTemplate.Batchs]
        }
        bordered
        pagination={{ pageSize: 30 }}
        scroll={{ y: 400, x: 1100 }}
        size={"small"}
      ></Table>
    </Fragment>
  );
};

NewTemplateSNMPMapping.propTypes = {
  allTypes: PropTypes.array,
  currentTemplate: PropTypes.object,
  createInitalTemplate: PropTypes.func.isRequired,
  permissions: PropTypes.object,
};

const mapStateToProps = (state) => ({
  allTypes: state.deviceTypes.types_detailed,
  currentTemplate: state.template.newTemplate,
  permissions: state.auth.permissions,
});

export default connect(mapStateToProps, { createInitalTemplate })(
  NewTemplateSNMPMapping
);
