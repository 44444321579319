import React, { Fragment, useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  InputNumber,
  Table,
  Steps,
  Button,
  Descriptions,
} from "antd";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  closeConfigModal,
  updateConfig,
  updateCurrentDevice,
} from "../../../../actions/modbusconfig";
import axios from "axios";

const { Search } = Input;
const { Step } = Steps;

const NewDevModal = ({
  modbusConfig: { modalOpen, currentList },
  closeConfigModal,
  updateConfig,
  updateCurrentDevice,
}) => {
  let FormData = {};
  let chosenSelect = {};

  const DevSetup = () => {
    const columns = [
      {
        title: "Model",
        dataIndex: "model",
        key: "model",
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Maunfacture",
        dataIndex: "maunfacture",
        key: "maunfacture",
      },
    ];

    const [deviceTypes, setdeviceTypes] = useState([]);

    useEffect(() => {
      const getDevTypes = async () => {
        const res = await axios.get("/api/devtemplates/devicetypes");
        // const res = await axios.get(
        //   "http://localhost:5000/api/devtemplates/devicetypes"
        // );
        // console.log(res.data);
        setdeviceTypes(res.data);
      };

      getDevTypes();
    }, []);

    const [interfacetype, setInterfacetype] = useState(0);

    const selectAdd = deviceTypes.map((device, index) => {
      return (
        <Select.Option key={index} value={device}>
          {device}
        </Select.Option>
      );
    });

    const [templateData, setTemplateData] = useState([]);

    async function handleChangeSelect(value) {
      const res = await axios.get("/api/devtemplates/type/" + value);
      // const res = await axios.get(
      //   "http://localhost:5000/api/devtemplates/type/" + value
      // );

      // console.log(res.data);
      const template = [];

      res.data.forEach((data, index) => {
        data.key = index;

        template.push(data);
      });

      setTemplateData(template);
    }

    const [ping, setPing] = useState({
      loading: false,
      result: "",
    });

    const onSearch = async (value) => {
      setPing({ ...ping, loading: true });

      const data = {
        task: "ping",
        Data: {
          IP: value,
        },
      };
      const res = await axios.post("/api/utl", data);
      // const res = await axios.post("http://localhost:5000/api/utl", data);

      setPing({ result: res.data, loading: false });
    };

    return (
      <Fragment>
        <Form
          labelCol={{ span: 8, offset: 1 }}
          wrapperCol={{ span: 8 }}
          layout="horizontal"
          size="default"
          onValuesChange={(changedValues, allValues) => {
            FormData = allValues;
          }}
        >
          <Form.Item label="Device Name" name="Name">
            {/* {Object.keys(currentList).length > 0 ? (
              <Input defaultValue={currentList.config.Name} />
            ) : (
              <Input />
            )} */}
            <Input />
          </Form.Item>
          <Form.Item label="Interface Type" name="interfaceType">
            <Select
              onChange={(e) => setInterfacetype(e)}
              // defaultValue={
              //   currentList.length > 0
              //     ? currentList.config.interfaceType
              //     : 0
              // }
            >
              <Select.Option value={0}>TCP</Select.Option>
              <Select.Option value={1}>RTU</Select.Option>
            </Select>
          </Form.Item>
          {interfacetype === 0 ? (
            <Form.Item
              label="IP Address"
              name="sidIP"
              extra={ping.result ? ping.result : ""}
            >
              <Search
                placeholder="IP Address"
                allowClear
                enterButton="Ping"
                onSearch={() => onSearch()}
                loading={ping.loading}
              />
            </Form.Item>
          ) : (
            <Form.Item label="Slave ID" name="sidIP">
              <Input />
            </Form.Item>
          )}

          <Form.Item label="Poll Rate">
            <Form.Item name="pollrate" noStyle>
              <InputNumber
                min={1}
                max={10}
                defaultValue={
                  Object.keys(currentList).length > 0
                    ? currentList.config.pollrate
                    : 3
                }
              />
            </Form.Item>
            <span className="ant-form-text"> seconds</span>
          </Form.Item>
          <Form.Item label="Device Type" name="devType">
            <Select
              loading={deviceTypes.length === 0 ? true : false}
              onChange={handleChangeSelect}
            >
              {deviceTypes.length > 0 && selectAdd}
            </Select>
          </Form.Item>
        </Form>
        <p>Choose a template</p>
        <Table
          rowSelection={{
            type: "radio",
            onChange: (selectedRowKeys, selectedRows) => {
              chosenSelect = selectedRows;
              console.log(chosenSelect);
            },
          }}
          pagination={{ pageSize: 10 }}
          scroll={{ y: 200 }}
          columns={columns}
          dataSource={templateData}
        />
      </Fragment>
    );
  };

  const handleOk = () => {
    const FieldData = {};

    if (Object.keys(FormData).length > 0) {
      if (!FormData.pollrate) FormData.pollrate = 3;
      if (!FormData.interfaceType) FormData.interfaceType = 0;

      FieldData.templateID = chosenSelect[0]._id;
      FieldData.config = FormData;

      console.log(FieldData);

      updateConfig(FieldData);
    }
  };

  const handleCancel = () => {
    closeConfigModal();
  };

  const [current, setCurrent] = React.useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const VerfiyStep = () => {
    const verfiyCol = [
      {
        title: "Address",
        dataIndex: "address",
      },
      {
        title: "Value",
        dataIndex: "value",
      },
    ];

    const verfyData = [];

    return (
      <>
        {Object.keys(currentList).length > 0 && (
          <Descriptions
            bordered
            style={{ marginTop: "10px" }}
            title="Device Details"
            size={"small"}
            extra={<Button type="primary">Poll device</Button>}
          >
            <Descriptions.Item label="Device Name">
              {currentList.config.Name}
            </Descriptions.Item>
            <Descriptions.Item label="Interface Type">
              {currentList.config.interfaceType === 0
                ? "Ethernet TCP"
                : "RTU RS485"}
            </Descriptions.Item>
            <Descriptions.Item label="Poll Rate">
              {currentList.config.pollrate}
            </Descriptions.Item>
            {currentList.config.interfaceType === 0 ? (
              <Descriptions.Item label="IP Address">
                {currentList.config.sidIP}
              </Descriptions.Item>
            ) : (
              <Descriptions.Item label="Slave ID">
                {currentList.config.sidIP}
              </Descriptions.Item>
            )}
            <Descriptions.Item label="Device Type">
              {currentList.config.devType}
            </Descriptions.Item>
            <Descriptions.Item label="template">$60.00</Descriptions.Item>
          </Descriptions>
        )}

        <Table
          style={{ marginTop: "10px" }}
          columns={verfiyCol}
          dataSource={verfyData}
        />
      </>
    );
  };

  const steps = [
    {
      title: "Device Detials",
      content: <DevSetup />,
    },
    {
      title: "Verification",
      content: <VerfiyStep />,
    },
  ];

  return (
    <>
      <Modal
        title="Create a modbus device to poll"
        visible={modalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width="800px"
      >
        <Steps current={current}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className="steps-content">{steps[current].content}</div>
        <div className="steps-action">
          {current < steps.length - 1 && (
            <Button
              style={{ marginTop: "10px" }}
              type="primary"
              onClick={() => {
                const FieldData = {};

                if (Object.keys(currentList).length > 0) {
                  if (
                    FormData.pollrate === undefined &&
                    currentList.config.pollrate !== undefined
                  )
                    FormData.pollrate = currentList.config.pollrate;
                  else if (FormData.pollrate === undefined)
                    FormData.pollrate = 3;

                  if (FormData.Name === undefined && currentList.config.Name)
                    FormData.Name = currentList.config.Name;

                  if (FormData.sidIP === undefined && currentList.config.sidIP)
                    FormData.sidIP = currentList.config.sidIP;

                  if (
                    FormData.interfaceType === undefined &&
                    currentList.config.interfaceType !== undefined
                  )
                    FormData.interfaceType = currentList.config.interfaceType;

                  if (
                    chosenSelect !== undefined &&
                    Object.keys(chosenSelect).length > 0
                  )
                    FieldData.templateID = chosenSelect[0]._id;
                } else {
                }

                if (Object.keys(FormData).length > 0) {
                  if (FormData.pollrate === undefined) FormData.pollrate = 3;
                  if (FormData.interfaceType === undefined)
                    FormData.interfaceType = 0;

                  FieldData.config = FormData;
                  updateCurrentDevice(FieldData);
                  next();
                }
              }}
            >
              Verfiy
            </Button>
          )}
          {current > 0 && (
            <Button
              style={{ margin: "0 8px", marginTop: "10px" }}
              onClick={() => prev()}
            >
              Previous
            </Button>
          )}
        </div>
      </Modal>
    </>
  );
};

NewDevModal.propTypes = {
  closeConfigModal: PropTypes.func.isRequired,
  updateConfig: PropTypes.func.isRequired,
  updateCurrentDevice: PropTypes.func.isRequired,
  modbusConfig: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  modbusConfig: state.modbusConfig,
});

export default connect(mapStateToProps, {
  closeConfigModal,
  updateConfig,
  updateCurrentDevice,
})(NewDevModal);
