import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Antd
import { Table, Space, Tooltip, Button } from "antd";
import { PoweroffOutlined } from "@ant-design/icons";

// External functions
import { ResetInterface } from "../../../../actions/utl";

const IPWIFI = ({ InterfaceIP, ResetInterface }) => {
  const columns = [
    {
      title: "IP Address",
      key: "IPAddress",
      dataIndex: "address",
    },
    {
      title: "Subnet",
      key: "Subnet",
      dataIndex: "netmask",
    },
    {
      title: "IPV",
      key: "IPV",
      dataIndex: "family",
    },
  ];

  return (
    <Fragment>
      <Space>
        <Tooltip title="Click to reset the interface, turns it off and the on">
          <Button
            type={"primary"}
            size={"small"}
            icon={<PoweroffOutlined />}
            onClick={() => ResetInterface("wifi")}
          >
            Reset interface
          </Button>
        </Tooltip>
      </Space>

      <Table
        bordered
        style={{ marginTop: "10px" }}
        size={"small"}
        scroll={320}
        dataSource={InterfaceIP.wlan0}
        columns={columns}
        pagination={{
          defaultPageSize: 30,
          showSizeChanger: true,
          pageSizeOptions: ["30", "40", "50"],
        }}
      />
    </Fragment>
  );
};

IPWIFI.propTypes = {
  permissions: PropTypes.object,
  InterfaceIP: PropTypes.object,
  ResetInterface: PropTypes.func.isRequired,
};

const MapStateToProps = (state) => ({
  permissions: state.auth.permissions,
  InterfaceIP: state.utl.InterfaceIP,
});

export default connect(MapStateToProps, { ResetInterface })(IPWIFI);
