import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Ant design components
import { Tree, Typography, Row, Col } from "antd";

const { Text, Title, Paragraph } = Typography;

// Actual componet
const SiteContact = ({ sitedetails }) => {
  const [treeData, setTreeData] = useState([]);

  useEffect(() => {
    if (sitedetails) {
      let treeItems = [];

      // Site Contact
      treeItems.push({
        title: "Site Contact",
        key: "SiteContact",
        children: [
          {
            title: (
              <Fragment>
                <strong>Name : </strong>
                {sitedetails.site_contact.name}
              </Fragment>
            ),
            key: "ContactName",
          },
          {
            title: (
              <Fragment>
                <strong>Phone : </strong>
                {sitedetails.site_contact.phone}
              </Fragment>
            ),
            key: "ContactPhone",
          },
          {
            title: (
              <Fragment>
                <strong>Email : </strong>
                {sitedetails.site_contact.email}
              </Fragment>
            ),
            key: "ContactEmail",
          },
        ],
      });

      // Address
      treeItems.push({
        title: "Site Address",
        key: "SiteAddress",
        children: [
          {
            title: (
              <Fragment>
                <strong>Street : </strong>
                {sitedetails.address.street}
              </Fragment>
            ),
            key: "SiteAddressStreet",
          },
          {
            title: (
              <Fragment>
                <strong>Suburb : </strong>
                {sitedetails.address.suburb}
              </Fragment>
            ),
            key: "SiteAddressSuburb",
          },
          {
            title: (
              <Fragment>
                <strong>City : </strong>
                {sitedetails.address.city}
              </Fragment>
            ),
            key: "SiteAddressCity",
          },
          {
            title: (
              <Fragment>
                <strong>Country : </strong>
                {sitedetails.address.country}
              </Fragment>
            ),
            key: "SiteAddressCountry",
          },
        ],
      });

      setTreeData(treeItems);
    }
  }, [sitedetails]);

  return (
    treeData.length > 0 && (
      <div style={{ marginTop: "2vh", minHeight: "500px" }}>
        <Row style={{ marginBottom: "20px" }}>
          <Col span={8}></Col>
          <Col span={8}>
            <Typography style={{ textAlign: "center" }}>
              <Title level={2}>Site details confirmation</Title>
              <Paragraph>
                <Text strong>Below</Text> is the details related to the site{" "}
                {sitedetails.name} for client {sitedetails.client_name}, if any
                of the details is incorrect, please contact the Master Power
                Technologies ClientZone administrator to update them.
              </Paragraph>
            </Typography>
            <Tree defaultExpandAll={true} treeData={treeData} />
          </Col>
          <Col span={8}></Col>
        </Row>
      </div>
    )
  );
};

SiteContact.propTypes = {
  sitedetails: PropTypes.object,
};

const mapStateToProps = (state) => ({
  sitedetails: state.site.siteDetails,
});

export default connect(mapStateToProps)(SiteContact);
