import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd components
import { Button, Tooltip, Tabs, Typography } from "antd";

// external Functions
import { getAllCalculation, updateCalcsList } from "../../actions/calculations";
import {
  getDeviceTypesDetailed,
  getDeviceTypesShort,
} from "../../actions/deviceTypes";

// other components
import TabTemplate from "./Tabs/tabTemplate";
import TabNonTemplate from "./Tabs/tabNonTemplate";
import TabVirtualDevices from "./Tabs/tabVirtualDevices";

const { TabPane } = Tabs;

// Actual component
const Calculation = ({
  permissions,
  getDeviceTypesShort,
  getDeviceTypesDetailed,
  updateCalcsList,
  getAllCalculation,
}) => {
  // On Page Load
  useEffect(() => {
    getDeviceTypesDetailed();
    getDeviceTypesShort();
    getAllCalculation();
  }, [getDeviceTypesShort, getDeviceTypesDetailed, getAllCalculation]);

  const HandleTabChange = () => {
    getAllCalculation();
  };

  return (
    <Fragment>
      <h1 className="large text-primary">Calculations</h1>

      {permissions && permissions.update && (
        <Tooltip title="Updates all the lists from the server">
          <Button style={{ marginBottom: "10px" }} onClick={updateCalcsList}>
            Update all list
          </Button>
        </Tooltip>
      )}

      <Typography>
        Use the tabs to change between the differnet types of calculations
      </Typography>

      <Tabs defaultActiveKey="0" size="small" onChange={HandleTabChange}>
        <TabPane tab="Template Type" key="0">
          <TabTemplate />
        </TabPane>
        <TabPane tab="Non Template Type" key="1">
          <TabNonTemplate />
        </TabPane>
        <TabPane tab="Virtual Devices" key="2">
          <TabVirtualDevices />
        </TabPane>
      </Tabs>
    </Fragment>
  );
};

Calculation.propTypes = {
  permissions: PropTypes.object,
  updateCalcsList: PropTypes.func.isRequired,
  getDeviceTypesShort: PropTypes.func.isRequired,
  getDeviceTypesDetailed: PropTypes.func.isRequired,
  getAllCalculation: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  permissions: state.auth.permissions,
});

export default connect(mapStateToProps, {
  updateCalcsList,
  getDeviceTypesShort,
  getDeviceTypesDetailed,
  getAllCalculation,
})(Calculation);
