import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import { withRouter } from "react-router-dom";
import { createSite } from "../../actions/site";
import axios from "axios";

// Components
import SiteDetail from "./SiteDetail";
import SiteNetwork from "./siteNetwork";
import SiteDiscovery from "./siteDiscovery";
import SiteDevices from "../tools/config/devices/Devices";
import SiteAlarms from "../tools/config/alarms/Alarms";
import SiteFinishing from "./siteFinishing";

// Ant Design components
import { Steps, Button } from "antd";

// function
import { updateBackendConfiguration } from "../../actions/deviceList";
import { updateBackend } from "../../actions/modbusconfig";
import { updateSNMPBackend } from "../../actions/SNMPConfig";
import {
  updateManufacturesList,
  updateTemplates,
} from "../../actions/templates";
import { updateDeviceTypes } from "../../actions/deviceTypes";
import { updateClients } from "../../actions/client";
import { updateHMIComponents } from "../../actions/site";
import { saveAlarmListTODB, CreateAlarmsList } from "../../actions/alarms";
import { updateCalcsList } from "../../actions/calculations";
// External functions
import { SaveEthernetIP, exportPointsList } from "../../actions/utl";
import { clearDeviceLists } from "../../actions/deviceList";

const { Step } = Steps;

// actual component
const NewSiteWizard = ({
  auth: { loading },
  createSite,
  history,
  sitedetails,
  updateSNMPBackend,
  updateBackend,
  updateBackendConfiguration,
  currentList,
  updateManufacturesList,
  updateTemplates,
  updateDeviceTypes,
  updateClients,
  clientDataLoading,
  saveAlarmListTODB,
  CreateAlarmsList,
  currentAlarmsList,
  updateCalcsList,
  updateHMIComponents,
  calcsLoading,
  SaveEthernetIP,
  InterfaceIP,
  clearDeviceLists,
  templates: { manufacturesLoading, dataloaded },
}) => {
  useEffect(() => {
    updateClients(true);
    updateManufacturesList(true);
    updateDeviceTypes(true);
    updateTemplates(true);
    updateHMIComponents(true);
    updateCalcsList(true);
  }, [
    updateManufacturesList,
    updateDeviceTypes,
    updateTemplates,
    updateClients,
    updateHMIComponents,
    updateCalcsList,
  ]);

  const steps = [
    {
      title: "Site Detail",
      content: <SiteDetail />,
    },
    {
      title: "Network",
      content: <SiteNetwork />,
    },
    {
      title: "Discovery",
      content: <SiteDiscovery />,
    },
    {
      title: "Devices",
      content: <SiteDevices fromWizard={true} />,
    },
    {
      title: "Alarms",
      content: <SiteAlarms fromWizard={true} />,
    },
    {
      title: "Finish",
      content: <SiteFinishing />,
    },
  ];

  const [current, setCurrent] = useState(0);

  const handleDiscoverySave = async () => {
    try {
      if (sessionStorage.getItem("wizardDiscoveryName")) {
        await axios.post("/api/site/discovername", {
          discoveryName: sessionStorage.getItem("wizardDiscoveryName"),
        });

        sessionStorage.removeItem("wizardDiscoveryName");
      }
    } catch (error) {}
  };

  const next = () => {
    if (current === 0) {
      if (sitedetails) {
        if (sitedetails.client_name && sitedetails.name) {
          setCurrent(current + 1);
        }
      }
    } else if (current === 1) {
      // Network
      SaveEthernetIP(InterfaceIP.Ethernet);

      setTimeout(() => {
        setCurrent(current + 1);
      }, 100);
    } else if (current === 3) {
      // Device list to alarms list
      CreateAlarmsList(currentList);

      setTimeout(() => {
        setCurrent(current + 1);
      }, 2000);
    } else {
      setCurrent(current + 1);
    }
  };

  const prev = () => {
    // Going Backward from devices to read
    if (current === 3) {
      clearDeviceLists();
    }

    setCurrent(current - 1);
  };

  const handleSave = () => {
    let entireModbusConfig = [];
    let entireSNMPConfig = [];
    let configData = {};

    currentList.forEach((item) => {
      configData = {};

      if (item.Information.deviceCommType === "Modbus") {
        configData.templateID = item.Information.template._id;
        configData.config = {};

        // Does the device have a name, usually a temperature boards doesn't
        if (item.Information.deviceName)
          configData.config.Name = item.Information.deviceName;
        else {
          configData.config.customNames = [];

          item.Details.childNames.forEach((arrayItem) => {
            if (arrayItem.name !== "") {
              configData.config.customNames.push(arrayItem);
            }
          });
        }

        if (item.Information.deviceInterfaceType === "TCP") {
          configData.config.interfaceType = 0;
          if (item.Information.deviceTCPSlaveID)
            configData.config.TCPSlaveID = item.Information.deviceTCPSlaveID;
        } else configData.config.interfaceType = 1;

        configData.config.sidIP = item.Information.deviceIPSlaveID;
        configData.config.pollrate = item.Information.devicePollRate;
        configData.config.devType = item.Information.deviceType;

        entireModbusConfig.push(configData);
      } else if (item.Information.deviceCommType === "SNMP") {
        configData.templateID = item.Information.template._id;
        configData.config = {};

        if (item.Information.deviceName)
          configData.config.Name = item.Information.deviceName;
        else {
          configData.config.customNames = [];

          item.Details.childNames.forEach((arrayItem) => {
            if (arrayItem.name !== "") {
              configData.config.customNames.push(arrayItem);
            }
          });
        }

        // Always TCP
        configData.config.interfaceType = 0;
        // Other Information
        configData.config.sidIP = item.Information.deviceIPSlaveID;
        configData.config.pollrate = item.Information.devicePollRate;
        configData.config.devType = item.Information.deviceType;
        configData.config.SNMPVersion = item.Information.SNMPVersion;
        configData.config.SNMPPort = item.Information.SNMPPort;
        configData.config.communityString = item.Information.communityString;
        configData.config.deviceID = item.Information.deviceID;

        entireSNMPConfig.push(configData);
      }
    });

    const FileName = sitedetails.name + " Initial";

    // Save the SNMP configuration
    if (entireSNMPConfig.length > 0) {
      let formData = {};

      formData.config = entireSNMPConfig;
      formData.name = FileName;
      formData.current = true;

      updateSNMPBackend(formData);
    }

    // Save the Modbuus configuration
    if (entireModbusConfig.length > 0) {
      let formData = {};

      formData.config = entireModbusConfig;
      formData.name = FileName;
      formData.current = true;

      updateBackend(formData);
    }

    // Saving Actual Config list
    let configFile = {
      name: FileName,
      configuration: { ...currentList },
      notes: "Initial site from the wizard",
    };

    updateBackendConfiguration(configFile);
  };

  const sendToServer = () => {
    saveAlarmListTODB(currentAlarmsList);
    handleSave();
    createSite(sitedetails, history);
    // this is the discovery name that is saved on the site model on the DB, but this is only getting created the first time above
    setTimeout(() => {
      exportPointsList();
      handleDiscoverySave();
    }, 1000);
  };

  return loading ||
    manufacturesLoading ||
    !dataloaded ||
    !clientDataLoading ||
    calcsLoading ? (
    <Spinner />
  ) : (
    <Fragment>
      <Steps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[current].content}</div>
      <div className="steps-action">
        {current > 0 && (
          <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
            Previous
          </Button>
        )}
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            type="primary"
            onClick={() => {
              sendToServer();
            }}
          >
            Done
          </Button>
        )}
      </div>
    </Fragment>
  );
};

NewSiteWizard.propTypes = {
  auth: PropTypes.object.isRequired,
  currentList: PropTypes.array,
  createSite: PropTypes.func.isRequired,
  updateManufacturesList: PropTypes.func.isRequired,
  SaveEthernetIP: PropTypes.func.isRequired,
  updateHMIComponents: PropTypes.func.isRequired,
  updateCalcsList: PropTypes.func.isRequired,
  saveAlarmListTODB: PropTypes.func.isRequired,
  updateBackend: PropTypes.func.isRequired,
  updateSNMPBackend: PropTypes.func.isRequired,
  updateTemplates: PropTypes.func.isRequired,
  updateClients: PropTypes.func.isRequired,
  updateDeviceTypes: PropTypes.func.isRequired,
  updateBackendConfiguration: PropTypes.func.isRequired,
  CreateAlarmsList: PropTypes.func.isRequired,
  templates: PropTypes.object,
  clientDataLoading: PropTypes.bool,
  calcsLoading: PropTypes.bool,
  currentAlarmsList: PropTypes.array,
  InterfaceIP: PropTypes.object,
  clearDeviceLists: PropTypes.func.isRequired,
};

const mapStateToProp = (state) => ({
  sitedetails: state.site.siteDetails,
  templates: state.template,
  currentList: state.deviceList.deviceToRead,
  auth: state.auth,
  clientDataLoading: state.client.dataloaded,
  currentAlarmsList: state.alarms.alarmsList,
  calcsLoading: state.calcs.calcsLoading,
  InterfaceIP: state.utl.InterfaceIP,
});

export default connect(mapStateToProp, {
  updateBackend,
  createSite,
  updateSNMPBackend,
  updateBackendConfiguration,
  updateManufacturesList,
  updateTemplates,
  updateDeviceTypes,
  updateClients,
  saveAlarmListTODB,
  CreateAlarmsList,
  updateHMIComponents,
  updateCalcsList,
  SaveEthernetIP,
  clearDeviceLists,
})(withRouter(NewSiteWizard));
