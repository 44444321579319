import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd
import { Table, Typography, Space, Popconfirm, Tooltip, Button } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

// external compontns
import ModalCreateCalcTemp from "../Modals/ModalCreateCalcTemp";

// external functions
import {
  openModalTempCalc,
  deleteCalculation,
} from "../../../actions/calculations";

// actual component
const TabTemplate = ({
  permissions,
  openModalTempCalc,
  allCalcs,
  deleteCalculation,
}) => {
  const handleEdit = (record) => {
    openModalTempCalc(record);
  };

  const handleDelete = (record) => {
    deleteCalculation(record);
  };

  // table columns
  const tableColumns = [
    {
      title: "Name",
      dataIndex: "calculationName",
      key: "calculationName",
      render: (text, record) => (
        <a href="#!" onClick={() => handleEdit(record)}>
          {text}
        </a>
      ),
    },
    {
      title: "Description",
      dataIndex: "calculationDescription",
      key: "calculationDescription",
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      responsive: ["md"],
    },
    {
      title: "Updated By",
      dataIndex: "updatedBy",
      key: "updatedBy",
      responsive: ["md"],
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <p>{text.replace("T", " ").replace("Z", "")}</p>,
      responsive: ["md"],
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text) => <p>{text.replace("T", " ").replace("Z", "")}</p>,
      responsive: ["md"],
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {permissions && permissions.delete && (
            <Fragment>
              <Popconfirm
                title={
                  "Sure you want to delete " + record.calculationName + "?"
                }
                onConfirm={() => {
                  handleDelete(record);
                }}
              >
                <Tooltip title="Delete this calculation">
                  <a href="#!">
                    <DeleteOutlined style={{ color: "red" }} />
                  </a>
                </Tooltip>
              </Popconfirm>
              <Tooltip title="Edit this calculation">
                <a href="#!" onClick={() => handleEdit(record)}>
                  <EditOutlined />
                </a>
              </Tooltip>
            </Fragment>
          )}
        </Space>
      ),
    },
  ];

  return (
    <Fragment>
      <Typography>
        These are calculation that will only show up for templates, use these in
        the templates only
      </Typography>

      <ModalCreateCalcTemp />

      <Table
        bordered
        columns={tableColumns}
        size="small"
        pagination={{ pageSize: 40 }}
        scroll={{ y: 500 }}
        dataSource={
          allCalcs && allCalcs.filter((calc) => calc.isTemplateOnly === true)
        }
        loading={allCalcs === null ? true : false}
      ></Table>

      {permissions && permissions.write && (
        <Tooltip title="Create a new calculation, this is local and not on the server">
          <Button
            type="primary"
            style={{ marginTop: "10px" }}
            onClick={() => openModalTempCalc(null)}
          >
            Create New
          </Button>
        </Tooltip>
      )}
    </Fragment>
  );
};

TabTemplate.propTypes = {
  permissions: PropTypes.object,
  allCalcs: PropTypes.array,
  openModalTempCalc: PropTypes.func.isRequired,
  deleteCalculation: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  permissions: state.auth.permissions,
  allCalcs: state.calcs.allCalcs,
});

export default connect(mapStateToProps, {
  openModalTempCalc,
  deleteCalculation,
})(TabTemplate);
