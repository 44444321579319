import React, { Fragment } from "react";

// Ant design
import { Typography } from "antd";

const { Title, Paragraph, Text } = Typography;

const ModalDetailsTCO = (props) => {
  return (
    <Fragment>
      <div style={{ textAlign: "center" }}>
        <Title level={2}>Nothing to Edit</Title>
        <Paragraph>
          There is no information that you will need to configure, click on the{" "}
          <Text keyboard>OK</Text> button.
        </Paragraph>
      </div>
    </Fragment>
  );
};

ModalDetailsTCO.propTypes = {};

export default ModalDetailsTCO;
