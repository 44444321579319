import {
  DEVICELIST_MODAL_CLOSE,
  DEVICELIST_MODAL_OPEN,
  DEVICELIST_UPDATE_DETAILS,
  DEVICELIST_UPDATE_INFO,
  DEVICELIST_UPDATE_LIST,
  DEVICELIST_MODAL_OPEN_DUPLICATE,
  DEVICELIST_UPDATE_LISTTOREAD,
  DEVICELIST_MODAL_OPEN_EDIT,
  DEVICELIST_DELETE_LISTITEM,
  DEVICELIST_UPDATE_LISTTOREAD_ALL,
  DEVICELIST_CREATE_TYPENAMELIST,
  DEVICELIST_ADD_TYPENAMELIST,
  DEVICELIST_UPDATE_TEMPTYPENAMELIST,
  DEVICELIST_UPDATE_TYPENAMELIST,
  DEVICELIST_IS_SAVING,
  DEVICELIST_DEVICE_STATUS,
  DEVICELIST_EDITINPUT_MODAL_OPEN,
  DEVICELIST_EDITINPUT_MODAL_CLOSE,
  DEVICELIST_DIGITALEDITINPUT_MODAL_OPEN,
  DEVICELIST_DIGITALEDITINPUT_MODAL_CLOSE,
  DEVICELIST_SELECT_CONFIG,
  DEVICELIST_MODAL_SAVECONFIG_OPEN,
  DEVICELIST_MODAL_SAVECONFIG_CLOSE,
  DEVICELIST_CREATE_IPRTULIST,
  DEVICELIST_CLEAR,
} from "./types";
import setAuthToken from "../utils/setAuthToken";
import { v4 as uuid } from "uuid";
import axios from "axios";
import { setAlert } from "./alert";

// Open the modal to add a device to the list
export const openModal = () => async (dispatch) => {
  dispatch({
    type: DEVICELIST_MODAL_OPEN,
  });
};

// Close the modal to add a device to the list
export const closeModal = () => async (dispatch) => {
  dispatch({
    type: DEVICELIST_MODAL_CLOSE,
  });
};

export const deleteListItem = (itemTODelete) => (dispatch) => {
  dispatch({
    payload: itemTODelete,
    type: DEVICELIST_DELETE_LISTITEM,
  });
};

export const updateDeviceInformation = (deviceInformation) => (dispatch) => {
  dispatch({
    payload: deviceInformation,
    type: DEVICELIST_UPDATE_INFO,
  });

  // if(deviceInformation)
};

export const EditListItem = (itemTOEdit) => (dispatch) => {
  dispatch({
    payload: itemTOEdit,
    type: DEVICELIST_MODAL_OPEN_EDIT,
  });
};

export const DuplicateListItem = (itemTOEdit) => (dispatch) => {
  dispatch({
    payload: itemTOEdit,
    type: DEVICELIST_MODAL_OPEN_DUPLICATE,
  });
};

export const updateDeviceDetails = (deviceDetails) => (dispatch) => {
  dispatch({
    payload: deviceDetails,
    type: DEVICELIST_UPDATE_DETAILS,
  });
};

export const getConfigurationFiles = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    let result = await axios.get("/api/site/configfile");

    if (!result) dispatch(setAlert("No configurations found", "success"));
    else {
      dispatch({
        payload: [...result.data],
        type: DEVICELIST_UPDATE_LISTTOREAD_ALL,
      });
    }
  } catch (error) {
    console.log(error);
    if (error.response !== undefined) {
      console.log(error.response);
      if (error.response.status === 500) {
        dispatch(
          setAlert("Configuration file - " + error.response.statusText, "error")
        );
      }
    }
  }
};

export const updateBackendConfiguration =
  (currentConfig) => async (dispatch) => {
    dispatch({
      payload: true,
      type: DEVICELIST_IS_SAVING,
    });

    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      let result = await axios.post(
        "/api/site/configfile",
        currentConfig,
        config
      );

      // if (result) dispatch(setAlert("Configuration file saved", "success"));

      dispatch({
        payload: false,
        type: DEVICELIST_IS_SAVING,
      });

      const newItemInDeviceList = await CreateDeviceList(currentConfig);

      dispatch({
        payload: newItemInDeviceList,
        type: DEVICELIST_UPDATE_LIST,
      });

      result = await axios.post(
        "/api/site/configlist",
        {
          deviceList: newItemInDeviceList,
        },
        config
      );

      if (result) dispatch(setAlert("Everything was saved okay", "success"));
    } catch (error) {
      dispatch({
        payload: false,
        type: DEVICELIST_IS_SAVING,
      });
      console.log(error);
      if (error.response && error.response.status === 500) {
        dispatch(
          setAlert(
            "Saving configuration file - " + error.response.statusText,
            "error"
          )
        );
      }
    }
  };

const CreateDeviceList = async (currentConfig) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  // Get all the templates to be able to extract the children from the list
  const devTemplates = await axios.get("/api/devtemplates");

  let comibinedList = [];

  // Make up the parents list, first
  if (currentConfig) {
    if (currentConfig.configuration) {
      Object.entries(currentConfig.configuration).forEach(([_, device]) => {
        // Create the Device list
        let newItemInDeviceList = {
          device_name: device.Information.deviceName,
          device_type: device.Information.deviceType,
          device_templateID: device.Information.template._id,
          // device_details: device.Details,
          children: [],
        };

        // If there is groups, add then
        if (device.Information.deviceGroup) {
          newItemInDeviceList.device_groups = [];
          newItemInDeviceList.device_groups.push(
            device.Information.deviceGroup
          );
        }

        let thisDeviceTemplate = devTemplates.data.find(
          (template) => template._id === newItemInDeviceList.device_templateID
        );

        // if we found one
        if (thisDeviceTemplate) {
          if (
            device.Information.deviceType !== "Environment Board" &&
            device.Information.deviceType !== "Analogue Input Board"
          ) {
            if (device.Information.deviceType !== "Fuel Sensor")
              newItemInDeviceList.device_details = device.Details;

            // for Modbus
            if (thisDeviceTemplate.commProtocol === "Modbus") {
              thisDeviceTemplate.Batchs.forEach((Batch) => {
                Batch.Map.forEach((Maps) => {
                  if (Maps.ExtraChildren !== undefined) {
                    let ChildName = "";

                    if (Maps.ExtraChildren.name === undefined) {
                      ChildName =
                        newItemInDeviceList.device_name +
                        " " +
                        Maps.ExtraChildren.type;
                    } else {
                      if (device.Information.deviceType !== "Battery Bank") {
                        ChildName =
                          newItemInDeviceList.device_name +
                          " " +
                          Maps.ExtraChildren.name +
                          " " +
                          Maps.ExtraChildren.type;
                      } else {
                        ChildName =
                          newItemInDeviceList.device_name +
                          " " +
                          Maps.ExtraChildren.name;
                      }
                    }

                    // if there is duplicate children we only want the first one
                    if (newItemInDeviceList.children.length === 0)
                      newItemInDeviceList.children.push({
                        device_type: Maps.ExtraChildren.type,
                        device_name: ChildName,
                      });
                    else {
                      // check to see if we have this type already
                      if (
                        !newItemInDeviceList.children.find(
                          (o) => o.device_type === Maps.ExtraChildren.type
                        )
                      )
                        newItemInDeviceList.children.push({
                          device_type: Maps.ExtraChildren.type,
                          device_name: ChildName,
                        });
                      else {
                        let name = ChildName;

                        if (
                          !newItemInDeviceList.children.find(
                            (o) => o.device_name === name
                          )
                        )
                          newItemInDeviceList.children.push({
                            device_type: Maps.ExtraChildren.type,
                            device_name: ChildName,
                          });
                      }
                    }
                  }
                });
              });
            } else if (thisDeviceTemplate.commProtocol === "SNMP") {
              thisDeviceTemplate.Batchs.forEach((Batch) => {
                if (Batch.ExtraChildren !== undefined) {
                  let ChildName = "";

                  if (Batch.ExtraChildren.name === undefined) {
                    ChildName =
                      newItemInDeviceList.device_name +
                      " " +
                      Batch.ExtraChildren.type;
                  } else {
                    ChildName =
                      newItemInDeviceList.device_name +
                      " " +
                      Batch.ExtraChildren.name +
                      " " +
                      Batch.ExtraChildren.type;
                  }

                  // if there is duplicate children we only want the first one
                  if (newItemInDeviceList.children.length === 0)
                    newItemInDeviceList.children.push({
                      device_type: Batch.ExtraChildren.type,
                      device_name: ChildName,
                    });
                  else {
                    // check to see if we have this type already
                    if (
                      !newItemInDeviceList.children.find(
                        (o) => o.device_type === Batch.ExtraChildren.type
                      )
                    )
                      newItemInDeviceList.children.push({
                        device_type: Batch.ExtraChildren.type,
                        device_name: ChildName,
                      });
                    else {
                      let name = ChildName;

                      if (
                        !newItemInDeviceList.children.find(
                          (o) => o.device_name === name
                        )
                      )
                        newItemInDeviceList.children.push({
                          device_type: Batch.ExtraChildren.type,
                          device_name: ChildName,
                        });
                    }
                  }
                }
              });
            }
          } else if (device.Information.deviceType === "Analogue Input Board") {
            if (newItemInDeviceList.device_name)
              delete newItemInDeviceList.device_name;

            device.Details.childNames.forEach((sensorInput) => {
              if (sensorInput.deviceName !== "") {
                newItemInDeviceList.children.push({
                  device_type: sensorInput.deviceType,
                  device_name: sensorInput.deviceName,
                });
              }
            });
          } else {
            device.Details.childNames.forEach((sensorInput) => {
              if (sensorInput.name !== "") {
                newItemInDeviceList.children.push({
                  device_type: "Environment Sensor",
                  device_name: sensorInput.name,
                  device_details: {
                    floor: sensorInput.floor,
                    group: sensorInput.group,
                  },
                });
              }
            });
          }
        }

        comibinedList.push(newItemInDeviceList);
      });
    }
  }

  let childList = [];

  if (comibinedList.length > 0) {
    comibinedList.forEach((listItem) => {
      if (listItem.children.length > 0) {
        let childLoop = listItem.children;
        let ParentName = listItem.device_name;

        let parentDetails = {
          device_name: listItem.device_name,
          device_type: listItem.device_type,
        };

        childLoop.forEach((child) => {
          let newItemInDeviceList = {};

          newItemInDeviceList.device_name =
            child.device_name === undefined
              ? ParentName + " " + child.device_type
              : child.device_name;
          newItemInDeviceList.device_type = child.device_type;

          if (child.device_details)
            newItemInDeviceList.device_details = child.device_details;

          newItemInDeviceList.parents = [];
          newItemInDeviceList.parents.push(parentDetails);

          childList.push(newItemInDeviceList);
        });
      }
    });
  }

  childList.forEach((child) => comibinedList.push(child));

  return comibinedList;
};

export const updateDeviceList = (currentList, isEdit) => (dispatch) => {
  const id = uuid();

  if (isEdit === false) currentList.KeyID = id;

  dispatch({
    payload: currentList,
    type: DEVICELIST_UPDATE_LISTTOREAD,
  });

  if (currentList.Information.deviceType !== "Input Board") {
    dispatch({
      payload: currentList.Information,
      type: DEVICELIST_ADD_TYPENAMELIST,
    });
  }
};

// create a Type to name list
export const createTypeNameList = (currentConfig) => (dispatch) => {
  // Device Name to type list
  let deviceTypeNameList = [];
  let deviceIPRTUIDList = [];
  let listItems = Object.entries(currentConfig[0]);

  listItems.forEach((device) => {
    // check to see if it contains an IP or RTU slave ID
    if (device[1].Information.deviceIPSlaveID) {
      deviceIPRTUIDList.push(device[1].Information.deviceIPSlaveID);
    }

    if (
      device[1].Information.deviceType !== "Environment Board" &&
      device[1].Information.deviceType !== "Analogue Input Board" &&
      device[1].Information.deviceType !== "Input Board"
    ) {
      const deviceIndex = deviceTypeNameList.findIndex(
        (typeNameDevice) =>
          typeNameDevice &&
          typeNameDevice.type === device[1].Information.deviceType &&
          typeNameDevice.name === device[1].Information.deviceName
      );

      // No Match
      if (deviceIndex === -1) {
        deviceTypeNameList.push({
          name: device[1].Information.deviceName,
          type: device[1].Information.deviceType,
        });
      }
    } else if (
      device[1].Information.deviceType === "Input Board" ||
      device[1].Information.deviceType === "Analogue Input Board"
    ) {
      if (device[1].Details && device[1].Details.childNames) {
        device[1].Details.childNames.forEach((child) => {
          if (child.deviceName && child.deviceType) {
            const deviceChildIndex = deviceTypeNameList.findIndex(
              (typeNameDevice) =>
                typeNameDevice &&
                typeNameDevice.type === child.deviceType &&
                typeNameDevice.name === child.deviceName
            );

            // No Match
            if (deviceChildIndex === -1) {
              deviceTypeNameList.push({
                name: child.deviceName,
                type: child.deviceType,
              });
            }
          }
        });
      }
    }
  });

  dispatch({
    payload: deviceTypeNameList,
    type: DEVICELIST_CREATE_TYPENAMELIST,
  });

  dispatch({
    payload: deviceIPRTUIDList,
    type: DEVICELIST_CREATE_IPRTULIST,
  });
};

// create/update a temp Type to name list
export const updateTempTypeNameList = (currentConfig) => (dispatch) => {
  dispatch({
    payload: currentConfig,
    type: DEVICELIST_UPDATE_TEMPTYPENAMELIST,
  });
};

// create/update a temp Type to name list
export const updateTypeNameList = (TypeNameList) => (dispatch) => {
  dispatch({
    payload: TypeNameList,
    type: DEVICELIST_UPDATE_TYPENAMELIST,
  });
};

// create/update a temp Type to name list
export const clearDeviceLists = () => (dispatch) => {
  dispatch({
    type: DEVICELIST_CLEAR,
  });
};

// get the device Status from the backend
export const getDeviceStatus = () => async (dispatch) => {
  try {
    let result = await axios.get("/api/site/devicestatus");

    // if (typeof result.data !== "string") {
    dispatch({
      payload: result.data,
      type: DEVICELIST_DEVICE_STATUS,
    });
    // }
  } catch (error) {}
};

// Open the EDIT input modal, passing data will mean the user is editing existing data
export const OpenEditInputModal = (data) => (dispatch) => {
  dispatch({
    payload: data,
    type: DEVICELIST_EDITINPUT_MODAL_OPEN,
  });
};

// Close the EDIT input modal
export const CloseEditInputModal = () => (dispatch) => {
  dispatch({
    type: DEVICELIST_EDITINPUT_MODAL_CLOSE,
  });
};

// Open the EDIT Digital input modal, passing data will mean the user is editing existing data
export const OpenEditDigialInputModal = (data) => (dispatch) => {
  dispatch({
    payload: data,
    type: DEVICELIST_DIGITALEDITINPUT_MODAL_OPEN,
  });
};

// Close the EDIT Digital input modal
export const CloseEditDigitalInputModal = () => (dispatch) => {
  dispatch({
    type: DEVICELIST_DIGITALEDITINPUT_MODAL_CLOSE,
  });
};

// Get specific Configs Data
export const GetSelectedConfig =
  (item, UpdateDeviceList = false, queryAPI = true) =>
  async (dispatch) => {
    try {
      let result = "";
      if (queryAPI) {
        result = await axios.get("/api/site/configfile/" + item);

        result = result.data.configuration;
      } else {
        result = item;
      }

      // Make up the list of device and name to use on the frontend to select already used devices
      dispatch(createTypeNameList(result));

      const newItemInDeviceList = await CreateDeviceList({
        configuration: result[0],
      });

      dispatch({
        payload: newItemInDeviceList,
        type: DEVICELIST_UPDATE_LIST,
      });

      dispatch({
        payload: result[0],
        type: DEVICELIST_SELECT_CONFIG,
      });

      if (UpdateDeviceList === true) {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        // Update the device list
        result = await axios.post(
          "/api/site/configlist",
          {
            deviceList: newItemInDeviceList,
          },
          config
        );

        dispatch(setAlert("Device List changed", "success"));
      }
    } catch (err) {
      dispatch(setAlert("An error occured!!", "error"));
    }
  };

// enable a config file
export const EnableSelectedConfig = (item) => async (dispatch) => {
  try {
    let result = await axios.get("/api/site/enableconfigfile/" + item);

    result = result.data.configuration;

    let configItems = [];

    result = result[0];

    for (const [key, value] of Object.entries(result)) {
      let useless = key;
      useless = useless + "f";
      configItems.push(value);
    }

    return configItems;
  } catch (error) {
    console.log(error);
  }
};

// Open the save config modal
export const OpenSaveConfigModal = () => (dispatch) => {
  dispatch({
    type: DEVICELIST_MODAL_SAVECONFIG_OPEN,
  });
};

// Close the save config modal
export const CloseSaveConfigModal = () => (dispatch) => {
  dispatch({
    type: DEVICELIST_MODAL_SAVECONFIG_CLOSE,
  });
};

// Close the save config modal
export const ClearDeviceList = () => (dispatch) => {
  dispatch({
    type: DEVICELIST_CLEAR,
  });
};
