import React, { Fragment, useEffect, useState } from "react";
import { Table, Button } from "antd";
import axios from "axios";

const Discovery = () => {
  const [tableLoading, setTabletLoad] = useState(true);
  const [dataS, setdataS] = useState([]);

  const columns = [
    {
      title: "IP Address",
      dataIndex: "IPaddress",
      key: "IPaddress",
    },
    {
      title: "Device Type",
      dataIndex: "DeviceType",
      key: "DeviceType",
    },
    {
      title: "MACaddress",
      dataIndex: "MACaddress",
      key: "MACaddress",
      responsive: ["lg"],
    },
    {
      title: "Code Version",
      dataIndex: "CodeVersion",
      key: "CodeVersion",
      responsive: ["lg"],
    },
    {
      title: "Details",
      dataIndex: "Details",
      key: "Details",
      responsive: ["lg"],
    },
  ];

  const getDiscoveryData = async () => {
    const data = {
      task: "discover",
    };
    const result = await axios.post("/api/utl", data);
    // const result = await axios.post("http://localhost:5000/api/utl", data);

    setdataS(result.data);
    setTabletLoad(false);
  };

  useEffect(() => {
    getDiscoveryData();
  }, []);

  const refreshData = () => {
    setdataS([]);
    getDiscoveryData();
    setTabletLoad(true);
  };

  return (
    <Fragment>
      <h1 className="large text-primary">Discover MPT Devices</h1>
      <Table
        columns={columns}
        size="middle"
        dataSource={dataS}
        pagination={{ pageSize: 10 }}
        scroll={{ y: 800 }}
        loading={tableLoading}
      />
      <Button type="primary" onClick={refreshData}>
        Refresh
      </Button>
    </Fragment>
  );
};

export default Discovery;
