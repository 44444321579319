import React, { Fragment, useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Space,
  Table,
  Button,
  Popconfirm,
  Tooltip,
} from "antd";
import { connect } from "react-redux";
import {
  removerDeviceManufacture,
  createManufacture,
  fetchDeviceManufactures,
  updateManufacturesList,
} from "../../actions/templates";
import PropTypes from "prop-types";

// Components
import Spinner from "../layout/Spinner";

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};

const DeviceMaunfacture = ({
  createManufacture,
  removerDeviceManufacture,
  updateManufacturesList,
  fetchDeviceManufactures,
  templates: { manufactures, manufacturesLoading },
  permissions,
}) => {
  useEffect(() => {
    fetchDeviceManufactures();
  }, [fetchDeviceManufactures]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Date Created",
      dataIndex: "createdAt",
      key: "createdAt",
      responsive: ["lg"],
      render: (text) => <p>{text.replace("T", " ").replace("Z", "")}</p>,
      defaultSortOrder: "descend",
      sorter: (a, b) => a.createdAt - b.createdAt,
    },
    {
      title: "Updated At",
      key: "updatedAt",
      dataIndex: "updatedAt",
      responsive: ["lg"],
      render: (text) => <p>{text.replace("T", " ").replace("Z", "")}</p>,
      defaultSortOrder: "descend",
      sorter: (a, b) => a.updatedAt - b.updatedAt,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          {permissions && permissions.delete && (
            <Popconfirm
              title={"Sure to delete " + record.name + " ?"}
              onConfirm={() => {
                removerDeviceManufacture(record._id);
              }}
            >
              <a href="#!">Delete</a>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  const [modalVis, setmodalVis] = useState(false);

  const CreatNewManufacturesModal = () => {
    const handleOk = () => {
      if (inputName) {
        setmodalVis(false);
        createManufacture(inputName);
      }
    };

    const [inputName, setinputName] = useState("");

    return (
      <Fragment>
        <Modal
          title={"Create a new Manufacture"}
          visible={modalVis}
          onOk={handleOk}
          onCancel={() => {
            setmodalVis(false);
          }}
          width="800px"
        >
          <Form
            {...formLayout}
            name="basic"
            initialValues={""}
            onValuesChange={(changedValues, allValues) => {
              setinputName(allValues);
            }}
          >
            <Form.Item label="Name" name="name">
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      </Fragment>
    );
  };

  return manufacturesLoading ? (
    <Spinner />
  ) : (
    <Fragment>
      <h1 className="large text-primary">Device Maunfacture</h1>
      <Table
        columns={columns}
        dataSource={manufactures}
        size="small"
        pagination={{ pageSize: 40 }}
        scroll={{ y: 600 }}
        loading={!manufacturesLoading && manufactures === null ? true : false}
      />

      <CreatNewManufacturesModal />

      {permissions && permissions.update && (
        <Tooltip title="Update for the server">
          <Button
            style={{ marginTop: "10px" }}
            type="primary"
            onClick={updateManufacturesList}
          >
            Update
          </Button>
        </Tooltip>
      )}

      {permissions && permissions.write && (
        <Tooltip title="Create a new manufacture, this is local and not on the server">
          <Button
            type="primary"
            style={{ margin: "10px" }}
            onClick={() => setmodalVis(true)}
          >
            Create New
          </Button>
        </Tooltip>
      )}
    </Fragment>
  );
};

DeviceMaunfacture.propTypes = {
  templates: PropTypes.object,
  auth: PropTypes.object.isRequired,
  createManufacture: PropTypes.func.isRequired,
  removerDeviceManufacture: PropTypes.func.isRequired,
  fetchDeviceManufactures: PropTypes.func.isRequired,
  updateManufacturesList: PropTypes.func.isRequired,
  permissions: PropTypes.object,
};

const mapStateToProps = (state) => ({
  templates: state.template,
  auth: state.auth,
  permissions: state.auth.templatePermissions,
});

export default connect(mapStateToProps, {
  createManufacture,
  removerDeviceManufacture,
  fetchDeviceManufactures,
  updateManufacturesList,
})(DeviceMaunfacture);
