import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// ant design componet
import { Modal, Tabs, Form, Input } from "antd";

// other components
import DeviceTypeVariablesTab from "./deviceTypeVariables";
import DeviceTypeAlarmStateTab from "./deviceAlarmsStates";

// functions
import { closeTypeModal, updateTypesList } from "../../actions/deviceTypes";

const { TabPane } = Tabs;

// Actual component
const CreatNewTypeModal = ({
  isEdit,
  modalVisablity,
  editTypeDetials,
  closeTypeModal,
  updateTypesList,
}) => {
  const [newTypeForm] = Form.useForm();
  const [tabNumber, setTabNumber] = useState("0");

  let FormData = {};

  const closeOperations = () => {
    setTabNumber("0");
    newTypeForm.resetFields();
    closeTypeModal();
  };

  const handelCancel = () => {
    closeOperations();
  };

  const handleOk = () => {
    if (isEdit) {
      // deep copy
      let newFullList = JSON.parse(JSON.stringify(editTypeDetials));
      // update the list
      updateTypesList(newFullList);
      closeOperations();
    } else {
      if (FormData) {
        if (FormData.device_type_name) {
          FormData.device_type_name = FormData.device_type_name.trim();
          // update the list
          updateTypesList({ ...FormData, vairables: [] });
          closeOperations();
        }
      }
    }
  };

  return (
    <Fragment>
      <Modal
        title={
          isEdit
            ? "Editing type : " + editTypeDetials.device_type_name
            : "Creating a new Type"
        }
        visible={modalVisablity}
        onOk={handleOk}
        okText={isEdit ? "Update" : "Add"}
        onCancel={handelCancel}
        width="900px"
      >
        {!isEdit ? (
          <Form
            form={newTypeForm}
            size="small"
            labelCol={{ span: 9 }}
            wrapperCol={{ span: 6 }}
            onValuesChange={(_, allValues) => {
              FormData = allValues;
            }}
          >
            <Form.Item label="Type Name" name="device_type_name">
              <Input />
            </Form.Item>
          </Form>
        ) : (
          <Tabs activeKey={tabNumber} onChange={(key) => setTabNumber(key)}>
            <TabPane tab="Variables" key="0">
              <DeviceTypeVariablesTab />
            </TabPane>
            <TabPane tab="Alarms/States" key="1">
              <DeviceTypeAlarmStateTab />
            </TabPane>
          </Tabs>
        )}
      </Modal>
    </Fragment>
  );
};

CreatNewTypeModal.propTypes = {
  isEdit: PropTypes.bool,
  modalVisablity: PropTypes.bool,
  closeTypeModal: PropTypes.func,
  editTypeDetials: PropTypes.object,
};

const mapStateToProps = (state) => ({
  isEdit: state.deviceTypes.isEdit,
  modalVisablity: state.deviceTypes.modalVisablity,
  editTypeDetials: state.deviceTypes.editTypeDetials,
});

export default connect(mapStateToProps, { closeTypeModal, updateTypesList })(
  CreatNewTypeModal
);
