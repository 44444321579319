import React, { Fragment } from "react";

// Antd
import { Typography, Tabs } from "antd";

// External components
import TabIP from "./IP";
import TabSSID from "./SSID";

// Constants
const { Title } = Typography;
const { TabPane } = Tabs;

// Actual Component
const WIFI = () => {
  return (
    <Fragment>
      <Typography>
        <Title level={4}>WIFI Interface</Title>
      </Typography>

      <Tabs defaultActiveKey="0" size="small">
        <TabPane tab="Network" key="0">
          <TabSSID />
        </TabPane>
        <TabPane tab="IP" key="1">
          <TabIP />
        </TabPane>
      </Tabs>
    </Fragment>
  );
};

export default WIFI;
