import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from "axios";

// Antd comonent
import {
  Form,
  Button,
  Tooltip,
  Popconfirm,
  Row,
  Col,
  Typography,
  Input,
  Space,
} from "antd";
import {
  FileTextOutlined,
  StopOutlined,
  FileDoneOutlined,
  SaveOutlined,
} from "@ant-design/icons";

// extenerl functions
import {
  exportLogs,
  factoryResetMe,
  exportPointsList,
  serviceReset,
  onlyResetMe,
  configResetMe,
} from "../../actions/utl";
import { loadSite } from "../../actions/site";

const { Title } = Typography;

// Actual componet
const TabGeneral = ({
  permissions,
  exportLogs,
  factoryResetMe,
  configResetMe,
  exportPointsList,
  onlyResetMe,
  serviceReset,
  discoveryName,
  loadSite,
}) => {
  const [GeneralForm] = Form.useForm();
  const [saveButtonLoading, setsaveButtonLoading] = useState(false);
  const [resetServiceLoading, setResetServiceLoading] = useState(false);

  if (
    saveButtonLoading === false &&
    (GeneralForm.getFieldValue("discoveryName") === undefined ||
      GeneralForm.getFieldValue("discoveryName") !== discoveryName)
  ) {
    GeneralForm.setFieldsValue({ discoveryName });
  }

  const handleSave = async () => {
    setsaveButtonLoading(true);
    try {
      await axios.post("/api/site/discovername", {
        discoveryName: GeneralForm.getFieldValue("discoveryName"),
      });
      loadSite();
    } catch (error) {}
    setTimeout(() => setsaveButtonLoading(false), 500);
  };

  return (
    <Row>
      <Col
        xs={24}
        md={11}
        style={{
          borderRadius: "5px",
          borderStyle: "dashed",
          borderWidth: "1px",
        }}
      >
        <Typography>
          <Title style={{ textAlign: "center" }} level={3}>
            Logs and downloads
          </Title>
        </Typography>
        <Form
          labelCol={{ span: 9 }}
          wrapperCol={{ span: 5 }}
          size="small"
          form={GeneralForm}
        >
          <Form.Item
            label="Get configTool Logs"
            style={{ marginBottom: "5px" }}
          >
            <Tooltip title="Get the logs from the controller">
              <Button icon={<FileTextOutlined />} onClick={exportLogs}>
                Get Logs
              </Button>
            </Tooltip>
          </Form.Item>
          <Form.Item
            label="Config points list"
            style={{ marginBottom: "5px" }}
            tooltip="Download the variable points and alarms list"
          >
            <Button icon={<FileDoneOutlined />} onClick={exportPointsList}>
              Points List
            </Button>
          </Form.Item>
          <Form.Item
            label="Discovery Name"
            style={{ marginBottom: "5px" }}
            tooltip="This will be the name that will display on the discovery tool"
          >
            <Space>
              <Form.Item name="discoveryName">
                <Input
                  style={{ width: 95 }}
                  maxLength={25}
                  disabled={saveButtonLoading}
                />
              </Form.Item>
              <Button
                style={{ marginBottom: "22px" }}
                icon={<SaveOutlined />}
                loading={saveButtonLoading}
                onClick={handleSave}
              ></Button>
            </Space>
          </Form.Item>
        </Form>
      </Col>
      <Col xs={1} md={2}></Col>
      {permissions && permissions.update && (
        <Col
          xs={24}
          md={11}
          style={{
            borderRadius: "5px",
            borderStyle: "dashed",
            borderWidth: "1px",
          }}
        >
          <Fragment>
            <Typography>
              <Title style={{ textAlign: "center" }} level={3}>
                Device and service resets
              </Title>
            </Typography>
            <Form labelCol={{ span: 9 }} wrapperCol={{ span: 5 }} size="small">
              <Fragment>
                <Form.Item label="Clear UC" style={{ marginBottom: "5px" }}>
                  <Tooltip title="Clear all the databases and configs, as if it was a new UC">
                    <Popconfirm
                      title="This can not be undone, sure?"
                      onConfirm={() => factoryResetMe()}
                    >
                      <Button icon={<StopOutlined />} danger>
                        Factory Reset
                      </Button>
                    </Popconfirm>
                  </Tooltip>
                </Form.Item>
                <Form.Item label="Clear config" style={{ marginBottom: "5px" }}>
                  <Tooltip title="Clear the site configurations data, to setup using the wizard">
                    <Popconfirm
                      title="This can not be undone, sure?"
                      onConfirm={() => configResetMe()}
                    >
                      <Button icon={<StopOutlined />} danger>
                        Config Reset
                      </Button>
                    </Popconfirm>
                  </Tooltip>
                </Form.Item>
                <Form.Item
                  label="Reboot Backend"
                  style={{ marginBottom: "5px" }}
                >
                  <Tooltip title="Resets all the backend services">
                    <Popconfirm
                      title="Are you sure you want to reboot the backend services?"
                      onConfirm={() => {
                        setResetServiceLoading(true);
                        serviceReset();
                        setTimeout(() => setResetServiceLoading(false), 2000);
                      }}
                    >
                      <Button
                        style={{ color: "orange" }}
                        loading={resetServiceLoading}
                      >
                        Reboot Services
                      </Button>
                    </Popconfirm>
                  </Tooltip>
                </Form.Item>
                <Form.Item label="Reboot UC" style={{ marginBottom: "5px" }}>
                  <Tooltip title="Resets the UC controller">
                    <Popconfirm
                      title="Are you sure you want to reboot the UC?"
                      onConfirm={() => onlyResetMe()}
                    >
                      <Button style={{ color: "orange" }}>Reboot UC</Button>
                    </Popconfirm>
                  </Tooltip>
                </Form.Item>
              </Fragment>
            </Form>
          </Fragment>
        </Col>
      )}
    </Row>
  );
};

TabGeneral.propTypes = {
  permissions: PropTypes.object,
  exportLogs: PropTypes.func,
  factoryResetMe: PropTypes.func,
  serviceReset: PropTypes.func,
  onlyResetMe: PropTypes.func,
  exportPointsList: PropTypes.func,
  configResetMe: PropTypes.func,
  discoveryName: PropTypes.string,
};

const mapStateToProps = (state) => ({
  permissions: state.auth.permissions,
  discoveryName: state.site.siteDetails && state.site.siteDetails.DiscoveryName,
});

export default connect(mapStateToProps, {
  exportLogs,
  factoryResetMe,
  exportPointsList,
  onlyResetMe,
  serviceReset,
  loadSite,
  configResetMe,
})(TabGeneral);
