import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// ant design comoponetns
import {
  Modal,
  Form,
  Input,
  Button,
  Row,
  Col,
  Divider,
  Select,
  Checkbox,
  Tooltip,
  Typography,
} from "antd";
import { PlusSquareOutlined } from "@ant-design/icons";

// external functions
import { stepModalClose, stepAdd } from "../../actions/calculations";
// step constants
import * as ops from "./stepOperations";

const { Text, Paragraph } = Typography;

// actual compponent
const NewStepModal = ({
  modalVisibility,
  stepModalClose,
  constants,
  stepAdd,
  isEdit,
  results,
  isEditData,
  outputs,
  newCalculation,
  thisIsTemplateCalc,
}) => {
  const [stepForm] = Form.useForm();
  const [formula, setFormula] = useState("");
  const [formData, setFormData] = useState({});
  const [isOutput, setIsOutput] = useState(false);

  if (isEdit && modalVisibility) {
    if (formData && formData.resultName === undefined) {
      setFormData({ resultName: isEditData.resultName });
      stepForm.setFieldsValue({ resultName: isEditData.resultName });
      setTimeout(() => setFormula(isEditData.formula), 100);
    }
  }

  const closingSequence = () => {
    setTimeout(() => {
      setFormula("");
      setFormData({});
      setIsOutput(false);
      stepForm.resetFields();
    }, 200);

    stepModalClose();
  };

  const handleOK = () => {
    stepAdd({
      resultName: formData.resultName,
      formula,
    });
    closingSequence();
  };

  const handleCancel = () => {
    closingSequence();
  };

  const handleAddOperation = () => {
    if (formData.operator) {
      stepForm.setFieldsValue({ operator: "" });
      setFormula(formula + formData.operator);
    }
  };

  const handleAddConstant = () => {
    if (formData.constants) {
      stepForm.setFieldsValue({ constants: "" });
      setFormula(formula + formData.constants);
    }
  };

  const handleAddResult = () => {
    if (formData.stepResults) {
      stepForm.setFieldsValue({ stepResults: "" });
      setFormula(formula + formData.stepResults);
    }
  };

  const handleInputChange = (e) => {
    setFormula(e.target.value);
  };

  const handleCheck = (e) => {
    let symb = "";

    if (e.target.checked) {
      if (outputs) {
        console.log(thisIsTemplateCalc);
        if (outputs[0].outputSymbol !== undefined)
          symb = "-" + outputs[0].outputSymbol;
        else symb = "";

        stepForm.setFieldsValue({
          resultName:
            outputs[0].outputType + "-" + outputs[0].outputVariable + symb,
        });

        setFormData({
          ...formData,
          resultName:
            outputs[0].outputType + "-" + outputs[0].outputVariable + symb,
        });
      } else {
        stepForm.setFieldsValue({ resultName: "Output" });
        setFormData({
          ...formData,
          resultName: "Output",
        });
      }
      setIsOutput(true);
    } else {
      setIsOutput(false);
      stepForm.setFieldsValue({ resultName: "" });
      setFormData({ ...formData, resultName: "" });
    }
  };

  const outputList =
    outputs &&
    outputs.map((out, index) => (
      <Select.Option
        value={
          out.outputType +
          out.outputVariable +
          (out.outputSymbol !== undefined ? out.outputSymbol : "")
        }
        key={index}
      >
        {out.outputType +
          "-" +
          out.outputVariable +
          (out.outputSymbol !== undefined ? "-" + out.outputSymbol : "")}
      </Select.Option>
    ));

  return (
    <Modal
      width={700}
      onOk={handleOK}
      onCancel={handleCancel}
      visible={modalVisibility}
      okButtonProps={{
        disabled:
          formData.resultName === undefined ||
          formData.resultName === "" ||
          formula === ""
            ? true
            : false,
      }}
      title={isEdit ? "Editing step" : "New calculation step"}
    >
      <Form
        form={stepForm}
        size="small"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 10 }}
        onValuesChange={(_, All) => setFormData(All)}
      >
        {!isEdit && (
          <Typography style={{ textAlign: "left" }}>
            <Paragraph>
              <Text strong>- Firstly:</Text> Give this step a name, this is used
              as the answer to this step so that it can be used in other steps.
              If this is the <Text strong>final</Text> step the answer is used
              in the output of the calculation then tick the checkbox and select
              the appropriate output if there are mulitples.
            </Paragraph>
            <Paragraph>
              <Text strong>- Lastly:</Text> Make up the formula, use set of math
              operators and/or user input constant and/or use previous step
              answers where needed, else you are able to use normal operations
              such as <Text keyboard>*</Text> to mulitply and number to complete
              the desired formula. Once complete, hit the okay button to save
              it.
            </Paragraph>
          </Typography>
        )}

        <Form.Item
          label="Is output"
          name="resultNamechk"
          style={{ marginBottom: "5px" }}
          tooltip="Is this used as a final answer, an output of the calculation?"
        >
          <Checkbox disabled={isEdit} onChange={handleCheck} />
        </Form.Item>
        <Form.Item label="Answer/Result Name" name="resultName">
          {isOutput &&
          (thisIsTemplateCalc === undefined || thisIsTemplateCalc === false) ? (
            outputs.length === 1 ? (
              <Input disabled />
            ) : (
              <Select allowClear showSearch>
                {outputList}
              </Select>
            )
          ) : (
            <Input disabled={isEdit || (thisIsTemplateCalc && isOutput)} />
          )}
        </Form.Item>

        <Divider></Divider>

        <Form.Item label="Math Operators" style={{ marginBottom: "5px" }}>
          <Row>
            <Col>
              <Form.Item name="operator" style={{ marginBottom: "5px" }}>
                <Select
                  style={{ width: 200, marginRight: 10 }}
                  allowClear
                  showSearch
                  size="small"
                >
                  {ops.stepOperations.map((item, index) => (
                    <Select.Option key={index} value={item.value}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Tooltip title="Click to add this operator to the formula">
                <Button onClick={handleAddOperation}>
                  <PlusSquareOutlined />
                </Button>
              </Tooltip>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item label="User Input Constants" style={{ marginBottom: "5px" }}>
          <Row>
            <Col>
              <Form.Item name="constants" style={{ marginBottom: "5px" }}>
                <Select
                  style={{ width: 200, marginRight: 10 }}
                  allowClear
                  showSearch
                  size="small"
                >
                  {(thisIsTemplateCalc === false ||
                    thisIsTemplateCalc === undefined) &&
                    newCalculation &&
                    !newCalculation.inOutSameType && (
                      <Select.Option key="Input" value="Input">
                        Input
                      </Select.Option>
                    )}
                  {constants &&
                    constants.map((item, index) => (
                      <Select.Option key={index} value={item.name}>
                        {item.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Tooltip title="Click to add this constant to the formula">
                <Button onClick={handleAddConstant}>
                  <PlusSquareOutlined />
                </Button>
              </Tooltip>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item
          label="Previous Steps Results"
          style={{ marginBottom: "5px" }}
        >
          <Row>
            <Col>
              <Form.Item name="stepResults">
                <Select
                  style={{ width: 200, marginRight: 10 }}
                  allowClear
                  showSearch
                  size="small"
                >
                  {results &&
                    results.map((item, index) => (
                      <Select.Option key={index} value={item.resultName}>
                        {item.resultName}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Tooltip title="Click to add this result to the formula">
                <Button onClick={handleAddResult}>
                  <PlusSquareOutlined />
                </Button>
              </Tooltip>
            </Col>
          </Row>
        </Form.Item>
      </Form>

      <Divider>Formula</Divider>

      <Input value={formula} onChange={handleInputChange} />
    </Modal>
  );
};

NewStepModal.propTypes = {
  modalVisibility: PropTypes.bool,
  isEdit: PropTypes.bool,
  stepModalClose: PropTypes.func.isRequired,
  constants: PropTypes.array,
  results: PropTypes.array,
  outputs: PropTypes.array,
  stepAdd: PropTypes.func,
  isEditData: PropTypes.object,
  newCalculation: PropTypes.object,
};

const mapStateToProps = (state) => ({
  modalVisibility: state.calcs.newStepModalVis,
  constants: state.calcs.newCalculation && state.calcs.newCalculation.constant,
  results: state.calcs.newCalculation && state.calcs.newCalculation.steps,
  outputs: state.calcs.newCalculation && state.calcs.newCalculation.outputs,
  isEdit: state.calcs.newStepEdit,
  isEditData: state.calcs.newStepEditData,
  newCalculation: state.calcs.newCalculation,
});

export default connect(mapStateToProps, { stepModalClose, stepAdd })(
  NewStepModal
);
