import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { Table, Space, Button, Switch, Modal } from "antd";
import { Link } from "react-router-dom";
import {
  fetchConfigurationsList,
  enableConfig,
  deleteConfig,
} from "../../../../actions/modbusconfig";
import PropTypes from "prop-types";
import Spinner from "../../../layout/Spinner";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { confirm } = Modal;

const Landing = ({
  auth: { loading, user },
  fetchConfigurationsList,
  modbusConfig,
  enableConfig,
  deleteConfig,
}) => {
  const showDeleteConfirm = (name, id) => {
    confirm({
      title: "Are you sure delete " + name + "?",
      icon: <ExclamationCircleOutlined />,
      content: "This action is permanent and can not be undone",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteConfig(id);
      },
      onCancel() {},
    });
  };

  const onChangeSwitch = (checked, event) => {
    enableConfig(checked, event.target.name);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link to={"/tools/config/modbus/" + record._id}>{text}</Link>
      ),
    },
    {
      title: "Enabled",
      dataIndex: "current",
      key: "current",
      render: (current, record) => (
        <Switch
          onChange={onChangeSwitch}
          key={record._id}
          name={record._id}
          size="small"
          defaultChecked={current}
          disabled={user.permissions[0].update === false ? true : false}
        />
      ),
    },
    {
      title: "Date Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <p>{text.replace("T", " ").replace("Z", "")}</p>,
    },
    {
      title: "Created by",
      key: "user",
      dataIndex: "user",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          {user.permissions[0].delete && (
            <a
              href="#!"
              onClick={() => showDeleteConfirm(record.name, record._id)}
            >
              Delete
            </a>
          )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    fetchConfigurationsList();
  }, [fetchConfigurationsList]);

  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <h1 className="large text-primary">Modbus configurations</h1>
      <Table columns={columns} dataSource={modbusConfig.currentList} />

      {user.permissions[0].write && (
        <Button type="primary" style={{ marginTop: "10px" }}>
          <Link to="/tools/config/new/modbus">New Config</Link>
        </Button>
      )}
    </Fragment>
  );
};

Landing.propTypes = {
  fetchConfigurationsList: PropTypes.func.isRequired,
  deleteConfig: PropTypes.func.isRequired,
  enableConfig: PropTypes.func.isRequired,
  modbusConfig: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  modbusConfig: state.modbusConfig,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  fetchConfigurationsList,
  enableConfig,
  deleteConfig,
})(Landing);
