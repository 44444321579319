import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// external funtion
import { closeOptionsModal } from "../../actions/auth";
import { updateUsers } from "../../actions/users";
import { exportLogs } from "../../actions/utl";

// Antd Componetn
import { Modal, Button, Space, Tooltip } from "antd";
import {
  UserSwitchOutlined,
  FileTextOutlined,
  DesktopOutlined,
} from "@ant-design/icons";

// Actual component
const LoginModal = ({
  modalVisible,
  closeOptionsModal,
  updateUsers,
  exportLogs,
}) => {
  const handleCancel = () => {
    closeOptionsModal();
  };

  const openInNewTab = (url) => {
    let newURL = url.split(":");

    const fullurl = newURL[0] + ":" + newURL[1] + ":80";

    const newWindow = window.open(fullurl, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <Modal
      title="Select an option"
      centered
      visible={modalVisible}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Close
        </Button>,
      ]}
    >
      <Space direction="vertical">
        <p>
          <Tooltip title="Update Users - If you are not able to log in, and you have consulted with a clientzone operator to add you, click here to update the users, requires internet">
            <Button
              icon={<UserSwitchOutlined />}
              onClick={() => {
                updateUsers();
                setTimeout(() => handleCancel(), 500);
              }}
            ></Button>
          </Tooltip>{" "}
          Update controllers users
        </p>
        <p>
          <Tooltip title="Get Logs - Get the logs from the controller">
            <Button icon={<FileTextOutlined />} onClick={exportLogs}></Button>
          </Tooltip>{" "}
          Get the logs
        </p>
        <p>
          <Tooltip title="HMI - Go to the HMI page">
            <Button
              icon={<DesktopOutlined />}
              onClick={() => {
                openInNewTab(window.location.href);
                setTimeout(() => handleCancel(), 500);
              }}
            ></Button>
          </Tooltip>{" "}
          View HMI display
        </p>
      </Space>
    </Modal>
  );
};

LoginModal.propTypes = {
  exportLogs: PropTypes.func,
  updateUsers: PropTypes.func,
  closeOptionsModal: PropTypes.func,
  modalVisible: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  modalVisible: state.auth.modalLoginVis,
});

export default connect(mapStateToProps, {
  closeOptionsModal,
  updateUsers,
  exportLogs,
})(LoginModal);
