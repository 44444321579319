import React, { Fragment, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { v4 as uuid } from "uuid";

// antd componet
import { Modal, Form, Select, Input, Divider, InputNumber } from "antd";

// External Functions
import {
  closeCalcAddModal,
  updateTemplateCalc,
} from "../../../actions/templates";

// actual component
const NewTemplateCalcModal = ({
  modalVis,
  modalData,
  closeCalcAddModal,
  availableCalcs,
  allTypes,
  currentTemplate,
  updateTemplateCalc,
}) => {
  const calcName = useRef(null);
  const childType = useRef(null);
  const [calcToUse, setCalcToUse] = useState(null);
  const [childNames, setChildNames] = useState([]);
  const [variableNames, setVariableNames] = useState(null);
  const [SymbolList, setSymbolList] = useState([]);
  const [calcForm] = Form.useForm();

  const closingSequence = () => {
    closeCalcAddModal();

    setTimeout(() => {
      setCalcToUse(null);
      setChildNames([]);
      calcName.current = null;
      childType.current = null;
      calcForm.resetFields();
    }, 500);
  };

  const handelOk = () => {
    const id = uuid();
    let newTemplate = { ...currentTemplate };

    const calcOutputs = {
      outputType:
        childType.current !== null
          ? childType.current
          : currentTemplate.device_type_name,
      outputVariable: calcForm.getFieldValue("outputVariable"),
      outputSymbol: calcForm.getFieldValue("symbol"),
    };

    let calcInfo = {
      ...calcName.current,
      key: id,
      calculationID: calcToUse._id,
      calculationOutputs: [calcOutputs],
    };

    if (calcInfo.outputVariable) delete calcInfo.outputVariable;
    if (calcInfo.calculationDescription) delete calcInfo.calculationDescription;
    if (calcInfo.childType) delete calcInfo.childType;
    if (calcInfo.symbol) delete calcInfo.symbol;

    if (calcToUse.childName) calcInfo.childName = calcToUse.childName;

    // check to see if there are any calcs on this template
    if (newTemplate.Calculations) {
      // check to see if this is an edit
      if (modalData) {
        const index = newTemplate.Calculations.findIndex(
          (calc) => calc.key === modalData.key
        );

        // we matched, changed the matched one only
        if (index !== -1) {
          newTemplate.Calculations[index].constants =
            calcName.current.constants;
          newTemplate.Calculations[index].calculationOutputs = calcOutputs;
        } else {
          newTemplate.Calculations.push(calcInfo);
        }
      } else {
        newTemplate.Calculations.push(calcInfo);
      }
    } else {
      newTemplate.Calculations = [calcInfo];
    }

    updateTemplateCalc(newTemplate.Calculations);
    closingSequence();
  };

  const handelCancel = () => {
    closingSequence();
  };

  const handelCalcNameSelect = (value) => {
    if (value) {
      const calcToUse = availableCalcs.find(
        (calc) => calc.calculationName === value
      );

      setCalcToUse(calcToUse);
      calcForm.setFieldsValue({
        calculationDescription: calcToUse.calculationDescription,
      });
      checkUpdateChildNamesSymbols(calcToUse);
      checkTypeToVar(calcToUse);

      if (
        calcName.current === null ||
        calcName.current.calculationName !== calcToUse.calculationName
      ) {
        setConstantFromCalc(calcToUse);
      }
    } else {
      calcForm.setFieldsValue({
        calculationDescription: "",
      });
      setCalcToUse({});
    }
  };

  const checkUpdateChildNamesSymbols = () => {
    const chosenVar = calcForm.getFieldValue("outputVariable");

    let list = allTypes.filter(
      (listItems) =>
        listItems.device_type_name === currentTemplate.device_type_name
    )[0];

    list = list.vairables;

    // clear the array
    setSymbolList([]);
    setChildNames([]);
    // if there was another type
    if (childType.current) childType.current = null;

    if (chosenVar) {
      const sym = list.filter((item) => item.variable === chosenVar)[0];

      let HasArray = "";
      let HasArrayChildren = "";

      if (sym) {
        if (sym.symbol) {
          HasArray = sym.symbol.includes(",");

          if (HasArray) {
            HasArray = sym.symbol.split(",");
            setSymbolList(HasArray);
            HasArray = true;
          } else {
            HasArray = false;
            calcForm.setFields([{ name: "symbol", value: sym.symbol }]);
          }

          if (sym.ExtraChildren !== undefined) {
            HasArrayChildren = sym.ExtraChildren.name.includes(",");

            if (HasArrayChildren) {
              HasArrayChildren = sym.ExtraChildren.name.split(",");
              setChildNames(HasArrayChildren);
              HasArrayChildren = true;
            } else {
              HasArrayChildren = false;
            }

            childType.current = sym.ExtraChildren.type;
          }
        }
      }
    } else {
      calcForm.setFields([{ name: "symbol", value: "" }]);
      calcForm.setFields([{ name: "childName", value: "" }]);
    }
  };

  const checkTypeToVar = () => {
    let list = allTypes.filter(
      (listItems) =>
        listItems.device_type_name === currentTemplate.device_type_name
    )[0];

    setVariableNames(list.vairables);
  };

  const setConstantFromCalc = (calcData) => {
    if (availableCalcs) {
      const indexOfCalc = availableCalcs.findIndex(
        (calc) => calc.calculationName === calcData.calculationName
      );

      if (indexOfCalc !== -1) {
        calcForm.setFieldsValue({
          constants: [...availableCalcs[indexOfCalc].constant],
        });
      }
    }
  };

  // Calculation Types select
  const CalcTypeList =
    availableCalcs !== null
      ? availableCalcs.map(
          (calc, _) =>
            calc.isTemplateOnly && (
              <Select.Option value={calc.calculationName} key={calc._id}>
                {calc.calculationName}
              </Select.Option>
            )
        )
      : null;

  // If there is more than one symbol show the list
  const createVarList =
    variableNames &&
    variableNames.map((lists, index) => {
      return (
        <Select.Option value={lists.variable} key={index}>
          {lists.variable}
        </Select.Option>
      );
    });

  // If there is more than one symbol show the list
  const createChildList =
    childNames &&
    childNames.map((lists, index) => {
      return (
        <Select.Option value={lists} key={index}>
          {lists}
        </Select.Option>
      );
    });

  // If there is more than one symbol show the list
  const createSymbolList = SymbolList.map((lists, index) => {
    return (
      <Select.Option value={lists} key={index}>
        {lists}
      </Select.Option>
    );
  });

  if (modalVis) {
    if (modalData !== null) {
      if (calcName.current === null) {
        calcName.current = modalData;
        if (modalData.calculationName)
          calcForm.setFieldsValue({ childName: modalData.calculationName });
        calcForm.setFieldsValue({
          calculationName: modalData.calculationName,
          constants: modalData.constants,
          outputVariable: modalData.calculationOutputs[0].outputVariable,
          symbol: modalData.calculationOutputs[0].outputSymbol,
          childName: modalData.childName,
        });
        handelCalcNameSelect(modalData.calculationName);
      }
    }
  }

  return (
    <Modal
      visible={modalVis}
      title={modalData ? "Editing calculation" : "Add new calculation"}
      onCancel={handelCancel}
      onOk={handelOk}
      width={700}
      okButtonProps={{
        disabled:
          calcName.current === null
            ? true
            : (calcName.current.calculationName === "") |
              (calcName.current.calculationName === undefined)
            ? true
            : false,
      }}
    >
      <Form
        form={calcForm}
        size="small"
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 10 }}
        onValuesChange={(_, All) => {
          handelCalcNameSelect(All.calculationName);
          calcName.current = All;
        }}
      >
        <Form.Item
          label="Calculation"
          name="calculationName"
          style={{ marginBottom: "5px" }}
        >
          <Select allowClear showSearch disabled={modalData !== null}>
            {CalcTypeList}
          </Select>
        </Form.Item>
        {calcToUse && calcToUse.calculationName && (
          <Form.Item
            label="Description"
            name="calculationDescription"
            style={{ marginBottom: "5px" }}
          >
            <Input.TextArea autoSize={{ minRows: 3, maxRows: 4 }} disabled />
          </Form.Item>
        )}
        {calcToUse && calcToUse.constant && (
          <Fragment>
            <Divider style={{ marginBottom: "5px" }}>
              Calculation Inputs
            </Divider>

            <Form.List name="constants">
              {(fields, { add, remove }) => {
                return (
                  <>
                    {fields.map((data, index) => (
                      <Form.Item
                        {...data}
                        name={[data.name, "value"]}
                        fieldKey={[
                          data.fieldKey,
                          calcToUse.constant[index].key,
                        ]}
                        style={{ marginBottom: "5px" }}
                        label={calcToUse && calcToUse.constant[index].name}
                      >
                        {calcToUse && calcToUse.constant[index].listSelect ? (
                          <Select
                            allowClear
                            showSearch
                            mode={
                              calcToUse &&
                              calcToUse.constant[index].allowMultiples
                                ? "multiple"
                                : "none"
                            }
                          >
                            {variableNames &&
                              variableNames.map((dev, indexKey) => (
                                <Select.Option
                                  key={indexKey}
                                  value={dev.variable}
                                >
                                  {dev.variable}
                                </Select.Option>
                              ))}
                          </Select>
                        ) : (
                          <InputNumber />
                        )}
                      </Form.Item>
                    ))}
                  </>
                );
              }}
            </Form.List>
          </Fragment>
        )}
        {calcToUse && (
          <Fragment>
            <Divider style={{ marginBottom: "5px" }}>
              Calculation Output
            </Divider>
            <Form.Item
              label="Output"
              name="outputVariable"
              style={{ marginBottom: "5px" }}
            >
              <Select
                allowClear
                showSearch
                onChange={checkUpdateChildNamesSymbols}
              >
                {createVarList}
              </Select>
            </Form.Item>
            {childNames.length > 0 && (
              <Form.Item
                label="Child"
                name="childName"
                style={{ marginBottom: "5px" }}
              >
                <Select allowClear showSearch>
                  {createChildList}
                </Select>
              </Form.Item>
            )}
            <Form.Item label="Symbol" name="symbol">
              {SymbolList.length === 0 ? (
                <Input disabled />
              ) : (
                <Select>{createSymbolList}</Select>
              )}
            </Form.Item>
          </Fragment>
        )}
      </Form>
    </Modal>
  );
};

NewTemplateCalcModal.propTypes = {
  modalVis: PropTypes.bool,
  modalData: PropTypes.object,
  closeCalcAddModal: PropTypes.func.isRequired,
  updateTemplateCalc: PropTypes.func.isRequired,
  availableCalcs: PropTypes.array,
  allTypes: PropTypes.array,
  currentTemplate: PropTypes.object,
};

const mapStateToProps = (state) => ({
  modalVis: state.template.modalVisCalcAdd,
  modalData: state.template.modalVisCalcAddData,
  availableCalcs: state.calcs.allCalcs,
  allTypes: state.deviceTypes.types_detailed,
  currentTemplate: state.template.newTemplate,
});

export default connect(mapStateToProps, {
  closeCalcAddModal,
  updateTemplateCalc,
})(NewTemplateCalcModal);
