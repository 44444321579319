import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// ant desing components
import { Typography, Tooltip, Button, Table, Space, Popconfirm } from "antd";

// Functions
import {
  openTypeAddAlaModal,
  updateCurrentType,
} from "../../actions/deviceTypes";

// other components
import ModalAddAlarm from "./modalAlarmStates";

const { Title, Paragraph, Text } = Typography;

// acutal component
const DeviceAlarmsStates = ({
  openTypeAddAlaModal,
  editTypeDetials,
  updateCurrentType,
  permissions,
}) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    let test = [];

    if (editTypeDetials) {
      if (editTypeDetials.alarms) {
        editTypeDetials.alarms.forEach((varItem, indexNumber) =>
          test.push({ ...varItem, key: indexNumber })
        );
      }
    }

    setTableData(test);
  }, [editTypeDetials]);

  // table cloumb
  const columnsTable = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record) => (
        <Space size="middle">
          {permissions && permissions.delete && (
            <Popconfirm
              title={"Delete " + record.name + " ?"}
              onConfirm={() => handleDelete(record)}
            >
              <a href="#!">Delete</a>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  const handleDelete = (item) => {
    let newList = tableData;

    newList = newList.filter((listitem) => listitem.key !== item.key);

    updateCurrentType({ ...editTypeDetials, alarms: newList });
  };

  const handelAdd = () => {
    openTypeAddAlaModal();
  };

  return (
    <Fragment>
      <Typography>
        <Title level={3}>Alarms or States</Title>
        <Paragraph>
          Below is a list of alarms or states associated to this type, use the{" "}
          <Text keyboard>Add Item</Text> button to add a new alarm or state to
          the type. list
        </Paragraph>
      </Typography>
      <Tooltip title="Press to add a new alarm/state to this type">
        <Button style={{ margin: "10px" }} onClick={handelAdd}>
          Add Item
        </Button>
      </Tooltip>

      <ModalAddAlarm />

      <Table
        dataSource={tableData}
        bordered
        columns={columnsTable}
        scroll={{ y: 300 }}
        size="small"
        pagination={{
          defaultPageSize: 30,
          showSizeChanger: true,
          pageSizeOptions: ["30", "40", "50"],
        }}
      ></Table>
    </Fragment>
  );
};

DeviceAlarmsStates.propTypes = {
  editTypeDetials: PropTypes.array,
  updateCurrentType: PropTypes.func.isRequired,
  permissions: PropTypes.object,
};

const mapStateToProps = (state) => ({
  editTypeDetials: state.deviceTypes.editTypeDetials,
  permissions: state.auth.permissions,
});

export default connect(mapStateToProps, {
  openTypeAddAlaModal,
  updateCurrentType,
})(DeviceAlarmsStates);
