import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchTemplates } from "../../actions/templates";
import PropTypes from "prop-types";
import axios from "axios";

// Ant design components
import { Table, Button, Tooltip } from "antd";

// Components
// import CreateNewModal from "./createNewModal";
import CreateNewTemplateModal from "./newTemplateModal";
import Spinner from "../layout/Spinner";

// external Functions
import {
  OpenModal,
  createInitalTemplate,
  updateTemplates,
} from "../../actions/templates";
import { getAllCalculation } from "../../actions/calculations";

// Actual componet
const Layout = ({
  createInitalTemplate,
  OpenModal,
  fetchTemplates,
  auth: { user, loading },
  template: { dataloaded, templates },
  updateTemplates,
  getAllCalculation,
  permissions,
}) => {
  const [deviceTypes, setdeviceTypes] = useState([]);

  const columns = [
    {
      title: "Device Name",
      dataIndex: "device_type_name",
      key: "device_type_name",
      render: (text, record) => (
        // <Link to={"/tools/plugin/devtemp/" + record._id}>{text}</Link>
        <a href="#!" onClick={() => HandleEditTemplate(record)}>
          {text}
        </a>
      ),
      filters: deviceTypes.map((item) => ({
        text: item,
        value: item,
      })),
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      onFilter: (value, record) => record.device_type_name.indexOf(value) === 0,
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      responsive: ["md"],
    },
    {
      title: "Maunfacture",
      dataIndex: "maunfacture",
      key: "maunfacture",
      responsive: ["lg"],
    },
    {
      title: "Protocol",
      dataIndex: "commProtocol",
      key: "commProtocol",
      responsive: ["lg"],
      filters: [
        {
          text: "Modbus",
          value: "Modbus",
        },
        {
          text: "SNMP",
          value: "SNMP",
        },
      ],
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      onFilter: (value, record) => record.commProtocol.indexOf(value) === 0,
    },
    {
      title: "Date Created",
      dataIndex: "createdAt",
      key: "createdAt",
      responsive: ["lg"],
      render: (text) => <p>{text.replace("T", " ").replace("Z", "")}</p>,
      defaultSortOrder: "descend",
      sorter: (a, b) => a.createdAt - b.createdAt,
    },
    {
      title: "Updated At",
      key: "updatedAt",
      dataIndex: "updatedAt",
      responsive: ["lg"],
      render: (text) => <p>{text.replace("T", " ").replace("Z", "")}</p>,
      defaultSortOrder: "descend",
      sorter: (a, b) => a.updatedAt - b.updatedAt,
    },
  ];

  useEffect(() => {
    const getDevTypes = async () => {
      const res = await axios.get("/api/devtemplates/devicetypes");
      // const res = await axios.get(
      //   "http://localhost:5000/api/devtemplates/devicetypes"
      // );

      setdeviceTypes(res.data);
    };

    getDevTypes();

    fetchTemplates();
    getAllCalculation();
  }, [fetchTemplates, getAllCalculation]);

  const HandleEditTemplate = (record) => {
    // createInitalTemplate({ ...record });
    createInitalTemplate(record);
    OpenModal(true);
  };

  const HandleCreateNew = () => {
    OpenModal();
  };

  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <h1 className="large text-primary">Devices Templates</h1>
      <Table
        columns={columns}
        size="small"
        dataSource={templates}
        pagination={{ pageSize: 40 }}
        scroll={{ y: 600 }}
        loading={!dataloaded && templates === null ? true : false}
      />

      {permissions && permissions.update && (
        <Tooltip title="This will ask the server for all its templates">
          <Button type="primary" onClick={updateTemplates}>
            Update
          </Button>
        </Tooltip>
      )}

      {/* <CreateNewModal /> */}
      <CreateNewTemplateModal />

      {permissions && permissions.write && (
        <Tooltip title="Create a brand new template">
          <Button
            type="primary"
            style={{ margin: "10px" }}
            onClick={HandleCreateNew}
          >
            Create New
          </Button>
        </Tooltip>
      )}
    </Fragment>
  );
};

Layout.propTypes = {
  createInitalTemplate: PropTypes.func.isRequired,
  OpenModal: PropTypes.func.isRequired,
  updateTemplates: PropTypes.func.isRequired,
  fetchTemplates: PropTypes.func.isRequired,
  getAllCalculation: PropTypes.func.isRequired,
  template: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  permissions: PropTypes.object,
};

const mapStateToProps = (state) => ({
  template: state.template,
  auth: state.auth,
  permissions: state.auth.templatePermissions,
});

export default connect(mapStateToProps, {
  createInitalTemplate,
  fetchTemplates,
  OpenModal,
  updateTemplates,
  getAllCalculation,
})(Layout);
