import React, { Fragment, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "antd/dist/antd.css";
import "./App.css";
import setAuthToken from "./utils/setAuthToken";

// Components
import Navbar from "./components/navbar/navbar";
import Layout from "./components/layout/Layout";
import Contents from "./components/layout/Content";
import Footers from "./components/layout/Footer";
import Landing from "./components/layout/Landing";
import Dashboard from "./components/dashboard/dashboard";
import siteWizard from "./components/siteWizard/newSiteWizard";
import PrivateRoute from "./components/routing/PrivateRoute";
import Alert from "./components/layout/Alert";
import ModbusConfig from "./components/tools/config/modbus/landing";
import ModbusConfigDetails from "./components/tools/config/modbus/details";
import ModbusNewConfig from "./components/tools/config/modbus/newConfig";
import DevTemplates from "./components/DeviceTemplates/layout";
import Clients from "./components/clients/Layout";
import DevTemplatesDetails from "./components/DeviceTemplates/details";
import Users from "./components/users/users";
import DiscoverMPT from "./components/DiscoveryTool/discovery";
import ModbusPoll from "./components/ModbusPoll/ModbusPoll";
// import DevType from "./components/DeviceTemplates/deviceType";
import DevType from "./components/deviceTypes/index";
import DeviceMaunfacture from "./components/DeviceTemplates/deviceMaunfacture";
import DeviceConfig from "./components/tools/config/devices/Devices";
import DeviceGroups from "./components/groups/index";
import AlarmsConfig from "./components/tools/config/alarms/Alarms";
import SettingsPage from "./components/settings/settings";
import CalculationsPage from "./components/Calulations/Calculation";
import StatisticsPage from "./components/tools/config/advanced/advanced";
import DistributionPage from "./components/distribution/index";
import DeviceInformation from "./components/tools/DeviceInfo/index";

// Redux
import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./actions/auth";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Fragment>
          <Navbar />
          <Layout>
            <Alert />
            <Contents>
              <Route exact path="/" component={Landing} />
              <Switch>
                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                <PrivateRoute exact path="/siteWizard" component={siteWizard} />
                <PrivateRoute
                  exact
                  path="/tools/plugin/devtemp"
                  component={DevTemplates}
                />
                <PrivateRoute
                  exact
                  path="/tools/plugin/calcs"
                  component={CalculationsPage}
                />
                <PrivateRoute
                  exact
                  path="/tools/devinfo"
                  component={DeviceInformation}
                />
                <PrivateRoute
                  exact
                  path="/tools/config/statistics"
                  component={StatisticsPage}
                />
                <PrivateRoute
                  exact
                  path="/tools/config/distribution"
                  component={DistributionPage}
                />
                <PrivateRoute exact path="/settings" component={SettingsPage} />
                <PrivateRoute
                  exact
                  path="/tools/plugin/groups"
                  component={DeviceGroups}
                />
                <PrivateRoute
                  exact
                  path="/tools/config/devices"
                  component={DeviceConfig}
                />
                <PrivateRoute
                  exact
                  path="/tools/plugin/clients"
                  component={Clients}
                />
                <PrivateRoute
                  exact
                  path="/tools/plugin/devtemp/:id"
                  component={DevTemplatesDetails}
                />
                <PrivateRoute
                  exact
                  path="/tools/config/modbus"
                  component={ModbusConfig}
                />
                <PrivateRoute
                  exact
                  path="/tools/config/alarms"
                  component={AlarmsConfig}
                />
                <PrivateRoute
                  exact
                  path="/tools/config/modbus/:id"
                  component={ModbusConfigDetails}
                />
                <PrivateRoute
                  exact
                  path="/tools/config/new/modbus"
                  component={ModbusNewConfig}
                />
                <PrivateRoute
                  exact
                  path="/tools/plugin/users"
                  component={Users}
                />
                <PrivateRoute
                  exact
                  path="/tools/discover"
                  component={DiscoverMPT}
                />
                <PrivateRoute
                  exact
                  path="/tools/mpoll"
                  component={ModbusPoll}
                />
                <PrivateRoute
                  exact
                  path="/tools/plugin/devtype"
                  component={DevType}
                />
                <PrivateRoute
                  exact
                  path="/tools/plugin/devmanu"
                  component={DeviceMaunfacture}
                />
              </Switch>
            </Contents>
            <Footers />
          </Layout>
        </Fragment>
      </Router>
    </Provider>
  );
};

export default App;
