import React, { Fragment } from "react";
import { connect } from "react-redux";

// antd
import { Row, Col, Typography, Form, Input, Button } from "antd";
import { DesktopOutlined } from "@ant-design/icons";

const { Title, Paragraph } = Typography;

// acutal component
const SiteFinishing = () => {
  const openInNewTab = (url) => {
    let newURL = url.split(":");

    const fullurl = newURL[0] + ":" + newURL[1] + ":80";

    const newWindow = window.open(fullurl, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <Fragment>
      <Row style={{ marginBottom: "20px" }}>
        <Col sm={0} lg={5} xl={7}></Col>
        <Col sm={24} lg={14} xl={10}>
          <Typography style={{ textAlign: "center" }}>
            <Title>🎉 Finish 🎈</Title>
            <Title level={2}>Well done!! 🥳 We are complete</Title>

            <Paragraph style={{ textAlign: "left" }}>
              We are all set up and ready to go, give your UC a unique name,
              this will be the name if you discover on the network
            </Paragraph>
          </Typography>

          <Form
            labelCol={{ span: 5, offset: 2 }}
            wrapperCol={{ span: 15 }}
            onValuesChange={(_, value) => {
              sessionStorage.setItem(
                "wizardDiscoveryName",
                value.discoveryName
              );
            }}
          >
            <Form.Item
              label="UC Name"
              name="discoveryName"
              tooltip="This will be the name that will display on the discovery tool"
            >
              <Input style={{ width: 200 }} maxLength={25} />
            </Form.Item>
          </Form>

          <Row>
            <Col>
              <Paragraph style={{ textAlign: "left" }}>
                You will now need to configure the HMI, use this button
              </Paragraph>
            </Col>
            <Col span={1}></Col>
            <Col>
              <div>
                <Button
                  size="small"
                  style={{ margin: "2px" }}
                  icon={<DesktopOutlined />}
                  onClick={() => {
                    openInNewTab(window.location.href);
                  }}
                >
                  HMI
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
};

export default connect()(SiteFinishing);
