import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Ant d componets
import { Modal, Form, Select, Input, Divider, InputNumber } from "antd";

// extrnal functions
import {
  modalAddNewClose,
  saveConfigToList,
} from "../../../../../actions/advanced";

// Actual componet
const ModalAddNew = ({
  modalVis,
  modalAddNewClose,
  availableCalcs,
  devicesAvailable,
  saveConfigToList,
  modalData,
}) => {
  const [advConfig, setAdvConfig] = useState(null);
  const [selectedcalc, setSelectedcalc] = useState();
  const [selectableDevices, setSelectableDevices] = useState();
  const [advConfigForm] = Form.useForm();

  const closingSequence = () => {
    modalAddNewClose();

    setTimeout(() => {
      setAdvConfig(null);
      setSelectedcalc(null);
      setSelectableDevices(null);
      advConfigForm.resetFields();
    }, 200);
  };

  const handleOk = () => {
    if (modalData === null) {
      saveConfigToList({ ...advConfig, calculationID: selectedcalc._id });
    } else {
      saveConfigToList(
        { ...advConfig, calculationID: selectedcalc._id, key: modalData.key },
        true
      );
    }
    closingSequence();
  };

  const handleCancel = () => {
    closingSequence();
  };

  // Calculation Types select
  const CalcTypeList =
    availableCalcs !== null
      ? availableCalcs.map(
          (calc, _) =>
            calc.noInputRequired && (
              <Select.Option value={calc.calculationName} key={calc._id}>
                {calc.calculationName}
              </Select.Option>
            )
        )
      : null;

  const setCalcDetails = (calcData) => {
    if (calcData) {
      advConfigForm.setFieldsValue({
        constants: calcData.constant,
      });

      advConfigForm.setFieldsValue({
        calculationDescription: calcData.calculationDescription,
      });

      let totalDeviceTypes = [];

      calcData.constant.map((item) => {
        let inividualDeviceTypes = [];

        if (item.types && item.types.length > 0) {
          if (devicesAvailable) {
            devicesAvailable.map((device) => {
              if (device.device_type === item.types) {
                inividualDeviceTypes.push({ name: device.device_name });
              }
              return true;
            });
          }
        } else {
          if (item.listSelect) {
            devicesAvailable.map((device) => {
              inividualDeviceTypes.push({ name: device.device_name });
              return true;
            });
          }
        }

        totalDeviceTypes.push([...inividualDeviceTypes]);

        return true;
      });
      setSelectableDevices(totalDeviceTypes);
    }
  };

  const findCalc = (calcName) => {
    if (calcName) {
      const calc = availableCalcs.find(
        (item) => item.calculationName === calcName.calculationName
      );

      setSelectedcalc(calc);

      return calc;
    }
  };

  if (modalVis) {
    if (modalData && modalData !== advConfig) {
      if (!selectableDevices) {
        setAdvConfig({ ...modalData });
        const calc = findCalc(modalData);
        setCalcDetails(calc);
        setTimeout(() => advConfigForm.resetFields(), 200);
      }
    }
  }

  return (
    <Modal
      onCancel={handleCancel}
      onOk={handleOk}
      title={
        modalData === null
          ? "Add new statistical configuration"
          : "Editing statistical configuration"
      }
      afterClose={() => {
        setAdvConfig(null);
        setSelectedcalc(null);
        setSelectableDevices(null);
        advConfigForm.resetFields();
      }}
      visible={modalVis}
      width={800}
      okButtonProps={{
        disabled:
          advConfig === null || (advConfig && !advConfig.calculationName),
      }}
    >
      <Form
        form={advConfigForm}
        size="small"
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 10 }}
        initialValues={advConfig}
        onValuesChange={(_, All) => {
          if (All) {
            if (
              advConfig === null ||
              (advConfig && All.calculationName !== advConfig.calculationName)
            ) {
              const calc = findCalc(All);

              if (calc) {
                setCalcDetails(calc);
              } else {
                advConfigForm.setFieldsValue({
                  constants: "",
                });

                advConfigForm.setFieldsValue({
                  calculationDescription: "",
                });
              }
            }
          }

          setAdvConfig(All);
        }}
      >
        <Form.Item label="Calculation" name="calculationName">
          <Select allowClear showSearch>
            {CalcTypeList}
          </Select>
        </Form.Item>
        {advConfig && advConfig.calculationName && (
          <Fragment>
            <Form.Item label="Description" name="calculationDescription">
              <Input.TextArea rows={3} disabled />
            </Form.Item>

            <Divider>Input Parameters</Divider>

            <Form.List name="constants">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((data, index) => (
                    <Form.Item
                      {...data}
                      name={[data.name, "value"]}
                      key={[data.key, index]}
                      label={selectedcalc && selectedcalc.constant[index].name}
                    >
                      {selectedcalc &&
                      selectedcalc.constant[index].listSelect ? (
                        selectedcalc.constant[index].allowMultiples ? (
                          <Select mode="multiple" allowClear showSearch>
                            {selectableDevices &&
                              selectableDevices[index].map((dev, index) => (
                                <Select.Option
                                  key={index}
                                  value={dev && dev.name}
                                >
                                  {dev && dev.name}
                                </Select.Option>
                              ))}
                          </Select>
                        ) : (
                          <Select allowClear showSearch>
                            {selectableDevices &&
                              selectableDevices[index].map((dev, index) => (
                                <Select.Option key={index}>
                                  {dev.name}
                                </Select.Option>
                              ))}
                          </Select>
                        )
                      ) : (
                        <InputNumber />
                      )}
                    </Form.Item>
                  ))}
                </>
              )}
            </Form.List>
          </Fragment>
        )}
      </Form>
    </Modal>
  );
};

ModalAddNew.propTypes = {
  modalVis: PropTypes.bool,
  modalAddNewClose: PropTypes.func.isRequired,
  saveConfigToList: PropTypes.func.isRequired,
  availableCalcs: PropTypes.array,
  devicesAvailable: PropTypes.array,
  modalData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  modalVis: state.advanced.modalAddNewVis,
  modalData: state.advanced.modalAddNewData,
  availableCalcs: state.calcs.allCalcs,
  devicesAvailable: state.deviceList.deviceList,
});

export default connect(mapStateToProps, { modalAddNewClose, saveConfigToList })(
  ModalAddNew
);
