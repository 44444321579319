import axios from "axios";
import { CONFIG_SNMP_UPDATE, CONFIG_SNMP_GET } from "./types";
import { setAlert } from "./alert";
import setAuthToken from "../utils/setAuthToken";

export const updateSNMPBackend = (data, isNew) => async (dispatch) => {
  try {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (isNew) data.version = 0;

    await axios.post("/api/SNMP", data, config);

    if (isNew) dispatch(setAlert("New SNMP config added", "success"));
    else dispatch(setAlert("SNMP config updated", "success"));

    dispatch({
      type: CONFIG_SNMP_UPDATE,
    });
  } catch (error) {}
};

export const fetchConfigurationsList = () => async (dispatch) => {
  try {
    const SNMPConfigurationList = await axios.get("/api/SNMP");

    dispatch({
      type: CONFIG_SNMP_GET,
      payload: SNMPConfigurationList.data,
    });
  } catch (error) {}
};
