import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// antd components
import { Menu } from "antd";
import { SettingOutlined } from "@ant-design/icons";

// Other functions
import { logout } from "../../actions/auth";

// actual function
const RightMenu = ({ auth: { isAuthenticated, loading }, logout, newSite }) => {
  return (
    <Menu mode="horizontal">
      {!newSite && !loading && isAuthenticated && (
        <Menu.Item key="TakeMeToSettings">
          <Link to="/settings">
            <SettingOutlined />
          </Link>
        </Menu.Item>
      )}
      {!loading && isAuthenticated && (
        <Menu.Item key="LogMEOUTDDD">
          <a onClick={logout} href="#!">
            Sign Out
          </a>
        </Menu.Item>
      )}
    </Menu>
  );
};

RightMenu.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  newSite: PropTypes.bool,
};

const mapStateToProp = (state) => ({
  auth: state.auth,
  newSite: state.site.newSite,
});

export default connect(mapStateToProp, { logout })(RightMenu);
