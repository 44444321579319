import React, { Fragment } from "react";
import spinner from "../../img/spinner.gif";

const spin = () => (
  <Fragment>
    <img
      src={spinner}
      style={{ width: "200", display: "block", margin: "auto" }}
      alt="Loading"
    />
  </Fragment>
);

export default spin;
