import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from "axios";

// Ant design
import { Select, Form, Modal, Input, Divider, Switch, InputNumber } from "antd";

// Icons
import { PlusOutlined } from "@ant-design/icons";

// External Functions
import {
  CloseEditDigitalInputModal,
  updateTempTypeNameList,
  updateDeviceDetails,
} from "../../../../../actions/deviceList";

// Actual component
const ModalEditFeild = ({
  CloseEditDigitalInputModal,
  updateTempTypeNameList,
  updateDeviceDetails,
  modalVis,
  currentDeviceListDetails,
  recordToEdit,
  tempTypeNameList,
  allGroups,
  siteFloors,
  deviceTypesDetailed,
  allBusses,
}) => {
  const [deviceName, setDeviceName] = useState("");
  const [formEditInputDetails] = Form.useForm();
  const [bussName, setBussName] = useState("");
  const [bussNames, setBussNames] = useState(allBusses);
  const [groupInputName, setGroupInputName] = useState("");
  const [groupsNames, setGroupsNames] = useState(allGroups);
  const [sensorDetails, setSensorDetails] = useState(recordToEdit);
  const [alarmsNames, setAlarmsNames] = useState([]);

  const closingSequence = () => {
    CloseEditDigitalInputModal();
  };

  const handelCancel = () => {
    closingSequence();
  };

  const handelOK = () => {
    // input defences
    if (sensorDetails) {
      if (
        sensorDetails.deviceType &&
        sensorDetails.deviceName &&
        sensorDetails.alarmName &&
        sensorDetails.alarmPriority &&
        sensorDetails.alarmDelayOn > 0
      ) {
        let allInputsData = [...currentDeviceListDetails];

        const inputIndex = currentDeviceListDetails.findIndex(
          (Input) => Input.input === recordToEdit.input
        );

        allInputsData.splice(inputIndex, 1, {
          ...recordToEdit,
          ...sensorDetails,
          alarmOperation: "=",
          fixed: true,
        });

        // add the details
        setTimeout(() => {
          updateDeviceDetails({ childNames: allInputsData });
        }, 200);
        // Close the modal
        CloseEditDigitalInputModal();
      }
    }
  };

  // this is the types names list
  const handleAddDeviceName = () => {
    if (deviceName !== "") {
      let names = [...tempTypeNameList];

      names.push({
        name: deviceName,
        type: sensorDetails.deviceType,
      });
      updateTempTypeNameList(names);
      setDeviceName("");
    }
  };

  // Adding a group name to the list of groups from the select
  const HandelAddGroupName = async () => {
    if (groupInputName !== "") {
      try {
        let newGroupList = [...groupsNames];

        // save new group item to the DB
        await axios.post("/api/site/groups", {
          name: groupInputName,
        });

        newGroupList.push(groupInputName);
        setGroupsNames(newGroupList);
      } catch (error) {
        console.log(error);
      }

      setGroupInputName("");
    }
  };

  // Adding a buss name to the list of buss's from the select
  const HandelAddBussName = async () => {
    if (bussName !== "") {
      try {
        // save new buss item to the DB
        const dbBuss = await axios.post("/api/site/buss", {
          name: bussName,
        });

        setBussNames(dbBuss.data);
      } catch (error) {
        console.log(error);
      }

      setBussName("");
    }
  };

  // Device Types select
  const DeviceTypeList =
    deviceTypesDetailed !== null
      ? deviceTypesDetailed.map((device, index) => (
          <Select.Option value={device.device_type_name} key={index}>
            {device.device_type_name}
          </Select.Option>
        ))
      : null;

  // Floors select
  const floorSelect = siteFloors
    ? siteFloors.map((device, index) => (
        <Select.Option value={device.name} key={index}>
          {device.name}
        </Select.Option>
      ))
    : null;

  // Alarm Name select
  const alarmNames =
    alarmsNames !== null
      ? alarmsNames.map((alarm, index) => (
          <Select.Option value={alarm.name} key={index}>
            {alarm.name}
          </Select.Option>
        ))
      : null;

  if (modalVis) {
    if (sensorDetails === null) {
      setSensorDetails(recordToEdit);

      setTimeout(() => {
        if (recordToEdit && recordToEdit.deviceType) {
          const typeIndex = deviceTypesDetailed.findIndex(
            (type) => type.device_type_name === recordToEdit.deviceType
          );

          // match found
          if (typeIndex !== -1) {
            setAlarmsNames(deviceTypesDetailed[typeIndex].alarms);
          }
        }
        formEditInputDetails.resetFields();
      }, 200);
    }
  } else {
    if (sensorDetails !== null) {
      setSensorDetails(null);
      formEditInputDetails.resetFields();
    }
  }

  return (
    <Modal
      title={recordToEdit && "Editing " + recordToEdit.input}
      visible={modalVis}
      width={800}
      onCancel={handelCancel}
      onOk={() => formEditInputDetails.validateFields().then(handelOK())}
    >
      <Form
        form={formEditInputDetails}
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 9 }}
        layout="horizontal"
        initialValues={recordToEdit}
        onValuesChange={(_, all) => {
          // set the correct alarms list when user selects the type
          if (all.deviceType) {
            // check to see if the user has changed the type midway
            if (sensorDetails && all.deviceType !== sensorDetails.deviceType) {
              // save the new type else it is going to get overwritten
              const newType = all.deviceType;
              formEditInputDetails.resetFields();
              formEditInputDetails.setFieldsValue({ deviceType: newType });
              formEditInputDetails.scrollToField("alarmName");
            } else {
              const typeIndex = deviceTypesDetailed.findIndex(
                (type) => type.device_type_name === all.deviceType
              );

              // match found
              if (typeIndex !== -1) {
                setAlarmsNames(deviceTypesDetailed[typeIndex].alarms);
              }

              // set a default on time and off time if not set
              if (all.alarmName) {
                // On time
                if (
                  all.alarmDelayOn === undefined ||
                  all.alarmDelayOn === null
                ) {
                  formEditInputDetails.setFieldsValue({ alarmDelayOn: 10 });
                }

                // Off time
                if (
                  all.alarmDelayOff === undefined ||
                  all.alarmDelayOff === null
                ) {
                  formEditInputDetails.setFieldsValue({ alarmDelayOff: 10 });
                }

                // Trigger switch
                if (all.alarmLevel === undefined || all.alarmLevel === null) {
                  formEditInputDetails.setFieldsValue({
                    alarmLevel: 0,
                  });
                } else {
                  if (all.alarmLevel) all.alarmLevel = 1;
                  else all.alarmLevel = 0;
                }
              }
            }
          } else {
            formEditInputDetails.resetFields();
            formEditInputDetails.scrollToField("deviceType");
          }

          setSensorDetails({ ...sensorDetails, ...all });
        }}
        size="small"
      >
        <Form.Item
          label="Device Type"
          name="deviceType"
          rules={[{ required: true, message: "Can't be blank" }]}
          style={{ marginBottom: "5px" }}
        >
          <Select allowClear showSearch>
            {DeviceTypeList}
          </Select>
        </Form.Item>
        {sensorDetails?.deviceType && (
          <Fragment>
            <Form.Item
              label={
                sensorDetails?.deviceType === "Breaker"
                  ? "Breaker Name"
                  : "Device Name"
              }
              name="deviceName"
              rules={[{ required: true, message: "Can't be blank" }]}
              style={
                sensorDetails?.deviceType === "Breaker" && {
                  marginBottom: "5px",
                }
              }
            >
              <Select
                allowClear
                showSearch
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <Divider style={{ margin: "4px 0" }} />
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        padding: 8,
                      }}
                    >
                      <Input
                        style={{ flex: "auto" }}
                        value={deviceName}
                        onChange={(event) => setDeviceName(event.target.value)}
                      />
                      <a
                        href="#!"
                        style={{
                          flex: "none",
                          padding: "5px",
                          display: "block",
                          cursor: "pointer",
                        }}
                        onClick={handleAddDeviceName}
                      >
                        <PlusOutlined /> Add name
                      </a>
                    </div>
                  </div>
                )}
              >
                {tempTypeNameList &&
                  tempTypeNameList.map(
                    (item, index) =>
                      sensorDetails &&
                      sensorDetails.deviceType === item.type && (
                        <Select.Option key={index} value={item.name}>
                          {item.name}
                        </Select.Option>
                      )
                  )}
              </Select>
            </Form.Item>
            {sensorDetails?.deviceType === "Breaker" && (
              <Form.Item
                label="Buss"
                tooltip="Which buss does this breaker belong on"
                name="buss"
                rules={[{ required: true, message: "Can't be blank" }]}
              >
                <Select
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          padding: 8,
                        }}
                      >
                        <Input
                          style={{ flex: "auto" }}
                          value={bussName}
                          onChange={(event) => setBussName(event.target.value)}
                        />
                        <a
                          href="#!"
                          style={{
                            flex: "none",
                            padding: "5px",
                            display: "block",
                            cursor: "pointer",
                          }}
                          onClick={HandelAddBussName}
                        >
                          <PlusOutlined /> Add buss
                        </a>
                      </div>
                    </div>
                  )}
                >
                  {bussNames &&
                    bussNames.map((item, index) => (
                      <Select.Option key={index} value={item}>
                        {item}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            )}
            {sensorDetails.deviceName && (
              <Fragment>
                <Form.Item
                  label="Alarm Name"
                  name="alarmName"
                  tooltip="These alarms come from the template of this device, if you don't see it in the list ask for it to be added"
                  rules={[{ required: true, message: "Can't be blank" }]}
                  style={{ marginBottom: "5px" }}
                >
                  <Select allowClear showSearch>
                    {alarmNames}
                  </Select>
                </Form.Item>
                {sensorDetails.alarmName && (
                  <Fragment>
                    <Form.Item
                      label="Priority"
                      name="alarmPriority"
                      rules={[{ required: true, message: "Can't be blank" }]}
                      style={{ marginBottom: "5px" }}
                    >
                      <Select allowClear>
                        {sensorDetails?.deviceType === "Breaker" && (
                          <Select.Option
                            key={"NA"}
                            value="none"
                            style={{ color: "grey" }}
                          >
                            None
                          </Select.Option>
                        )}

                        <Select.Option
                          key={0}
                          value="Event"
                          style={{ color: "blue" }}
                        >
                          Event
                        </Select.Option>
                        <Select.Option
                          key={1}
                          value="Warning"
                          style={{ color: "yellowgreen" }}
                        >
                          Warning
                        </Select.Option>
                        <Select.Option
                          key={2}
                          value="Urgent"
                          style={{ color: "orange" }}
                        >
                          Urgent
                        </Select.Option>
                        <Select.Option
                          key={3}
                          value="Critical"
                          style={{ color: "red" }}
                        >
                          Critical
                        </Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="On Delay"
                      name="alarmDelayOn"
                      rules={[{ required: true, message: "Can't be blank" }]}
                      tooltip="Time is seconds, condition needs to be active for more than this time to create alarm"
                      style={{ marginBottom: "5px" }}
                    >
                      <InputNumber min={1} />
                    </Form.Item>
                    <Form.Item
                      label="Off Delay"
                      name="alarmDelayOff"
                      rules={[{ required: true, message: "Can't be blank" }]}
                      tooltip="Time is seconds, condition needs to be inactive for more than this time to clear alarm"
                      style={{ marginBottom: "5px" }}
                    >
                      <InputNumber min={1} />
                    </Form.Item>
                    <Form.Item
                      label="Trigger Level"
                      name="alarmLevel"
                      tooltip="If this alarm is active when the input goes ON, then choose High on the switch, else if it is normally ON with NO alarm then choose Low"
                      rules={[{ required: true, message: "Can't be blank" }]}
                      style={{ marginBottom: "5px" }}
                    >
                      <Switch
                        checkedChildren="High"
                        unCheckedChildren="Low"
                        size="default"
                        defaultChecked={
                          recordToEdit && recordToEdit.alarmLevel !== undefined
                            ? recordToEdit.alarmLevel === 1
                              ? true
                              : false
                            : false
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      tooltip="Is this device on a specific level in a building? If you not seeing any of the floors you want ask to add it to the site"
                      label="Floor"
                      name="floor"
                      extra="Optional"
                      style={{ marginBottom: "5px" }}
                    >
                      <Select showSearch allowClear>
                        {floorSelect}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Group"
                      extra="Group this device with others"
                      name="group"
                    >
                      <Select
                        mode="multiple"
                        dropdownRender={(menu) => (
                          <div>
                            {menu}
                            <Divider style={{ margin: "4px 0" }} />
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "nowrap",
                                padding: 8,
                              }}
                            >
                              <Input
                                style={{ flex: "auto" }}
                                value={groupInputName}
                                onChange={(event) =>
                                  setGroupInputName(event.target.value)
                                }
                              />
                              <a
                                href="#!"
                                style={{
                                  flex: "none",
                                  padding: "5px",
                                  display: "block",
                                  cursor: "pointer",
                                }}
                                onClick={HandelAddGroupName}
                              >
                                <PlusOutlined /> Add item
                              </a>
                            </div>
                          </div>
                        )}
                      >
                        {groupsNames &&
                          groupsNames.map((item) => (
                            <Select.Option key={item}>{item}</Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Fragment>
                )}
              </Fragment>
            )}
          </Fragment>
        )}
      </Form>
    </Modal>
  );
};

ModalEditFeild.propTypes = {
  modalVis: PropTypes.bool,
  recordToEdit: PropTypes.object,
  updateDeviceDetails: PropTypes.func.isRequired,
  updateTempTypeNameList: PropTypes.func.isRequired,
  CloseEditDigitalInputModal: PropTypes.func.isRequired,
  currentDeviceListDetails: PropTypes.array,
  tempTypeNameList: PropTypes.array,
  siteFloors: PropTypes.array,
  deviceTypesDetailed: PropTypes.array,
  allGroups: PropTypes.array,
  allBusses: PropTypes.array,
};

const mapStateToProps = (state) => ({
  modalVis: state.deviceList.modalEditDigitalInputVisible,
  recordToEdit: state.deviceList.modalEditDigitalInputEditData,
  currentDeviceListDetails:
    state.deviceList.currentDeviceList.Details &&
    state.deviceList.currentDeviceList.Details.childNames,
  tempTypeNameList: state.deviceList.tempTypeNameList,
  siteFloors: state.site.siteDetails.floors,
  allGroups: state.template.groups,
  deviceTypesDetailed: state.deviceTypes.types_detailed,
  allBusses: state.template.busses,
});

export default connect(mapStateToProps, {
  updateTempTypeNameList,
  updateDeviceDetails,
  CloseEditDigitalInputModal,
})(ModalEditFeild);
