import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// antd component
import { Col, Row } from "antd";
// external Components
import ColLeft from "./colLeft";
import ColRight from "./colRight";

// external Functions
import { GetDeviceInfo } from "../../../actions/utl";

// Actual component
const DeviceInformation = ({ GetDeviceInfo }) => {
  useEffect(() => {
    const timeoutHandle = setInterval(() => GetDeviceInfo(), 1000);

    return () => {
      clearInterval(timeoutHandle);
    };
  }, [GetDeviceInfo]);

  return (
    <Row>
      <Col xs={21} md={9}>
        <ColLeft />
      </Col>
      <Col xs={21} md={13}>
        <ColRight />
      </Col>
    </Row>
  );
};

DeviceInformation.propTypes = {
  GetDeviceInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  controllerInfo: state.utl.UCInfo,
});

export default connect(mapStateToProps, { GetDeviceInfo })(DeviceInformation);
