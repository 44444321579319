import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd components
import { Modal, Form, Select, Input, Typography, InputNumber } from "antd";

// external functions
import { outputModalClose, outputAdd } from "../../actions/calculations";

const { Paragraph } = Typography;

// actual component
const NewOutputModal = ({
  modalVis,
  outputModalClose,
  deviceTypesDetailed,
  outputAdd,
  newCalculation,
  inOutSameType,
  modalData,
}) => {
  const [symbolList, setSymbolList] = useState([]);
  const [outputValues, setOutputValues] = useState({});
  const [newOutputForm] = Form.useForm();

  const closingSequence = () => {
    setOutputValues({});
    setSymbolList([]);
    outputModalClose();
    newOutputForm.resetFields();
  };

  const handleCancel = () => {
    closingSequence();
  };

  const handleOk = () => {
    outputAdd(outputValues);
    closingSequence();
  };

  // Device Variables select
  const DeviceVariablesList =
    deviceTypesDetailed !== null && outputValues && outputValues.outputType
      ? deviceTypesDetailed.map(
          (device, _) =>
            device.device_type_name === outputValues.outputType &&
            device.vairables.map((item, number) => (
              <Select.Option value={item.variable} key={number}>
                {item.variable}
              </Select.Option>
            ))
        )
      : null;

  // Device Types select
  const DeviceTypeList =
    deviceTypesDetailed !== null
      ? deviceTypesDetailed.map((device, index) => (
          <Select.Option value={device.device_type_name} key={index}>
            {device.device_type_name}
          </Select.Option>
        ))
      : null;

  // Device Symbol select
  const DeviceSymbolList = symbolList
    ? symbolList.map((symbol, index) => (
        <Select.Option value={symbol} key={index}>
          {symbol}
        </Select.Option>
      ))
    : null;

  const alarmNames =
    deviceTypesDetailed !== null && outputValues && outputValues.outputType
      ? deviceTypesDetailed.map(
          (device, _) =>
            device.device_type_name === outputValues.outputType &&
            device.alarms.map((item, number) => (
              <Select.Option value={item.name} key={number}>
                {item.name}
              </Select.Option>
            ))
        )
      : null;

  if (modalVis) {
    if (newCalculation && newCalculation.inOutSameType) {
      if (
        newOutputForm.getFieldValue("outputType") !==
        newCalculation.inputOutputType
      ) {
        newOutputForm.setFieldsValue({
          outputType: newCalculation.inputOutputType,
        });
        setTimeout(() => {
          setOutputValues({
            ...outputValues,
            outputType: newCalculation.inputOutputType,
          });
        }, 500);
      }
    }

    if (modalData && modalData.type && modalData.deviceType) {
      newOutputForm.setFieldsValue({
        outputType: modalData.deviceType,
      });
      setTimeout(() => {
        setOutputValues({
          ...outputValues,
          outputType: modalData.deviceType,
        });
      }, 500);
    }
  }

  return (
    <Modal
      title={
        modalData && modalData.type === "Virtual"
          ? "Add new virtual output"
          : "Add new output"
      }
      onCancel={handleCancel}
      onOk={handleOk}
      visible={modalVis}
      okButtonProps={{
        disabled:
          outputValues && outputValues.outputType !== undefined
            ? outputValues.outputVariable !== undefined
              ? false
              : true
            : true,
      }}
    >
      <Typography style={{ textAlign: "left" }}>
        <Paragraph>
          Outputs are used as the results of the calculation, this is what is
          seen on the HMI or cloud, select a type, variable, and symbol
        </Paragraph>
      </Typography>
      <Form
        form={newOutputForm}
        size="small"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 10 }}
        onValuesChange={(_, All) => {
          if (All.outputType === undefined) {
            if (modalData && modalData.type === "Virtual") {
              if (All.outputVariable !== undefined) {
                if (deviceTypesDetailed) {
                  deviceTypesDetailed.map((device) => {
                    if (device.device_type_name === modalData.deviceType) {
                      device.vairables.map((variable) => {
                        if (variable.variable === All.outputVariable) {
                          if (variable.symbol) {
                            const symbols = variable.symbol.split(",");
                            setSymbolList(symbols);

                            if (symbols.length === 1) {
                              newOutputForm.setFieldsValue({
                                outputSymbol: symbols[0],
                              });

                              All.outputSymbol = symbols[0];
                            }
                          }
                        }
                        return true;
                      });
                    }
                    return true;
                  });
                }
              }
            } else {
              if (All.outputVariable !== undefined) {
                newOutputForm.setFieldsValue({ outputVariable: undefined });
                newOutputForm.setFieldsValue({ outputSymbol: undefined });
                setSymbolList([]);
              }
            }
          } else {
            if (
              outputValues.outputType !== All.outputType &&
              All.outputVariable !== undefined
            ) {
              newOutputForm.setFieldsValue({ outputVariable: undefined });
              newOutputForm.setFieldsValue({ outputSymbol: undefined });
              setSymbolList([]);
            }

            if (All.outputVariable !== undefined) {
              if (deviceTypesDetailed) {
                deviceTypesDetailed.map((device) => {
                  if (device.device_type_name === All.outputType) {
                    device.vairables.map((variable) => {
                      if (variable.variable === All.outputVariable) {
                        if (variable.symbol) {
                          const symbols = variable.symbol.split(",");
                          setSymbolList(symbols);

                          if (symbols.length === 1) {
                            newOutputForm.setFieldsValue({
                              outputSymbol: symbols[0],
                            });

                            All.outputSymbol = symbols[0];
                          }
                        }
                      }
                      return true;
                    });
                  }
                  return true;
                });
              }
            }
          }
          setOutputValues(All);
        }}
      >
        {modalData && modalData.type === "Virtual" ? (
          <Fragment>
            <Form.Item
              label="Variable"
              name="outputVariable"
              style={{ marginBottom: "5px" }}
            >
              <Select allowClear showSearch>
                {DeviceVariablesList}
              </Select>
            </Form.Item>
            <Form.Item label="Symbol" name="outputSymbol">
              {symbolList && symbolList.length === 1 ? (
                <Input disabled />
              ) : (
                <Select allowClear showSearch>
                  {DeviceSymbolList}
                </Select>
              )}
            </Form.Item>
          </Fragment>
        ) : (
          <Fragment>
            <Form.Item label="Type" name="outputType">
              {newCalculation && newCalculation.inOutSameType ? (
                <Input disabled />
              ) : (
                <Select allowClear showSearch>
                  {DeviceTypeList}
                </Select>
              )}
            </Form.Item>
            {outputValues && outputValues.outputType !== undefined && (
              <Fragment>
                <Form.Item label="Variable" name="outputVariable">
                  <Select allowClear showSearch>
                    {DeviceVariablesList}
                  </Select>
                </Form.Item>
                <Form.Item label="Symbol" name="outputSymbol">
                  {symbolList && symbolList.length === 1 ? (
                    <Input disabled />
                  ) : (
                    <Select allowClear showSearch>
                      {DeviceSymbolList}
                    </Select>
                  )}
                </Form.Item>
                {!inOutSameType && (
                  <Fragment>
                    <Form.Item
                      label="Alarm Name"
                      name="alarmName"
                      tooltip="This is the name when the alarm is triggered, if the alarm you looking for is not here add it in the type section"
                    >
                      <Select allowClear showSearch>
                        {alarmNames}
                      </Select>
                    </Form.Item>
                    <Form.Item label="Trigger Level" name="alarmLevel">
                      <InputNumber />
                    </Form.Item>
                    <Form.Item label="Operation" name="alarmOperation">
                      <Select>
                        <Select.Option key={0} value="<">
                          Less than
                        </Select.Option>
                        <Select.Option key={1} value="=">
                          Equal
                        </Select.Option>
                        <Select.Option key={2} value="!=">
                          Not Equal
                        </Select.Option>
                        <Select.Option key={3} value=">">
                          Greater than
                        </Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="On Delay"
                      name="alarmDelayOn"
                      tooltip="Time is seconds, condition needs to be active for more than this time to create alarm"
                    >
                      <InputNumber min={1} />
                    </Form.Item>
                    <Form.Item
                      label="Off Delay"
                      name="alarmDelayOff"
                      tooltip="Time is seconds, condition needs to be inactive for more than this time to clear alarm"
                    >
                      <InputNumber min={1} />
                    </Form.Item>
                    <Form.Item label="Priority" name="alarmPriority">
                      <Select allowClear>
                        <Select.Option
                          key={0}
                          value="Event"
                          style={{ color: "blue" }}
                        >
                          Event
                        </Select.Option>
                        <Select.Option
                          key={1}
                          value="Warning"
                          style={{ color: "yellowgreen" }}
                        >
                          Warning
                        </Select.Option>
                        <Select.Option
                          key={2}
                          value="Urgent"
                          style={{ color: "orange" }}
                        >
                          Urgent
                        </Select.Option>
                        <Select.Option
                          key={3}
                          value="Critical"
                          style={{ color: "red" }}
                        >
                          Critical
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Fragment>
                )}
              </Fragment>
            )}
          </Fragment>
        )}
      </Form>
    </Modal>
  );
};

NewOutputModal.propTypes = {
  modalVis: PropTypes.bool,
  modalData: PropTypes.object,
  inOutSameType: PropTypes.bool,
  outputModalClose: PropTypes.func.isRequired,
  deviceTypesDetailed: PropTypes.array,
  outputAdd: PropTypes.func,
  newCalculation: PropTypes.object,
};

const mapStateToProps = (state) => ({
  modalVis: state.calcs.createOutputModalVis,
  modalData: state.calcs.createOutputModalEdit,
  deviceTypesDetailed: state.deviceTypes.types_detailed,
  newCalculation: state.calcs.newCalculation,
  inOutSameType:
    state.calcs.newCalculation && state.calcs.newCalculation.inOutSameType,
});

export default connect(mapStateToProps, { outputModalClose, outputAdd })(
  NewOutputModal
);
