import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd componets
import {
  Col,
  Row,
  Typography,
  Table,
  Space,
  Popconfirm,
  Tooltip,
  Button,
} from "antd";
import {
  EditFilled,
  DeleteFilled,
  PlusCircleOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";

// external components
import AddIPModal from "../settings/Network/modalAddIP";

// External functions
import {
  ModalAddIPOpen,
  EthernetIPDelete,
  ResetInterface,
  getAllInterfaceIP,
} from "../../actions/utl";

const { Title, Paragraph, Text } = Typography;

// actual component
const SiteNetwork = ({
  InterfaceIP,
  getAllInterfaceIP,
  ModalAddIPOpen,
  ResetInterface,
  EthernetIPDelete,
}) => {
  useEffect(() => {
    getAllInterfaceIP();
  }, [getAllInterfaceIP]);

  const handleDelete = (record) => {
    EthernetIPDelete(record);
  };

  const handleEdit = (record) => {
    ModalAddIPOpen(record);
  };

  const columns = [
    {
      title: "IP Address",
      key: "IPAddress",
      dataIndex: "address",
    },
    {
      title: "Subnet",
      key: "Subnet",
      dataIndex: "netmask",
    },
    {
      title: "Gateway",
      key: "Gateway",
      dataIndex: "gateway",
    },
    {
      title: "IPV",
      key: "IPV",
      dataIndex: "family",
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (text, record) => (
        <Space size="middle">
          <Fragment>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record)}
            >
              <Tooltip title="Delete IP">
                <a href="#!">
                  <DeleteFilled />
                </a>
              </Tooltip>
            </Popconfirm>
            <Tooltip title="Edit IP">
              <a href="#!" onClick={() => handleEdit(record)}>
                <EditFilled />
              </a>
            </Tooltip>
          </Fragment>
        </Space>
      ),
    },
  ];

  return (
    <Fragment>
      <Row style={{ marginBottom: "20px" }}>
        <Col sm={0} lg={5} xl={7}></Col>
        <Col sm={24} lg={14} xl={10}>
          <Typography style={{ textAlign: "center" }}>
            <Title>Ethernet network configuration</Title>
            <Title level={2}>Let us now configure the IP address</Title>

            <Paragraph style={{ textAlign: "left" }}>
              The table shows the current IP address that are on the UC, use the{" "}
              <Text keyboard>Add IP</Text> to add an IP address to the
              controller, to reset the Ethernet port press on the{" "}
              <Text keyboard>Reset</Text> button.
            </Paragraph>
          </Typography>
        </Col>
      </Row>

      <Space style={{ marginBottom: "10px" }}>
        <Tooltip title="Click to add an IP to this interface">
          <Button
            type={"primary"}
            size={"small"}
            icon={<PlusCircleOutlined />}
            onClick={() => ModalAddIPOpen(undefined)}
          >
            Add IP
          </Button>
        </Tooltip>
        <Tooltip title="Click to reset the interface, turns it off and the on">
          <Button
            type={"primary"}
            size={"small"}
            icon={<PoweroffOutlined />}
            onClick={() => ResetInterface("eth")}
          >
            Reset
          </Button>
        </Tooltip>
      </Space>

      <AddIPModal />

      <Table
        bordered
        style={{ marginTop: "10px" }}
        size={"small"}
        scroll={320}
        dataSource={InterfaceIP.Ethernet}
        columns={columns}
        pagination={{
          defaultPageSize: 30,
          showSizeChanger: true,
          pageSizeOptions: ["30", "40", "50"],
        }}
      />
    </Fragment>
  );
};

SiteNetwork.propTypes = {
  InterfaceIP: PropTypes.object,
  getAllInterfaceIP: PropTypes.func.isRequired,
  ModalAddIPOpen: PropTypes.func.isRequired,
  ResetInterface: PropTypes.func.isRequired,
  EthernetIPDelete: PropTypes.func.isRequired,
};

const MapStateToProps = (state) => ({
  InterfaceIP: state.utl.InterfaceIP,
});

export default connect(MapStateToProps, {
  getAllInterfaceIP,
  ModalAddIPOpen,
  ResetInterface,
  EthernetIPDelete,
})(SiteNetwork);
