import React, { Fragment, useState } from "react";
import { Steps, Button, Result } from "antd";
import { Redirect } from "react-router-dom";
import { SmileOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Components
import ConfigDetails from "./configDeviceDetails";
// import Templates from "./Template";
import { Link } from "react-router-dom";
import { updateBackend } from "../../../../actions/modbusconfig";

const { Step } = Steps;

const NewConfig = ({ updateBackend, modbusConfig, operationComplete }) => {
  const steps = [
    {
      title: "Configuration details",
      content: <ConfigDetails />,
    },
    // {
    //   title: "Template",
    //   content: <Templates />,
    // },
    {
      title: "Confirmation",
      //   content: <Confirmation />,
      content: (
        <Result
          icon={<SmileOutlined />}
          title="Great, we have done all the operations!"
        />
      ),
    },
  ];

  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  // If we are done
  if (operationComplete) {
    return <Redirect to="/tools/config/modbus" />;
  }

  return (
    <Fragment>
      <Steps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content" style={{ minHeight: "60vh" }}>
        {steps[current].content}
      </div>
      <div className="steps-action" style={{ marginTop: "10px" }}>
        <Link to="/tools/config/modbus" style={{ marginRight: "10px" }}>
          <Button type="primary" onClick={sessionStorage.clear()}>
            Back
          </Button>
        </Link>
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          //   <Button type="primary" onClick={() => sendToServer()}>
          <Button
            type="primary"
            onClick={() => {
              const configName = localStorage.getItem("configName");
              localStorage.removeItem("configName");
              let formData = {};
              formData.config = modbusConfig.entireConfig;
              formData.name = configName;
              formData.current = false;

              updateBackend(formData);
            }}
          >
            Done
          </Button>
        )}
        {current > 0 && (
          <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
            Previous
          </Button>
        )}
      </div>
    </Fragment>
  );
};

NewConfig.propTypes = {
  modbusConfig: PropTypes.object.isRequired,
  updateBackend: PropTypes.func.isRequired,
  operationComplete: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  modbusConfig: state.modbusConfig,
  operationComplete: state.modbusConfig.operationComplete,
});

export default connect(mapStateToProps, { updateBackend })(NewConfig);
