import React, { Fragment, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { fabric } from "fabric";
import { connect } from "react-redux";
import { v4 as uuid } from "uuid";

// antd
import {
  Typography,
  Tooltip,
  Button,
  Upload,
  Row,
  Col,
  Dropdown,
  Menu,
  Space,
} from "antd";
// Icons
import {
  PlusCircleOutlined,
  ClearOutlined,
  SaveOutlined,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";

// External components
import ModalEditDevice from "./Modals/EditDevice";
import Icon_PowerMeter from "./images/PowerMeter.png";
import Icon_SinglePhaseBreaker from "./images/SinglePhaseBreaker.png";
import { deleteIcon, cloneIcon } from "./images/helperIcons";

// External functions
import {
  ModalDeviceEditOpen,
  DeviceUpdateComplete,
} from "../../actions/distribution";

const { Title, Text, Paragraph } = Typography;

// Grid size in px
const grid = 10;
const MIN_SCALE = 0.5;
const MAX_SCALE = 3;

// actual function
const Index = ({
  ModalDeviceEditOpen,
  updateData,
  updateAvaiable,
  permissions,
  fromWizard,
  sitedetails,
  DeviceUpdateComplete,
}) => {
  const distCanvas = useRef();

  var deleteImg = document.createElement("img");
  deleteImg.src = deleteIcon;

  var cloneImg = document.createElement("img");
  cloneImg.src = cloneIcon;

  const initCanvas = () =>
    new fabric.Canvas("distbutionEdit", {
      height: 700,
      width: 500,
      backgroundColor: "#f4f0ec",
    });

  useEffect(() => {
    window.addEventListener("resize", resizeCanvas);
  }, []);

  const resizeCanvas = () => {
    if (distCanvas.current) {
      const outerCanvasContainer = document.getElementById(
        "fabric-canvas-wrapper"
      );

      const containerWidth = outerCanvasContainer.clientWidth;
      let containerHeight = window.innerHeight;
      let ratio = containerHeight * 34;
      ratio /= 100;
      containerHeight -= ratio;

      const scale = containerWidth / distCanvas.current.getWidth();
      const zoom = distCanvas.current.getZoom() * scale;

      distCanvas.current.setDimensions({
        width: containerWidth,
        height: containerHeight,
      });
      distCanvas.current.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
    }
  };

  fabric.Object.prototype.transparentCorners = false;
  fabric.Object.prototype.cornerColor = "blue";
  fabric.Object.prototype.cornerStyle = "circle";

  useEffect(() => {
    if (updateAvaiable && updateData) {
      const objects = distCanvas.current.getObjects();

      objects.map((canvasItem) => {
        if (
          canvasItem.distributionData?.id === updateData?.distributionData?.id
        ) {
          canvasItem.distributionData = updateData.distributionData;

          if (canvasItem.isType("group")) {
            const itemsInGroup = canvasItem.getObjects();

            itemsInGroup.map((item, index) => {
              const itemExists = canvasItem.item(index).id ?? false;

              if (itemExists) {
                if (updateData.distributionData[itemExists]) {
                  canvasItem.item(index).set({
                    text: updateData.distributionData[itemExists].toString(),
                  });
                }
              }
              return true;
            });
          }
        }

        return true;
      });
      DeviceUpdateComplete();
      distCanvas.current.renderAll();
    }
  }, [updateAvaiable, updateData, DeviceUpdateComplete]);

  const renderIcon = (icon) => {
    return function renderIcon(ctx, left, top, styleOverride, fabricObject) {
      var size = this.cornerSize;
      ctx.save();
      ctx.translate(
        Math.round(left / grid) * grid,
        Math.round(top / grid) * grid
      );
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
      ctx.drawImage(icon, -size / 2, -size / 2, size, size);
      ctx.restore();
    };
  };

  const deleteObject = (eventData, transform) => {
    var target = transform.target;
    var canvas = target.canvas;
    canvas.remove(target);
    canvas.requestRenderAll();
  };

  const cloneObject = (eventData, transform) => {
    var target = transform.target;

    AddDevice(
      target?.distributionData?.type,
      target?.distributionData?.variation,
      target.left + 20,
      target.top + 20
    );
  };

  fabric.Object.prototype.controls.deleteControl = new fabric.Control({
    x: 0.5,
    y: -0.5,
    offsetY: -16,
    offsetX: 16,
    cursorStyle: "pointer",
    mouseUpHandler: deleteObject,
    render: renderIcon(deleteImg),
    cornerSize: 15,
  });

  fabric.Object.prototype.controls.clone = new fabric.Control({
    x: -0.5,
    y: -0.5,
    offsetY: -16,
    offsetX: -16,
    cursorStyle: "pointer",
    mouseUpHandler: cloneObject,
    render: renderIcon(cloneImg),
    cornerSize: 15,
  });

  useEffect(() => {
    distCanvas.current = initCanvas();

    distCanvas.current.setZoom(0.3);

    // double click
    distCanvas.current.on("mouse:dblclick", function (options) {
      if (options?.target) {
        ModalDeviceEditOpen(options.target);
      }
    });

    // middle mouse wheel
    distCanvas.current.on("mouse:wheel", function (opt) {
      var delta = opt.e.deltaY;
      var zoom = distCanvas.current.getZoom();

      zoom *= 0.999 ** delta;

      if (zoom > MAX_SCALE) zoom = MAX_SCALE;
      if (zoom < MIN_SCALE) zoom = MIN_SCALE;

      this.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
      opt.e.preventDefault();
      opt.e.stopPropagation();
    });

    // mouse click, button down
    distCanvas.current.on("mouse:down", function (opt) {
      var evt = opt.e;
      if (evt.altKey === true) {
        this.isDragging = true;
        this.selection = false;
        this.lastPosX = evt.clientX;
        this.lastPosY = evt.clientY;
      } else {
        if (opt?.target) {
          this.bringToFront(opt.target);
        }
      }
    });

    // mouse click, button released
    distCanvas.current.on("mouse:up", function (opt) {
      // on mouse up we want to recalculate new interaction
      // for all objects, so we call setViewportTransform
      this.setViewportTransform(this.viewportTransform);
      this.isDragging = false;
      this.selection = true;
    });

    // mouse is moving, no object
    distCanvas.current.on("mouse:move", function (opt) {
      if (this.isDragging) {
        var e = opt.e;
        var vpt = this.viewportTransform;
        vpt[4] += e.clientX - this.lastPosX;
        vpt[5] += e.clientY - this.lastPosY;
        this.requestRenderAll();
        this.lastPosX = e.clientX;
        this.lastPosY = e.clientY;
      }
    });

    // object is moving
    distCanvas.current.on("object:moving", function (options) {
      options.target.set({
        left: Math.round(options.target.left / grid) * grid,
        top: Math.round(options.target.top / grid) * grid,
      });

      distCanvas.current.bringToFront(options.target);
    });

    resizeCanvas();

    return () => {
      distCanvas.current = null;
    };
  }, [ModalDeviceEditOpen]);

  const AddDevice = (type, variation, left, top, w, h, data) => {
    const id = uuid();
    let imgObj = "";

    switch (type) {
      case "Breaker":
        imgObj = new Image();
        imgObj.src = Icon_SinglePhaseBreaker;
        const testFontSize = 10;

        imgObj.onload = () => {
          var image = new fabric.Image(imgObj);

          image.set({
            angle: 0,
            padding: 0,
            originX: "center",
            originY: "center",
          });

          image.scale(0.5);

          let topPostition = -10;
          let leftPostition = +10;

          var textBreakerDescription = new fabric.Text("NSX20", {
            fontSize: testFontSize,
            left: leftPostition,
            top: topPostition,
            textAlign: "end",
            // originX: "center",
            originY: "center",
            id: "breakerType",
          });

          topPostition += testFontSize;

          var textBreakerRating = new fabric.Text("200A", {
            fontSize: testFontSize,
            left: leftPostition,
            top: topPostition,
            // originX: "center",
            originY: "center",
            id: "breakerRate",
          });

          topPostition += testFontSize;

          var textBreakerName = new fabric.Text("Q?", {
            fontSize: testFontSize,
            left: leftPostition,
            top: topPostition,
            // originX: "center",
            originY: "center",
            id: "breakerName",
          });

          var group = new fabric.Group(
            [image, textBreakerName, textBreakerRating, textBreakerDescription],
            {
              left: left !== undefined ? left : 0,
              top: top !== undefined ? top : 0,
              lockRotation: true,
              lockScalingY: true,
              lockScalingX: true,
              distributionData: {
                type: "Breaker",
                id,
              },
            }
          );

          if (data) group.distributionData = data;
          if (w) group.width = w;
          if (h) group.height = h;

          distCanvas.current.add(group);
        };

        break;
      case "PowerMeter":
        imgObj = new Image();
        imgObj.src = Icon_PowerMeter;

        imgObj.onload = () => {
          var image = new fabric.Image(imgObj);

          image.set({
            angle: 0,
            padding: 0,
            lockRotation: true,
            lockScalingY: true,
            lockScalingX: true,
            distributionData: {
              type: "PowerMeter",
              id,
            },
            left: left !== undefined ? left : 0,
            top: top !== undefined ? top : 0,
            //   originX: "center",
            //   originY: "center",
          });

          if (data) image.distributionData = data;
          if (w) image.width = w;
          if (h) image.height = h;

          image.scale(0.15);

          distCanvas.current.add(image);
        };
        break;
      case "Buss":
        let rect = new fabric.Rect({
          height: 5,
          width: 200,
          fill: "black",
          centeredRotation: true,
          centeredScaling: true,
          lockScalingY: true,
          lockScalingX: false,
          lockRotation: true,
          left: left !== undefined ? left : 0,
          top: top !== undefined ? top : 0,
          distributionData: {
            type: "Buss",
            id,
          },
          //   hasControls: false,
        });

        if (data) rect.distributionData = data;
        if (w) rect.width = w;
        if (h) rect.height = h;

        distCanvas.current.add(rect);
        break;
      default:
        return;
    }

    distCanvas.current.renderAll();
  };

  const clearList = (canvi) => {
    var listItems = canvi.getObjects();

    if (listItems !== undefined) {
      var list = [];

      for (let i = 0; i < listItems.length; i += 1) {
        var item = listItems[i];

        if (item.fill === "yellow" || item.distributionData) {
          list.push(item);
        }
      }

      for (let i = 0; i < list.length; i += 1) {
        distCanvas.current.remove(list[i]);
      }
    }
  };

  const HandleExport = async () => {
    const objects = distCanvas.current.getObjects();

    if (objects !== undefined && objects.length > 0) {
      const d = new Date();
      var utc = d.toJSON().slice(0, 10);
      var hours = d.getHours() + "h" + d.getMinutes();

      const fileName = sitedetails.name + " Distribution-" + utc + " " + hours;

      let list = [];

      objects.map((item) => {
        list.push({
          x: Math.round(item.left),
          y: Math.round(item.top),
          w:
            item.distributionData.type === "Buss"
              ? item.width * item.scaleX
              : Math.round(item.width),
          h: Math.round(item.height),
          id: item.id,
          distributionData: item.distributionData,
        });

        return true;
      });

      const json = JSON.stringify(list, null, 2);
      const blob = new Blob([json], { type: "application/json" });
      const href = await URL.createObjectURL(blob);
      const link = document.createElement("a");

      link.href = href;
      link.download = fileName + ".json";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handelUpload = ({ fileList }) => {
    let reader = new FileReader();

    reader.onload = (e) => {
      const objects = JSON.parse(e.target.result);

      if (objects !== undefined) {
        objects.map((item) => {
          AddDevice(
            item.distributionData.type,
            item.distributionData?.variation,
            item.x,
            item.y,
            item.w,
            item.h,
            item.distributionData
          );

          return true;
        });
        distCanvas.current.renderAll();
      }
    };

    // convert the blob to a texxt file
    reader.readAsText(fileList[0].originFileObj);
  };

  const UploaderProps = {
    name: "file",
    maxCount: 1,
    showUploadList: false,
    accept: ".json",
  };

  const handleMenuClickBreakers = (e) => {
    switch (e.key) {
      case "1":
        AddDevice("Breaker");
        break;
      default:
        break;
    }
  };

  const menubreakers = (
    <Menu onClick={handleMenuClickBreakers}>
      <Menu.Item key="1">Single Phase</Menu.Item>
      <Menu.Item key="2">Three Phase</Menu.Item>
      <Menu.Item key="3">Rackable</Menu.Item>
    </Menu>
  );

  return (
    <Fragment>
      {fromWizard === undefined ? (
        <Fragment>
          <Title level={2}>SLD/Distribution</Title>
          {permissions && permissions.write && (
            <Fragment>
              <Paragraph>
                Use the below to select different configuration versions
              </Paragraph>

              {/* <TableConfigList /> */}
            </Fragment>
          )}

          {permissions && permissions.write && (
            <Paragraph style={{ marginTop: "10px" }}>
              Use the below to edit the distribution, the distribution will not
              be saved to the device until you press on the{" "}
              <Text keyboard>Save</Text> button
            </Paragraph>
          )}

          <Space style={{ marginBottom: "10px" }}>
            {permissions && permissions.write && (
              <Fragment>
                <Tooltip title="Click to add a new breaker to the SLD">
                  <Dropdown.Button
                    size="small"
                    overlay={menubreakers}
                    icon={<PlusCircleOutlined />}
                  >
                    Breaker
                  </Dropdown.Button>
                </Tooltip>
                <Tooltip title="Click to clear the list but will not save it">
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => clearList(distCanvas.current)}
                    icon={<ClearOutlined />}
                  >
                    Clear
                  </Button>
                </Tooltip>
                <Tooltip title="Save this configuration to the device to start using it">
                  <Button
                    type="primary"
                    size="small"
                    // disabled={
                    //   currentList && Object.keys(currentList).length === 0
                    // }
                    // onClick={OpenSaveConfigModal}
                    // onClick={handleSave}
                    // loading={alarmListIsSaving || deviceListIsSaving}
                    icon={<SaveOutlined />}
                  >
                    Save
                  </Button>
                </Tooltip>
              </Fragment>
            )}
            <Tooltip title="Click to download this configuration to your machine">
              <Button
                // disabled={currentList && Object.keys(currentList).length === 0}
                icon={<DownloadOutlined />}
                type="primary"
                size="small"
                onClick={() => HandleExport()}
              >
                Export
              </Button>
            </Tooltip>
            {permissions && permissions.write && (
              <Tooltip title="Click to upload a new configuration from your machine">
                <Upload
                  {...UploaderProps}
                  onChange={handelUpload}
                  beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                >
                  <Button size="small" icon={<UploadOutlined />}>
                    Import
                  </Button>
                </Upload>
              </Tooltip>
            )}
          </Space>
        </Fragment>
      ) : (
        <Fragment>
          <Row style={{ marginBottom: "20px" }}>
            <Col span={8}></Col>
            <Col span={8}>
              <Typography style={{ textAlign: "center" }}>
                <Title level={2}>Devices to read</Title>
                <Paragraph>
                  This is the list of devices your UC-4 will read in the field,
                  use the <Text code>Add device</Text> button to add devices to
                  read to the list. Once you are done hit the{" "}
                  <Text code>Next</Text> button to save the list.
                </Paragraph>
                <Paragraph>
                  <Text keyboard strong type="warning">
                    NB:
                  </Text>
                  You don't need to complete this now, it can be edited later
                </Paragraph>
              </Typography>
            </Col>
            <Col span={8}></Col>
          </Row>
          <Space style={{ marginBottom: "10px" }}>
            <Tooltip title="Click to add a new breaker to the SLD">
              <Dropdown.Button
                overlay={menubreakers}
                icon={<PlusCircleOutlined />}
              >
                Breaker
              </Dropdown.Button>
            </Tooltip>
            <Tooltip title="Click to upload a new configuration from your machine">
              <Upload
                {...UploaderProps}
                onChange={handelUpload}
                beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
              >
                <Button icon={<UploadOutlined />}>Import</Button>
              </Upload>
            </Tooltip>
          </Space>
        </Fragment>
      )}

      <ModalEditDevice />
      {/* <button onClick={() => addRect(distCanvas.current)}>Rectangle</button> */}
      {/* <button onClick={() => AddDevice("PowerMeter")}>Add PM</button> */}
      {/* <button onClick={() => AddDevice("Breaker")}>Add Breaker</button> */}
      {/* <button onClick={() => AddDevice("Buss")}>Add Buss</button> */}
      {/* <button onClick={() => getSerial(distCanvas.current)}>Export</button> */}
      {/* <button onClick={() => importList(exportDist)}>Import</button> */}
      {/* <button onClick={() => clearList(distCanvas.current)}>Clear</button> */}

      {/* <TextArea
        rows={4}
        value={exportDist}
        onChange={(e) => setExportDist(e.target.value)}
      /> */}
      <div id="fabric-canvas-wrapper">
        <canvas id="distbutionEdit" />
      </div>
    </Fragment>
  );
};

Index.propTypes = {
  ModalDeviceEditOpen: PropTypes.func.isRequired,
  DeviceUpdateComplete: PropTypes.func.isRequired,
  updateData: PropTypes.object,
  updateAvaiable: PropTypes.bool,
  permissions: PropTypes.object,
  sitedetails: PropTypes.object,
};

const mapStateToProps = (state) => ({
  updateAvaiable: state.distribution.deviceEditUpdateAvaiable,
  updateData: state.distribution.deviceEditUpdateData,
  permissions: state.auth.permissions,
  sitedetails: state.site.siteDetails,
});

export default connect(mapStateToProps, {
  ModalDeviceEditOpen,
  DeviceUpdateComplete,
})(Index);
