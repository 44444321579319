import {
  CLIENT_FAIL,
  CLIENT_CLEAR,
  CLIENT_LOADING,
  CLIENT_UPDATED,
  CLIENT_SITE_UPDATE,
  CLIENT_SITE_LOADING,
  CLIENT_SITE_FAIL,
} from "../actions/types";

const initalState = {
  dataloaded: false,
  clientsList: null,
  clientSiteListLoaded: false,
  clientSiteList: null,
};

export default function site(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case CLIENT_SITE_LOADING:
      return { ...state, clientSiteListLoaded: false };
    case CLIENT_SITE_UPDATE:
      return { ...state, clientSiteListLoaded: true, clientSiteList: payload };
    case CLIENT_SITE_FAIL:
      return { ...state, clientSiteListLoaded: true, clientSiteList: null };
    case CLIENT_UPDATED:
      return { ...state, dataloaded: true, clientsList: payload };
    case CLIENT_LOADING:
    case CLIENT_CLEAR:
    case CLIENT_FAIL:
      return {
        ...state,
        dataloaded: false,
        clientsList: null,
      };
    default:
      return state;
  }
}
