import {
  ADV_LIST_ADD,
  ADV_LIST_DELETE,
  ADV_LIST_GET,
  ADV_LIST_LOAD,
  ADV_LIST_LOAD_DONE,
  ADV_LIST_SAVE,
  ADV_LIST_SAVE_DONE,
  ADV_MODAL_ADD_CLOSE,
  ADV_MODAL_ADD_OPEN,
  ADV_MODAL_VIRTUAL_CLOSE,
  ADV_MODAL_VIRTUAL_OPEN,
} from "./types";
import { v4 as uuid } from "uuid";
import setAuthToken from "../utils/setAuthToken";
import axios from "axios";
import { setAlert } from "./alert";

// Open Add new modal
export const modalAddNewOpen = (data) => (dispatch) => {
  dispatch({
    type: ADV_MODAL_ADD_OPEN,
    payload: data,
  });
};

// Close Add new modal
export const modalAddNewClose = () => (dispatch) => {
  dispatch({
    type: ADV_MODAL_ADD_CLOSE,
  });
};

// Open Add new virtual device modal
export const modalAddVirtualOpen = (data) => (dispatch) => {
  dispatch({
    type: ADV_MODAL_VIRTUAL_OPEN,
    payload: data,
  });
};

// Close Add new virtual device modal
export const modalAddVirtualClose = () => (dispatch) => {
  dispatch({
    type: ADV_MODAL_VIRTUAL_CLOSE,
  });
};

// Save the config list
export const saveConfigToList =
  (config, isEdit = false) =>
  (dispatch) => {
    if (isEdit) {
      dispatch({
        type: ADV_LIST_ADD,
        payload: { ...config },
      });
    } else {
      const id = uuid();

      dispatch({
        type: ADV_LIST_ADD,
        payload: { ...config, key: id },
      });
    }
  };

// Delete an item in the list
export const deleteItem = (data) => (dispatch) => {
  dispatch({
    type: ADV_LIST_DELETE,
    payload: data,
  });
};

// get the list for the stats page
export const getTheList = () => async (dispatch) => {
  dispatch({
    type: ADV_LIST_LOAD,
  });

  try {
    const listItems = await axios.get("/api/calcs/statslist");

    if (listItems) {
      if (listItems.data[0]) {
        dispatch({
          type: ADV_LIST_GET,
          payload: listItems.data[0].statsList,
        });
      }
    }

    dispatch({
      type: ADV_LIST_LOAD_DONE,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ADV_LIST_LOAD_DONE,
    });
  }
};

// saves and creates the config list for the backend
export const createAndSaveEntireList = (statsItems) => async (dispatch) => {
  dispatch({
    type: ADV_LIST_SAVE,
  });

  try {
    if (statsItems !== null) {
      // save the stats page items to the DB
      await saveStatsPageToDB(statsItems);
    } else {
      statsItems = await axios.get("/api/calcs/statslist");

      if (statsItems) {
        if (statsItems.data[0]) {
          statsItems = statsItems.data[0].statsList;
        }
      }
    }

    let entireList = [];

    // Add the new stats items to the list
    if (statsItems && statsItems.length > 0) {
      statsItems.map((listItem) => {
        if (listItem.key) delete listItem.key;
        if (listItem.calculationDescription)
          delete listItem.calculationDescription;
        if (listItem.calculationName) {
          listItem.deviceName = listItem.calculationName;
          delete listItem.calculationName;
        }

        if (listItem.constants) {
          listItem.constants.forEach((constant) => {
            if (constant.listSelect) delete constant.listSelect;
            if (constant.allowMultiples) delete constant.allowMultiples;
            if (constant.key) delete constant.key;
            if (constant.typeVariable) delete constant.typeVariable;
            if (constant.types) delete constant.types;
          });
        }

        return entireList.push({ ...listItem });
      });
    }
    // save the items to the DB
    await saveEntireListToDB(entireList);

    dispatch(setAlert("Calculation list is saved", "success"));

    dispatch({
      type: ADV_LIST_SAVE_DONE,
    });
  } catch (error) {
    console.log(error);
    dispatch(setAlert("List not saved", "error"));
    dispatch({
      type: ADV_LIST_SAVE_DONE,
    });
  }
};

// save the list so we can retreev just this section
const saveStatsPageToDB = async (config) => {
  try {
    if (config.length > 0) {
      if (localStorage.token) {
        setAuthToken(localStorage.token);
      }

      const configHeader = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      await axios.post("/api/calcs/statslist", { config }, configHeader);
    }
  } catch (error) {
    console.log(error);
  }
};

// saves the entire list to the DB
const saveEntireListToDB = async (config) => {
  try {
    if (config.length > 0) {
      if (localStorage.token) {
        setAuthToken(localStorage.token);
      }

      const configHeader = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      await axios.post("/api/calcs/config", { config: config }, configHeader);
    }
  } catch (error) {
    console.log(error);
  }
};
