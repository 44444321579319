import {
  DEVICELIST_EDITINPUT_MODAL_CLOSE,
  DEVICELIST_EDITINPUT_MODAL_OPEN,
  DEVICELIST_MODAL_CLOSE,
  DEVICELIST_MODAL_OPEN,
  DEVICELIST_UPDATE_DETAILS,
  DEVICELIST_UPDATE_INFO,
  DEVICELIST_UPDATE_LIST,
  DEVICELIST_UPDATE_LISTTOREAD,
  DEVICELIST_MODAL_OPEN_EDIT,
  DEVICELIST_DELETE_LISTITEM,
  DEVICELIST_UPDATE_LISTTOREAD_ALL,
  DEVICELIST_MODAL_OPEN_DUPLICATE,
  DEVICELIST_CREATE_TYPENAMELIST,
  DEVICELIST_ADD_TYPENAMELIST,
  DEVICELIST_UPDATE_TEMPTYPENAMELIST,
  DEVICELIST_UPDATE_TYPENAMELIST,
  DEVICELIST_IS_SAVING,
  DEVICELIST_DEVICE_STATUS,
  DEVICELIST_DIGITALEDITINPUT_MODAL_OPEN,
  DEVICELIST_DIGITALEDITINPUT_MODAL_CLOSE,
  DEVICELIST_SELECT_CONFIG,
  DEVICELIST_MODAL_SAVECONFIG_CLOSE,
  DEVICELIST_MODAL_SAVECONFIG_OPEN,
  DEVICELIST_CREATE_IPRTULIST,
  DEVICELIST_CLEAR,
} from "../actions/types";

const initalState = {
  deviceList: [],
  deviceToRead: [],
  currentDeviceList: {},
  openAddDeviceModal: false,
  isEdit: false,
  isDuplication: false,
  isSaving: false,
  typeNameList: [],
  tempTypeNameList: [],
  allConfigFiles: [],
  IPRTUDuplicatonList: [],
  modalEditInputVisible: false,
  modalEditInputEditData: null,
  modalEditDigitalInputVisible: false,
  modalEditDigitalInputEditData: null,
  modalSaveConfigVisible: false,
};

export default function site(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case DEVICELIST_DEVICE_STATUS:
      try {
        const deviceStatus = payload;
        let currentDeviceList = JSON.parse(JSON.stringify(state.deviceToRead));

        if (currentDeviceList.length > 0) {
          currentDeviceList.map((device) => {
            const index = Object.keys(deviceStatus).findIndex((deviceName) =>
              deviceName.includes("#")
                ? deviceName.split("#")[0] ===
                  device.Information.deviceIPSlaveID
                : deviceName === device.Information.deviceIPSlaveID
            );

            if (index !== -1) {
              device.commStaus = deviceStatus[Object.keys(deviceStatus)[index]];
            } else {
              if (device.commStaus) {
                delete device.commStaus;
              }
            }

            return true;
          });
        }
        return { ...state, deviceToRead: currentDeviceList };
      } catch (error) {
        console.error("DEVICELIST_DEVICE_STATUS,deviceList.js ", error);
        return state;
      }
    case DEVICELIST_IS_SAVING:
      return { ...state, isSaving: payload };
    case DEVICELIST_UPDATE_TYPENAMELIST:
      return { ...state, typeNameList: payload };
    case DEVICELIST_UPDATE_TEMPTYPENAMELIST:
      return { ...state, tempTypeNameList: payload };
    case DEVICELIST_ADD_TYPENAMELIST:
      let currentTypeNameList = [...state.typeNameList];
      let currentIPRTUList = [...state.IPRTUDuplicatonList];

      const indexOfItem = currentTypeNameList.findIndex(
        (typeName) =>
          typeName.type === payload.deviceType &&
          typeName.name === payload.deviceName
      );

      // No match
      if (indexOfItem === -1) {
        currentTypeNameList.push({
          name: payload.deviceName,
          type: payload.deviceType,
        });

        currentIPRTUList.push(payload.deviceIPSlaveID);
      } else {
        currentIPRTUList[indexOfItem] = payload.deviceIPSlaveID;
      }

      return {
        ...state,
        typeNameList: currentTypeNameList,
        IPRTUDuplicatonList: currentIPRTUList,
      };
    case DEVICELIST_CLEAR:
      return { ...state, deviceList: [], deviceToRead: [] };
    case DEVICELIST_CREATE_TYPENAMELIST:
      return { ...state, typeNameList: payload };
    case DEVICELIST_CREATE_IPRTULIST:
      return { ...state, IPRTUDuplicatonList: payload };
    case DEVICELIST_MODAL_OPEN_DUPLICATE:
      return {
        ...state,
        openAddDeviceModal: true,
        tempTypeNameList: [...state.typeNameList],
        isDuplication: true,
        currentDeviceList: { ...payload },
      };
    case DEVICELIST_DELETE_LISTITEM:
      let currentList = [...state.deviceToRead];
      let duplicateIPRTUIDlist = [];

      currentList = currentList.filter((o) => o.KeyID !== payload.KeyID);

      currentList.map((listItem) => {
        return duplicateIPRTUIDlist.push(listItem.Information.deviceIPSlaveID);
      });

      return {
        ...state,
        deviceToRead: currentList,
        IPRTUDuplicatonList: duplicateIPRTUIDlist,
      };
    case DEVICELIST_UPDATE_LISTTOREAD_ALL:
      return { ...state, allConfigFiles: payload };
    case DEVICELIST_SELECT_CONFIG:
      let list = [];

      for (const [key, value] of Object.entries(payload)) {
        let useless = key;
        useless = useless + "f";
        list.push(value);
      }

      return { ...state, deviceToRead: list };
    case DEVICELIST_UPDATE_LISTTOREAD:
      let deviceToRead = [...state.deviceToRead];

      let index = deviceToRead.findIndex((o) => o.KeyID === payload.KeyID);

      if (index !== -1) {
        deviceToRead.splice(index, 1, payload);
      } else {
        deviceToRead.push(payload);
      }

      return { ...state, deviceToRead: deviceToRead };
    case DEVICELIST_MODAL_OPEN_EDIT:
      return {
        ...state,
        openAddDeviceModal: true,
        tempTypeNameList: [...state.typeNameList],
        currentDeviceList: { ...payload },
        isEdit: true,
      };
    case DEVICELIST_UPDATE_LIST:
      return { ...state, deviceList: payload };
    case DEVICELIST_UPDATE_DETAILS:
      let currentDeviceLists = { ...state.currentDeviceList };
      currentDeviceLists.Details = { ...payload };

      // state.currentDeviceList.Details = {};
      // state.currentDeviceList.Details = payload;

      return { ...state, currentDeviceList: currentDeviceLists };
    case DEVICELIST_UPDATE_INFO:
      let currentDeviceListss = { ...state.currentDeviceList };
      currentDeviceListss.Information = { ...payload };
      // state.currentDeviceList.Information = {};
      // state.currentDeviceList.Information = payload;

      return { ...state, currentDeviceList: currentDeviceListss };
    case DEVICELIST_MODAL_CLOSE:
      return {
        ...state,
        openAddDeviceModal: false,
        currentDeviceList: {},
        tempTypeNameList: [],
        isEdit: false,
        isDuplication: false,
      };
    case DEVICELIST_EDITINPUT_MODAL_CLOSE:
      return {
        ...state,
        modalEditInputVisible: false,
        modalEditInputEditData: null,
      };
    case DEVICELIST_MODAL_SAVECONFIG_OPEN:
      return {
        ...state,
        modalSaveConfigVisible: true,
      };
    case DEVICELIST_MODAL_SAVECONFIG_CLOSE:
      return {
        ...state,
        modalSaveConfigVisible: false,
      };
    case DEVICELIST_EDITINPUT_MODAL_OPEN:
      return {
        ...state,
        modalEditInputVisible: true,
        modalEditInputEditData: payload,
      };
    case DEVICELIST_DIGITALEDITINPUT_MODAL_CLOSE:
      return {
        ...state,
        modalEditDigitalInputVisible: false,
        modalEditDigitalInputEditData: null,
      };
    case DEVICELIST_DIGITALEDITINPUT_MODAL_OPEN:
      return {
        ...state,
        modalEditDigitalInputVisible: true,
        modalEditDigitalInputEditData: payload,
      };
    case DEVICELIST_MODAL_OPEN:
      return {
        ...state,
        tempTypeNameList: [...state.typeNameList],
        openAddDeviceModal: true,
      };
    default:
      return state;
  }
}
