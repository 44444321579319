import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import {
  SITE_LOADED,
  SITE_FAIL,
  SITE_NEW,
  SITE_UPDATE_DETAILS,
  SITE_UPDATE_BACKENDVERSION,
  SITE_UPDATE_BACKEND_START,
  SITE_UPDATE_BACKEND_END,
  SITE_UPDATE_BKSERVICE_START,
  SITE_UPDATE_BKSERVICE_END,
  SITE_UPDATE_HMISERVICE_START,
  SITE_UPDATE_HMISERVICE_END,
} from "./types";
import { setAlert } from "./alert";

// load site
export const loadSite = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const res = await axios.get("/api/site", { token: localStorage.token });
    // const res = await axios.get("http://localhost:5000/api/site", { token: localStorage.token });

    if (res.data.length === 0) {
      dispatch({
        type: SITE_NEW,
        payload: res.data[0],
      });
    } else {
      dispatch({
        type: SITE_LOADED,
        payload: res.data[0],
      });
    }

    // Get Backend Versions
    const backVersion = await axios.get("/api/utl/version", {
      token: localStorage.token,
    });

    dispatch({
      type: SITE_UPDATE_BACKENDVERSION,
      payload: backVersion.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: SITE_FAIL,
    });
  }
};

// create a site
export const createSite =
  (formData, history, edit = false) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      // const res = await axios.post("http://localhost:5000/api/site", formData, config);
      const res = await axios.post("/api/site", formData, config);

      dispatch({
        type: SITE_LOADED,
        payload: res.data,
      });

      dispatch(setAlert(edit ? "Site Updated" : "Site Created", "success"));

      if (!edit) {
        history.push("/dashboard");
      }
    } catch (error) {
      dispatch(setAlert("Site not saved!!", "error"));

      if (error.response) {
        if (error.response.status === 400) {
          const errors = error.response.data.errors;
          if (errors) {
            errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
          }
        }
      }
    }
  };

// up date the details
export const updateSiteDetails = (record) => (dispatch) => {
  dispatch({
    type: SITE_UPDATE_DETAILS,
    payload: record,
  });
};

// Update HMI Components
export const updateHMIComponents =
  (fromWizard = false) =>
  async (dispatch) => {
    try {
      if (fromWizard) await axios.get("/api/users/usrmanagementupdate/y");
      else {
        await axios.get("/api/users/usrmanagementupdate/n");

        dispatch(setAlert("HMI components updated!!", "success"));
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          const errors = error.response.data.errors;
          if (errors) {
            errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
          }
        }
      }
    }
  };

// Update Config Components
export const updateConfigServices = () => async (dispatch) => {
  dispatch({
    type: SITE_UPDATE_BACKEND_START,
  });

  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    await axios.post("/api/update/config", { token: localStorage.token });

    dispatch(setAlert("Services Updated, reloading page!!", "success"));

    dispatch({
      type: SITE_UPDATE_BACKEND_END,
    });

    // need a bit of time to wait for the backend to startup
    setTimeout(() => window.location.reload(), 2500);
  } catch (error) {
    dispatch(setAlert("Services not Updated!!", "error"));
    console.log(error);

    dispatch({
      type: SITE_UPDATE_BACKEND_END,
    });
  }
};

// Update HMI services Components
export const updateHMIServices = () => async (dispatch) => {
  dispatch({
    type: SITE_UPDATE_HMISERVICE_START,
  });

  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    await axios.post("/api/update/hmiservices", { token: localStorage.token });

    dispatch(setAlert("HMI Services Updated", "success"));

    dispatch({
      type: SITE_UPDATE_HMISERVICE_END,
    });
  } catch (error) {
    dispatch(setAlert("HMI Services not Updated!!", "error"));
    console.log(error);

    dispatch({
      type: SITE_UPDATE_HMISERVICE_END,
    });
  }
};

// Update Backend Components
export const updateBackendServices = () => async (dispatch) => {
  dispatch({
    type: SITE_UPDATE_BKSERVICE_START,
  });

  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    await axios.post("/api/update/backend", { token: localStorage.token });

    dispatch(setAlert("Backend services updated!!", "success"));

    dispatch({
      type: SITE_UPDATE_BKSERVICE_END,
    });
  } catch (error) {
    dispatch(setAlert("Backend services not Updated!!", "error"));
    console.log(error);

    dispatch({
      type: SITE_UPDATE_BKSERVICE_END,
    });
  }
};
