import React, { Fragment, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Table, Button } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchTemplates, updateClients } from "../../actions/client";

const Layout = ({
  client: { clientsList, dataloaded },
  fetchTemplates,
  auth: { user },
  updateClients,
  permissions,
}) => {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Date Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <p>{text.replace("T", " ").replace("Z", "")}</p>,
    },
  ];

  useEffect(() => {
    fetchTemplates();
  }, [fetchTemplates]);

  // If we are already authenticated
  if (user === null) {
    return <Redirect to="/" />;
  }

  return (
    <Fragment>
      <h1 className="large text-primary">Clients List</h1>
      <Table
        columns={columns}
        dataSource={clientsList}
        size="small"
        pagination={{ pageSize: 40 }}
        scroll={{ y: 600 }}
        loading={!dataloaded && clientsList === null ? true : false}
      />
      {permissions && permissions.update && (
        <Button type="primary" onClick={updateClients}>
          Update
        </Button>
      )}
    </Fragment>
  );
};

Layout.propTypes = {
  client: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  fetchTemplates: PropTypes.func.isRequired,
  updateClients: PropTypes.func.isRequired,
  permissions: PropTypes.object,
};

const mapStateToProps = (state) => ({
  client: state.client,
  auth: state.auth,
  permissions: state.auth.templatePermissions,
});

export default connect(mapStateToProps, { fetchTemplates, updateClients })(
  Layout
);
