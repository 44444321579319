import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";

// Functions
import {
  openModal,
  deleteListItem,
  EditListItem,
  DuplicateListItem,
  updateBackendConfiguration,
  getConfigurationFiles,
  createTypeNameList,
  getDeviceStatus,
  OpenSaveConfigModal,
  GetSelectedConfig,
  ClearDeviceList,
} from "../../../../actions/deviceList";
import { updateBackend } from "../../../../actions/modbusconfig";
import { updateSNMPBackend } from "../../../../actions/SNMPConfig";
import { getDeviceTypesShort } from "../../../../actions/deviceTypes";
import { CreateAlarmsList } from "../../../../actions/alarms";
import { fetchGroups } from "../../../../actions/templates";

// Components
import AddDeviceModal from "./addDeviceModal";
import TableConfigList from "./tableConfigList";
import SaveConfigModal from "./saveConfigModal";

// Ant design
import {
  Typography,
  Table,
  Button,
  Space,
  Popconfirm,
  Tooltip,
  Row,
  Col,
  Upload,
} from "antd";
import {
  CopyOutlined,
  EditFilled,
  DeleteFilled,
  DownloadOutlined,
  UploadOutlined,
  SaveOutlined,
  PlusCircleOutlined,
  ClearOutlined,
} from "@ant-design/icons";

// style sheet
import "./Devices.css";

// Constants
const { Title, Paragraph, Text } = Typography;

// actual component
const DevicesToRead = ({
  openModal,
  currentList,
  EditListItem,
  deleteListItem,
  DuplicateListItem,
  updateBackendConfiguration,
  getConfigurationFiles,
  getDeviceTypesShort,
  sitedetails,
  fetchGroups,
  alarmListIsSaving,
  deviceListIsSaving,
  getDeviceStatus,
  fromWizard,
  permissions,
  OpenSaveConfigModal,
  GetSelectedConfig,
  newConfig,
  ClearDeviceList,
}) => {
  const [deviceTypes, setDeviceTypes] = useState([]);

  // Load Effect
  useEffect(() => {
    const getTypes = async () => {
      const devTypes = await axios.get("/api/devtemplates/devicetypes");

      setDeviceTypes(devTypes.data);
    };

    getTypes();
    getDeviceTypesShort();
    getConfigurationFiles();
    fetchGroups();

    // Constant poll for the device status of the devices
    if (fromWizard === undefined) {
      const timerID = setInterval(() => {
        getDeviceStatus();
      }, 2000);
      sessionStorage.setItem("intervalTimerId", timerID);
    }
  }, [
    getConfigurationFiles,
    setDeviceTypes,
    fetchGroups,
    getDeviceTypesShort,
    getDeviceStatus,
    fromWizard,
  ]);

  // unload Effect
  useEffect(() => {
    return () => {
      clearInterval(sessionStorage.getItem("intervalTimerId"));
      sessionStorage.removeItem("intervalTimerId");
      if (fromWizard === undefined) ClearDeviceList();
    };
  }, [ClearDeviceList, fromWizard]);

  const columns = [
    {
      title: "Name",
      dataIndex: ["Information", "deviceName"],
      key: "deviceName",
      render: (text, record) => {
        return {
          props: {
            style: {
              color:
                record.Information.deviceType !== "Input Board" &&
                record.Information.deviceType !== "Analogue Input Board" &&
                record.Information.deviceType !== "Environment Board"
                  ? "black"
                  : "white",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Device Type",
      dataIndex: ["Information", "deviceType"],
      key: "deviceType",
      filters: deviceTypes.map((item) => ({
        // text: item.charAt(0) + item.slice(1).toLowerCase(),
        text: item,
        value: item,
      })),
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      onFilter: (value, record) =>
        record.Information.deviceType.indexOf(value) === 0,
    },
    {
      title: "Protocal",
      dataIndex: ["Information", "deviceCommType"],
      key: "protocal",
      filters: [
        {
          text: "Modbus",
          value: "Modbus",
        },
        { text: "SNMP", value: "SNMP" },
      ],
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      onFilter: (value, record) =>
        record.Information.deviceCommType.indexOf(value) === 0,
    },
    {
      title: "Interface",
      dataIndex: ["Information", "deviceInterfaceType"],
      key: "deviceInterfaceType",
      filters: [
        {
          text: "RTU",
          value: "RTU",
        },
        { text: "TCP", value: "TCP" },
      ],
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      onFilter: (value, record) =>
        record.Information.deviceInterfaceType &&
        record.Information.deviceInterfaceType.indexOf(value) === 0,
    },
    {
      title: "IP/RS485 ID",
      dataIndex: ["Information", "deviceIPSlaveID"],
      key: "deviceIPSlaveID",
    },
    {
      title: "Device connection Status",
      dataIndex: "commStaus",
      key: "commStaus",
      filters: [
        {
          text: "Active",
          value: "active",
        },
        { text: "Failed", value: "failed" },
      ],
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      onFilter: (value, record) =>
        record.commStaus &&
        record.commStaus.toString().toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (text, record) => (
        <Space size="middle">
          {permissions && permissions.write && (
            <Fragment>
              {permissions.delete && (
                <Popconfirm
                  title="Sure to delete?"
                  onConfirm={() => handleDelete(record)}
                >
                  <Tooltip title="Delete device">
                    <a href="#!">
                      <DeleteFilled />
                    </a>
                  </Tooltip>
                </Popconfirm>
              )}
              <Tooltip title="Edit device">
                <a href="#!" onClick={() => handleEdit(record)}>
                  <EditFilled />
                </a>
              </Tooltip>
              <Tooltip title="Duplicate/Copy device">
                <a href="#!" onClick={() => handleDuplicate(record)}>
                  <CopyOutlined />
                </a>
              </Tooltip>
            </Fragment>
          )}
        </Space>
      ),
    },
  ];

  const wizardColumns = [
    {
      title: "Name",
      dataIndex: ["Information", "deviceName"],
      key: "deviceName",
      render: (text, record) => {
        return {
          props: {
            style: {
              color:
                record.Information.deviceType !== "Input Board" &&
                record.Information.deviceType !== "Analogue Input Board" &&
                record.Information.deviceType !== "Environment Board"
                  ? "black"
                  : "white",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Device Type",
      dataIndex: ["Information", "deviceType"],
      key: "deviceType",
      filters: deviceTypes.map((item) => ({
        // text: item.charAt(0) + item.slice(1).toLowerCase(),
        text: item,
        value: item,
      })),
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      onFilter: (value, record) =>
        record.Information.deviceType.indexOf(value) === 0,
    },
    {
      title: "Protocal",
      dataIndex: ["Information", "deviceCommType"],
      key: "protocal",
      filters: [
        {
          text: "Modbus",
          value: "Modbus",
        },
        { text: "SNMP", value: "SNMP" },
      ],
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      onFilter: (value, record) =>
        record.Information.deviceCommType.indexOf(value) === 0,
    },
    {
      title: "Interface",
      dataIndex: ["Information", "deviceInterfaceType"],
      key: "deviceInterfaceType",
      filters: [
        {
          text: "RTU",
          value: "RTU",
        },
        { text: "TCP", value: "TCP" },
      ],
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      onFilter: (value, record) =>
        record.Information.deviceInterfaceType &&
        record.Information.deviceInterfaceType.indexOf(value) === 0,
    },
    {
      title: "IP/RS485 ID",
      dataIndex: ["Information", "deviceIPSlaveID"],
      key: "deviceIPSlaveID",
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (text, record) => (
        <Space size="middle">
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record)}
          >
            <Tooltip title="Delete device">
              <a href="#!">
                <DeleteFilled />
              </a>
            </Tooltip>
          </Popconfirm>
          <Tooltip title="Edit device">
            <a href="#!" onClick={() => handleEdit(record)}>
              <EditFilled />
            </a>
          </Tooltip>
          <Tooltip title="Duplicate/Copy device">
            <a href="#!" onClick={() => handleDuplicate(record)}>
              <CopyOutlined />
            </a>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const handleClearList = () => {
    ClearDeviceList();
  };

  const handleDelete = (record) => {
    deleteListItem(record);
  };

  const handleDuplicate = (record) => {
    let duplicationRecord = {};
    duplicationRecord.Information = { ...record.Information };
    duplicationRecord.Details = { ...record.Details };
    duplicationRecord.Information.deviceName = null;
    duplicationRecord.Information.deviceIPSlaveID = "";

    // The temperatue board Child mapping needs extra work
    if (
      duplicationRecord.Information.deviceType === "Environment Board" ||
      duplicationRecord.Information.deviceType === "Input Board"
    ) {
      duplicationRecord.Details = undefined;
    }
    DuplicateListItem(duplicationRecord);
  };

  const handleEdit = (record) => {
    EditListItem(record);
  };

  const handleAddDevice = () => {
    openModal();
  };

  const handelUpload = ({ fileList }) => {
    let reader = new FileReader();

    // when the conversion is finsished this will run
    reader.onload = (e) => {
      const newConfigurationFile = [JSON.parse(e.target.result)];

      GetSelectedConfig(newConfigurationFile, true, false);
    };

    // convert the blob to a texxt file
    reader.readAsText(fileList[0].originFileObj);
  };

  const UploaderProps = {
    name: "file",
    maxCount: 1,
    showUploadList: false,
    accept: ".json",
  };

  const HandleExport = async () => {
    const d = new Date();
    var utc = d.toJSON().slice(0, 10);
    var hours = d.getHours() + "h" + d.getMinutes();

    const fileName = sitedetails.name + " Devices-" + utc + " " + hours;
    const json = JSON.stringify(currentList, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const href = await URL.createObjectURL(blob);
    const link = document.createElement("a");

    link.href = href;
    link.download = fileName + ".json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Fragment>
      {fromWizard === undefined ? (
        <Fragment>
          <Title level={2}>Devices to read</Title>
          {permissions && permissions.write && (
            <Fragment>
              <Paragraph>
                Use the below to select different configuration versions
              </Paragraph>

              <TableConfigList />
            </Fragment>
          )}

          {permissions && permissions.write && (
            <Paragraph style={{ marginTop: "10px" }}>
              Use the below to edit the list of devices to read, the list will
              not be saved to the device until you press on the{" "}
              <Text keyboard>Save List</Text> button
            </Paragraph>
          )}

          <Space style={{ marginBottom: "10px" }}>
            {permissions && permissions.write && (
              <Fragment>
                <Tooltip title="Click to add a new device to the list">
                  <Button
                    type="primary"
                    onClick={handleAddDevice}
                    icon={<PlusCircleOutlined />}
                  >
                    Add device
                  </Button>
                </Tooltip>
                <Tooltip title="Click to clear the list but will not save it">
                  <Button
                    type="primary"
                    onClick={handleClearList}
                    icon={<ClearOutlined />}
                  >
                    Clear
                  </Button>
                </Tooltip>
                <Tooltip title="Save this configuration to the device to start using it">
                  <Button
                    type="primary"
                    disabled={
                      currentList && Object.keys(currentList).length === 0
                    }
                    onClick={OpenSaveConfigModal}
                    // onClick={handleSave}
                    loading={alarmListIsSaving || deviceListIsSaving}
                    icon={<SaveOutlined />}
                  >
                    Save List
                  </Button>
                </Tooltip>
              </Fragment>
            )}
            <Tooltip title="Click to download this configuration to your machine">
              <Button
                disabled={currentList && Object.keys(currentList).length === 0}
                icon={<DownloadOutlined />}
                type="primary"
                onClick={() => HandleExport()}
              >
                Export
              </Button>
            </Tooltip>
            {permissions && permissions.write && (
              <Tooltip title="Click to upload a new configuration from your machine">
                <Upload
                  {...UploaderProps}
                  onChange={handelUpload}
                  beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                >
                  <Button icon={<UploadOutlined />}>Import</Button>
                </Upload>
              </Tooltip>
            )}
          </Space>
        </Fragment>
      ) : (
        <Fragment>
          <Row style={{ marginBottom: "20px" }}>
            <Col span={8}></Col>
            <Col span={8}>
              <Typography style={{ textAlign: "center" }}>
                <Title level={2}>Devices to read</Title>
                <Paragraph>
                  This is the list of devices your UC-4 will read in the field,
                  use the <Text code>Add device</Text> button to add devices to
                  read to the list. Once you are done hit the{" "}
                  <Text code>Next</Text> button to save the list.
                </Paragraph>
                <Paragraph>
                  <Text keyboard strong type="warning">
                    NB:
                  </Text>
                  You don't need to complete this now, it can be edited later
                </Paragraph>
              </Typography>
            </Col>
            <Col span={8}></Col>
          </Row>
          <Space style={{ marginBottom: "10px" }}>
            <Tooltip title="Click to add a new device to the list">
              <Button
                type="primary"
                onClick={handleAddDevice}
                icon={<PlusCircleOutlined />}
              >
                Add device
              </Button>
            </Tooltip>
            <Tooltip title="Click to upload a new configuration from your machine">
              <Upload
                {...UploaderProps}
                onChange={handelUpload}
                beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
              >
                <Button icon={<UploadOutlined />}>Import</Button>
              </Upload>
            </Tooltip>
          </Space>
        </Fragment>
      )}

      <AddDeviceModal />

      <SaveConfigModal />

      <Table
        bordered
        size={"small"}
        scroll={{ y: fromWizard ? 500 : 320 }}
        dataSource={currentList}
        columns={fromWizard === undefined ? columns : wizardColumns}
        rowClassName={(record, _) =>
          record.commStaus &&
          record.commStaus.toLowerCase().includes("failed") &&
          "failedRow"
        }
        pagination={{
          defaultPageSize: 30,
          showSizeChanger: true,
          pageSizeOptions: ["30", "40", "50"],
        }}
      />
    </Fragment>
  );
};

DevicesToRead.propTypes = {
  currentList: PropTypes.array,
  openModal: PropTypes.func.isRequired,
  EditListItem: PropTypes.func.isRequired,
  updateSNMPBackend: PropTypes.func.isRequired,
  updateBackend: PropTypes.func.isRequired,
  getDeviceTypesShort: PropTypes.func.isRequired,
  deleteListItem: PropTypes.func.isRequired,
  getConfigurationFiles: PropTypes.func.isRequired,
  updateBackendConfiguration: PropTypes.func.isRequired,
  DuplicateListItem: PropTypes.func.isRequired,
  CreateAlarmsList: PropTypes.func.isRequired,
  createTypeNameList: PropTypes.func.isRequired,
  fetchGroups: PropTypes.func.isRequired,
  alarmListIsSaving: PropTypes.bool,
  deviceListIsSaving: PropTypes.bool,
  getDeviceStatus: PropTypes.func.isRequired,
  OpenSaveConfigModal: PropTypes.func.isRequired,
  GetSelectedConfig: PropTypes.func.isRequired,
  permissions: PropTypes.object,
  newConfig: PropTypes.array,
  ClearDeviceList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  currentList: state.deviceList.deviceToRead,
  newConfig: state.deviceList.allConfigFiles,
  deviceListIsSaving: state.deviceList.isSaving,
  sitedetails: state.site.siteDetails,
  alarmListIsSaving: state.alarms.saving,
  permissions: state.auth.permissions,
});

export default connect(mapStateToProps, {
  openModal,
  EditListItem,
  deleteListItem,
  updateBackend,
  DuplicateListItem,
  updateBackendConfiguration,
  getConfigurationFiles,
  updateSNMPBackend,
  getDeviceTypesShort,
  CreateAlarmsList,
  createTypeNameList,
  fetchGroups,
  getDeviceStatus,
  OpenSaveConfigModal,
  GetSelectedConfig,
  ClearDeviceList,
})(DevicesToRead);
