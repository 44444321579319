import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Ant design
import { Select, Form, Input, Divider, InputNumber } from "antd";

// External Functions
import { getAllCalculation } from "../../../../actions/calculations";
import { updateDeviceDetails } from "../../../../actions/deviceList";

// Actual component
const FuelSensor = ({
  deviceList,
  availableCalcs,
  updateDeviceDetails,
  getAllCalculation,
}) => {
  const [sensorDetail, setSensorDetail] = useState(deviceList);
  const [calcDetails, setCalcDetails] = useState(null);
  const [formEditInputDetails] = Form.useForm();

  useEffect(() => {
    getAllCalculation();
  }, [getAllCalculation]);

  const setConstantFromCalc = (calcData) => {
    if (availableCalcs) {
      const indexOfCalc = availableCalcs.findIndex(
        (calc) => calc.calculationName === calcData
      );

      if (indexOfCalc !== -1) {
        setCalcDetails(availableCalcs[indexOfCalc]);
        formEditInputDetails.setFieldsValue({
          constants: [...availableCalcs[indexOfCalc].constant],
        });
      }
    }
  };

  if (deviceList) {
    if (deviceList.calculation !== undefined) {
      if (calcDetails === null) {
        setConstantFromCalc(deviceList.calculation);
        formEditInputDetails.resetFields();
      }
    }
  }

  // Calculation Types select
  const CalcTypeList =
    availableCalcs !== null
      ? availableCalcs.map(
          (calc, _) =>
            !calc.noInputRequired &&
            !calc.outputNeedsName && (
              <Select.Option value={calc.calculationName} key={calc._id}>
                {calc.calculationName}
              </Select.Option>
            )
        )
      : null;

  return (
    <Form
      form={formEditInputDetails}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 12 }}
      layout="horizontal"
      initialValues={deviceList}
      onValuesChange={(_, all) => {
        setSensorDetail(all);
        updateDeviceDetails({
          ...all,
          calculationID: calcDetails && calcDetails._id,
        });

        if (all) {
          if (all.calculation) {
            if (all.constants === undefined) {
              setConstantFromCalc(all.calculation);
            }

            // if the user switches between calc
            if (deviceList && all.calculation !== deviceList.calculation) {
              setConstantFromCalc(all.calculation);
            }
          } else {
            if (calcDetails) setCalcDetails(null);
            formEditInputDetails.setFieldsValue({
              constants: undefined,
            });

            formEditInputDetails.setFieldsValue({
              calculationDescription: undefined,
            });

            formEditInputDetails.setFieldsValue({
              calculation: undefined,
            });

            setTimeout(() => {
              setCalcDetails(null);
              formEditInputDetails.resetFields();
            }, 200);
          }
        }
      }}
      size="small"
    >
      <Form.Item label="Calculation" name="calculation">
        <Select allowClear showSearch>
          {CalcTypeList}
        </Select>
      </Form.Item>
      {calcDetails && calcDetails.calculationDescription && (
        <Form.Item label="Description">
          <Input.TextArea
            rows={3}
            disabled
            value={calcDetails && calcDetails.calculationDescription}
          />
        </Form.Item>
      )}

      {sensorDetail && sensorDetail.calculation && (
        <Fragment>
          <Divider>Calculation Inputs</Divider>
          <Form.List name="constants">
            {(fields, { add, remove }) => (
              <>
                {fields.map((data, index) => (
                  <Form.Item
                    {...data}
                    name={[data.name, "value"]}
                    key={[data.key, index]}
                    label={calcDetails && calcDetails.constant[index].name}
                  >
                    <InputNumber />
                  </Form.Item>
                ))}
              </>
            )}
          </Form.List>
        </Fragment>
      )}
    </Form>
  );
};

FuelSensor.propTypes = {
  availableCalcs: PropTypes.array,
  deviceList: PropTypes.object,
  updateDeviceDetails: PropTypes.func.isRequired,
  getAllCalculation: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  availableCalcs: state.calcs.allCalcs,
  deviceList: state.deviceList.currentDeviceList.Details,
});

export default connect(mapStateToProps, {
  updateDeviceDetails,
  getAllCalculation,
})(FuelSensor);
