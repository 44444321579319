import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// ant Design Components
import { Form, Input, DatePicker, InputNumber } from "antd";

// Functions
import { updateDeviceDetails } from "../../../../actions/deviceList";

// Actual Compone
const ModalDetailsRectifier = ({ deviceList, updateDeviceDetails }) => {
  return (
    <Form
      style={{ marginTop: "20px" }}
      name="DeviceInfo"
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 6 }}
      layout="horizontal"
      initialValues={deviceList}
      onValuesChange={(_, all) => {
        updateDeviceDetails(all);
      }}
      size="small"
    >
      <Form.Item label="Rectifer Total Size" name="size">
        <Input />
      </Form.Item>
      <Form.Item label="Serial Number" name="serial">
        <Input />
      </Form.Item>
      <Form.Item label="Hardware Version Number" name="hwVersionNumber">
        <Input />
      </Form.Item>
      <Form.Item label="Software Version Number" name="swVersionNumber">
        <Input />
      </Form.Item>
      <Form.Item label="Amount of modules" name="amountOfModules">
        <InputNumber min={1} />
      </Form.Item>
      <Form.Item label="Commisioned" name="commisionedDate">
        <DatePicker />
      </Form.Item>
      <Form.Item label="Last Service" name="lastServiceDate">
        <DatePicker />
      </Form.Item>
    </Form>
  );
};

ModalDetailsRectifier.propTypes = {
  updateDeviceDetails: PropTypes.func.isRequired,
  deviceList: PropTypes.object,
};

const mapStateToProps = (state) => ({
  deviceList: state.deviceList.currentDeviceList.Details,
});

export default connect(mapStateToProps, { updateDeviceDetails })(
  ModalDetailsRectifier
);
