import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Antd comonent
import { Form, Input, InputNumber, Switch, Space, Button, Tooltip } from "antd";
import { SaveOutlined } from "@ant-design/icons";

// extenerl functions
import { createSite } from "../../actions/site";

// Actual componet
const TabCloud = ({ permissions, site, createSite }) => {
  const [formData, setFormData] = useState();
  const [settingsForm] = Form.useForm();

  useEffect(() => {
    setFormData(site);
    setTimeout(() => {
      settingsForm.resetFields();
      if (site) {
        settingsForm.setFieldsValue({
          intervalSend: site?.serverConfiguration?.intervalSend,
          deviationAmount: site?.serverConfiguration?.deviationAmount,
          refreshRate: site?.serverConfiguration?.refreshRate,
        });
      }
    }, 200);
  }, [settingsForm, site]);

  const handleDefaults = () => {
    settingsForm.setFieldsValue({
      cloud_URL: "http://mpt.dedicated.co.za",
      cloud_port: 1880,
      legacySiteId: null,
      useLegacyServer: false,
      intervalSend: 10,
      deviationAmount: 10,
      refreshRate: 10,
    });

    setFormData({
      cloud_URL: "http://mpt.dedicated.co.za",
      cloud_port: 1880,
      intervalSend: 10,
      deviationAmount: 10,
      refreshRate: 10,
    });
  };

  const handleSave = () => {
    settingsForm.validateFields().then(() => {
      let newSiteDetails = { ...site, ...formData, client_name: site.client };

      if (newSiteDetails.useLegacyServer === false) {
        if (newSiteDetails.legacySiteId) delete newSiteDetails.legacySiteId;
        if (newSiteDetails.legacyClientId) delete newSiteDetails.legacyClientId;
      }
      createSite(newSiteDetails, true, true);
    });
  };

  const handleSwitch = (e) => {
    if (e) {
      setFormData({ ...formData, useLegacyServer: true });
    } else {
      setFormData({ ...formData, useLegacyServer: false });
    }
  };

  return (
    <Form
      form={settingsForm}
      initialValues={formData}
      onValuesChange={(_, AllValues) => {
        setFormData(AllValues);
      }}
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 5 }}
      size="small"
    >
      <Form.Item
        label="Cloud URL/IP"
        name="cloud_URL"
        style={{ marginBottom: "5px" }}
        rules={[{ required: true, message: "Needs a cloud address" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Cloud Port"
        name="cloud_port"
        style={{ marginBottom: "5px" }}
        rules={[{ required: true, message: "Needs a port number" }]}
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        label="Value send interval"
        name="intervalSend"
        tooltip="The rate in seconds this controller will send data to the cloud"
        style={{ marginBottom: "5px" }}
        rules={[{ required: true, message: "Can't be blank" }]}
      >
        <InputNumber step={1} min={1} />
      </Form.Item>
      <Form.Item
        label="Deviation amount"
        name="deviationAmount"
        tooltip="On most values, to save data on sending it will only send the value if it is more than this amount"
        rules={[{ required: true, message: "Can't be blank" }]}
        style={{ marginBottom: "5px" }}
      >
        <InputNumber step={1} min={1} />
      </Form.Item>
      <Form.Item
        label="Refresh rate"
        name="refreshRate"
        tooltip="Refresh in seconds"
        style={{ marginBottom: "5px" }}
        rules={[{ required: true, message: "Can't be blank" }]}
      >
        <InputNumber step={1} min={1} />
      </Form.Item>

      <Form.Item label="Use Legacy server" name="useLegacyServer">
        <Switch
          checked={formData && formData.useLegacyServer}
          onClick={handleSwitch}
        />
      </Form.Item>
      {formData && formData.useLegacyServer === true && (
        <Fragment>
          <Form.Item
            label="Legacy Site ID"
            name="legacySiteId"
            style={{ marginBottom: "5px" }}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="Legacy Client ID"
            name="legacyClientId"
            style={{ marginBottom: "5px" }}
          >
            <InputNumber />
          </Form.Item>
        </Fragment>
      )}

      {permissions && permissions.write && (
        <Form.Item wrapperCol={{ offset: 9, span: 4 }}>
          <Space>
            <Button
              type="primary"
              onClick={handleSave}
              disabled={
                formData?.cloud_URL === "" || formData?.cloud_port === ""
              }
            >
              <SaveOutlined /> Save
            </Button>
            <Tooltip title="This will set the information above to the default settings, but will not save it, you will still need to press on the save button">
              <Button onClick={handleDefaults}>Defaults</Button>
            </Tooltip>
          </Space>
        </Form.Item>
      )}
    </Form>
  );
};

TabCloud.propTypes = {
  permissions: PropTypes.object,
  site: PropTypes.object,
  createSite: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  site: state.site.siteDetails,
  permissions: state.auth.permissions,
});

export default connect(mapStateToProps, { createSite })(TabCloud);
