import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// external Components
import { Chart } from "react-google-charts";

// antd component
import { Typography } from "antd";

const { Title, Paragraph } = Typography;

// Actual component
const ColLeft = ({ controllerInfo }) => {
  const Uptime = () => {
    let time = {
      days: 0,
      hours: 0,
      min: 0,
      seconds: 0,
    };

    const day = 86400;
    const hour = 3600;
    const minute = 60;

    let totalseconds = controllerInfo.Misc.UPtimeSeconds;

    let daysout = Math.floor(totalseconds / day);
    let hoursout = Math.floor((totalseconds - daysout * day) / hour);
    let minutesout = Math.floor(
      (totalseconds - daysout * day - hoursout * hour) / minute
    );
    let secondsout =
      totalseconds - daysout * day - hoursout * hour - minutesout * minute;

    time.days = daysout;
    time.hours = hoursout;
    time.min = minutesout;
    time.seconds = secondsout.toFixed(1);

    return (
      <Fragment>
        {time.days > 0 && time.days + " days"}
        {time.hours > 0 && " " + time.hours + " hours"}
        {time.min > 0 && " " + time.min + " min"}
        {time.seconds > 0 && " " + time.seconds + " seconds"}
      </Fragment>
    );
  };

  return (
    controllerInfo && (
      <Fragment>
        <Title level={2}>UC4 Information</Title>

        <Paragraph>
          Some information on the internal working of the UC4
        </Paragraph>

        <Paragraph>
          <strong>Uptime :</strong> <Uptime />
        </Paragraph>

        <Paragraph>
          <strong>CPU : </strong>
          {controllerInfo.CPU && controllerInfo.CPU.SpeedMhz}Mhz{", "}
          {controllerInfo.CPU && Number(controllerInfo.CPU.Throttle) > 0
            ? " throttling"
            : " not throttling"}
        </Paragraph>

        <Chart
          width={400}
          height={120}
          chartType="Gauge"
          data={[
            ["Label", "Value"],
            ["CPU", controllerInfo.CPU && controllerInfo.CPU.UsagePercentage],
            ["Temp", controllerInfo.CPU && controllerInfo.CPU.TempDeg],
            [
              "Memory",
              controllerInfo.Memory && controllerInfo.Memory.usedMemPercentage,
            ],
            ["Disk", controllerInfo.Disk && controllerInfo.Disk.usedPercentage],
          ]}
          options={{
            redFrom: 90,
            redTo: 100,
            yellowFrom: 75,
            yellowTo: 90,
            minorTicks: 5,
          }}
          rootProps={{ "data-testid": "1" }}
        />
      </Fragment>
    )
  );
};

ColLeft.propTypes = {
  controllerInfo: PropTypes.object,
};

const mapStateToProps = (state) => ({
  controllerInfo: state.utl.UCInfo,
});

export default connect(mapStateToProps)(ColLeft);
