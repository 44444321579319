export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const USER_LOADED = "USER_LOADED";
export const USER_PERMISIONS = "USER_PERMISIONS";
export const USER_TEMPLATE_PERMISIONS = "USER_TEMPLATE_PERMISIONS";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_MODAL_OPEN = "LOGIN_MODAL_OPEN";
export const LOGIN_MODAL_CLOSE = "LOGIN_MODAL_CLOSE";
export const LOGIN_LOAD = "LOGIN_LOAD";
export const LOGOUT = "LOGOUT";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const CLEAR_PROFILE = "CLEAR_PROFILE";

export const SITE_LOADED = "SITE_LOADED";
export const SITE_NEW = "SITE_NEW";
export const SITE_FAIL = "SITE_FAIL";
export const SITE_CLEAR = "SITE_CLEAR";
export const SITE_UPDATE_DETAILS = "SITE_UPDATE_DETAILS";
export const SITE_UPDATE_BACKENDVERSION = "SITE_UPDATE_BACKENDVERSION";
export const SITE_UPDATE_BACKEND_START = "SITE_UPDATE_BACKEND_START";
export const SITE_UPDATE_BACKEND_END = "SITE_UPDATE_BACKEND_END";
export const SITE_UPDATE_BKSERVICE_START = "SITE_UPDATE_BKSERVICE_START";
export const SITE_UPDATE_BKSERVICE_END = "SITE_UPDATE_BKSERVICE_END";
export const SITE_UPDATE_HMISERVICE_START = "SITE_UPDATE_HMISERVICE_START";
export const SITE_UPDATE_HMISERVICE_END = "SITE_UPDATE_HMISERVICE_END";

export const CLIENT_CLEAR = "CLIENT_CLEAR";
export const CLIENT_FAIL = "CLIENT_FAIL";
export const CLIENT_LOADING = "CLIENT_LOADING";
export const CLIENT_UPDATED = "CLIENT_UPDATED";
export const CLIENT_SITE_UPDATE = "CLIENT_SITE_UPDATE";
export const CLIENT_SITE_LOADING = "CLIENT_SITE_LOADING";
export const CLIENT_SITE_FAIL = "CLIENT_SITE_FAIL";

export const CONFIG_OPEN = "CONFIG_OPEN";
export const CONFIG_CLOSE = "CONFIG_CLOSE";
export const CONFIG_UPDATE = "CONFIG_UPDATE";
export const CONFIG_ADD = "CONFIG_ADD";
export const CONFIG_CURRENT_ADD = "CONFIG_CURRENT_ADD";
export const CONFIG_GET = "CONFIG_GET";
export const CONFIG_DETAILS = "CONFIG_DETAILS";

export const CONFIG_SNMP_UPDATE = "CONFIG_SNMP_UPDATE";
export const CONFIG_SNMP_GET = "CONFIG_SNMP_GET";

export const USERS_LOADING = "USERS_LOADING";
export const USERS_UPDATED = "USERS_UPDATED";
export const USERS_FAIL = "USERS_FAIL";

export const TEMPLATE_MODAL_CLOSE = "TEMPLATE_MODAL_CLOSE";
export const TEMPLATE_MODAL_OPEN = "TEMPLATE_MODAL_OPEN";
export const TEMPLATE_MODAL_MODBUSMAPPING_OPEN =
  "TEMPLATE_MODAL_MODBUSMAPPING_OPEN";
export const TEMPLATE_MODAL_MODBUSMAPPING_CLOSE =
  "TEMPLATE_MODAL_MODBUSMAPPING_CLOSE";
export const TEMPLATE_UPDATE_MAP = "TEMPLATE_UPDATE_MAP";
export const TEMPLATE_UPDATE_ALARM = "TEMPLATE_UPDATE_ALARM";
export const TEMPLATE_CREATE_DETAILS = "TEMPLATE_CREATE_DETAILS";
export const TEMPLATE_UPDATED = "TEMPLATE_UPDATED";
export const TEMPLATE_LOADING = "TEMPLATE_LOADING";
export const TEMPLATE_FAIL = "TEMPLATE_FAIL";
export const TEMPLATE_CLEAR = "TEMPLATE_CLEAR";
export const TEMPLATE_MODAL_BATCH_CLOSE = "TEMPLATE_MODAL_BATCH_CLOSE";
export const TEMPLATE_MODAL_BATCH_OPEN = "TEMPLATE_MODAL_BATCH_OPEN";

export const TEMPLATE_MODAL_ALARMEDIT_OPEN = "TEMPLATE_MODAL_ALARMEDIT_OPEN";
export const TEMPLATE_MODAL_ALARMEDIT_CLOSE = "TEMPLATE_MODAL_ALARMEDIT_CLOSE";
export const TEMPLATE_ALARMEDIT_UPDATE = "TEMPLATE_ALARMEDIT_UPDATE";
export const TEMPLATE_MODAL_COPYALA_CLOSE = "TYPE_MODAL_COPYALA_CLOSE";
export const TEMPLATE_MODAL_COPYALA_OPEN = "TYPE_MODAL_COPYALA_OPEN";
export const TEMPLATE_MODAL_CALCADD_OPEN = "TEMPLATE_MODAL_CALCADD_OPEN";
export const TEMPLATE_MODAL_CALCADD_CLOSE = "TEMPLATE_MODAL_CALCADD_CLOSE";
export const TEMPLATE_UPDATE_CALC = "TEMPLATE_UPDATE_CALC";

export const BUSSES_UPDATE = "BUSSES_UPDATE";
export const BUSSES_LOADING = "BUSSES_LOADING";

export const TYPE_FAIL = "TYPE_FAIL";
export const TYPE_UPDATED_DETAILED = "TYPE_UPDATED_DETAILED";
export const TYPE_UPDATED_SHORT = "TYPE_UPDATED_SHORT";
export const TYPE_LOADING_DETAILED = "TYPE_LOADING_DETAILED";
export const TYPE_LOADING_SHORT = "TYPE_LOADING_SHORT";
export const TYPE_MODAL_OPEN = "TYPE_MODAL_OPEN";
export const TYPE_MODAL_CLOSE = "TYPE_MODAL_CLOSE";
export const TYPE_MODAL_OPEN_EDIT = "TYPE_MODAL_OPEN_EDIT";
export const TYPE_MODAL_ADDVAR_OPEN = "TYPE_MODAL_ADDVAR_OPEN";
export const TYPE_MODAL_ADDVAR_CLOSE = "TYPE_MODAL_ADDVAR_CLOSE";
export const TYPE_UPDATE_CURRENT = "TYPE_UPDATE_CURRENT";
export const TYPE_MODAL_ADDALA_CLOSE = "TYPE_MODAL_ADDALA_CLOSE";
export const TYPE_MODAL_ADDALA_OPEN = "TYPE_MODAL_ADDALA_OPEN";

export const GROUPS_LOADING = "GROUPS_LOADING";
export const GROUPS_UPDATED = "GROUPS_UPDATED";
export const GROUPS_FAIL = "GROUPS_FAIL";

export const MANUFACTURE_LOADING = "MANUFACTURE_LOADING";
export const MANUFACTURE_UPDATED = "MANUFACTURE_UPDATED";
export const MANUFACTURE_FAIL = "MANUFACTURE_FAIL";

export const ALARMS_LOADING = "ALARMS_LOADING";
export const ALARMS_UPDATED = "ALARMS_UPDATED";
export const ALARMS_SAVING = "ALARMS_SAVING";
export const ALARMS_MODAL_OPEN = "ALARMS_MODAL_OPEN";
export const ALARMS_MODAL_CLOSE = "ALARMS_MODAL_CLOSE";
export const ALARMS_EDIT = "ALARMS_EDIT";

export const DEVICELIST_EDITINPUT_MODAL_OPEN =
  "DEVICELIST_EDITINPUT_MODAL_OPEN";
export const DEVICELIST_EDITINPUT_MODAL_CLOSE =
  "DEVICELIST_EDITINPUT_MODAL_CLOSE";
export const DEVICELIST_DIGITALEDITINPUT_MODAL_OPEN =
  "DEVICELIST_DIGITALEDITINPUT_MODAL_OPEN";
export const DEVICELIST_DIGITALEDITINPUT_MODAL_CLOSE =
  "DEVICELIST_DIGITALEDITINPUT_MODAL_CLOSE";
export const DEVICELIST_MODAL_OPEN = "DEVICELIST_MODAL_OPEN";
export const DEVICELIST_MODAL_CLOSE = "DEVICELIST_MODAL_CLOSE";
export const DEVICELIST_MODAL_SAVECONFIG_OPEN =
  "DEVICELIST_MODAL_SAVECONFIG_OPEN";
export const DEVICELIST_MODAL_SAVECONFIG_CLOSE =
  "DEVICELIST_MODAL_SAVECONFIG_CLOSE";
export const DEVICELIST_CLEAR = "DEVICELIST_CLEAR";
export const DEVICELIST_SELECT_CONFIG = "DEVICELIST_SELECT_CONFIG";
export const DEVICELIST_UPDATE_INFO = "DEVICELIST_UPDATE_INFO";
export const DEVICELIST_DEVICE_STATUS = "DEVICELIST_DEVICE_STATUS";
export const DEVICELIST_UPDATE_DETAILS = "DEVICELIST_UPDATE_DETAILS";
export const DEVICELIST_UPDATE_LIST = "DEVICELIST_UPDATE_LIST";
export const DEVICELIST_CREATE_TYPENAMELIST = "DEVICELIST_CREATE_TYPENAMELIST";
export const DEVICELIST_ADD_TYPENAMELIST = "DEVICELIST_ADD_TYPENAMELIST";
export const DEVICELIST_ADD_IPRTULIST = "DEVICELIST_ADD_IPRTULIST";
export const DEVICELIST_CREATE_IPRTULIST = "DEVICELIST_CREATE_IPRTULIST";
export const DEVICELIST_UPDATE_LISTTOREAD = "DEVICELIST_UPDATE_LISTTOREAD";
export const DEVICELIST_UPDATE_TYPENAMELIST = "DEVICELIST_UPDATE_TYPENAMELIST";
export const DEVICELIST_IS_SAVING = "DEVICELIST_IS_SAVING";
export const DEVICELIST_UPDATE_TEMPTYPENAMELIST =
  "DEVICELIST_UPDATE_TEMPTYPENAMELIST";
export const DEVICELIST_MODAL_OPEN_EDIT = "DEVICELIST_MODAL_OPEN_EDIT";
export const DEVICELIST_DELETE_LISTITEM = "DEVICELIST_DELETE_LISTITEM";
export const DEVICELIST_MODAL_OPEN_DUPLICATE =
  "DEVICELIST_MODAL_OPEN_DUPLICATE";
export const DEVICELIST_UPDATE_LISTTOREAD_ALL =
  "DEVICELIST_UPDATE_LISTTOREAD_ALL";

export const CALC_NEWOUTPUTMODAL_OPEN = "CALC_NEWOUTPUTMODAL_OPEN";
export const CALC_NEWOUTPUTMODAL_CLOSE = "CALC_NEWOUTPUTMODAL_CLOSE";
export const CALC_NEWSTEPMODAL_OPEN = "CALC_NEWSTEPMODAL_OPEN";
export const CALC_NEWSTEPMODAL_CLOSE = "CALC_NEWSTEPMODAL_CLOSE";
export const CALC_NEWMODAL_OPEN = "CALC_NEWMODAL_OPEN";
export const CALC_NEWMODAL_CLOSED = "CALC_NEWMODAL_CLOSED";
export const CALC_CONSTMODAL_OPEN = "CALC_CONSTMODAL_OPEN";
export const CALC_CONSTMODAL_CLOSE = "CALC_CONSTMODAL_CLOSE";
export const CALC_CONST_ADD = "CALC_CONST_ADD";
export const CALC_OUTPUT_ADD = "CALC_OUTPUT_ADD";
export const CALC_OUTPUT_DELETE = "CALC_OUTPUT_DELETE";
export const CALC_CONST_DELETE = "CALC_CONST_DELETE";
export const CALC_NEWMODAL_UPDATE = "CALC_NEWMODAL_UPDATE";
export const CALC_STEP_ADD = "CALC_STEP_ADD";
export const CALC_STEP_DELETE = "CALC_STEP_DELETE";
export const CALC_UPDATED = "CALC_UPDATED";
export const CALC_UPDATING = "CALC_UPDATING";
export const CALC_FAILED = "CALC_FAILED";

export const CALC_MODAL_TEMPL_OPEN = "CALC_MODAL_TEMPL_OPEN";
export const CALC_MODAL_TEMPL_CLOSE = "CALC_MODAL_TEMPL_CLOSE";
export const CALC_MODAL_VIRTUAL_CLOSE = "CALC_MODAL_VIRTUAL_CLOSE";
export const CALC_MODAL_VIRTUAL_OPEN = "CALC_MODAL_VIRTUAL_OPEN";

export const ADV_MODAL_VIRTUAL_OPEN = "ADV_MODAL_VIRTUAL_OPEN";
export const ADV_MODAL_VIRTUAL_CLOSE = "ADV_MODAL_VIRTUAL_CLOSE";
export const ADV_MODAL_ADD_OPEN = "ADV_MODAL_ADD_OPEN";
export const ADV_MODAL_ADD_CLOSE = "ADV_MODAL_ADD_CLOSE";
export const ADV_LIST_ADD = "ADV_LIST_ADD";
export const ADV_LIST_DELETE = "ADV_LIST_DELETE";
export const ADV_LIST_SAVE = "ADV_LIST_SAVE";
export const ADV_LIST_SAVE_DONE = "ADV_LIST_SAVE_DONE";
export const ADV_LIST_LOAD = "ADV_LIST_LOAD";
export const ADV_LIST_LOAD_DONE = "ADV_LIST_LOAD_DONE";
export const ADV_LIST_GET = "ADV_LIST_GET";

export const UTL_MODEM_TOGGLE_START = "UTL_MODEM_TOGGLE_START";
export const UTL_MODEM_TOGGLE_STOP = "UTL_MODEM_TOGGLE_STOP";
export const UTL_MODEM_APNSET_START = "UTL_MODEM_APNSET_START";
export const UTL_MODEM_APNSET_STOP = "UTL_MODEM_APNSET_STOP";
export const UTL_MODAL_ADDIP_OPEN = "UTL_MODAL_ADDIP_OPEN";
export const UTL_MODAL_ADDIP_CLOSE = "UTL_MODAL_ADDIP_CLOSE";

export const UTL_IP_REFRESH = "UTL_IP_REFRESH";
export const UTL_IP_ETH_DEL = "UTL_IP_ETH_DEL";
export const UTL_IP_SAVE_START = "UTL_IP_SAVE_START";
export const UTL_IP_SAVE_END = "UTL_IP_SAVE_END";

export const UTL_UC_INFO = "UTL_UC_INFO";

export const UTL_VPN_GETKEYSTART = "UTL_VPN_GETKEYSTART";
export const UTL_VPN_KEYUPDATE = "UTL_VPN_KEYUPDATE";

export const UTL_WIFI_MODAL_CON_OPEN = "UTL_WIFI_MODAL_CON_OPEN";
export const UTL_WIFI_MODAL_CON_CLOSE = "UTL_WIFI_MODAL_CON_CLOSE";
export const UTL_WIFI_CONNECTING = "UTL_WIFI_CONNECTING";

export const DIS_MODAL_DEVEDIT_OPEN = "DIS_MODAL_DEVEDIT_OPEN";
export const DIS_MODAL_DEVEDIT_CLOSE = "DIS_MODAL_DEVEDIT_CLOSE";
export const DIS_DEVEDIT_UPDATE = "DIS_DEVEDIT_UPDATE";
export const DIS_DEVEDIT_UPDATE_END = "DIS_DEVEDIT_UPDATE_END";
