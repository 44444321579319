import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// ant design componets
import { Table, Space, Popconfirm, Button, Tooltip, Typography } from "antd";

// Other components
import AddVarModal from "./deviceTypeCreateVariable";

// Functions
import {
  openTypeAddVarModal,
  updateCurrentType,
} from "../../actions/deviceTypes";

const { Title, Paragraph, Text } = Typography;

// actual function
const DeviceTypeVariables = ({
  editTypeDetials,
  openTypeAddVarModal,
  updateCurrentType,
  permissions,
}) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    let test = [];

    if (editTypeDetials) {
      editTypeDetials.vairables.forEach((varItem, itemCount) =>
        test.push({ ...varItem, key: itemCount })
      );
    }

    setTableData(test);
  }, [editTypeDetials]);

  // table columbs
  const columnsTable = [
    {
      title: "Variable",
      dataIndex: "variable",
      key: "variable",
    },
    {
      title: "Symbol",
      dataIndex: "symbol",
      key: "symbol",
    },
    {
      title: "Children",
      children: [
        {
          title: "Name",
          dataIndex: ["ExtraChildren", "name"],
          key: "name",
        },
        {
          title: "Type",
          dataIndex: ["ExtraChildren", "type"],
          key: "type",
        },
      ],
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record) => (
        <Space size="middle">
          {permissions && permissions.delete && (
            <Popconfirm
              title={"Delete " + record.variable + " ?"}
              onConfirm={() => handleDelete(record)}
            >
              <a href="#!">Delete</a>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  const handleDelete = (item) => {
    let newList = tableData;

    newList = newList.filter((listitem) => listitem.key !== item.key);

    updateCurrentType({ ...editTypeDetials, vairables: newList });
  };

  const handelAddVariable = () => {
    openTypeAddVarModal();
  };

  return (
    <Fragment>
      <Typography>
        <Title level={3}>Variables</Title>
        <Paragraph>
          Below is a list of variables associated to this type, use the{" "}
          <Text keyboard>Add Variable</Text> button to add a new variable to the
          list
        </Paragraph>
      </Typography>
      <Tooltip title="Press to add a new variable to this type">
        <Button style={{ margin: "10px" }} onClick={handelAddVariable}>
          Add Variable
        </Button>
      </Tooltip>

      <AddVarModal />

      <Table
        dataSource={tableData}
        bordered
        columns={columnsTable}
        scroll={{ y: 300 }}
        size="small"
        pagination={{
          defaultPageSize: 30,
          showSizeChanger: true,
          pageSizeOptions: ["30", "40", "50"],
        }}
      ></Table>
    </Fragment>
  );
};

DeviceTypeVariables.propTypes = {
  editTypeDetials: PropTypes.object,
  openTypeAddVarModal: PropTypes.func.isRequired,
  updateCurrentType: PropTypes.func.isRequired,
  permissions: PropTypes.object,
};

const mapStateToProps = (state) => ({
  editTypeDetials: state.deviceTypes.editTypeDetials,
  permissions: state.auth.permissions,
});

export default connect(mapStateToProps, {
  openTypeAddVarModal,
  updateCurrentType,
})(DeviceTypeVariables);
