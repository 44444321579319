import React, { Fragment, useEffect } from "react";
import { Table, Button, Checkbox, Row, Col } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateUsers, fetchUsers } from "../../actions/users";
import { loadUser } from "../../actions/auth";

function User({
  updateUsers,
  fetchUsers,
  loadUser,
  auth: { user },
  users: { usersList, dataloaded },
  permissions,
}) {
  const FindPrem = (data) => {
    const configPrem = data.find((perm) => perm.name === "UC Configuration");

    return configPrem === undefined ? (
      <>
        <p>Not a configurator</p>
      </>
    ) : (
      <Row>
        <Col span={5}>
          <Checkbox
            value="A"
            disabled
            checked={configPrem.read === true ? true : false}
          >
            Read
          </Checkbox>
        </Col>
        <Col span={5}>
          <Checkbox
            value="Write"
            disabled
            checked={configPrem.write === true ? true : false}
          >
            Write
          </Checkbox>
        </Col>
        <Col span={5}>
          <Checkbox
            value="C"
            disabled
            checked={configPrem.update === true ? true : false}
          >
            Update
          </Checkbox>
        </Col>
        <Col>
          <Checkbox
            value="D"
            disabled
            checked={configPrem.delete === true ? true : false}
          >
            Delete
          </Checkbox>
        </Col>
      </Row>
    );
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Surname",
      dataIndex: "surname",
      key: "surname",
    },
    {
      title: "Email",
      dataIndex: "email_address",
      key: "email_address",
    },
    {
      title: "Number",
      dataIndex: "contact_number",
      key: "contact_number",
    },
    {
      title: "Activated",
      dataIndex: "activated",
      key: "activated",
      render: (data) => <p>{data === true ? "True" : "False"}</p>,
    },
    {
      title: "Date Created",
      dataIndex: "created_at",
      key: "created_at",
      responsive: ["lg"],
      render: (text) => <p>{text.replace("T", " ").replace("Z", "")}</p>,
      defaultSortOrder: "descend",
      sorter: (a, b) => a.created_at - b.created_at,
    },
    {
      title: "Premissions",
      dataIndex: "permissions",
      render: (data) => FindPrem(data),
    },
  ];

  useEffect(() => {
    loadUser();
    fetchUsers();
  }, [fetchUsers, loadUser]);

  return (
    <Fragment>
      <h1 className="large text-primary">Users List</h1>
      <Table
        columns={columns}
        dataSource={usersList}
        size="small"
        pagination={{ pageSize: 40 }}
        scroll={{ y: 600 }}
        loading={!dataloaded ? true : false}
      />
      {permissions && permissions.update && (
        <Button type="primary" onClick={updateUsers}>
          Update
        </Button>
      )}
    </Fragment>
  );
}

User.propTypes = {
  auth: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  updateUsers: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  loadUser: PropTypes.func.isRequired,
  permissions: PropTypes.object,
};

const mapStateToProps = (state) => ({
  users: state.users,
  auth: state.auth,
  permissions: state.auth.permissions,
});

export default connect(mapStateToProps, { updateUsers, fetchUsers, loadUser })(
  User
);
