import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//Antd compoonent
import { Typography, Tree } from "antd";

const { Title } = Typography;

// Actual Component
const LeftColumn = ({ sitedetails, site }) => {
  const [treeData, setTreeData] = useState([]);

  useEffect(() => {
    if (sitedetails) {
      let treeItems = [];

      // Site Details
      treeItems.push({
        title: "Site Details",
        key: "SiteDetails",
        children: [
          {
            title: (
              <Fragment>
                <strong>Client : </strong>
                {sitedetails.client}
              </Fragment>
            ),
            key: "SiteDetailsClient",
          },
          {
            title: (
              <Fragment>
                <strong>Site Name : </strong>
                {sitedetails.name}
              </Fragment>
            ),
            key: "SiteDetailsSiteName",
          },
        ],
      });

      // Site Contact
      treeItems.push({
        title: "Site Contact",
        key: "SiteContact",
        children: [
          {
            title: (
              <Fragment>
                <strong>Name : </strong>
                {sitedetails.siteContact.name}
              </Fragment>
            ),
            key: "ContactName",
          },
          {
            title: (
              <Fragment>
                <strong>Phone : </strong>
                {sitedetails.siteContact.phone}
              </Fragment>
            ),
            key: "ContactPhone",
          },
          {
            title: (
              <Fragment>
                <strong>Email : </strong>
                {sitedetails.siteContact.email}
              </Fragment>
            ),
            key: "ContactEmail",
          },
        ],
      });

      // Address
      treeItems.push({
        title: "Site Address",
        key: "SiteAddress",
        children: [
          {
            title: (
              <Fragment>
                <strong>Street : </strong>
                {sitedetails.address.street}
              </Fragment>
            ),
            key: "SiteAddressStreet",
          },
          {
            title: (
              <Fragment>
                <strong>Suburb : </strong>
                {sitedetails.address.suburb}
              </Fragment>
            ),
            key: "SiteAddressSuburb",
          },
          {
            title: (
              <Fragment>
                <strong>City : </strong>
                {sitedetails.address.city}
              </Fragment>
            ),
            key: "SiteAddressCity",
          },
          {
            title: (
              <Fragment>
                <strong>Country : </strong>
                {sitedetails.address.country}
              </Fragment>
            ),
            key: "SiteAddressCountry",
          },
          {
            title: (
              <Fragment>
                <strong>Longitude : </strong>
                {sitedetails.address.longitude}
              </Fragment>
            ),
            key: "SiteAddressLongitude",
          },
          {
            title: (
              <Fragment>
                <strong>Latitude : </strong>
                {sitedetails.address.latitude}
              </Fragment>
            ),
            key: "SiteAddressLatitude",
          },
        ],
      });

      // Created At
      treeItems.push({
        title: "CreatedAt",
        key: "CreatedAt",
        children: [
          {
            title: sitedetails.createdAt,
            key: "CreatedAtDate",
          },
        ],
      });

      setTreeData(treeItems);
    }
  }, [sitedetails]);

  return (
    <Fragment>
      {site.dataloaded && treeData.length > 0 && (
        <Fragment>
          <Title level={4}>Site details</Title>
          <Tree defaultExpandAll={true} treeData={treeData} />
        </Fragment>
      )}
    </Fragment>
  );
};

LeftColumn.propTypes = {
  sitedetails: PropTypes.object,
  site: PropTypes.object.isRequired,
};

const mapStateToProp = (state) => ({
  sitedetails: state.site.siteDetails,
  site: state.site,
});

export default connect(mapStateToProp)(LeftColumn);
