import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Ant design components
import { Modal, Input } from "antd";

// external Functions
import {
  CloseSaveConfigModal,
  getConfigurationFiles,
  updateBackendConfiguration,
} from "../../../../actions/deviceList";
import { updateBackend } from "../../../../actions/modbusconfig";
import { updateSNMPBackend } from "../../../../actions/SNMPConfig";
import { CreateAlarmsList } from "../../../../actions/alarms";

const { TextArea } = Input;

// actual Component
const SaveConfigModal = ({
  isModalVisible,
  CloseSaveConfigModal,
  currentList,
  updateBackend,
  updateSNMPBackend,
  CreateAlarmsList,
  getConfigurationFiles,
  updateBackendConfiguration,
  sitedetails,
}) => {
  const [textBoxText, setTextBoxText] = useState("");
  const [loadingButton, setLoadingButton] = useState(false);

  const handleOk = async () => {
    // this is the button on the modal
    setLoadingButton(true);
    let entireModbusConfig = [];
    let entireSNMPConfig = [];
    let configData = {};

    currentList.forEach((item) => {
      configData = {};

      if (item.Information.deviceCommType === "Modbus") {
        configData.templateID = item.Information.template._id;
        configData.config = {};

        // Does the device have a name, usually a temperature boards doesn't
        if (item.Information.deviceName)
          configData.config.Name = item.Information.deviceName;
        else {
          configData.config.customNames = [];

          item.Details.childNames.forEach((arrayItem) => {
            if (arrayItem.name !== "") {
              configData.config.customNames.push(arrayItem);
            }
          });
        }

        // the fuel sensors require a constanst and calculation ID
        if (item.Information.deviceType === "Fuel Sensor") {
          configData.config.calculations = [];

          configData.config.calculations.push({
            calculationID: item.Details.calculationID,
            constants: item.Details.constants,
          });
        } else if (item.Information.deviceType === "Analogue Input Board") {
          configData.config.calculations = [];

          // go through each input
          item.Details.childNames.forEach((child) => {
            // if it is set
            if (child.deviceType !== "" && child.deviceName !== "") {
              configData.config.calculations.push({
                calculationID: child.calculationID,
                constants: child.constants,
                name: child.deviceName,
                type: child.deviceType,
                input: child.input,
              });
            }
          });
        }

        if (item.Information.deviceInterfaceType === "TCP") {
          configData.config.interfaceType = 0;
          if (item.Information.deviceTCPSlaveID)
            configData.config.TCPSlaveID = item.Information.deviceTCPSlaveID;
        } else configData.config.interfaceType = 1;

        configData.config.sidIP = item.Information.deviceIPSlaveID;
        configData.config.pollrate = item.Information.devicePollRate;
        configData.config.devType = item.Information.deviceType;
        configData.config.baudRate = item.Information.deviceRTUBaudRate;

        entireModbusConfig.push(configData);
      } else if (item.Information.deviceCommType === "SNMP") {
        configData.templateID = item.Information.template._id;
        configData.config = {};

        if (item.Information.deviceName)
          configData.config.Name = item.Information.deviceName;
        else {
          configData.config.customNames = [];

          item.Details.childNames.forEach((arrayItem) => {
            if (arrayItem.name !== "") {
              configData.config.customNames.push(arrayItem);
            }
          });
        }

        // Always TCP
        configData.config.interfaceType = 0;
        // Other Information
        configData.config.sidIP = item.Information.deviceIPSlaveID;
        configData.config.pollrate = item.Information.devicePollRate;
        configData.config.devType = item.Information.deviceType;
        configData.config.SNMPVersion = item.Information.SNMPVersion;
        configData.config.SNMPPort = item.Information.SNMPPort;
        configData.config.communityString = item.Information.communityString;
        configData.config.deviceID = item.Information.deviceID;

        entireSNMPConfig.push(configData);
      }
    });

    const FileName = sitedetails.name + " Initial";

    // Save the SNMP configuration
    if (entireSNMPConfig.length > 0) {
      let formData = {};

      formData.config = entireSNMPConfig;
      formData.name = FileName;
      formData.current = true;

      updateSNMPBackend(formData);
    } else {
      let formData = {};

      formData.config = [];
      formData.name = FileName;
      formData.current = false;

      updateSNMPBackend(formData);
    }

    // Save the Modbus configuration
    if (entireModbusConfig.length > 0) {
      let formData = {};

      formData.config = entireModbusConfig;
      formData.name = FileName;
      formData.current = true;

      updateBackend(formData);
    } else {
      let formData = {};

      formData.config = [];
      formData.name = FileName;
      formData.current = false;

      updateBackend(formData);
    }

    // Saving Actual Config list
    let configFile = {
      name: FileName,
      configuration: { ...currentList },
      notes: textBoxText,
    };

    await updateBackendConfiguration(configFile);
    await CreateAlarmsList(currentList);
    // Refresh the tables
    getConfigurationFiles();
    // Close the modal
    closingSequence();
  };

  const closingSequence = () => {
    setLoadingButton(false);
    setTextBoxText("");
    CloseSaveConfigModal();
  };

  const handleCancel = () => {
    closingSequence();
  };

  const handleTextChange = (e) => {
    setTextBoxText(e.target.value);
  };

  return (
    <Modal
      title="Save Configuration File"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={loadingButton}
    >
      <p>
        Are you sure you want to save this configuration? Please write a
        description of this file so it is easier to find
      </p>

      <TextArea showCount maxLength={100} onChange={handleTextChange} />
    </Modal>
  );
};

SaveConfigModal.propTypes = {
  isModalVisible: PropTypes.bool,
  CloseSaveConfigModal: PropTypes.func.isRequired,
  currentList: PropTypes.array,
  updateBackend: PropTypes.func.isRequired,
  updateSNMPBackend: PropTypes.func.isRequired,
  CreateAlarmsList: PropTypes.func.isRequired,
  getConfigurationFiles: PropTypes.func.isRequired,
  updateBackendConfiguration: PropTypes.func.isRequired,
  sitedetails: PropTypes.object,
};

const mapStateToProps = (state) => ({
  isModalVisible: state.deviceList.modalSaveConfigVisible,
  currentList: state.deviceList.deviceToRead,
  sitedetails: state.site.siteDetails,
});

export default connect(mapStateToProps, {
  CloseSaveConfigModal,
  updateBackend,
  updateSNMPBackend,
  CreateAlarmsList,
  getConfigurationFiles,
  updateBackendConfiguration,
})(SaveConfigModal);
