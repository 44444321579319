import axios from "axios";
import { setAlert } from "./alert";
import { logout } from "./auth";
import setAuthToken from "../utils/setAuthToken";
import {
  UTL_MODEM_TOGGLE_START,
  UTL_MODEM_TOGGLE_STOP,
  UTL_MODEM_APNSET_STOP,
  UTL_MODEM_APNSET_START,
  UTL_IP_REFRESH,
  UTL_MODAL_ADDIP_OPEN,
  UTL_MODAL_ADDIP_CLOSE,
  UTL_IP_ETH_DEL,
  UTL_IP_SAVE_START,
  UTL_IP_SAVE_END,
  UTL_UC_INFO,
  UTL_VPN_KEYUPDATE,
  UTL_VPN_GETKEYSTART,
  UTL_WIFI_MODAL_CON_OPEN,
  UTL_WIFI_MODAL_CON_CLOSE,
  UTL_WIFI_CONNECTING,
} from "./types";

// @des             Asks the config backend to get the logs, creates a link, clicks it and removes link
// @access          Public
// @required        none
// @output          .zip file download
export const exportLogs = async () => {
  try {
    const result = await axios.get("/api/utl/logs", { responseType: "blob" });

    const fileName = "LogFiles";

    const blob = new Blob([result.data], {
      type: "application/octet-stream",
    });

    const href = await URL.createObjectURL(blob);
    const link = document.createElement("a");

    link.href = href;
    link.download = fileName + ".zip";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.log(error);
  }
};

// @des             Asks the config backend to get the status of the other services
// @access          Public
// @required        none
// @output          object array of service name and status
export const getServiceStatus = async () => {
  try {
    const result = await axios.get("/api/utl/backendservicestatus");

    return result.data;
  } catch (error) {
    console.log(error);
  }
};

// @des             Gets all the IP's from the Backend
// @access          Public
// @required        none
// @output          object array of IP and interfaces
export const getAllInterfaceIP = () => async (dispatch) => {
  try {
    const result = await axios.get("/api/utl/network/all");

    let InterfacesIP = result.data;

    if (InterfacesIP.Ethernet) {
      InterfacesIP.Ethernet.map((ip, index) => {
        return (ip.key = `${ip.address}#${index}`);
      });
    } else {
      InterfacesIP.Ethernet = [];
    }

    dispatch({ type: UTL_IP_REFRESH, payload: InterfacesIP });
  } catch (error) {
    console.log(error);
  }
};

// @des             Asks the config backend to get the version of the other services
// @access          Public
// @required        none
// @output          object array of service name and status
export const getServiceVersions = async () => {
  try {
    const result = await axios.get("/api/utl/backendversion");

    return result.data;
  } catch (error) {
    console.log(error);
  }
};

// @des             Reset the interface
export const ResetInterface = (interfaceName) => async (dispatch) => {
  try {
    await axios.get("/api/utl/network/reset/" + interfaceName);

    dispatch(setAlert("Interface Reset", "success"));
  } catch (error) {
    dispatch(setAlert("An error occured, please check logs", "error"));
  }
};

// @des             Turn the interface off
export const OffInterface = (interfaceName) => async (dispatch) => {
  try {
    await axios.get("/api/utl/network/off/" + interfaceName);

    dispatch(setAlert("Interface turned off", "success"));
  } catch (error) {
    dispatch(setAlert("An error occured, please check logs", "error"));
  }
};

// @des             Get the UC4 information
export const GetDeviceInfo = () => async (dispatch) => {
  try {
    let res = await axios.get("/api/utl/telematics");

    res = res.data;
    dispatch({ type: UTL_UC_INFO, payload: res });
  } catch (error) {
    console.error(error);
  }
};

// @des             Open the modal to add the IP
// @required        if Editing an IP then the IP info will need to be passed
export const SaveEthernetIP = (IPinfo) => async (dispatch) => {
  try {
    dispatch({ type: UTL_IP_SAVE_START });

    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    const configHeader = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios.post(
      "/api/utl/network/eth",
      {
        IPconfig: IPinfo,
      },
      configHeader
    );

    dispatch(setAlert("IP Successfully changed", "success"));
    dispatch(getAllInterfaceIP());
    dispatch({ type: UTL_IP_SAVE_END });
  } catch (error) {
    dispatch(setAlert("An error occured, please check logs", "error"));
    dispatch({ type: UTL_IP_SAVE_END });
  }
};

// @des             Open the modal to add the IP
// @required        if Editing an IP then the IP info will need to be passed
export const EthernetIPDelete = (IPinfo) => (dispatch) => {
  dispatch({ type: UTL_IP_ETH_DEL, payload: IPinfo });
};

// @des             Open the modal to add the IP
// @required        if Editing an IP then the IP info will need to be passed
export const ModalAddIPOpen = (editIP) => (dispatch) => {
  dispatch({ type: UTL_MODAL_ADDIP_OPEN, payload: editIP });
};

// @des             Close the modal to add the IP
// @required        none
export const ModalAddIPClose = (IPInfo) => (dispatch) => {
  dispatch({ type: UTL_MODAL_ADDIP_CLOSE, payload: IPInfo });
};

// @des             Asks the config backend to get the points and alarms list in excel format
// @access          Public
// @required        none
// @output          .xlsx file
export const exportPointsList = async () => {
  try {
    const response = await axios.get("/api/utl/xlssheet", {
      responseType: "arraybuffer",
    });

    const fileName = "BMS Points and Alarms List";

    // const blob = new Blob([result.data]);
    var blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const href = await URL.createObjectURL(blob);
    const link = document.createElement("a");

    link.href = href;
    link.download = fileName + ".xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.log(error);
  }
};

// @des             Factroy Resets the device
// @access          Private
// @required        none
// @output          none
export const factoryResetMe = () => async (dispatch) => {
  try {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    await axios.get("/api/utl/factoryreset");

    dispatch(setAlert("Okay thank you, bye bye", "success"));
    dispatch(logout());
  } catch (error) {
    console.log(error);
  }
};

// @des             Config Resets the device
// @access          Private
// @required        none
// @output          none
export const configResetMe = () => async (dispatch) => {
  try {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    await axios.get("/api/utl/configreset");

    dispatch(setAlert("Lets re-config it all 😀", "success"));
    dispatch(logout());
  } catch (error) {
    console.log(error);
  }
};

// @des             Resets the UC
// @access          Private
// @required        none
// @output          none
export const onlyResetMe = () => async (dispatch) => {
  try {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    await axios.get("/api/utl/resetme");

    dispatch(
      setAlert("Bye bye, give me about 5mins to rest my eyes 😲", "success")
    );
    dispatch(logout());
  } catch (error) {
    console.log(error);
  }
};

// @des             Resets the backend services
// @access          Private
// @required        none
// @output          none
export const serviceReset = () => async (dispatch) => {
  try {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    await axios.get("/api/utl/resetservices");
  } catch (error) {
    console.log(error);
  }
};

// @des             Asks the config backend to get the modem status
// @access          Private
// @required        none
// @output          object array of active status and message
export const getModemStatus = () => async (dispatch) => {
  try {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    const result = await axios.get("/api/utl/modemstatus");

    return result.data;
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      dispatch(logout());
    }
    console.log(error.response.status);
    return undefined;
  }
};

// @des             Asks the config backend to get the modem APN name
// @access          Private
// @required        none
// @output          object array of active status and message
export const getModemAPN = () => async (dispatch) => {
  try {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    const result = await axios.get("/api/utl/modemapn");

    return result.data;
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      dispatch(logout());
    }
    console.log(error.response.status);
    return undefined;
  }
};

// @des             Asks the backend to get the WIFI SSID namea
// @access          Private
// @required        none
// @output          string array of active WIFI Names
export const getWIFISSIDNames = () => async (dispatch) => {
  try {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    const result = await axios.get("/api/utl/network/scan");

    return result.data;
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      dispatch(logout());
    }
    console.log(error.response.status);
    return undefined;
  }
};

// @des             Connect to a specific WIFI network
// @required        ssid  : name of wifi
//                  psk   : password to the wifi network
export const ConnectToWIFI = (WIFIDetails) => async (dispatch) => {
  try {
    dispatch({ type: UTL_WIFI_CONNECTING, payload: true });

    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    const configHeader = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios.post("/api/utl/network/wifi/ssid", WIFIDetails, configHeader);

    dispatch(setAlert("Connection is successful", "success"));
    dispatch(WIFIConnectModalClose());
  } catch (error) {
    dispatch(setAlert("An error occured, please check logs", "error"));
    dispatch({ type: UTL_WIFI_CONNECTING, payload: false });
  }
};

// @des             Asks the config backend to get the modem APN name
// @access          Private
// @required        none
// @output          object array of active status and message
export const getModemReset = async () => {
  try {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    await axios.get("/api/utl/modemreset");
  } catch (error) {
    console.log(error);
  }
};

// @des             Opens the wifi connect modal
export const WIFIConnectModalOpen = (WIFIName) => async (dispatch) => {
  dispatch({ type: UTL_WIFI_MODAL_CON_OPEN, payload: WIFIName });
};

// @des             Closes the wifi connect modal
export const WIFIConnectModalClose = () => async (dispatch) => {
  dispatch({ type: UTL_WIFI_MODAL_CON_CLOSE });
};

// @des             sets the config backend to get the modem status
// @access          Private
// @required        none
// @output          object array of active status and message
export const toggleModemStatus = () => async (dispatch) => {
  try {
    dispatch({ type: UTL_MODEM_TOGGLE_START });

    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    await axios.get("/api/utl/modemtoggle");

    dispatch({ type: UTL_MODEM_TOGGLE_STOP });
  } catch (error) {
    console.log(error);
    dispatch(setAlert("Unable to toggle modem, please check logs", "error"));
    dispatch({ type: UTL_MODEM_TOGGLE_STOP });
  }
};

// @des             Gets the public key from the backend
// @access          Private
// @required        none
// @output          String key
export const getVPNPublicKey = () => async (dispatch) => {
  try {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    let key = await axios.get("/api/utl/vpn/key");
    key = key.data;

    dispatch({ type: UTL_VPN_KEYUPDATE, payload: key });
  } catch (error) {
    dispatch(setAlert("Unable to get VPN Key, please check logs", "error"));
  }
};

// @des             Makes the public key from the backend
// @access          Private
// @required        none
// @output          String key
export const postVPNPublicKey = () => async (dispatch) => {
  try {
    dispatch({ type: UTL_VPN_GETKEYSTART });

    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    let key = await axios.post("/api/utl/vpn/key");
    key = key.data;

    dispatch({ type: UTL_VPN_KEYUPDATE, payload: key });
  } catch (error) {
    dispatch(
      setAlert("Unable to generate VPN Key, please check logs", "error")
    );
    dispatch({ type: UTL_VPN_KEYUPDATE, payload: "" });
  }
};

// @des             sets the config backend to set the modem apn
// @access          Private
// @required        none
// @output          object array of active status and message
export const setModemAPN = (newAPN) => async (dispatch) => {
  try {
    dispatch({ type: UTL_MODEM_APNSET_START });

    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    await axios.post("/api/utl/modemapn", { apn: newAPN });

    dispatch(setAlert("APN set successfully", "success"));
    dispatch({ type: UTL_MODEM_APNSET_STOP });
  } catch (error) {
    console.log(error);
    dispatch(setAlert("APN not set", "error"));
    dispatch({ type: UTL_MODEM_APNSET_STOP });
  }
};
