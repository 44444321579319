import React, { Fragment } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const SubMenu = Menu.SubMenu;

const LeftMenu = ({
  auth: { isAuthenticated, loading },
  newSite,
  templatePermissions,
}) => {
  const openInNewTab = (url) => {
    let newURL = url.split(":");

    const fullurl = newURL[0] + ":" + newURL[1] + ":80";

    const newWindow = window.open(fullurl, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <Menu mode="horizontal">
      {!newSite && (
        <Fragment>
          {!loading && isAuthenticated && (
            <Fragment>
              <Menu.Item key="takeMeHome">
                <Link to="/">Home</Link>
              </Menu.Item>
              <SubMenu title={<span>Tools</span>}>
                <Menu.Item key="toolsDiscovery">
                  <Link to="/tools/discover">Discovery</Link>
                </Menu.Item>
                <Menu.Item key="toolsDevicePoll">
                  <Link to="/tools/mpoll">Device Poll</Link>
                </Menu.Item>
                <Menu.Item key="toolsUCInformation">
                  <Link to="/tools/devinfo">UC4 Information</Link>
                </Menu.Item>
              </SubMenu>
              {templatePermissions && templatePermissions.read && (
                <Fragment>
                  <SubMenu title={<span>Devices</span>}>
                    <Menu.Item key="DevTemplates">
                      <Link to="/tools/plugin/devtemp">Templates</Link>
                    </Menu.Item>
                    <Menu.Item key="DevTypes">
                      <Link to="/tools/plugin/devtype">Types</Link>
                    </Menu.Item>
                    <Menu.Item key="DevManu">
                      <Link to="/tools/plugin/devmanu">Maunfactures</Link>
                    </Menu.Item>
                    <Menu.Item key="DevGroups">
                      <Link to="/tools/plugin/groups">Groups</Link>
                    </Menu.Item>
                    <Menu.Item key="DevCalcs">
                      <Link to="/tools/plugin/calcs">Calulations</Link>
                    </Menu.Item>
                  </SubMenu>

                  <SubMenu title={<span>Plugins</span>}>
                    <Menu.Item key="pluginsClients">
                      <Link to="/tools/plugin/clients">Clients</Link>
                    </Menu.Item>
                    <Menu.Item key="pluginsUsers">
                      <Link to="/tools/plugin/users">Users</Link>
                    </Menu.Item>
                  </SubMenu>
                </Fragment>
              )}
              <SubMenu title={<span>Configurations</span>}>
                <Menu.Item key="configDevices">
                  <Link to="/tools/config/devices">Devices</Link>
                </Menu.Item>
                <Menu.Item key="configAlarms">
                  <Link to="/tools/config/alarms">Alarms</Link>
                </Menu.Item>
                <Menu.Item key="configStats">
                  <Link to="/tools/config/statistics">Statistics</Link>
                </Menu.Item>
                <Menu.Item key="configDist">
                  <Link to="/tools/config/distribution">Distribution</Link>
                </Menu.Item>
              </SubMenu>
              <Menu.Item key="takeMeToHMI">
                <a href="#!" onClick={() => openInNewTab(window.location.href)}>
                  HMI
                </a>
              </Menu.Item>
            </Fragment>
          )}
        </Fragment>
      )}
    </Menu>
  );
};

LeftMenu.propTypes = {
  auth: PropTypes.object.isRequired,
  templatePermissions: PropTypes.object,
  newSite: PropTypes.bool,
};

const mapStateToProp = (state) => ({
  auth: state.auth,
  templatePermissions: state.auth.templatePermissions,
  newSite: state.site.newSite,
});

export default connect(mapStateToProp)(LeftMenu);
