import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Antd componet
import { Modal, Form, Input, Checkbox, Select } from "antd";

// external functions
import { constantModalClose, constantAdd } from "../../actions/calculations";

// actual Component
const NewConstModal = ({
  modalVis,
  constantModalClose,
  constantAdd,
  deviceTypesDetailed,
  newCalculation,
  modalData,
}) => {
  const [constForm] = Form.useForm();
  const [formData, setformData] = useState({});
  const [typeVars, setTypeVars] = useState();

  const handleOK = () => {
    if (formData.name) {
      constantAdd(formData);
      closingProcedure();
    }
  };

  const closingProcedure = () => {
    setformData({});

    setTimeout(() => {
      constForm.resetFields();
      constantModalClose();
    }, 100);
  };

  const handleCancel = () => {
    closingProcedure();
  };

  const handleTypeSelect = (value) => {
    setformData({ ...formData, types: value });

    const typeSelected = deviceTypesDetailed.find(
      (type) => type.device_type_name === value
    );

    if (typeSelected) {
      setTypeVars(typeSelected.vairables);
    }
  };

  // Device Types select
  const DeviceTypeList =
    deviceTypesDetailed !== null
      ? deviceTypesDetailed.map((device, index) => (
          <Select.Option value={device.device_type_name} key={index}>
            {device.device_type_name}
          </Select.Option>
        ))
      : null;

  // Device Types select
  const DeviceVarList = typeVars
    ? typeVars.map((device, index) => (
        <Select.Option value={device.variable} key={index}>
          {device.variable}
        </Select.Option>
      ))
    : null;

  if (modalVis) {
    if (newCalculation && newCalculation.inOutSameType) {
      if (constForm.getFieldValue("types") !== newCalculation.inputOutputType) {
        constForm.setFieldsValue({ types: newCalculation.inputOutputType });
        constForm.setFieldsValue({ listSelect: true });
        setTimeout(() => {
          if (modalData.thisIsVitualCalc === undefined) {
            const typeSelected = deviceTypesDetailed.find(
              (type) => type.device_type_name === newCalculation.inputOutputType
            );

            if (typeSelected) {
              setTypeVars(typeSelected.vairables);
            }
          }

          setformData({
            ...formData,
            types: newCalculation.inputOutputType,
            listSelect: true,
          });
        }, 500);
      }
    }

    if (
      modalData &&
      modalData.virtualType &&
      modalData.thisIsVitualCalc &&
      typeVars === undefined
    ) {
      const typeSelected = deviceTypesDetailed.find(
        (type) => type.device_type_name === modalData.virtualType
      );

      if (typeSelected) {
        setTypeVars(typeSelected.vairables);
      }
    }
  }

  return (
    <Modal
      title={
        modalData && modalData.thisIsVitualCalc
          ? "Add a virtual device input"
          : "Add a constant"
      }
      onCancel={handleCancel}
      onOk={handleOK}
      afterClose={() => {
        setTypeVars();
      }}
      okButtonProps={{
        disabled:
          (formData.name === undefined) | (formData.name === "") ? true : false,
      }}
      visible={modalVis}
    >
      <Form
        initialValues={formData}
        size="small"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 10 }}
        form={constForm}
      >
        {modalData && modalData.thisIsVitualCalc === true ? (
          <Form.Item
            label="Variable of type"
            tooltip="This will be the variable of the type, it is what will be extracted from the type"
            name="typeVariable"
          >
            <Select
              showSearch
              allowClear
              onChange={(value) => {
                setformData({
                  ...formData,
                  name: value,
                  typeVariable: value,
                });
                constForm.setFieldsValue({ name: value });
              }}
            >
              {DeviceVarList}
            </Select>
          </Form.Item>
        ) : (
          <Fragment>
            <Form.Item label="Name" name="name" style={{ marginBottom: "5px" }}>
              <Input
                disabled={newCalculation && newCalculation.inOutSameType}
                onChange={(e) =>
                  setformData({ ...formData, name: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item
              label="List selectable"
              tooltip="The user must be only able to select from a list of devices and not input unique numbers/data"
              name="listSelect"
              style={{ marginBottom: "5px" }}
            >
              <Checkbox
                checked={
                  newCalculation && newCalculation.inOutSameType
                    ? newCalculation.inOutSameType
                    : formData && formData.listSelect
                }
                onChange={(e) =>
                  e.target.checked
                    ? setformData({ ...formData, listSelect: true })
                    : setformData({ ...formData, listSelect: false })
                }
              />
            </Form.Item>
            {formData.listSelect && (
              <Fragment>
                <Form.Item
                  label="Allow multiples"
                  tooltip="Allow the user to be able to select more than one device for this constant"
                  name="allowMultiples"
                  style={{ marginBottom: "5px" }}
                >
                  <Checkbox
                    onChange={(e) =>
                      e.target.checked
                        ? setformData({ ...formData, allowMultiples: true })
                        : setformData({ ...formData, allowMultiples: false })
                    }
                  />
                </Form.Item>
                {((modalData && modalData.thisIsTemplateCalc === undefined) ||
                  modalData.thisIsTemplateCalc === false) && (
                  <Fragment>
                    <Form.Item
                      label="Lock to type"
                      tooltip="This will limit the user to only be able to select the type of device for this type"
                      name="types"
                    >
                      {newCalculation && newCalculation.inOutSameType ? (
                        <Input disabled />
                      ) : (
                        <Select
                          showSearch
                          allowClear
                          onChange={handleTypeSelect}
                        >
                          {DeviceTypeList}
                        </Select>
                      )}
                    </Form.Item>
                    <Form.Item
                      label="Variable of type"
                      tooltip="This will be the variable of the type, it is what will be extracted from the type"
                      name="typeVariable"
                    >
                      <Select
                        showSearch
                        allowClear
                        onChange={(value) => {
                          if (newCalculation && newCalculation.inOutSameType) {
                            setformData({
                              ...formData,
                              name: value,
                              typeVariable: value,
                            });
                            constForm.setFieldsValue({ name: value });
                          } else {
                            setformData({ ...formData, typeVariable: value });
                          }
                        }}
                      >
                        {DeviceVarList}
                      </Select>
                    </Form.Item>
                  </Fragment>
                )}
              </Fragment>
            )}
          </Fragment>
        )}
      </Form>
    </Modal>
  );
};

NewConstModal.propTypes = {
  modalVis: PropTypes.bool,
  modalData: PropTypes.object,
  constantModalClose: PropTypes.func.isRequired,
  constantAdd: PropTypes.func.isRequired,
  deviceTypesDetailed: PropTypes.array,
  newCalculation: PropTypes.object,
};

const mapStateToProps = (state) => ({
  modalVis: state.calcs.constantModalVis,
  modalData: state.calcs.constantModalData,
  deviceTypesDetailed: state.deviceTypes.types_detailed,
  newCalculation: state.calcs.newCalculation,
});

export default connect(mapStateToProps, { constantModalClose, constantAdd })(
  NewConstModal
);
