import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// antd components
import { Button, Tooltip } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

// External comoonents
import CalcTable from "./newTemplateCalcTable";
import CalcModal from "./newTemplateCalcModal";

// External fnctions
import { openCalcAddModal } from "../../../actions/templates";

// actual component
const NewTemplateCalcs = ({ openCalcAddModal }) => {
  return (
    <Fragment>
      <Tooltip title="Click to add a calculation to the list">
        <Button
          style={{ marginTop: "20px" }}
          onClick={() => openCalcAddModal(null)}
        >
          <PlusCircleOutlined />
          New
        </Button>
      </Tooltip>

      <CalcModal />

      <CalcTable />
    </Fragment>
  );
};

NewTemplateCalcs.propTypes = {
  openCalcAddModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { openCalcAddModal })(NewTemplateCalcs);
