import {
  CONFIG_CLOSE,
  CONFIG_OPEN,
  CONFIG_UPDATE,
  CONFIG_ADD,
  CONFIG_CURRENT_ADD,
  CONFIG_GET,
  CONFIG_DETAILS,
} from "../actions/types";

const initalState = {
  modalOpen: false,
  hasEdits: false,
  operationComplete: false,
  currentList: [],
  entireConfig: [],
  configDetails: [],
  hasDetailData: false,
};

export default function site(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case CONFIG_OPEN:
      return { ...state, modalOpen: true };
    case CONFIG_CLOSE:
      return { ...state, modalOpen: false, currentList: [] };
    case CONFIG_ADD:
      return {
        ...state,
        entireConfig: [...state.entireConfig, payload],
        modalOpen: false,
      };
    case CONFIG_CURRENT_ADD:
      return {
        ...state,
        currentList: payload,
      };
    case CONFIG_UPDATE:
      return {
        ...state,
        entireConfig: [],
        configDetails: [],
        modalOpen: false,
        operationComplete: true,
        hasDetailData: false,
      };
    case CONFIG_GET:
      return {
        ...state,
        currentList: payload,
        configDetails: [],
        modalOpen: false,
        operationComplete: false,
        hasDetailData: false,
      };
    case CONFIG_DETAILS:
      return {
        ...state,
        configDetails: payload,
        entireConfig: [],
        currentList: [],
        modalOpen: false,
        hasDetailData: true,
        operationComplete: false,
      };
    default:
      return state;
  }
}
