import {
  DIS_DEVEDIT_UPDATE,
  DIS_DEVEDIT_UPDATE_END,
  DIS_MODAL_DEVEDIT_CLOSE,
  DIS_MODAL_DEVEDIT_OPEN,
} from "../actions/types";

const initalState = {
  modalDeviceEditVisablity: false,
  modalDeviceEditData: null,
  deviceEditUpdateData: null,
  deviceEditUpdateAvaiable: false,
};

export default function distribution(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case DIS_MODAL_DEVEDIT_OPEN:
      return {
        ...state,
        modalDeviceEditVisablity: true,
        modalDeviceEditData: payload,
      };
    case DIS_DEVEDIT_UPDATE_END:
      return {
        ...state,
        deviceEditUpdateData: null,
        deviceEditUpdateAvaiable: false,
      };
    case DIS_DEVEDIT_UPDATE:
      return {
        ...state,
        deviceEditUpdateData: payload,
        deviceEditUpdateAvaiable: true,
      };
    case DIS_MODAL_DEVEDIT_CLOSE:
      return {
        ...state,
        modalDeviceEditVisablity: false,
        modalDeviceEditData: null,
      };
    default:
      return state;
  }
}
