import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Ant design
import { Table, Popconfirm, Space, Typography, Button, Tooltip } from "antd";

// Icons
import { EditFilled, DeleteFilled } from "@ant-design/icons";

// Functions
import {
  updateDeviceDetails,
  updateTempTypeNameList,
  OpenEditInputModal,
} from "../../../../../actions/deviceList";
import { fetchGroups } from "../../../../../actions/templates";
import { getAllCalculation } from "../../../../../actions/calculations";

// external Components
import EditItemModal from "./modalEditFeild";

// Constants
const { Title, Paragraph, Text } = Typography;

// Actual component
const ModalDetailsAnalogueInput = ({
  updateDeviceDetails,
  deviceListDetails,
  fetchGroups,
  deviceListInformation,
  OpenEditInputModal,
  getAllCalculation,
}) => {
  // Table Columns
  const columns = [
    {
      title: "Input",
      dataIndex: "input",
      key: "input",
    },
    {
      title: "Device Type",
      dataIndex: "deviceType",
      key: "deviceType",
    },
    {
      title: "Device Name",
      dataIndex: "deviceName",
      key: "deviceName",
    },
    {
      title: "Calculation",
      dataIndex: "calculation",
      key: "calculation",
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record) => (
        <Space size="middle">
          {record.deviceType && (
            <Popconfirm
              title="Sure you want to clear the information?"
              onConfirm={() => handleDelete(record)}
            >
              <Tooltip title="Delete inputs details">
                <a href="#!">
                  <DeleteFilled />
                </a>
              </Tooltip>
            </Popconfirm>
          )}
          <a href="#!" onClick={() => handleEdit(record)}>
            <Tooltip title="Edit inputs details">
              <EditFilled />
            </Tooltip>
          </a>
        </Space>
      ),
    },
  ];

  // get the inital table data
  let InitialTableData = [];
  if (deviceListDetails !== undefined && deviceListDetails.childNames)
    InitialTableData = deviceListDetails.childNames;
  const [tableData, setTableData] = useState(InitialTableData);

  // Page load
  useEffect(() => {
    if (tableData.length === 0) {
      // create the list
      let Inputs = [];

      for (let i = 0; i < 8; i++) {
        Inputs.push({
          device_name: deviceListInformation.deviceName,
          device_type: deviceListInformation.deviceType,
          input: "Input " + (1 + i),
          deviceType: "",
          deviceName: "",
          key: i,
        });
      }
      setTableData(Inputs);
      updateDeviceDetails({ childNames: Inputs });
    }

    fetchGroups();
    getAllCalculation();
  }, [
    updateDeviceDetails,
    deviceListInformation,
    tableData,
    fetchGroups,
    getAllCalculation,
  ]);

  const handleEdit = (record) => {
    OpenEditInputModal({ ...record });
  };

  const handleDelete = (record) => {
    let inputList = [...tableData];

    inputList.forEach((input) => {
      if (input.input === record.input) {
        input.deviceName = "";
        input.deviceType = "";

        if (input.calculation !== undefined) delete input.calculation;
        if (input.constants) delete input.constants;
      }
    });

    setTableData(inputList);
    updateDeviceDetails({ childNames: inputList });
  };

  const handelClearAll = () => {
    // create the list
    let Inputs = [];

    for (let i = 0; i < 8; i++) {
      Inputs.push({
        device_name: deviceListInformation.deviceName,
        device_type: deviceListInformation.deviceType,
        input: "Input " + (1 + i),
        deviceType: "",
        deviceName: "",
        key: i,
      });
    }
    // Set the table data
    setTableData(Inputs);
    // and the details
    updateDeviceDetails({ childNames: Inputs });
  };

  // Table force refresh after the modal closes
  if (tableData) {
    if (deviceListDetails && tableData !== deviceListDetails.childNames)
      setTableData(deviceListDetails.childNames);
  }

  return (
    <Fragment>
      <Title level={2}>Analogue Inputs Mapping</Title>

      <Paragraph>
        <Text code>NB</Text> An input with a blank name will not alarm
      </Paragraph>

      <Button type="primary" onClick={handelClearAll}>
        <Tooltip title="This will clear the entire list of details per sensor">
          Clear all details
        </Tooltip>
      </Button>

      <EditItemModal />

      <Table
        style={{ marginTop: "10px" }}
        scroll={{ y: 300 }}
        pagination={{
          defaultPageSize: 20,
        }}
        bordered
        size="small"
        columns={columns}
        dataSource={tableData}
      ></Table>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  deviceListDetails: state.deviceList.currentDeviceList.Details,
  deviceListInformation: state.deviceList.currentDeviceList.Information,
  deviceTypesDetailed: state.deviceTypes.types_detailed,
  siteFloors: state.site.siteDetails.floors,
  tempTypeNameList: state.deviceList.tempTypeNameList,
  allGroups: state.template.groups,
});

ModalDetailsAnalogueInput.propTypes = {
  updateDeviceDetails: PropTypes.func.isRequired,
  updateTempTypeNameList: PropTypes.func.isRequired,
  deviceListDetails: PropTypes.object,
  deviceListInformation: PropTypes.object,
  fetchGroups: PropTypes.func.isRequired,
  siteFloors: PropTypes.array,
  deviceTypesDetailed: PropTypes.array,
  tempTypeNameList: PropTypes.array,
  allGroups: PropTypes.array,
  OpenEditInputModal: PropTypes.func.isRequired,
  getAllCalculation: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  updateDeviceDetails,
  updateTempTypeNameList,
  fetchGroups,
  OpenEditInputModal,
  getAllCalculation,
})(ModalDetailsAnalogueInput);
