import React, { useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// external functions
import { ModalAddIPClose } from "../../../actions/utl";

// Antd
import { Modal, Form, Input, Divider, Button, Tooltip } from "antd";

// Actaul Component
const ModalAddIP = ({ modalAddIPVis, ModalAddIPClose, modalAddIPInfo }) => {
  const [FormIPInfo] = Form.useForm();
  const inputRef = useRef(null);

  const handleOK = () => {
    // check to see if we are editing an IP
    if (modalAddIPInfo) {
      const formData = FormIPInfo.getFieldsValue();

      ModalAddIPClose({ ...formData, key: modalAddIPInfo.key });
    } else {
      ModalAddIPClose(FormIPInfo.getFieldsValue());
    }
  };

  const handleCancel = () => {
    ModalAddIPClose(undefined);
  };

  if (modalAddIPVis) {
    setTimeout(() => {
      inputRef.current.focus({
        cursor: "start",
      });
    }, 500);

    if (modalAddIPInfo !== undefined) {
      FormIPInfo.setFieldsValue({
        address: modalAddIPInfo.address,
        netmask: modalAddIPInfo.netmask,
        gateway: modalAddIPInfo.gateway,
      });
    }
  }

  return (
    <Modal
      centered
      visible={modalAddIPVis}
      afterClose={() => {
        // Clear the form data
        FormIPInfo.setFieldsValue({ address: "", netmask: "", gateway: "" });
      }}
      onCancel={handleCancel}
      title={modalAddIPInfo === undefined ? "Add New IP" : "Edit IP"}
      footer={[
        <Tooltip title="Close window and not save changes">
          <Button size="small" onClick={() => handleCancel()}>
            Cancel
          </Button>
        </Tooltip>,
        <Divider type="vertical" />,
        <Button
          type="primary"
          size="small"
          itemProp={{
            form: "category-editor-form",
            key: "submit",
            htmlType: "submit",
          }}
          onClick={() => {
            FormIPInfo.validateFields().then(() => handleOK());
          }}
        >
          {modalAddIPInfo === undefined ? "Add" : "Change"}
        </Button>,
      ]}
    >
      <Form
        labelCol={{ span: 5, offset: 5 }}
        wrapperCol={{ span: 6 }}
        initialValues={modalAddIPInfo}
        size="small"
        form={FormIPInfo}
      >
        <Form.Item
          label="IP address"
          style={{ marginBottom: "5px" }}
          rules={[
            {
              required: true,
              message: "Can't be blank",
            },
            {
              message: "Invalid IP",
              pattern:
                /((\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/,
            },
          ]}
          name="address"
        >
          <Input ref={inputRef} />
        </Form.Item>
        <Form.Item
          label="Subnet"
          style={{ marginBottom: "5px" }}
          rules={[
            {
              required: true,
              message: "Can't be blank",
            },
            {
              message: "Invalid subnet",
              pattern:
                /((\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/,
            },
          ]}
          name="netmask"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Gateway"
          help="Optional"
          style={{ marginBottom: "5px" }}
          rules={[
            {
              message: "Invalid gateway",
              pattern:
                /((\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/,
            },
          ]}
          name="gateway"
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

ModalAddIP.propTypes = {
  modalAddIPVis: PropTypes.bool,
  ModalAddIPClose: PropTypes.func.isRequired,
};

const MapStateToProps = (state) => ({
  modalAddIPVis: state.utl.modalAddIPVis,
  modalAddIPInfo: state.utl.modalAddIPInfo,
});

export default connect(MapStateToProps, { ModalAddIPClose })(ModalAddIP);
