import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// ant design components
import { Modal, Form, Input, Select } from "antd";

// Functions
import {
  closeTypeAddVarModal,
  updateCurrentType,
} from "../../actions/deviceTypes";

const { Option } = Select;

// Actual function
const DeviceTypeCreateVariable = ({
  modalVisabilty,
  childTypes,
  updateCurrentType,
  currentType,
  closeTypeAddVarModal,
}) => {
  const [formData, setFormData] = useState({});
  const [childVariables, setChildVariables] = useState([]);
  const [addVarForm] = Form.useForm();

  const handelOK = () => {
    if (formData && formData.variable) {
      let tableData = {};

      tableData.variable = formData.variable;
      if (formData.symbol) tableData.symbol = formData.symbol;

      if (formData.type !== undefined) {
        const selectedVar = childVariables.filter(
          (varItem) => varItem.variable === formData.variable
        )[0];

        if (selectedVar) {
          tableData.symbol = selectedVar.symbol;
        }

        tableData.ExtraChildren = {};

        tableData.ExtraChildren.type = formData.type;
        if (formData.name) tableData.ExtraChildren.name = formData.name;
      }

      let currentTypeList = { ...currentType };

      currentTypeList.vairables.push({ ...tableData });

      updateCurrentType({ ...currentTypeList });
      CloseOperations();
    }
  };

  const CloseOperations = () => {
    addVarForm.resetFields();
    setChildVariables([]);
    setFormData({});
    closeTypeAddVarModal();
  };

  const handelCancel = () => {
    CloseOperations();
  };

  // List populate for device Types
  const deviceNames =
    childTypes &&
    childTypes.map((type, index) => (
      <Option key={index} value={type.device_type_name}>
        {type.device_type_name}
      </Option>
    ));

  // list populate for type select variables
  const deviceVariables =
    childVariables &&
    childVariables.map((type, index) => (
      <Option key={index} value={type.variable}>
        {type.variable}
      </Option>
    ));

  return (
    <Modal
      title="Add a new variable"
      visible={modalVisabilty}
      onCancel={handelCancel}
      onOk={handelOK}
    >
      <Form
        form={addVarForm}
        size="small"
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 6 }}
        onValuesChange={(_, allValues) => {
          setFormData(allValues);

          // set the variable name depending on the type
          if (allValues.type !== undefined) {
            let childVariable = [];

            childTypes.forEach((type) => {
              if (type.device_type_name === allValues.type) {
                type.vairables.forEach((varItem) => {
                  childVariable.push({ ...varItem });
                });
              }
            });

            setChildVariables(childVariable);

            if (allValues.variable !== undefined) {
              const selectedVar = childVariables.filter(
                (varItem) => varItem.variable === allValues.variable
              )[0];

              if (selectedVar) {
                addVarForm.setFields([
                  { name: "symbol", value: selectedVar.symbol },
                ]);
              }
            }
          }
        }}
      >
        <Form.Item
          label="Child Type"
          tooltip="If it is not required leave blank"
          name="type"
        >
          <Select
            style={{ width: 220 }}
            allowClear
            showSearch
            onClear={() => {
              addVarForm.setFields([{ name: "variable", value: undefined }]);
              addVarForm.setFields([{ name: "symbol", value: undefined }]);
              addVarForm.setFields([{ name: "name", value: undefined }]);
            }}
          >
            {deviceNames}
          </Select>
        </Form.Item>
        {formData.type !== undefined && (
          <Form.Item
            tooltip="Only enter a name here if there are multiples of the type, i.e Input and Output, else leave blank for 1"
            label="Child Name"
            name="name"
          >
            <Input />
          </Form.Item>
        )}
        <Form.Item label="Variable Name" name="variable">
          {formData && formData.type !== undefined ? (
            <Select style={{ width: 220 }} allowClear showSearch>
              {deviceVariables}
            </Select>
          ) : (
            <Input />
          )}
        </Form.Item>
        <Form.Item label="Variable Symbol" name="symbol">
          <Input disabled={formData && formData.type !== undefined} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

DeviceTypeCreateVariable.propTypes = {
  modalVisabilty: PropTypes.bool,
  childTypes: PropTypes.array,
  closeTypeAddVarModal: PropTypes.func.isRequired,
  updateCurrentType: PropTypes.func.isRequired,
  currentType: PropTypes.object,
};

const mapStateToProps = (state) => ({
  modalVisabilty: state.deviceTypes.modalAddVariableVisablity,
  childTypes: state.deviceTypes.types_detailed,
  currentType: state.deviceTypes.editTypeDetials,
});

export default connect(mapStateToProps, {
  closeTypeAddVarModal,
  updateCurrentType,
})(DeviceTypeCreateVariable);
