import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Modal, Steps, Button, Divider, Tooltip, Form } from "antd";
import { RightCircleOutlined, LeftCircleOutlined } from "@ant-design/icons";

// Functions
import {
  closeModal,
  updateDeviceInformation,
  updateDeviceDetails,
  updateDeviceList,
  updateTypeNameList,
} from "../../../../actions/deviceList";

import { getDeviceTypesDetailed } from "../../../../actions/deviceTypes";

// Components
import Spinner from "../../../layout/Spinner";
import DeviceInfo from "./addDeviceInfo";
import DeviceDetails from "./addDeviceDetails";

const { Step } = Steps;

const AddDeviceModal = ({
  closeModal,
  getDeviceTypesDetailed,
  updateDeviceDetails,
  updateDeviceInformation,
  deviceList: { openAddDeviceModal, currentDeviceList, isEdit, isDuplication },
  deviceTypesLoading,
  updateDeviceList,
  updateTypeNameList,
  typeNameList,
  tempTypeNameList,
}) => {
  const [current, setCurrent] = useState(0);
  const [FormInfo] = Form.useForm();
  const [addNewButtonLoading, setAddNewButtonLoading] = useState(false);

  useEffect(() => {
    getDeviceTypesDetailed();

    if (isEdit | isDuplication) {
      setCurrent(1);
      setTimeout(() => {
        setCurrent(0);
      }, 10);
    }
  }, [getDeviceTypesDetailed, isEdit, isDuplication]);

  const next = () => {
    if (isEdit) {
      setCurrent(current + 1);
    } else {
      if (currentDeviceList) {
        if (currentDeviceList.Information) {
          if (currentDeviceList.Information.template) {
            setCurrent(current + 1);
          }
        }
      }
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const CloseModalSequence = () => {
    updateDeviceInformation({});
    updateDeviceDetails({});
    if (current === 0) {
      setCurrent(1);
    }

    setTimeout(() => {
      setCurrent(0);
      FormInfo.resetFields();
      closeModal();
    }, 200);
  };

  const handleCancel = () => {
    CloseModalSequence();
  };

  const steps = [
    {
      title: "Device Info",
      content: <DeviceInfo formID={FormInfo} />,
    },
    {
      title: "Device Details",
      content: <DeviceDetails />,
    },
  ];

  const handleOkNoClose = (newDevice) => {
    // check to see we are on the last step
    if (current === steps.length - 1) {
      // check to see if we at the least entered a manufacture
      if (
        currentDeviceList.Information.deviceType === "Triple Change Over" ||
        currentDeviceList.Information.deviceType === "Chiller" ||
        currentDeviceList.Information.deviceType === "HVAC" ||
        currentDeviceList.Information.deviceType === "Power Meter" ||
        currentDeviceList.Information.deviceType === "DC Meter" ||
        currentDeviceList.Information.deviceType === "Water Meter" ||
        currentDeviceList.Information.deviceType === "DC PDU" ||
        currentDeviceList.Information.deviceType === "ATS" ||
        currentDeviceList.Information.deviceType === "Weather Station" ||
        currentDeviceList.Information.deviceType === "UC - TCO C" ||
        currentDeviceList.Information.deviceType === "Utility Controller" ||
        currentDeviceList.Information.deviceType === "Fuel" ||
        currentDeviceList.Information.deviceType === "Water Tank" ||
        currentDeviceList.Information.deviceType === "Rectifier" ||
        currentDeviceList.Information.deviceType === "Environment Sensor"
      ) {
        updateDeviceList(currentDeviceList, false);
        addNewSameProcess(newDevice);
      } else {
        if (
          currentDeviceList.Information.deviceType === "Input Board" ||
          currentDeviceList.Information.deviceType === "Analogue Input Board"
        ) {
          updateTypeNameList(tempTypeNameList);
        }

        if (currentDeviceList.Details !== undefined) {
          updateDeviceList(currentDeviceList, false);
          addNewSameProcess(newDevice);
        }
      }
    }
  };

  const addNewSameProcess = (clearAll) => {
    setAddNewButtonLoading(true);

    setTimeout(() => {
      if (clearAll === true) {
        updateDeviceDetails({});
        updateDeviceInformation({});
      } else {
        let newDeviceInfor = { ...currentDeviceList.Information };
        let newDeviceDetails = { ...currentDeviceList.Details };

        if (newDeviceInfor.deviceName) delete newDeviceInfor.deviceName;
        if (newDeviceInfor.deviceIPSlaveID)
          delete newDeviceInfor.deviceIPSlaveID;

        updateDeviceInformation({ ...newDeviceInfor });
        updateDeviceDetails({ ...newDeviceDetails });
      }

      if (current === 0) {
        setCurrent(1);
      }

      setTimeout(() => {
        setCurrent(0);
        FormInfo.resetFields();
        setAddNewButtonLoading(false);
      }, 100);
    }, 2000);
  };

  const handleOk = () => {
    if (isEdit) {
      if (
        currentDeviceList.Information.deviceType === "Input Board" ||
        currentDeviceList.Information.deviceType === "Analogue Input Board"
      ) {
        updateTypeNameList(tempTypeNameList);
      }

      updateDeviceList(currentDeviceList, isEdit);
      CloseModalSequence();
    } else {
      // check to see we are on the last step
      if (current === steps.length - 1) {
        // check to see if we at the least entered a manufacture
        if (
          currentDeviceList.Information.deviceType === "Triple Change Over" ||
          currentDeviceList.Information.deviceType === "Chiller" ||
          currentDeviceList.Information.deviceType === "DC PDU" ||
          currentDeviceList.Information.deviceType === "Water Meter" ||
          currentDeviceList.Information.deviceType === "HVAC" ||
          currentDeviceList.Information.deviceType === "Weather Station" ||
          currentDeviceList.Information.deviceType === "UC - TCO C" ||
          currentDeviceList.Information.deviceType === "Utility Controller" ||
          currentDeviceList.Information.deviceType === "Power Meter" ||
          currentDeviceList.Information.deviceType === "DC Meter" ||
          currentDeviceList.Information.deviceType === "ATS" ||
          currentDeviceList.Information.deviceType === "Fuel" ||
          currentDeviceList.Information.deviceType === "Water Tank" ||
          currentDeviceList.Information.deviceType === "Rectifier" ||
          currentDeviceList.Information.deviceType === "Environment Sensor"
        ) {
          updateDeviceList(currentDeviceList, isEdit);
          CloseModalSequence();
        } else {
          if (
            currentDeviceList.Information.deviceType === "Input Board" ||
            currentDeviceList.Information.deviceType === "Analogue Input Board"
          ) {
            updateTypeNameList(tempTypeNameList);
          }

          if (currentDeviceList.Details !== undefined) {
            updateDeviceList(currentDeviceList, isEdit);
            CloseModalSequence();
          }
        }
      }
    }
  };

  return (
    <Modal
      centered
      width={1000}
      title={isEdit ? "Edit device" : "Add Device"}
      visible={openAddDeviceModal}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{}}
      footer={[
        <Button
          type="primary"
          itemProp={{
            form: "category-editor-form",
            key: "submit",
            htmlType: "submit",
          }}
          onClick={() => {
            if (current > 0) prev();
            else {
              FormInfo.validateFields().then(() => next());
            }
          }}
          style={{ marginRight: "30px" }}
        >
          {current > 0 ? <LeftCircleOutlined /> : "Details"}
          {current > 0 ? "Info" : <RightCircleOutlined />}
        </Button>,
        <Divider type="vertical" />,
        <Tooltip title="Close window and not save changes">
          <Button
            style={{ marginRight: "10px" }}
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>
        </Tooltip>,
        !isEdit && (
          <Tooltip title="Add this device but dont close window to add another new device of the same type">
            <Button
              style={{ marginRight: "10px" }}
              type="primary"
              disabled={
                isEdit ? false : current === steps.length - 1 ? false : true
              }
              onClick={() => handleOkNoClose(false)}
              loading={addNewButtonLoading}
            >
              {!isEdit && "Add/Same"}
            </Button>
          </Tooltip>
        ),
        !isEdit && (
          <Tooltip title="Add this device but dont close window to add another new device">
            <Button
              style={{ marginRight: "10px" }}
              type="primary"
              disabled={
                isEdit ? false : current === steps.length - 1 ? false : true
              }
              onClick={() => handleOkNoClose(true)}
              loading={addNewButtonLoading}
            >
              {!isEdit && "Add/New"}
            </Button>
          </Tooltip>
        ),
        <Tooltip
          title={
            isEdit
              ? "Update this device"
              : "Add this device and close the window"
          }
        >
          <Button
            type="primary"
            disabled={
              isEdit ? false : current === steps.length - 1 ? false : true
            }
            loading={addNewButtonLoading}
            onClick={() => handleOk()}
          >
            {isEdit ? "Update" : "Add/Close"}
          </Button>
        </Tooltip>,
      ]}
    >
      {deviceTypesLoading ? (
        <Spinner />
      ) : (
        <Fragment>
          <Steps current={current}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div className="steps-content">{steps[current].content}</div>
        </Fragment>
      )}
    </Modal>
  );
};

AddDeviceModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  updateDeviceDetails: PropTypes.func.isRequired,
  updateTypeNameList: PropTypes.func.isRequired,
  updateDeviceInformation: PropTypes.func.isRequired,
  updateDeviceList: PropTypes.func.isRequired,
  getDeviceTypesDetailed: PropTypes.func.isRequired,
  deviceList: PropTypes.object,
  deviceTypes: PropTypes.array,
  tempTypeNameList: PropTypes.array,
  typeNameList: PropTypes.array,
  deviceTypesLoading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  deviceList: state.deviceList,
  tempTypeNameList: state.deviceList.tempTypeNameList,
  typeNameList: state.deviceList.typeNameList,
  deviceTypes: state.deviceTypes.types_detailed,
  deviceTypesLoading: state.deviceTypes.typesLoadingDetailed,
});

export default connect(mapStateToProps, {
  closeModal,
  getDeviceTypesDetailed,
  updateDeviceInformation,
  updateDeviceList,
  updateDeviceDetails,
  updateTypeNameList,
})(AddDeviceModal);
