import { USERS_LOADING, USERS_UPDATED, USERS_FAIL } from "../actions/types";

const initalState = {
  dataloaded: false,
  usersList: [],
};

export default function site(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case USERS_UPDATED:
      return { ...state, dataloaded: true, usersList: payload };
    case USERS_LOADING:
    case USERS_FAIL:
      return {
        ...state,
        dataloaded: false,
        usersList: [],
      };
    default:
      return state;
  }
}
