import React, { Fragment, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//Antd compoonent
import { Typography, Tooltip } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

// externral functions
import { getServiceStatus, getServiceVersions } from "../../../actions/utl";

const { Title, Paragraph } = Typography;

// Actual Component
const MidColumn = ({ site }) => {
  const timeOutHandle = useRef();
  const [backendVersionNumbers, setBackendVersionNumbers] = useState();

  useEffect(() => {
    const getVersionNumbers = async () => {
      try {
        const res = await getServiceVersions();

        setBackendVersionNumbers(res);
      } catch (error) {
        console.log(error);
      }
    };

    const getStatusNow = async () => {
      try {
        const status = await getServiceStatus();
        const allItems = [];

        if (status) {
          for (const [key, value] of Object.entries(status)) {
            const name = key[0].toUpperCase() + key.slice(1).replace("_", " ");

            allItems.push({ name, value });
          }
          setServiceStatus(allItems);

          timeOutHandle.current = setTimeout(() => getStatusNow(), 2000);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getVersionNumbers();
    getStatusNow();

    // Specify how to clean up after this effect:
    return function cleanup() {
      clearTimeout(timeOutHandle.current);
    };
  }, []);

  const [serviceStatus, setServiceStatus] = useState();

  return (
    <Fragment>
      <Title level={4}>Tool backend version:</Title>
      <Paragraph>
        {site && site.backendVersion && site.backendVersion.backend}
      </Paragraph>
      <Title level={4}>Backend service status:</Title>
      <Paragraph>
        <ul>
          {serviceStatus &&
            serviceStatus.map((statusItems) => (
              <li>
                {statusItems.name} :{" "}
                <Tooltip
                  title={
                    statusItems.value === true ? "Running" : "Not Running/Error"
                  }
                >
                  {statusItems.value === true ? (
                    <CheckOutlined />
                  ) : (
                    <CloseOutlined />
                  )}
                </Tooltip>
              </li>
            ))}
        </ul>
      </Paragraph>
      <Title level={4}>Backend service versions:</Title>
      <Paragraph>
        <ul>
          {backendVersionNumbers &&
            Object.entries(backendVersionNumbers).map((items) => (
              <li>
                {items[0].replace(/[_]/g, " ")} : {items[1]}
              </li>
            ))}
        </ul>
      </Paragraph>
    </Fragment>
  );
};

MidColumn.propTypes = {
  site: PropTypes.object.isRequired,
};

const mapStateToProp = (state) => ({
  site: state.site,
});

export default connect(mapStateToProp)(MidColumn);
