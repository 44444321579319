import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// ant design component
import { Modal, Form, Input } from "antd";

// functions
import {
  closeTypeAddAlaModal,
  updateCurrentType,
} from "../../actions/deviceTypes";

// actual componet
const ModalAlarmStates = ({
  modalAddAlarmVisablity,
  closeTypeAddAlaModal,
  currentType,
  updateCurrentType,
}) => {
  const inputRef = useRef(null);
  const [formData, setFormData] = useState({});
  const [alarmForm] = Form.useForm();

  setTimeout(() => {
    if (formData.name === undefined) {
      if (inputRef.current) {
        inputRef.current.focus({
          cursor: "start",
        });
      }
    }
  }, 200);

  const closeOperations = () => {
    setFormData({});
    alarmForm.resetFields();
    closeTypeAddAlaModal();
  };

  const handleOK = () => {
    if (formData && formData.name) {
      formData.name = formData.name.trim();

      let currentTypeList = { ...currentType };

      if (!currentTypeList.alarms) {
        currentTypeList.alarms = [];
      }

      currentTypeList.alarms.push({ ...formData });

      updateCurrentType({ ...currentTypeList });
      closeOperations();
    }
  };

  const handleCancel = () => {
    closeOperations();
  };

  return (
    <Modal
      title="Add new Alarm/State"
      onCancel={handleCancel}
      onOk={handleOK}
      visible={modalAddAlarmVisablity}
    >
      <Form
        form={alarmForm}
        onValuesChange={(_, allItems) => {
          setFormData(allItems);
        }}
        size="small"
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 9 }}
      >
        <Form.Item name="name" label="Alarm Name">
          <Input onPressEnter={handleOK} ref={inputRef} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

ModalAlarmStates.propTypes = {
  modalAddAlarmVisablity: PropTypes.bool,
  closeTypeAddAlaModal: PropTypes.func,
  updateCurrentType: PropTypes.func,
  currentType: PropTypes.object,
};

const mapStateToProps = (state) => ({
  modalAddAlarmVisablity: state.deviceTypes.modalAddAlarmVisablity,
  currentType: state.deviceTypes.editTypeDetials,
});

export default connect(mapStateToProps, {
  closeTypeAddAlaModal,
  updateCurrentType,
})(ModalAlarmStates);
