import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Ant design componets
import { Table, Button, Popconfirm, Space, Tooltip } from "antd";

// Functions
import {
  getDeviceTypesDetailed,
  removeDeviceType,
  updateDeviceTypes,
  openTypeModal,
  openTypeModalEdit,
} from "../../actions/deviceTypes";

// Components
import Spinner from "../layout/Spinner";
import CreatNewTypeModal from "./deviceTypeAddEdit";

// actual Componet
const DeviceTypeMain = ({
  typesLoading,
  typesDetailed,
  updateDeviceTypes,
  removeDeviceType,
  getDeviceTypesDetailed,
  openTypeModal,
  openTypeModalEdit,
  permissions,
}) => {
  // On page load
  useEffect(() => {
    getDeviceTypesDetailed();
  }, [getDeviceTypesDetailed]);

  // Table cloumbs
  const columns = [
    {
      title: "Device Name",
      dataIndex: "device_type_name",
      key: "device_type_name",
      filters:
        typesDetailed &&
        typesDetailed.map((item) => ({
          text: item.device_type_name,
          value: item.device_type_name,
          // text: item,
          // value: item,
        })),
      onFilter: (value, record) => record.device_type_name.indexOf(value) === 0,
      render: (_, record) => (
        <a
          href="#!"
          onClick={() => {
            handleEdit(record);
          }}
        >
          {record.device_type_name}
        </a>
      ),
    },
    {
      title: "Date Created",
      dataIndex: "createdAt",
      key: "createdAt",
      responsive: ["lg"],
      render: (text) => <p>{text.replace("T", " ").replace("Z", "")}</p>,
      defaultSortOrder: "descend",
      sorter: (a, b) => a.createdAt - b.createdAt,
    },
    {
      title: "Updated At",
      key: "updatedAt",
      dataIndex: "updatedAt",
      responsive: ["lg"],
      render: (text) => <p>{text.replace("T", " ").replace("Z", "")}</p>,
      defaultSortOrder: "descend",
      sorter: (a, b) => a.updatedAt - b.updatedAt,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {permissions && permissions.delete && (
            <Popconfirm
              title={"Sure to delete " + record.device_type_name + " ?"}
              onConfirm={() => {
                handleDelete(record._id);
              }}
            >
              <a href="#!">Delete</a>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  const handleEdit = (type) => {
    openTypeModalEdit(type);
  };

  const handleDelete = (type) => {
    removeDeviceType(type);
  };

  const handleUpdate = () => {
    updateDeviceTypes();
  };

  const handleCreateNew = () => {
    openTypeModal();
  };

  return typesLoading ? (
    <Spinner />
  ) : (
    <Fragment>
      <h1 className="large text-primary">Device Types</h1>
      <Table
        columns={columns}
        dataSource={typesDetailed}
        size="small"
        pagination={{ pageSize: 40 }}
        scroll={{ y: 600 }}
        loading={!typesLoading && typesDetailed === null ? true : false}
      />

      <CreatNewTypeModal />

      {permissions && permissions.update && (
        <Tooltip title="Update the list from the server">
          <Button
            style={{ marginTop: "10px" }}
            type="primary"
            onClick={handleUpdate}
          >
            Update
          </Button>
        </Tooltip>
      )}

      {permissions && permissions.write && (
        <Tooltip title="Create a new type, this is only local and not on the server">
          <Button
            type="primary"
            style={{ margin: "10px" }}
            onClick={handleCreateNew}
          >
            Create New
          </Button>
        </Tooltip>
      )}
    </Fragment>
  );
};

DeviceTypeMain.propTypes = {
  typesDetailed: PropTypes.array,
  typesLoading: PropTypes.bool,
  updateDeviceTypes: PropTypes.func.isRequired,
  removeDeviceType: PropTypes.func.isRequired,
  getDeviceTypesDetailed: PropTypes.func.isRequired,
  openTypeModal: PropTypes.func.isRequired,
  openTypeModalEdit: PropTypes.func.isRequired,
  permissions: PropTypes.object,
};

const mapStateToProps = (state) => ({
  typesLoading: state.deviceTypes.typesLoadingDetailed,
  typesDetailed: state.deviceTypes.types_detailed,
  permissions: state.auth.templatePermissions,
});

export default connect(mapStateToProps, {
  updateDeviceTypes,
  removeDeviceType,
  getDeviceTypesDetailed,
  openTypeModalEdit,
  openTypeModal,
})(DeviceTypeMain);
