import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Ant Design Components
import { Tabs, Typography, Table, Space, Popconfirm, Tooltip } from "antd";
import { FileExcelOutlined, FundOutlined } from "@ant-design/icons";

// Additional Components
import CreatNewTypeModal from "./newTemplateModbusAddress";

// Functions
import {
  createInitalTemplate,
  OpenAddressMappingModal,
  updateMapping,
} from "../../actions/templates";

// Constants
const { Paragraph, Text } = Typography;
const { TabPane } = Tabs;

// Actual Function
const NewTemplateModbusMapping = ({
  currentTemplate,
  OpenAddressMappingModal,
  updateMapping,
  permissions,
}) => {
  const [tabsPanels, setTabsPanels] = useState([]);
  const [mappingTableData, setMappingTableData] = useState([]);
  const [Batchs, setBatchs] = useState([]);

  useEffect(() => {
    const newPanes = [];

    let batch = [];

    if (currentTemplate) {
      currentTemplate.Batchs.forEach((address, index) => {
        // Create the tabs based off the start address
        newPanes.push({
          title: "Address " + address.startAddress,
          content: "",
          key: index,
        });

        // Populate the tables
        const tabelData = [];

        for (let i = 0; i < address.quantity; i++) {
          let addressDetails = {};

          addressDetails.address = address.startAddress + i;
          addressDetails.key = i;
          addressDetails.index = index;

          tabelData.push(addressDetails);
        }

        batch.push({
          startAddress: address.startAddress,
          key: index,
          quantity: address.quantity,
          function: address.function,
          Map: tabelData,
        });

        // If a Map already exists, map out the existing data
        if (address.Map) {
          batch[index].Map.forEach((MapItem, itemInTheList) => {
            let matchedItems = address.Map.find(
              (mapItems) => mapItems.address === MapItem.address
            );
            // Only copy the info if the addresses match
            if (matchedItems) {
              batch[index].Map[itemInTheList] = matchedItems;
            }
          });
        }
      });

      setBatchs(batch);
      setTabsPanels(newPanes);
      setMappingTableData(
        batch[
          localStorage.getItem("activeKey") === null
            ? 0
            : localStorage.getItem("activeKey") >= currentTemplate.Batchs.length
            ? 0
            : localStorage.getItem("activeKey")
        ].Map
      );
    }
  }, [currentTemplate]);

  // Mapping Table Columns
  const mappingColumns = [
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (name, record) =>
        permissions && permissions.write ? (
          <a
            href="#!"
            onClick={() => {
              OpenAddressMappingModal(record);
            }}
          >
            {name}
          </a>
        ) : (
          name
        ),
    },
    {
      title: "Type",
      dataIndex: "dataType",
      key: "dataType",
    },
    {
      title: "Tag",
      children: [
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "Symbol",
          dataIndex: "symbol",
          key: "symbol",
        },
      ],
    },
    {
      title: "Children",
      children: [
        {
          title: "Name",
          dataIndex: ["ExtraChildren", "name"],
          key: "childName",
        },
        {
          title: "Type",
          dataIndex: ["ExtraChildren", "type"],
          key: "childType",
        },
      ],
    },
    {
      title: "Clamp",
      children: [
        {
          title: "Max",
          dataIndex: ["clamp", "max"],
          key: "clampMax",
        },
        {
          title: "Min",
          dataIndex: ["clamp", "min"],
          key: "clampMin",
        },
      ],
    },
    {
      title: "Scaling",
      children: [
        {
          title: "Factor",
          dataIndex: "scalingFactor",
          key: "scalingFactor",
        },
        {
          title: "Operation",
          dataIndex: "scalingOperation",
          key: "scalingOperation",
        },
      ],
    },
    {
      title: "Don't log",
      dataIndex: "notLogging",
      key: "notLogging",
      render: (text) => (text ? "True" : null),
    },
    {
      title: "Action",
      key: "Action",
      dataIndex: "operation",
      render: (_, record) =>
        permissions &&
        permissions.write && (
          <Space size="middle">
            {record.dataType && (
              <Popconfirm
                title={
                  record.notLogging ? "Enable logging ?" : "Disable logging ?"
                }
                onConfirm={() => handleLogging(record)}
              >
                <Tooltip
                  title={
                    record.notLogging
                      ? "Enable logging of this variable"
                      : "Disable logging of this variable"
                  }
                >
                  {record.notLogging ? <FundOutlined /> : <FileExcelOutlined />}
                </Tooltip>
              </Popconfirm>
            )}
          </Space>
        ),
    },
  ];

  const handleLogging = (record) => {
    const indexOfBatch = currentTemplate.Batchs.findIndex(
      (Batch) => Batch.key === record.index
    );

    let dataSource = [...currentTemplate.Batchs[indexOfBatch].Map];

    // Find the one we want
    const removeItemIdex = dataSource.findIndex(
      (item) => item.address === record.address
    );

    // Load the replacement object
    let newAddressData = { ...record };

    if (newAddressData.notLogging) delete newAddressData.notLogging;
    else newAddressData.notLogging = true;

    // tthe index is not it the current list
    if (removeItemIdex !== -1) {
      dataSource[removeItemIdex] = newAddressData;

      // Update the redux MAPs of this template
      updateMapping(indexOfBatch, dataSource);
    }
  };

  const handleTabChange = (key) => {
    localStorage.setItem("activeKey", key);
    setMappingTableData(Batchs[key].Map);
  };

  // Mapping Table, with address data
  const MappingDetialsTable = () => (
    <Fragment>
      <Table
        size={"small"}
        bordered
        columns={mappingColumns}
        pagination={{
          defaultPageSize: 40,
        }}
        dataSource={mappingTableData}
        scroll={{ y: 400 }}
      />
    </Fragment>
  );

  return (
    <Fragment>
      <Paragraph style={{ marginTop: "10px" }}>
        Edit each address with a name and other details,
        <Text code>Blanks</Text> will be skipped when read by the controller
      </Paragraph>

      <CreatNewTypeModal />

      <Tabs
        style={{ marginBottom: "10px" }}
        onChange={handleTabChange}
        activeKey={
          localStorage.getItem("activeKey") === null
            ? "0"
            : localStorage.getItem("activeKey").toString()
        }
      >
        {tabsPanels.map((pane) => (
          <TabPane tab={pane.title} key={pane.key}>
            <MappingDetialsTable />
          </TabPane>
        ))}
      </Tabs>
    </Fragment>
  );
};

NewTemplateModbusMapping.propTypes = {
  currentTemplate: PropTypes.object,
  createInitalTemplate: PropTypes.func,
  updateMapping: PropTypes.func,
  OpenAddressMappingModal: PropTypes.func,
  permissions: PropTypes.object,
};

const mapStateToProps = (state) => ({
  currentTemplate: state.template.newTemplate,
  permissions: state.auth.permissions,
});

export default connect(mapStateToProps, {
  createInitalTemplate,
  updateMapping,
  OpenAddressMappingModal,
})(NewTemplateModbusMapping);
