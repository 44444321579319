import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// components
import DetailsGenerator from "./modalDetailsGenerator";
import DetailsEnvironment from "./modalDetailsEnvironment";
import DetailsPowerMeter from "./modalDetailsPowerMeter";
import DetailsUPS from "./modalDetailsUPS";
import DetailsInput from "./DigitalInput/modalDetailsInput";
import DetailsTCO from "./modalDetailsTCO";
import DetailsRectifier from "./modalDetailsRectifier";
import DetailsBatteryBank from "./modalDetailsBatteryBank";
import DetailsHVAC from "./modalDetailsHVAC";
import DetailsAnalogueInput from "./AnalogueInput/modalDetailsAnalogueInput";
import DetailsFuelSensor from "./modalDetailsFuelSensor";
import DetailsChiller from "./modalDetailsChiller";
import DetailsBlank from "./modalBlankDetails";

const AddDeviceDetails = ({ deviceList: { Information } }) => {
  return (
    <Fragment>
      {Information && (
        <Fragment>
          {Information.deviceType === "Generator" ? (
            <DetailsGenerator />
          ) : Information.deviceType === "Environment Board" ? (
            <DetailsEnvironment />
          ) : Information.deviceType === "Power Meter" ? (
            <DetailsPowerMeter />
          ) : Information.deviceType === "UPS" ? (
            <DetailsUPS />
          ) : Information.deviceType === "Input Board" ? (
            <DetailsInput />
          ) : Information.deviceType === "Triple Change Over" ? (
            <DetailsTCO />
          ) : Information.deviceType === "Rectifier" ? (
            <DetailsRectifier />
          ) : Information.deviceType === "Battery Bank" ? (
            <DetailsBatteryBank />
          ) : Information.deviceType === "HVAC" ? (
            <DetailsHVAC />
          ) : Information.deviceType === "Chiller" ? (
            <DetailsChiller />
          ) : Information.deviceType === "Analogue Input Board" ? (
            <DetailsAnalogueInput />
          ) : Information.deviceType === "Fuel Sensor" ? (
            <DetailsFuelSensor />
          ) : (
            <DetailsBlank />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  deviceList: state.deviceList.currentDeviceList,
});

AddDeviceDetails.propTypes = {
  deviceList: PropTypes.object,
};

export default connect(mapStateToProps)(AddDeviceDetails);
