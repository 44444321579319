import React, { Fragment, useEffect, useRef } from "react";
import axios from "axios";

// external Components
import { Chart } from "react-google-charts";

// Actual component
const ColRight = ({ pageData }) => {
  const chartdata = useRef([]);
  const timerHandle = useRef();

  useEffect(() => {
    return () => {
      clearTimeout(timerHandle.current);
    };
  }, []);

  const AddData = async () => {
    try {
      let res = await axios.get("/api/utl/telematics");

      res = res.data;

      let currentData = chartdata.current;

      let nextPoint = currentData[currentData.length - 1];
      nextPoint = nextPoint[0];
      nextPoint++;

      currentData.push([
        nextPoint,
        res.CPU && res.CPU.UsagePercentage,
        res.CPU && res.CPU.TempDeg,
        res.Memory && res.Memory.usedMemPercentage,
        res.Disk && Number(res.Disk.usedPercentage),
      ]);

      if (currentData.length > 60) {
        const newChartData = [...currentData.slice(2)];
        currentData = [["x", "CPU", "Temp", "Memory", "Disk"], ...newChartData];
      }

      chartdata.current = currentData;
    } catch (error) {}

    timerHandle.current = setTimeout(() => AddData(), 1000);
  };

  if (chartdata.current.length === 0) {
    chartdata.current = [
      ["x", "CPU", "Temp", "Memory", "Disk"],
      [0, 0, 0, 0, 0],
    ];

    setTimeout(() => AddData(), 1000);
  }

  return (
    <Fragment>
      <Chart
        width={"100%"}
        height={"400px"}
        chartType="LineChart"
        data={chartdata.current}
        options={{
          width: "100%",
          hAxis: {
            title: "Time",
          },
          vAxis: {
            title: "%",
          },
          series: {
            1: { curveType: "function" },
          },
        }}
      />
    </Fragment>
  );
};

export default ColRight;
