import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// ant design componets
import {
  Modal,
  Table,
  Form,
  Select,
  InputNumber,
  Tooltip,
  Button,
  Space,
  Popconfirm,
  Divider,
  Switch,
  Row,
  Col,
  Input,
  Checkbox,
} from "antd";
import { DeleteFilled, EditFilled, CopyFilled } from "@ant-design/icons";

// functions
import {
  closeAlarmEditModal,
  updateAlarms,
  updateAlarmEditModal,
} from "../../../actions/templates";

const { Option } = Select;
const CheckboxGroup = Checkbox.Group;

// Actual component
const NewTemplateEditAlarm = ({
  closeAlarmEditModal,
  isModalVisible,
  variableDetails,
  typesDetails,
  currentTemplate,
  updateAlarms,
  updateAlarmEditModal,
}) => {
  const [typeAlarmNames, setTypeAlarmNames] = useState([]);
  const [formData, setFormData] = useState({});
  const [keyNumber, setKeyNumber] = useState(0);
  const [editOperation, setEditOperation] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isSingleBit, setIsSingleBit] = useState(false);
  const [binaryMappedBits, setBiniaryBits] = useState("");
  const [defaultBinaryMappedBits, setDefaultBinaryMappedBits] = useState([]);
  const [levelSwitch, setLevelSwitch] = useState(false);
  const [operations, setOperation] = useState(null);

  const binaryBits = [
    { label: "16", value: 0 },
    { label: "15", value: 1 },
    { label: "14", value: 2 },
    { label: "13", value: 3 },
    { label: "12", value: 4 },
    { label: "11", value: 5 },
    { label: "10", value: 6 },
    { label: "9", value: 7 },
    { label: "8", value: 8 },
    { label: "7", value: 9 },
    { label: "6", value: 10 },
    { label: "5", value: 11 },
    { label: "4", value: 12 },
    { label: "3", value: 13 },
    { label: "2", value: 14 },
    { label: "1", value: 15 },
  ];

  const [formAlarm] = Form.useForm();

  // on page load
  useEffect(() => {
    let alarmNameList = [];

    if (typesDetails && currentTemplate) {
      typesDetails.forEach((type) => {
        if (type.device_type_name === currentTemplate.device_type_name) {
          alarmNameList = type.alarms;
        }
      });
    }

    setTypeAlarmNames(alarmNameList);
  }, [typesDetails, currentTemplate]);

  let columnData = [];

  // this is the columns in the table
  if (variableDetails && variableDetails.dataType === "Binary") {
    columnData = [
      {
        title: "Alarm Name",
        dataIndex: "alarmName",
        key: "alarmName",
      },
      {
        title: "Binary",
        dataIndex: "binaryBits",
        key: "binaryBits",
      },
      {
        title: "Trigger Level",
        dataIndex: "alarmLevel",
        key: "alarmLevel",
      },
      {
        title: "Operation",
        dataIndex: "alarmOperation",
        key: "alarmOperation",
      },
      {
        title: "Alarm Delay",
        children: [
          {
            title: "On",
            dataIndex: "alarmDelayOn",
            key: "alarmDelayOn",
          },
          {
            title: "Off",
            dataIndex: "alarmDelayOff",
            key: "alarmDelayOff",
          },
        ],
      },
      {
        title: "Priority",
        dataIndex: "alarmPriority",
        key: "alarmPriority",
      },
      {
        title: "Actions",
        key: "actions",
        render: (_, record) => (
          <Space size="middle">
            <Popconfirm
              title="Delete this condition?"
              onConfirm={() => handleDelete(record)}
            >
              <Tooltip title="Delete this condition for this alarm">
                <a href="#!">
                  <DeleteFilled />
                </a>
              </Tooltip>
            </Popconfirm>
            <Tooltip title="Edit this alarms details">
              <a href="#!" onClick={() => handleEditAlarm(record)}>
                <EditFilled />
              </a>
            </Tooltip>
            <Tooltip title="Duplicate/copy this alarms details">
              <a href="#!" onClick={() => handleCopyAlarm(record)}>
                <CopyFilled />
              </a>
            </Tooltip>
          </Space>
        ),
      },
    ];
  } else {
    columnData = [
      {
        title: "Alarm Name",
        dataIndex: "alarmName",
        key: "alarmName",
      },
      {
        title: "Range Min",
        key: "rangeMin",
        width: "90px",
        dataIndex: "rangeMin",
      },
      {
        title: "Range Max",
        key: "rangeMax",
        width: "90px",
        dataIndex: "rangeMax",
      },
      {
        title: "Trigger Level",
        dataIndex: "alarmLevel",
        key: "alarmLevel",
      },
      {
        title: "Operation",
        dataIndex: "alarmOperation",
        key: "alarmOperation",
      },
      {
        title: "Alarm Delay",
        children: [
          {
            title: "On",
            dataIndex: "alarmDelayOn",
            key: "alarmDelayOn",
          },
          {
            title: "Off",
            dataIndex: "alarmDelayOff",
            key: "alarmDelayOff",
          },
        ],
      },
      {
        title: "Priority",
        dataIndex: "alarmPriority",
        key: "alarmPriority",
      },
      {
        title: "Actions",
        key: "actions",
        render: (_, record) => (
          <Space size="middle">
            <Popconfirm
              title="Delete this condition?"
              onConfirm={() => handleDelete(record)}
            >
              <Tooltip title="Delete this condition for this alarm">
                <a href="#!">
                  <DeleteFilled />
                </a>
              </Tooltip>
            </Popconfirm>
            <Tooltip title="Edit this alarms details">
              <a href="#!" onClick={() => handleEditAlarm(record)}>
                <EditFilled />
              </a>
            </Tooltip>
            <Tooltip title="Duplicate/copy this alarms details">
              <a href="#!" onClick={() => handleCopyAlarm(record)}>
                <CopyFilled />
              </a>
            </Tooltip>
          </Space>
        ),
      },
    ];
  }

  const handleCopyAlarm = (item) => {
    if (variableDetails && variableDetails.dataType === "Binary") {
      let checkedItems = [];
      for (var i = 0; i < 16; i++) {
        if (item.binaryBits[i] === "1") {
          checkedItems.push(i);
        }
      }
      setDefaultBinaryMappedBits(checkedItems);
      setTimeout(() => setBinaryString(checkedItems), 500);
    }

    setOperation(item.alarmOperation);

    setFormData({ ...item });
    setTimeout(() => formAlarm.resetFields(), 100);
  };

  const handleEditAlarm = (item) => {
    if (variableDetails && variableDetails.dataType === "Binary") {
      let checkedItems = [];
      for (var i = 0; i < 16; i++) {
        if (item.binaryBits[i] === "1") {
          checkedItems.push(i);
        }
      }
      setDefaultBinaryMappedBits(checkedItems);
    }
    setEditOperation(true);

    setOperation(item.alarmOperation);

    setFormData({ ...item });
    setTimeout(() => formAlarm.resetFields(), 100);
  };

  const handleDelete = (item) => {
    let newTableData = [...variableDetails.alarmMap];

    newTableData = newTableData.filter((entry) => entry.key !== item.key);

    setOperation(null);
    updateAlarmEditModal({ ...variableDetails, alarmMap: newTableData });
  };

  const handleAdd = () => {
    // catch no bits set
    if (variableDetails && variableDetails.dataType === "Binary") {
      if (defaultBinaryMappedBits.length === 0) {
        return;
      }
    }

    // only do something if the
    if (formData) {
      if (
        formData.alarmName &&
        formData.alarmDelayOff &&
        formData.alarmDelayOn &&
        formData.alarmOperation &&
        formData.alarmPriority
      ) {
        if (formData.alarmLevel !== null || formData.alarmLevel !== undefined) {
          if (editOperation) {
            setEditOperation(false);
          }
          let newTableData = variableDetails.alarmMap && [
            ...variableDetails.alarmMap,
          ];
          let HighKeyNumber = keyNumber;

          // if it is a binary type add the binary items
          if (variableDetails && variableDetails.dataType === "Binary") {
            formData.binaryBits = binaryMappedBits;
          }

          if (newTableData) {
            const foundDevice = newTableData.findIndex((tableItems) => {
              if (
                tableItems.alarmName === formData.alarmName &&
                tableItems.alarmPriority === formData.alarmPriority
              )
                return true;

              return false;
            });

            newTableData.forEach((item) => {
              if (item.key >= HighKeyNumber) {
                HighKeyNumber = item.key;
                HighKeyNumber++;
              }
            });

            if (foundDevice === -1) {
              newTableData.push({ ...formData, key: HighKeyNumber });
            } else {
              newTableData.splice(foundDevice, 1, {
                ...formData,
                key: HighKeyNumber,
              });
            }
          } else {
            newTableData = [];
            newTableData.push({ ...formData, key: HighKeyNumber });
          }

          updateAlarmEditModal({ ...variableDetails, alarmMap: newTableData });
          setDefaultBinaryMappedBits([]);
          // get the next key item
          HighKeyNumber++;
          setKeyNumber(HighKeyNumber);
          // clear the form for new data
          setFormData({});
          setIsSingleBit(false);
          setLevelSwitch(false);
          setTimeout(() => {
            formAlarm.resetFields();
          }, 200);
        }
      }
    }
  };

  const handleOk = () => {
    // show the loading on the okay button
    setConfirmLoading(true);

    // Unload the data to edit it later
    let dataSource = [...currentTemplate.Alarms];

    // Find the one we want
    const removeItemIdex = dataSource.findIndex(
      (item) => item.key === variableDetails.key
    );

    // Load the replacement object
    let newAlarmData = {
      name: variableDetails.name,
      dataType: variableDetails.dataType,
      key: variableDetails.key,
      childname: variableDetails.childname,
      enabled: true,
      alarmMap: variableDetails.alarmMap,
    };

    // the index is not it the current list
    if (removeItemIdex === -1) {
      dataSource.push(newAlarmData);
    } else {
      dataSource[removeItemIdex] = newAlarmData;
    }

    // Update the redux MAPs of this template
    updateAlarms(dataSource);

    closeOperation();
  };

  const closeOperation = () => {
    setDefaultBinaryMappedBits([]);
    setFormData({});
    setIsSingleBit(false);
    setKeyNumber(0);
    setEditOperation(false);
    setConfirmLoading(false);
    setOperation(null);
    setTimeout(() => {
      formAlarm.resetFields();
      closeAlarmEditModal();
    }, 150);
  };

  const handleCancel = () => {
    closeOperation();
  };

  const setBinaryString = (value) => {
    let total = "0000000000000000";
    setDefaultBinaryMappedBits(value);

    if (value.length > 0) {
      value.forEach((bit) => {
        total = total.substr(0, bit) + "1" + total.substr(bit + 1);
      });
    }

    if (value.length === 1) {
      setIsSingleBit(true);

      if (
        formAlarm.getFieldValue("alarmLevel") === undefined ||
        formAlarm.getFieldValue("alarmLevel") === null
      ) {
        formAlarm.setFieldsValue({ alarmLevel: 0 });
        setLevelSwitch(false);
      } else {
        console.log("Hhehkjh ", formAlarm.getFieldValue("alarmLevel"));
        if (formAlarm.getFieldValue("alarmLevel") === 0) setLevelSwitch(false);
        else setLevelSwitch(true);
      }
    } else {
      setIsSingleBit(false);
    }

    setBiniaryBits(total);
  };

  const alarmNames =
    typeAlarmNames &&
    typeAlarmNames.map((alarmItem, index) => (
      <Option key={index} value={alarmItem.name}>
        {alarmItem.name}
      </Option>
    ));

  return (
    <Modal
      title={variableDetails && variableDetails.name}
      width={
        variableDetails && variableDetails.dataType === "Binary" ? 1200 : 950
      }
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={confirmLoading}
    >
      {variableDetails && variableDetails.dataType === "Binary" && (
        <Fragment>
          <Divider>Binary Bit Mapping</Divider>

          <Row>
            <Col xs={0} md={2} lg={3}></Col>
            <Col xs={24} md={22} lg={21}>
              <CheckboxGroup
                style={{ marginBottom: "20px" }}
                options={binaryBits}
                value={defaultBinaryMappedBits}
                onChange={(value) => setBinaryString(value)}
              />
            </Col>
          </Row>
        </Fragment>
      )}

      <Form
        form={formAlarm}
        size="small"
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 7 }}
        initialValues={formData}
        onValuesChange={(_, AllItems) => {
          setFormData(AllItems);
        }}
      >
        <Form.Item
          label="Alarm Name"
          name="alarmName"
          rules={[{ required: true }]}
          tooltip="This is the name when the alarm is triggered, if the alarm you looking for is not here add it in the type section"
        >
          <Select allowClear showSearch disabled={editOperation}>
            {alarmNames}
          </Select>
        </Form.Item>
        {operations === "range" ? (
          <Fragment>
            <Form.Item
              label={<span style={{ color: "black" }}>Range start</span>}
              name={"rangeMin"}
              rules={[
                {
                  required: true,
                  message: `Can't be blank`,
                },
              ]}
              tooltip="The minimum level the alarm will start at, below this point the alarm will not work"
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              label={<span style={{ color: "black" }}>Range end</span>}
              name={"rangeMax"}
              rules={[
                {
                  required: true,
                  message: `Can't be blank`,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (getFieldValue("rangeMin") + 0.1 > value) {
                      return Promise.reject("End must be greater than start");
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
              tooltip="The maximum level the alarm will start end, above this point the alarm will not work"
            >
              <InputNumber />
            </Form.Item>
          </Fragment>
        ) : (
          <Form.Item
            label="Trigger Level"
            name="alarmLevel"
            rules={[{ required: true }]}
          >
            {isSingleBit ? (
              <Switch
                checkedChildren={1}
                unCheckedChildren={0}
                size="default"
                checked={levelSwitch}
                onChange={(checked) =>
                  checked
                    ? formAlarm.setFieldsValue({ alarmLevel: 1 }) &
                      setLevelSwitch(true)
                    : formAlarm.setFieldsValue({ alarmLevel: 0 }) &
                      setLevelSwitch(false)
                }
              />
            ) : (
              <Input />
            )}
          </Form.Item>
        )}
        <Form.Item
          label="Operation"
          name="alarmOperation"
          rules={[{ required: true }]}
        >
          <Select onChange={setOperation}>
            <Option key={0} value="<">
              Less than
            </Option>
            <Option key={1} value="=">
              Equal
            </Option>
            <Option key={2} value="!=">
              Not Equal
            </Option>
            <Option key={3} value=">">
              Greater than
            </Option>
            <Option key={4} value="compair">
              Contains
            </Option>
            <Option key={4} value="range">
              Range
            </Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="On Delay"
          name="alarmDelayOn"
          rules={[{ required: true }]}
          tooltip="Time is seconds, condition needs to be active for more than this time to create alarm"
        >
          <InputNumber min={1} />
        </Form.Item>
        <Form.Item
          label="Off Delay"
          name="alarmDelayOff"
          rules={[{ required: true }]}
          tooltip="Time is seconds, condition needs to be inactive for more than this time to clear alarm"
        >
          <InputNumber min={1} />
        </Form.Item>
        <Form.Item
          label="Priority"
          name="alarmPriority"
          rules={[{ required: true }]}
        >
          <Select allowClear disabled={editOperation}>
            <Option key={0} value="Event" style={{ color: "blue" }}>
              Event
            </Option>
            <Option key={1} value="Warning" style={{ color: "yellowgreen" }}>
              Warning
            </Option>
            <Option key={2} value="Urgent" style={{ color: "orange" }}>
              Urgent
            </Option>
            <Option key={3} value="Critical" style={{ color: "red" }}>
              Critical
            </Option>
          </Select>
        </Form.Item>
      </Form>

      <Tooltip title="Press to add this alarm to the list">
        <Button style={{ marginBottom: "10px" }} onClick={handleAdd}>
          {editOperation ? "Update Alarm" : "Add Alarm"}
        </Button>
      </Tooltip>

      <Table
        // dataSource={tableData}
        dataSource={variableDetails && variableDetails.alarmMap}
        bordered
        size="small"
        scroll={{ y: 300 }}
        pagination={{ pageSize: 20 }}
        columns={columnData}
      ></Table>
    </Modal>
  );
};

NewTemplateEditAlarm.propTypes = {
  closeAlarmEditModal: PropTypes.func.isRequired,
  updateAlarms: PropTypes.func.isRequired,
  isModalVisible: PropTypes.bool,
  variableDetails: PropTypes.object,
  currentTemplate: PropTypes.object,
  typesDetails: PropTypes.array,
};

const mapStateToProps = (state) => ({
  isModalVisible: state.template.alarmsEditModalOpen,
  variableDetails: state.template.alarmsModalDetails,
  typesDetails: state.deviceTypes.types_detailed,
  currentTemplate: state.template.newTemplate,
});

export default connect(mapStateToProps, {
  closeAlarmEditModal,
  updateAlarms,
  updateAlarmEditModal,
})(NewTemplateEditAlarm);
