import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// ant d
import { Table, Space, Tooltip, Typography } from "antd";
import { ApiOutlined } from "@ant-design/icons";

// external functions
import {
  getWIFISSIDNames,
  WIFIConnectModalOpen,
} from "../../../../actions/utl";

// external components
import WIFIConnectModal from "./modalConnectWIFI";

// actual compont
const WIFISSID = ({ getWIFISSIDNames, permissions, WIFIConnectModalOpen }) => {
  const intervalRef = useRef();
  const [tableData, setTableData] = useState([]);

  const wifiNames = useCallback(async () => {
    try {
      const names = await getWIFISSIDNames();

      if (names.length > 0) {
        let tableObj = [];

        names.map((name, index) => {
          return tableObj.push({
            ssid: name,
            key: index,
          });
        });
        setTableData(tableObj);
      }
    } catch (error) {
      setTableData([]);
    }
  }, [getWIFISSIDNames]);

  useEffect(() => {
    if (intervalRef.current === undefined) {
      intervalRef.current = setInterval(async () => {
        await wifiNames();
      }, 3000);
    }

    return () => {
      clearInterval(intervalRef.current);
      intervalRef.current = undefined;
    };
  }, [wifiNames]);

  const handleConnect = (record) => {
    WIFIConnectModalOpen(record);
  };

  const tableColumns = [
    {
      title: "WIFI Name",
      key: "ssid",
      dataIndex: "ssid",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          {permissions && permissions.write && (
            <Tooltip title="Connect to this WIFI network">
              <a href="#!" onClick={() => handleConnect(record)}>
                <ApiOutlined />
              </a>
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  return (
    <Fragment>
      <Typography>The list below updates every 3 to 4 seconds</Typography>
      <WIFIConnectModal />

      <Table
        bordered
        size={"small"}
        scroll={320}
        dataSource={tableData}
        columns={tableColumns}
        pagination={{
          defaultPageSize: 30,
          showSizeChanger: true,
          pageSizeOptions: ["30", "40", "50"],
        }}
      />
    </Fragment>
  );
};

WIFISSID.propTypes = {
  getWIFISSIDNames: PropTypes.func.isRequired,
  WIFIConnectModalOpen: PropTypes.func.isRequired,
  permissions: PropTypes.object,
};

const mapStateToProps = (state) => ({
  permissions: state.auth.permissions,
});

export default connect(mapStateToProps, {
  getWIFISSIDNames,
  WIFIConnectModalOpen,
})(WIFISSID);
