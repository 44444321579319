import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// antd components
import { Typography, Tabs } from "antd";

// External Components
import TabCloud from "./tabCloud";
import TabUpdates from "./tabUpdates";
import TabGeneral from "./tabGeneral";
import TabVPN from "./tabVPN";
import TabModem from "./tabModem";
import TabNetwork from "./tabNetwork";

// External Functions
import { getAllInterfaceIP, getVPNPublicKey } from "../../actions/utl";
import { loadSite } from "../../actions/site";

// Constants
const { Title } = Typography;
const { TabPane } = Tabs;

// Actual component
const Settings = ({
  permissions,
  getAllInterfaceIP,
  loadSite,
  getVPNPublicKey,
}) => {
  const HandleTabChange = async (Tab) => {
    if (Tab === "6") {
      getAllInterfaceIP();
    } else if (Tab === "3") {
      // general
      loadSite();
    } else if (Tab === "4") {
      //VPN Tab
      getVPNPublicKey();
    }
  };

  return (
    <Fragment>
      <Typography>
        <Title level={2}>Device Settings</Title>
      </Typography>
      <Tabs defaultActiveKey="3" onChange={HandleTabChange} size="small">
        <TabPane tab="General" key="3">
          <TabGeneral />
        </TabPane>
        <TabPane tab="Cloud Config" key="1">
          <TabCloud />
        </TabPane>
        {permissions && permissions.update && (
          <TabPane tab="Updates" key="2">
            <TabUpdates />
          </TabPane>
        )}
        <TabPane tab="Modem" key="5">
          <TabModem />
        </TabPane>
        <TabPane tab="Network" key="6">
          <TabNetwork />
        </TabPane>
        {permissions && permissions.write && (
          <TabPane tab="VPN" key="4">
            <TabVPN />
          </TabPane>
        )}
      </Tabs>
    </Fragment>
  );
};

Settings.propTypes = {
  permissions: PropTypes.object,
  getAllInterfaceIP: PropTypes.func.isRequired,
  getVPNPublicKey: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  permissions: state.auth.permissions,
});

export default connect(mapStateToProps, {
  getAllInterfaceIP,
  loadSite,
  getVPNPublicKey,
})(Settings);
