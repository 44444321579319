import { Layout } from "antd";
import { Fragment } from "react";

const { Footer } = Layout;

const Footers = () => {
  /*
    Version History:

    v1.10.76 beta:
    v1.10.75 beta:
      - Bug fixing, template editing the binary bit on alarms would not work

    v1.10.74 beta:
      - Bug fixing, saving the device on the inputs would break the bits

    v1.10.73 beta:
      - Added support for Water Tank

    v1.10.72 beta:
      - Added support for DPA ATS

    v1.10.71 beta:
      - Modem AP settings bug
  */
  return (
    <Fragment>
      <Footer style={{ textAlign: "center" }}>
        <strong>Version: 1.10.78 beta</strong> -{" "}
        <a
          href="https://www.kva.co.za"
          target="_blank"
          rel="noopener noreferrer"
        >
          Master Power Technologies ©2023
        </a>
      </Footer>
    </Fragment>
  );
};

export default Footers;
