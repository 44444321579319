import React, { Fragment } from "react";
import { connect } from "react-redux";

// Actual Component
const RightColumn = () => {
  return <Fragment></Fragment>;
};

RightColumn.propTypes = {};

const mapStateToProp = (state) => ({});

export default connect(mapStateToProp)(RightColumn);
