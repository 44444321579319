import React, { Fragment } from "react";

// antd
import { Row, Col } from "antd";

// External componets
import NetWIFI from "./Network/WIFI/WIFI";
import NetETHE from "./Network/Ethernet";

// actual Component
const TabNetwork = (props) => {
  return (
    <Fragment>
      <Row>
        <Col xs={24} lg={12}>
          <NetETHE />
        </Col>
        <Col xs={0} lg={1}></Col>
        <Col xs={24} lg={11}>
          <NetWIFI />
        </Col>
      </Row>
    </Fragment>
  );
};

export default TabNetwork;
