import axios from "axios";
import {
  TEMPLATE_FAIL,
  TEMPLATE_LOADING,
  TEMPLATE_UPDATED,
  TEMPLATE_MODAL_CLOSE,
  TEMPLATE_MODAL_OPEN,
  TEMPLATE_UPDATE_MAP,
  TEMPLATE_CREATE_DETAILS,
  MANUFACTURE_LOADING,
  MANUFACTURE_FAIL,
  MANUFACTURE_UPDATED,
  TEMPLATE_MODAL_BATCH_OPEN,
  TEMPLATE_MODAL_BATCH_CLOSE,
  TEMPLATE_MODAL_MODBUSMAPPING_OPEN,
  TEMPLATE_MODAL_MODBUSMAPPING_CLOSE,
  GROUPS_UPDATED,
  GROUPS_FAIL,
  GROUPS_LOADING,
  TEMPLATE_MODAL_ALARMEDIT_OPEN,
  TEMPLATE_MODAL_ALARMEDIT_CLOSE,
  TEMPLATE_UPDATE_ALARM,
  TEMPLATE_ALARMEDIT_UPDATE,
  TEMPLATE_MODAL_COPYALA_OPEN,
  TEMPLATE_MODAL_COPYALA_CLOSE,
  TEMPLATE_MODAL_CALCADD_CLOSE,
  TEMPLATE_MODAL_CALCADD_OPEN,
  TEMPLATE_UPDATE_CALC,
  BUSSES_UPDATE,
  BUSSES_LOADING,
} from "./types";
import { setAlert } from "./alert";
import setAuthToken from "../utils/setAuthToken";

// Update the list of templates from the server
export const updateTemplates =
  (fromWizard = false) =>
  async (dispatch) => {
    let isEmpty = true;

    // from the wizard we dont want to wait for this to finish
    if (fromWizard) isEmpty = await GetTemplatesFromController();

    if (isEmpty === true) {
      dispatch({
        type: TEMPLATE_LOADING,
      });

      if (localStorage.token) {
        setAuthToken(localStorage.token);
      }
      try {
        await axios.get("/api/devtemplates/update");

        const devTemplates = await GetTemplatesFromController();

        dispatch({
          type: TEMPLATE_UPDATED,
          payload: devTemplates,
        });
        dispatch(setAlert("Templates updated from server", "success"));
      } catch (error) {
        const errors = error.response.data.errors;

        if (errors) {
          errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
        }

        dispatch({
          type: TEMPLATE_FAIL,
        });
      }
    } else {
      dispatch({
        type: TEMPLATE_UPDATED,
        payload: isEmpty,
      });
    }
  };

export const saveNewTemplate = (data) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    await axios.post("/api/devtemplates", data);
    const devTemplates = await axios.get("/api/devtemplates");
    // await axios.post("http://localhost:5000/api/devtemplates", data);
    // const devTemplates = await axios.get("http://localhost:5000/api/devtemplates");

    dispatch({
      type: TEMPLATE_UPDATED,
      payload: devTemplates.data,
    });
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
    }

    dispatch({
      type: TEMPLATE_FAIL,
    });
  }
};

// Update the frontend list
export const fetchTemplates = () => async (dispatch) => {
  const devTemplates = await GetTemplatesFromController();

  if (devTemplates === true) {
    dispatch(setAlert("Template list empty", "error"));

    dispatch({
      type: TEMPLATE_FAIL,
    });
  } else {
    dispatch({
      type: TEMPLATE_UPDATED,
      payload: devTemplates,
    });
  }
};

const GetTemplatesFromController = () => {
  return new Promise(async (resolve, reject) => {
    try {
      if (localStorage.token) {
        setAuthToken(localStorage.token);
      }

      let devTemplates = await axios.get("/api/devtemplates");
      devTemplates = devTemplates.data;

      return resolve(devTemplates);
    } catch (error) {
      return resolve(true);
    }
  });
};

export const OpenAddressMappingModal = (record) => (dispatch) => {
  dispatch({
    type: TEMPLATE_MODAL_MODBUSMAPPING_OPEN,
    payload: record,
  });
};

export const CloseAddressMappingModal = () => (dispatch) => {
  dispatch({
    type: TEMPLATE_MODAL_MODBUSMAPPING_CLOSE,
  });
};

export const OpenModal = (isEdit) => (dispatch) => {
  dispatch({
    type: TEMPLATE_MODAL_OPEN,
    payload: isEdit ? isEdit : false,
  });
};

export const CloseModal = () => (dispatch) => {
  dispatch({
    type: TEMPLATE_MODAL_CLOSE,
  });
};

export const OpenBatchModal = () => (dispatch) => {
  dispatch({
    type: TEMPLATE_MODAL_BATCH_OPEN,
  });
};

export const CloseBatchModal = () => (dispatch) => {
  dispatch({
    type: TEMPLATE_MODAL_BATCH_CLOSE,
  });
};

export const fetchGroups = () => async (dispatch) => {
  dispatch({
    type: GROUPS_LOADING,
  });

  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const devGroups = await axios.get("/api/site/groups");

    dispatch({
      type: GROUPS_UPDATED,
      payload: devGroups.data,
    });
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
    }

    dispatch({
      type: GROUPS_FAIL,
    });
  }
};

// Fetch the busses
export const fetchBusses = () => async (dispatch) => {
  dispatch({
    type: BUSSES_UPDATE,
    payload: true,
  });

  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const devGroups = await axios.get("/api/site/buss");

    dispatch({
      type: BUSSES_LOADING,
      payload: devGroups.data,
    });
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
    }

    dispatch({
      type: BUSSES_UPDATE,
      payload: false,
    });
  }
};

export const createManufacture = (name) => async (dispatch) => {
  dispatch({
    type: MANUFACTURE_LOADING,
  });

  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const data = {
      name,
    };

    let devMaufactures = await axios.post("/api/devtemplates/devmanu", data);
    devMaufactures = await axios.get("/api/devtemplates/devmanu");
    // let devMaufactures = await axios.post("http://localhost:5000/api/devtemplates/devmanu", data);
    // devMaufactures = await axios.get("http://localhost:5000/api/devtemplates/devmanu");

    dispatch({
      type: MANUFACTURE_UPDATED,
      payload: devMaufactures.data,
    });
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
    }

    dispatch({
      type: MANUFACTURE_FAIL,
    });
  }
};

export const removerDeviceManufacture = (id) => async (dispatch) => {
  dispatch({
    type: MANUFACTURE_LOADING,
  });

  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    let devManufactures = await axios.delete("/api/devtemplates/devmanu/" + id);

    devManufactures = await axios.get("/api/devtemplates/devmanu");
    // let devManufactures = await axios.delete("http://localhost:5000/api/devtemplates/devmanu/" + id);

    // devManufactures = await axios.get("http://localhost:5000/api/devtemplates/devmanu");

    dispatch({
      type: MANUFACTURE_UPDATED,
      payload: devManufactures.data,
    });
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
    }

    dispatch({
      type: MANUFACTURE_FAIL,
    });
  }
};

export const createInitalTemplate = (template) => async (dispatch) => {
  dispatch({
    type: TEMPLATE_CREATE_DETAILS,
    payload: template,
  });
};

export const updateMapping = (index, data) => async (dispatch) => {
  const payload = {
    index,
    data,
  };

  dispatch({
    type: TEMPLATE_UPDATE_MAP,
    payload,
  });
};

export const updateTemplateCalc = (data) => async (dispatch) => {
  dispatch({
    type: TEMPLATE_UPDATE_CALC,
    payload: data,
  });
};

// Get Device manufactures
export const fetchDeviceManufactures = () => async (dispatch) => {
  dispatch({
    type: MANUFACTURE_LOADING,
  });

  const DeviceMaunfactures = await GetControllerManufactures();

  if (DeviceMaunfactures === true) {
    dispatch(setAlert("Manufactures List Empty", "error"));

    dispatch({
      type: MANUFACTURE_FAIL,
    });
  } else {
    dispatch({
      type: MANUFACTURE_UPDATED,
      payload: DeviceMaunfactures,
    });
  }
};

const GetControllerManufactures = () => {
  return new Promise(async (resolve, reject) => {
    try {
      if (localStorage.token) {
        setAuthToken(localStorage.token);
      }

      let DeviceMaunfactures = await axios.get("/api/devtemplates/devmanu");

      DeviceMaunfactures = DeviceMaunfactures.data;
      return resolve(DeviceMaunfactures);
    } catch (error) {
      return resolve(true);
    }
  });
};

// update the list of manufactures from the server
export const updateManufacturesList =
  (fromWizard = false) =>
  async (dispatch) => {
    let isEmpty = true;

    // from the wizard we dont want to wait for this to finish
    if (fromWizard) isEmpty = await GetControllerManufactures();

    if (isEmpty === true) {
      // Indicate loading
      dispatch({
        type: MANUFACTURE_LOADING,
      });

      try {
        // get Token
        if (localStorage.token) {
          setAuthToken(localStorage.token);
        }
        await axios.get("/api/devtemplates/devmanu/update");

        const DeviceMaunfactures = await GetControllerManufactures();

        dispatch({
          type: MANUFACTURE_UPDATED,
          payload: DeviceMaunfactures,
        });
      } catch (error) {
        const errors = error.response.data.errors;

        if (errors) {
          errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
        }

        dispatch({
          type: MANUFACTURE_FAIL,
        });
      }
    } else {
      dispatch({
        type: MANUFACTURE_UPDATED,
        payload: isEmpty,
      });
    }
  };

// Open alarms Modal
export const openAlarmEditModal = (itemToEdit) => (dispatch) => {
  // Indicate loading
  dispatch({
    type: TEMPLATE_MODAL_ALARMEDIT_OPEN,
    payload: itemToEdit,
  });
};

// Open alarms Modal
export const updateAlarmEditModal = (data) => (dispatch) => {
  // Indicate loading
  dispatch({
    type: TEMPLATE_ALARMEDIT_UPDATE,
    payload: data,
  });
};

// close alarms Modal
export const closeAlarmEditModal = () => (dispatch) => {
  // Indicate loading
  dispatch({
    type: TEMPLATE_MODAL_ALARMEDIT_CLOSE,
  });
};

// update the alarms Modal
export const updateAlarms = (data) => async (dispatch) => {
  dispatch({
    type: TEMPLATE_UPDATE_ALARM,
    payload: data,
  });
};

// Open alarm copy Modal
export const closeCopyAlarmModal = () => (dispatch) => {
  // Indicate loading
  dispatch({
    type: TEMPLATE_MODAL_COPYALA_CLOSE,
  });
};

// close alarm copy Modal
export const openCopyAlarmModal = (data) => (dispatch) => {
  // Indicate loading
  dispatch({
    type: TEMPLATE_MODAL_COPYALA_OPEN,
    payload: data,
  });
};

// Open calc add new Modal
export const closeCalcAddModal = () => (dispatch) => {
  // Indicate loading
  dispatch({
    type: TEMPLATE_MODAL_CALCADD_CLOSE,
  });
};

// close calc add new Modal
export const openCalcAddModal = (data) => (dispatch) => {
  // Indicate loading
  dispatch({
    type: TEMPLATE_MODAL_CALCADD_OPEN,
    payload: data,
  });
};
