import axios from "axios";
import { USERS_LOADING, USERS_UPDATED, USERS_FAIL } from "./types";
import { setAlert } from "./alert";
import setAuthToken from "../utils/setAuthToken";

// Update the list of users from the server to our local DB
export const updateUsers = () => async (dispatch) => {
  dispatch({
    type: USERS_LOADING,
  });

  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const usersList = await axios.get("/api/users/update");
    // const usersList = await axios.get("http://localhost:5000/api/users/update");

    dispatch({
      type: USERS_UPDATED,
      payload: usersList.data,
    });

    dispatch(setAlert("Users list updated from server", "success"));
  } catch (error) {
    console.error(error);
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
    }

    dispatch({
      type: USERS_FAIL,
    });
  }
};

// Update the users list
export const fetchUsers = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const usersList = await axios.get("/api/users");
    // const usersList = await axios.get("http://localhost:5000/api/users");

    dispatch({
      type: USERS_UPDATED,
      payload: usersList.data,
    });
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
    }

    dispatch({
      type: USERS_FAIL,
    });
  }
};
