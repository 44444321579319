import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Ant design components
import { Modal, Steps, Button, Tooltip } from "antd";
import { RightCircleOutlined, LeftCircleOutlined } from "@ant-design/icons";

// Components
import Spinner from "../layout/Spinner";
import TemplateDetails from "./newTemplateDetails";
import SNMPMapping from "./newTemplateSNMPMapping";
import ModbusMapping from "./newTemplateModbusMapping";
import AlarmsMapping from "./stepAlarm/newTemplateAlarms";
import ExtraCalcs from "./stepCalc/newTemplateCalcs";

// Functions
import {
  fetchDeviceManufactures,
  CloseModal,
  createInitalTemplate,
  saveNewTemplate,
} from "../../actions/templates";
import { getDeviceTypesDetailed } from "../../actions/deviceTypes";

// Constants
const { Step } = Steps;

const NewTemplateModal = ({
  fetchDeviceManufactures,
  deviceTypesLoading,
  getDeviceTypesDetailed,
  CloseModal,
  deviceManufacturesLoading,
  createInitalTemplate,
  deviceTemplate: { modalOpen },
  currentTemplate,
  saveNewTemplate,
  templateEdit,
}) => {
  const [current, setCurrent] = useState(0);
  const [confirmLoading, setConfirmLoading] = useState(false);

  // On Modal Load get certian items
  useEffect(() => {
    if (!templateEdit) {
      fetchDeviceManufactures();
      getDeviceTypesDetailed();
    }

    if (templateEdit) {
      setCurrent(1);

      setTimeout(() => {
        setCurrent(0);
      }, 100);
    }
  }, [fetchDeviceManufactures, getDeviceTypesDetailed, templateEdit]);

  // Move to the next tab
  const next = () => {
    // check to see if the template is not empty
    if (currentTemplate) {
      // bare minimum is the protocal and maunfacture, check to see it is there
      if (currentTemplate.maunfacture && currentTemplate.commProtocol) {
        // if it is modbus make up the maps
        if (currentTemplate.commProtocol === "Modbus") {
          // Make sure there is at the least 1 batch
          if (currentTemplate.Batchs) {
            if (currentTemplate.Batchs.length > 0) {
              setCurrent(current + 1);
            }
          }
        } else {
          setCurrent(current + 1);
        }
      }
    }
  };

  // Move back to a step in the wizard
  const prev = () => {
    setCurrent(current - 1);
  };

  // Steps list, these are all the steps in the process
  const steps = [
    {
      title: "Device Details",
      content: <TemplateDetails />,
    },
    {
      title: "Device Mapping",
      content:
        currentTemplate && currentTemplate.commProtocol === "SNMP" ? (
          <SNMPMapping />
        ) : (
          <ModbusMapping />
        ),
    },
    {
      title: "Extra Calculations",
      content: <ExtraCalcs />,
    },
    {
      title: "Alarms/States",
      content: <AlarmsMapping />,
    },
  ];

  const CloseModalSequence = () => {
    localStorage.removeItem("activeKey");
    createInitalTemplate(null);

    if (current === 0) {
      setCurrent(1);
    }

    setTimeout(() => {
      setCurrent(0);
      CloseModal();
    }, 100);
  };

  const SaveSequence = async () => {
    setConfirmLoading(true);

    let newBatchs = [];
    let newAlarms = [];

    // If it is modbus do some post processing, this is to remove the blanks on the mapping
    if (currentTemplate.commProtocol === "Modbus") {
      currentTemplate.Batchs.forEach((batch) => {
        let batchDetails = {};

        batchDetails.startAddress = batch.startAddress;
        batchDetails.key = batch.key;
        batchDetails.quantity = batch.quantity;
        batchDetails.function = batch.function;
        batchDetails.Map = [];

        batch.Map.forEach((mapItem) => {
          if (mapItem.name) {
            batchDetails.Map.push(mapItem);
          }
        });

        newBatchs.push(batchDetails);
      });

      currentTemplate.Batchs = newBatchs;
    }

    if (currentTemplate.Alarms) {
      currentTemplate.Alarms.forEach((alarm) => {
        let alarmDetails = {};

        // Only save the ones that have some details to them
        if (alarm.alarmMap) {
          alarmDetails.alarmMap = alarm.alarmMap;
          alarmDetails.name = alarm.name;
          if (alarm.childname) alarmDetails.childname = alarm.childname;
          if (alarm.enabled) alarmDetails.enabled = alarm.enabled;
          if (alarm.fixed) alarmDetails.fixed = alarm.fixed;

          if (currentTemplate.commProtocol === "SNMP" && alarm.childname) {
            if (alarm.dataType) alarmDetails.dataType = alarm.dataType;
          }

          newAlarms.push(alarmDetails);
        }
      });

      currentTemplate.Alarms = newAlarms;
    }

    await saveNewTemplate(currentTemplate);
    CloseModalSequence();
    setConfirmLoading(false);
  };

  const handleOk = () => {
    if (templateEdit) {
      SaveSequence();
    } else {
      if (current === steps.length - 1) {
        SaveSequence();
      }
    }
  };

  const handleCancel = () => {
    CloseModalSequence();
  };

  const handleOnChange = (ThisTab) => {
    if (templateEdit) {
      setCurrent(ThisTab);
    }
  };

  return (
    <Modal
      centered
      width={1200}
      title={templateEdit ? "Editing Template" : "Create a new device template"}
      visible={modalOpen}
      // confirmLoading={confirmLoading}
      // onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Tooltip title="Move down to the previous step">
          <Button
            style={{ marginRight: "10px" }}
            key="prevButton"
            type="primary"
            disabled={current === 0}
            onClick={() => prev()}
          >
            <LeftCircleOutlined />
            {current === 1 ? "Details" : current === 2 ? "Mapping" : "Calcs"}
          </Button>
        </Tooltip>,
        <Tooltip title="Move up to the next step">
          <Button
            style={{ marginRight: "20px" }}
            key="nextButton"
            type="primary"
            disabled={current === steps.length - 1 || confirmLoading}
            onClick={() => next()}
          >
            {current === 0 ? "Mapping" : current === 1 ? "Calcs" : "Alarms"}
            <RightCircleOutlined />
          </Button>
        </Tooltip>,
        <Tooltip title="Click to close and not save changes">
          <Button key="back" onClick={handleCancel} disabled={confirmLoading}>
            Cancel
          </Button>
        </Tooltip>,
        <Tooltip
          title={
            templateEdit
              ? "Click to update this template"
              : "Click to create this new template"
          }
        >
          <Button
            key="submit"
            type="primary"
            onClick={handleOk}
            loading={confirmLoading}
          >
            {templateEdit ? "Update" : "Create"}
          </Button>
        </Tooltip>,
      ]}
    >
      {deviceTypesLoading || deviceManufacturesLoading ? (
        <Spinner />
      ) : (
        <Fragment>
          <Steps current={current} onChange={handleOnChange} size="small">
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div className="steps-content">{steps[current].content}</div>
        </Fragment>
      )}
    </Modal>
  );
};

NewTemplateModal.propTypes = {
  fetchDeviceManufactures: PropTypes.func.isRequired,
  createInitalTemplate: PropTypes.func.isRequired,
  CloseModal: PropTypes.func.isRequired,
  saveNewTemplate: PropTypes.func.isRequired,
  getDeviceTypesDetailed: PropTypes.func.isRequired,
  deviceTemplate: PropTypes.object,
  currentTemplate: PropTypes.object,
  deviceTypesLoading: PropTypes.bool,
  deviceManufacturesLoading: PropTypes.bool,
  templateEdit: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  deviceTemplate: state.template,
  templateEdit: state.template.EditTemplate,
  deviceTypesLoading: state.template.typesLoading,
  currentTemplate: state.template.newTemplate,
  deviceManufacturesLoading: state.template.manufacturesLoading,
});

export default connect(mapStateToProps, {
  fetchDeviceManufactures,
  getDeviceTypesDetailed,
  CloseModal,
  saveNewTemplate,
  createInitalTemplate,
})(NewTemplateModal);
