import {
  DIS_DEVEDIT_UPDATE,
  DIS_DEVEDIT_UPDATE_END,
  DIS_MODAL_DEVEDIT_CLOSE,
  DIS_MODAL_DEVEDIT_OPEN,
} from "./types";

// Open the window to edit a device
export const ModalDeviceEditOpen = (device) => (dispatch) => {
  dispatch({
    type: DIS_MODAL_DEVEDIT_OPEN,
    payload: device,
  });
};

// Close the window to edit a device
export const ModalDeviceEditClose = (device) => (dispatch) => {
  dispatch({
    type: DIS_MODAL_DEVEDIT_CLOSE,
  });

  if (device) {
    dispatch({
      type: DIS_DEVEDIT_UPDATE,
      payload: device,
    });
  }
};

// tell the stack that the update of object is complete
export const DeviceUpdateComplete = () => (dispatch) => {
  dispatch({
    type: DIS_DEVEDIT_UPDATE_END,
  });
};
