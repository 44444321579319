export const stepOperations = [
  { name: "PI", value: "pi" },
  { name: "SIN", value: "sin( deg)" },
  { name: "ARCSIN", value: "asin( deg)" },
  { name: "Hyperbolic arcsin", value: "asinh( deg)" },
  { name: "Hyperbolic sine", value: "sinh( deg)" },
  { name: "COS", value: "cos(x deg)" },
  { name: "ARCCOS", value: "acos(x deg)" },
  { name: "Hyperbolic arccos", value: "acosh(x deg)" },
  { name: "hyperbolic cosine", value: "cosh(x deg)" },
  { name: "Inverse cotangent", value: "acot(x deg)" },
  { name: "TAN", value: "tan(x deg)" },
  { name: "Hyperbolic tangent", value: "tanh(x deg)" },
  { name: "Square Root", value: "sqrt()" },
  { name: "Round", value: "round()" },
  { name: "Floor", value: "floor()" },
  { name: "Log", value: "log(x,base)" },
  { name: "Log 10", value: "log10()" },
  { name: "Modulus", value: "mod(x,y)" },
  { name: "Absolute", value: "abs()" },
  { name: "Max", value: "max()" },
  { name: "Min", value: "min()" },
  { name: "Sum", value: "sum()" },
  { name: "Inverse", value: "inv()" },
  { name: "Mean/Average", value: "mean()" },
];
